import axios from "axios"
import { API_URL } from "../../../config"

// Http client instance used to use the backend API
export const BackendClient = axios.create({
	baseURL: API_URL,
	transformRequest: [
		function (data, headers) {
			headers!["Authorization"] = "Bearer " + globalThis.localStorage.getItem("authToken")
			return data
		},
	],
})
