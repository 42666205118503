import { NavLink } from "react-router-dom"

type NavLinkButtonProps = {
	to: string
	icon: string
	text: string
	onClick?: () => void
}
export default function NavLinkButton({ to, icon, text, onClick = () => undefined }: NavLinkButtonProps): JSX.Element {
	return (
		<NavLink
			exact
			to={to}
			onClick={onClick}
			data-cy={`${text}-nav`}
			className="btn btn-square text-sm"
			activeClassName="active"
			role="button">
			<span className="btn-inner--icon d-block">
				<i className={"fad fa-" + icon + " fa-2x"} />
			</span>
			<span className="btn-inner--icon d-block pt-2 slim-bar-no-text">{text}</span>
		</NavLink>
	)
}
