import { HiboutikProductEntity, ProductReturnForm } from "@audiowizard/common"
import { cloneDeep } from "lodash"
import { toast } from "react-toastify"
import API from "services/API"

export const generateReturnFormPdf = async (
	generateDocument: Promise<string>,
	returnFormId: number,
	data: ProductReturnForm,
	catalog: HiboutikProductEntity[] | undefined
): Promise<void> => {
	try {
		if (!catalog) {
			return
		}

		const dataCpy = cloneDeep(data)

		let warehouseId = 0
		let supplierId = 0

		for (const k in dataCpy.logisticProducts) {
			const key = k as unknown as number

			const product = data.logisticProducts![key]

			const productData = catalog.find((f: any) => +f.id === +product.productId!)
			const sizeData = productData?.sizeDetails.find((s: any) => +s.sizeId === +product.productSizeId!)

			//@ts-ignore
			dataCpy.logisticProducts[key] = {
				...dataCpy.logisticProducts[key],
				...productData,
				...sizeData,
			}

			if (dataCpy.logisticProducts[key].isCreditReceived) {
				dataCpy.logisticProducts[key].state = "Avoir reçu"
			} else if (dataCpy.logisticProducts[key].isCreditRequested) {
				dataCpy.logisticProducts[key].state = "Avoir demandé"
			} else if (dataCpy.logisticProducts[key].isReturned) {
				dataCpy.logisticProducts[key].state = "Retour dépôt"
			}

			if (supplierId === 0 && productData?.supplierId) {
				supplierId = productData?.supplierId
			}

			if (warehouseId === 0 && product?.logistic?.stockIdHiboutik) {
				warehouseId = product?.logistic?.stockIdHiboutik
			}
		}
		const supplierInfo = (await API.getHiboutik<any[]>("suppliers"))?.find(
			(s: any) => +s?.supplier_id === +supplierId
		)
		const laboratoryInfo = (await API.findAll("LABORATORIES_API", `?warehouseIdHiboutik=${warehouseId}`))?.[0]

		//@ts-ignore
		const stringifiedContent = await generateDocument({
			...dataCpy,
			supplier: supplierInfo,
			laboratory: laboratoryInfo,
		})

		const result = await API.update("PRODUCT_RETURN_FORM_API", returnFormId, { template: stringifiedContent })

		//@ts-ignore
		window.open(result?.data?.url, "_blank")
	} catch (error) {
		console.error(error)
		toast.error("Impossible de générer le document pdf")
	}
}
