import { DateInput } from "components/forms/DateInput"
import { AnonymousFunction } from "constants/types/anonymousFunction"
import AuthContext from "contexts/AuthContext"
import { useContext } from "react"
import { formatDateForDB } from "services/functions"

type FiltersProps = {
	filters: Record<string, any>
	setFilters: AnonymousFunction
}

export const Filters = ({ filters, setFilters }: FiltersProps): JSX.Element => {
	const { user } = useContext(AuthContext)

	return (
		<>
			<div className="row">
				<div className="col">
					<DateInput
						label="Date de création"
						allowClear
						onChange={(e) => {
							if (e == null) {
								const tmp = filters
								delete filters["createdAt[after]"]
								setFilters((old: any) => ({ ...old, ...tmp }))
								return
							}
							const value = formatDateForDB(e?.toDate() as Date)
							setFilters((o: any) => ({ ...o, "createdAt[after]": value }))
						}}
					/>
				</div>
				<div className="col">
					<label>Label</label>
					<input
						className="form-control"
						placeholder="Retour pour une raison évidente"
						value={filters.label ?? ""}
						onChange={(e) => {
							const label = e.target.value
							setFilters((o: any) => ({ ...o, label }))
						}}
					/>
				</div>
				<div className="col">
					<label>Par numéro de série</label>
					<input
						type="text"
						className="form-control"
						placeholder="AD-987BNE"
						value={filters["logisticProducts.productSerialNumber"] ?? ""}
						onChange={(evt) => {
							const value = evt.target.value
							setFilters((o: any) => ({ ...o, "logisticProducts.productSerialNumber": value }))
						}}
					/>
				</div>
				<div className="col">
					<label>Par laboratoire</label>

					<select
						onChange={(evt) => {
							const value = evt.target.value
							setFilters((old: any) => ({
								...old,
								"logisticProducts.logistic.stockIdHiboutik": `${value}`,
							}))
						}}
						value={filters.warehouseId}
						className="form-control ">
						{(user.laboratories ?? [])
							.filter((f) => f.warehouseIdHiboutik)
							.map((v, k) => (
								<option value={v.warehouseIdHiboutik} key={k}>
									{v.label}
								</option>
							))}
					</select>
				</div>
			</div>
		</>
	)
}
