import { useEffect } from "react"

function useCustomTitle<T extends unknown[]>(
	fn: string | ((dependencies: T) => string),
	dependencies: T = [] as any
): void {
	useEffect(() => {
		if (typeof fn === "function") document.title = fn([...dependencies] as T)
		else document.title = fn

		return () => {
			document.title = "AudioWizard"
		}
	}, [...dependencies])
}

export default useCustomTitle
