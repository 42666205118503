const GROUP_FLAG_USER = 2
const GROUP_FLAG_LABORATORY = 4
const GROUP_FLAG_INVERT = 8
const GROUP_FLAG_DATE = 16

const groupViewHandler = (viewSettings, setViewSettings, users, laboratories) => {
	const userGroup = [],
		laboratoryGroup = [],
		defaultGroup = [],
		groups = []

	if (viewSettings.grouping === 0x0) {
		groups.push({
			resourceName: "laboratoryId",
			key: 1,
		})
		defaultGroup.push({
			fieldName: "laboratoryId",
			title: "Laboratoire",
			instances: laboratories.map((v) => ({
				text: v.label,
				id: v.id,
				color: "purple",
			})),
			allowMultiple: true,
		})
	}

	if (viewSettings.grouping & GROUP_FLAG_LABORATORY) {
		groups.push({
			resourceName: "laboratoryId",
			key: 1,
		})
		laboratoryGroup.push({
			fieldName: "laboratoryId",
			title: "Laboratoire",
			instances: laboratories.map((v) => ({
				text: v.label,
				id: v.id,
				color: "purple",
			})),
			allowMultiple: true,
		})
	}

	if (viewSettings.grouping & GROUP_FLAG_USER) {
		groups.push({
			resourceName: "userId",
			key: 2,
		})
		userGroup.push({
			fieldName: "userId",
			title: "Utilisateur",
			instances: users.map((v) => ({
				text: `${v.firstName} ${v.lastName}`,
				id: v.id,
				color: "purple",
			})),
			allowMultiple: true,
		})
	}
	if (viewSettings.options & GROUP_FLAG_INVERT) {
		groups.sort((a, b) => {
			return b?.key - a?.key
		})
	}

	setViewSettings((old) => ({
		...old,
		options: viewSettings.options,
		grouping: viewSettings.grouping,
		primaryGroup: viewSettings.primaryGroup,
		groupsAvailable: groups,
		groupsResources: [...defaultGroup, ...laboratoryGroup, ...userGroup],
		// needRefresh: false,
	}))
}

export { groupViewHandler, GROUP_FLAG_LABORATORY, GROUP_FLAG_USER, GROUP_FLAG_INVERT, GROUP_FLAG_DATE }
