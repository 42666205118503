import { useContext, useState } from "react"

import AuthContext from "../../contexts/AuthContext"
import NavLinkButton from "./NavLinkButton"
import NavLinkButtonInGroup from "./NavLinkButtonInGroup"
import localStorage from "../../services/localStorage"
import { Collapse } from "reactstrap"

export default function LeftBar(): JSX.Element {
	const { user, patientUI, setPatient, uiDisplay } = useContext(AuthContext)
	const [collapseOpen, setCollapseOpen] = useState(false)

	const shouldBeHidden =
		!user.gdprAgreement || patientUI || uiDisplay?.missingMandate || uiDisplay?.onboarding === true

	if (shouldBeHidden) {
		return <div className="row nav-application clearfix pt-2 justify-content-center" />
	}

	return (
		<div>
			<div
				className="row nav-application clearfix pt-2 justify-content-center"
				id="leftBar-body"
				data-cy="leftbar">
				<NavLinkButton to="/" icon="tasks" text="Tâches" data-cy="nav-todo" />

				<NavLinkButton
					onClick={() => {
						localStorage.update("appcache", { firstVisit: true })
						setCollapseOpen(false)
						setPatient({ user: user["@id"] } as any)
					}}
					to="/premiere-visite/creation-patient"
					icon="user-plus"
					text="Nouveau patient"
					data-cy="nav-premiere-visite"
				/>

				<NavLinkButton to="/mes-patients" icon="user-md" text="Mes patients" data-cy="nav-mes-patients" />

				<NavLinkButton
					to="/mes-rendezvous"
					icon="calendar-alt"
					text="Rendez-vous"
					data-cy="nav-mes-rendezvous"
				/>

				<NavLinkButton
					onClick={() => setCollapseOpen(false)}
					to="/statistiques"
					icon="chart-area"
					text="Statistiques"
					data-cy="nav-statistiques"
				/>
				{user.company?.hiboutikAccount?.token && (
					<>
						<div
							data-target="#caisse-nav-group"
							className={
								collapseOpen
									? "btn btn-square text-sm leftbar-navgroup-active"
									: "btn btn-square text-sm leftbar-navgroup"
							}
							onMouseEnter={() => setCollapseOpen(true)}
							onClick={() => setCollapseOpen(!collapseOpen)}>
							<span>
								<i className="fad fa-shopping-cart fa-2x" aria-hidden="true" />
							</span>
							<span className="btn-inner--icon d-flex justify-content-center pt-2 slim-bar-no-text">
								Caisse
								<span style={{ paddingLeft: "5%" }}>
									<i className="fad fa-caret-right" aria-hidden="true" />
								</span>
							</span>
						</div>

						<Collapse isOpen={collapseOpen} style={{ backgroundColor: "#8492a6", width: "100%" }}>
							<NavLinkButtonInGroup
								to="/mon-compte/mes-ventes"
								icon="shopping-basket"
								text="Ventes"
								data-cy="nav-ventes"
							/>
							<NavLinkButtonInGroup to="/sav" icon="headset" text="SAV" data-cy="nav-bon-sav" />
							<NavLinkButtonInGroup
								to="/bon-de-livraison"
								icon="clipboard"
								text="Bon de livraison"
								data-cy="nav-bon-livraison"
							/>
							<NavLinkButtonInGroup to="/mon-stock/stock" icon="boxes" text="Stock" data-cy="stock" />
							<NavLinkButtonInGroup
								to="/inventaire"
								icon="tags"
								text="Inventaire"
								data-cy="nav-inventaire"
							/>
							<NavLinkButtonInGroup
								to="/transfert-stock"
								icon="coins"
								text="Transfert stock"
								data-cy="nav-transfert"
							/>
						</Collapse>
					</>
				)}
			</div>
		</div>
	)
}
