import { PatientEquipment } from "@audiowizard/common"
import {
	getSerialNumbersForProduct,
	isSerialnumberAlreadyListed,
	pushEquipmentData,
	pushLogisticProductData,
	pushProductData,
} from "./Utility"
// import { Flags } from "../Shared/Flags"
import dayjs from "dayjs"
import * as Types from "./Types"
import React from "react"

export const parseData = (
	databaseData: React.MutableRefObject<Types.DatabaseData>,
	filters: Types.Filters
): Record<string, any>[] => {
	try {
		let exportedData: Record<string, any>[] = []

		const stock = databaseData.current.products
		const availableSerialNumbers = databaseData.current.availableSerialNumber
		// const afterSale: AfterSale[] = databaseData.current.afterSale
		const patientEquipment: PatientEquipment[] = databaseData.current.equipments

		for (const product of stock) {
			if (!product?.stockAvailable?.length && !product.quantityAvailable) continue

			if (filters?.categoryId && filters?.categoryId !== +product?.categoryId) continue
			if (filters?.brandId && filters?.brandId !== +product?.brandId) continue
			if (filters?.supplierId && filters?.supplierId !== +product?.supplierId) continue

			const serialNumbers = getSerialNumbersForProduct(availableSerialNumbers, product.id)
			const tmpFinalData: Record<string, any>[] = []

			const tmpData: Record<string, any> = pushProductData(product)

			if (serialNumbers.length > 0) {
				tmpData.quantity = `${serialNumbers.length} avec s/n | ${product.quantityAvailable} total`
				for (const productWithSn of serialNumbers) {
					const logisticProduct = productWithSn?.logisticProduct

					const isDepotMissed =
						productWithSn?.logisticProduct?.depotDateEnd &&
						dayjs().startOf("day").isAfter(dayjs(productWithSn.logisticProduct.depotDateEnd).startOf("day"))

					if (filters?.depot === "NONE" && logisticProduct?.isDepot) {
						continue
					}
					if (filters?.depot === "ONLY_MISSED" && !isDepotMissed) {
						continue
					} else if (filters?.depot === "ONLY_DEPOSIT" && !logisticProduct?.isDepot) {
						continue
					}

					const tmpProduct: Record<string, any> = {
						...pushProductData({ ...product, ...productWithSn }),
						...pushEquipmentData(databaseData, product, productWithSn.patientEquipment),
						...pushLogisticProductData(productWithSn?.logisticProduct),
						sizeId: productWithSn.sizeId,
						sizeName: productWithSn.sizeName,
						serialNumber: productWithSn.serialNumber,
						quantity: null,
					}
					tmpFinalData.push(tmpProduct)
				}
			} else {
				tmpData.quantity = `${product.quantityAvailable} total`
			}

			for (const equipment of patientEquipment.filter((f) => f.productIdHiboutik === +product.id)) {
				if (isSerialnumberAlreadyListed(tmpFinalData, equipment)) continue

				if (filters?.depot === "ONLY_DEPOSIT" || ["ONLY_STOCK", "NONE"].includes(filters?.equipment)) {
					continue
				}

				const tmpProduct: Record<string, any> = {
					...pushProductData(product),
					...pushEquipmentData(databaseData, product, equipment),
					//@ts-ignore
					...pushLogisticProductData(equipment?.logisticProduct),

					sizeId: equipment?.sizeIdHiboutik,
				}

				if (equipment?.sizeIdHiboutik) {
					tmpProduct.sizeName =
						equipment?.productJsonHiboutik?.size_name ??
						equipment?.productJsonHiboutik?.product_size_name ??
						"[inconnu]"
				}

				tmpFinalData.push(tmpProduct)
			}
			tmpFinalData.unshift(tmpData)
			exportedData = exportedData.concat(tmpFinalData)
		}

		// Suppression des lignes vide en cas de vision unique des appareils en dépôts
		if (["ONLY_DEPOSIT", "ONLY_MISSED"].includes(filters?.depot)) {
			for (const index in exportedData) {
				if (exportedData[+index + 1]) {
					if (
						exportedData[index].productId !== exportedData[+index + 1].productId &&
						exportedData[index].quantity
					) {
						delete exportedData[index]
					}
				} else if (exportedData[index].quantity) {
					delete exportedData[index]
				}
			}
		}

		return exportedData
	} catch (error) {
		console.error(error)
		return []
	}
}
