import { useContext, useEffect, useState } from "react"
import * as React from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { Modal, ModalBody, ModalFooter, ModalHeader, Alert } from "reactstrap"
import FieldWithError from "../../components/forms/FieldWithError"
import AuthContext from "../../contexts/AuthContext"

type PatientUiToggleModalProps = {
	open: boolean
	onClose: () => void
}
export default function PatientUIToggleModal({ open, onClose }: PatientUiToggleModalProps): JSX.Element {
	const history = useHistory()

	const { patient, patientUI, setPatientUI } = useContext(AuthContext)
	const [password, setPassword] = useState("") // La phrase de confirmation sera stocké dans le localstorage avec la clé "patientUIPassword"
	const [error, setError] = useState("")

	// Reset le state
	useEffect(() => {
		setPassword("")
		setError("")
	}, [open])

	const disablePatientUI: React.FormEventHandler<HTMLFormElement> = (e): void => {
		e.preventDefault()

		const disable = (): void => {
			setPatientUI(false)
			window.localStorage.setItem("patientUI", "false")
			window.localStorage.removeItem("patientUIPassword")

			if (patient.id != null) {
				history.push(`/fiche-patient/${patient.id}`) // Redirige vers la fiche patient qui vient d'être créée
			} else {
				history.push("/")
			}

			onClose()
		}

		const expectedPassword = window.localStorage.getItem("patientUIPassword")
		const validExpectedPassword = expectedPassword != null && expectedPassword.length > 0

		// Anti-softlock si patientUiPassword est vide ou n'existe pas, on permet quand même la désactivation du mode patient pour ne pas bloquer un utilisatuer dans un état invalide
		if (!validExpectedPassword) {
			toast.warn("Phrase de confirmation de confirmation sauvegardé invalide.\n Désactivation du mode patient.")
			disable()
			return
		}

		if (password !== expectedPassword) {
			setError("Mauvaise phrase de confirmation")
			return
		}

		disable()
	}

	const activatePatientUI: React.FormEventHandler<HTMLFormElement> = (e): void => {
		e.preventDefault()

		setPatientUI(true)
		window.localStorage.setItem("patientUI", "true")
		window.localStorage.setItem("patientUIPassword", password)

		history.push("/salle-attente/consentement-rgpd")

		onClose()
	}

	return (
		<Modal isOpen={open} toggle={onClose} centered size="lg">
			<form onSubmit={patientUI ? disablePatientUI : activatePatientUI}>
				<ModalHeader>
					<div className="d-flex flex-row align-items-center justify-content-between">
						<h1 className="h5">{patientUI ? "Désactiver" : "Activer"} le mode patient ?</h1>

						<button
							type="button"
							className="icon-sm icon-danger rounded-circle border-0 mr-3 d-flex justify-content-center align-items-center"
							title="Fermer"
							onClick={onClose}>
							<span aria-hidden="true">×</span>
						</button>
					</div>
				</ModalHeader>
				<ModalBody className="text-center">
					<FieldWithError
						name="check-patient"
						error={error}
						type={patientUI ? "password" : "text"} // Cache le mot de passe pendant la désactivation
						label={`Veuillez entrer ${patientUI ? "la" : "une"} phrase de confirmation pour continuer`}
						placeholder={`Entrez ${patientUI ? "la" : "une"} phrase de confirmation`}
						helpText={
							patientUI
								? "C'est la phrase qu vous avez choisi avant d'activer le mode patient"
								: "La phrase de confirmation vous permettra de désactiver le mode patient"
						}
						autoComplete="off"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>

					{!patientUI && <Alert color="warning">Veuillez ne pas utiliser votre mot de passe.</Alert>}
				</ModalBody>
				<ModalFooter>
					<button type="submit" className="m-auto btn btn-outline-primary" disabled={password.length === 0}>
						{patientUI ? "Désactiver" : "Activer"} le mode patient
					</button>
				</ModalFooter>
			</form>
		</Modal>
	)
}
