import { SyncOutlined } from "@ant-design/icons"
import { HiboutikProductEntity, Laboratory } from "@audiowizard/common"
import axios from "axios"
import { INVENTORY_PRODUCTS_API } from "config"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import uuid from "react-uuid"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import API from "services/API"
import { extractAvailableSerialnumber, extractAvailableWithoutSerialnumber } from "../StockUtils"

type InventorySettingsType = {
	label: string
	type: "ALL" | "ONLY_DEPOT" | "NO_DEPOT"
	laboratory: Laboratory
}

const ModalCreateNewInventory = ({
	isOpen,
	setOpen,
}: {
	isOpen: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}): JSX.Element => {
	const { user, laboratory } = useContext(AuthContext)
	const history = useHistory()
	const [newInventorySettings, setNewInventorySettings] = useState<InventorySettingsType>({
		label: "",
		type: "ALL",
		laboratory: laboratory,
	})
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		setNewInventorySettings({
			label: `Inventaire du ${dayjs().format("DD/MM/YYYY")}`,
			type: "ALL",
			laboratory: laboratory,
		})
	}, [isOpen])

	const createNewInventory = async (): Promise<void> => {
		try {
			setIsLoading(true)
			const result =
				(await API.findAll<HiboutikProductEntity[]>(
					"STOCK_AVAILABLE_API",
					`?warehouseId=${newInventorySettings.laboratory?.warehouseIdHiboutik}`
				)) ?? []

			const availableSerialNumber = extractAvailableSerialnumber(result).map((m) => ({
				...m,
				quantityAvailable: 1,
			}))

			const availableWithoutSn = extractAvailableWithoutSerialnumber(result).map((m) => ({
				...m,
				quantityAvailable: m.quantity,
			}))

			const productWithoutSn = result.filter((f: any) => !f?.stockAvailable?.length)

			let stockAvailable: Record<string, any>[] = [
				...availableSerialNumber,
				...productWithoutSn,
				...availableWithoutSn,
			]

			stockAvailable = stockAvailable
				.filter(
					(s: Record<string, any>) =>
						s.stockManagement &&
						(newInventorySettings.type === "ONLY_DEPOT" ? s.logisticProduct?.isDepot : true) &&
						(newInventorySettings.type === "NO_DEPOT" ? !s.logisticProduct?.isDepot : true)
				)
				.map((m) => ({ ...m, key: uuid(), inventoryState: "NONE" }))

			if (!stockAvailable.length) {
				toast.warn("Pas de stock disponible pour cette combinaison de paramètre")
				setIsLoading(false)
				return
			}

			const createdInventory: any = (
				await API.create("INVENTORY_API", {
					label: newInventorySettings.label,
					validated: false,
					type: newInventorySettings.type,
					laboratory: newInventorySettings.laboratory["@id"],
				})
			)?.data

			await axios.post(`${INVENTORY_PRODUCTS_API}/batch_add`, {
				inventory: createdInventory["@id"],
				products: stockAvailable.map((m) => ({
					originalQuantity: m.quantityAvailable,
					productId: m.id,
					productSizeId: m.sizeId,
					productSerialNumber: m.serialNumber ?? null,
					logisticProduct: m?.logisticProduct?.["@id"],
				})),
			})

			history.push("/mon-stock/inventaire/" + createdInventory.id)
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<>
			<Modal isOpen={isOpen} centered size="lg" className="modal-loading">
				<ModalHeader>Création d'un nouvel inventaire</ModalHeader>
				<ModalBody>
					<div className="cardtabs-subtitle">Label</div>
					<input
						type="text"
						className="form-control"
						value={newInventorySettings!.label}
						onChange={(evt) => {
							const value = evt.target.value
							//@ts-ignore
							setNewInventorySettings((old) => ({ ...old, label: value }))
						}}
					/>

					<div className="cardtabs-subtitle">Type</div>
					<select
						className="form-control"
						value={newInventorySettings!.type ?? "ALL"}
						onChange={(event) => {
							const value = event?.target.value
							//@ts-ignore
							setNewInventorySettings((old) => ({ ...old, type: value }))
						}}>
						<option value="ALL">Tout afficher</option>
						<option value="NO_DEPOT">Sans les dépôts</option>
						<option value="ONLY_DEPOT">Uniquement les dépôts</option>
					</select>

					<div className="cardtabs-subtitle">Laboratoire</div>
					<select
						className="form-control"
						value={newInventorySettings!.laboratory.id}
						onChange={(event) => {
							const value = event?.target.value
							//@ts-ignore
							setNewInventorySettings((old) => ({
								...old,
								//@ts-ignore
								laboratory: user.laboratories?.find((l) => +l?.warehouseIdHiboutik === +value),
							}))
						}}>
						{user.laboratories
							?.filter((l) => l.warehouseIdHiboutik)
							.map((lab) => (
								<option value={lab.warehouseIdHiboutik} key={lab.warehouseIdHiboutik}>
									{lab.label}
								</option>
							))}
					</select>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-outline-danger"
						onClick={() => setOpen(false)}
						disabled={isLoading}>
						Annuler
					</button>
					<button
						type="button"
						className="btn btn-primary"
						style={{ minWidth: "256px" }}
						onClick={() => createNewInventory()}
						disabled={isLoading}>
						{isLoading ? <SyncOutlined spin /> : "Nouvel inventaire"}
					</button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default ModalCreateNewInventory
