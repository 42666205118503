/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import API from "../../../services/API"
import { toast } from "react-toastify"

const itemsRelanceReussie = [
	{
		id: 1,
		placeholder: "Produit pas cher",
		reason: "PAS CHER",
	},
	{
		id: 2,
		placeholder: "Bon accueil",
		reason: "BON ACCUEIL",
	},
	{
		id: 3,
		placeholder: "Meilleur service",
		reason: "SERVICE",
	},
]

const itemsRelanceNonReussie = [
	{
		id: 1,
		placeholder: "Trop cher",
		reason: "TROP CHER",
	},
	{
		id: 2,
		placeholder: "Pas intéressant",
		reason: "PAS INTERESSANT",
	},
	{
		id: 3,
		placeholder: "Mauvais accueil",
		reason: "MAUVAIS ACCUEIL",
	},
]

const items = [itemsRelanceNonReussie, itemsRelanceReussie]

const btnDefault = "btn btn-outline-info btn-block "

export default function ActionRelance({ patient, schedule, setButtonDisabled, setRelanceSaved }) {
	const [getState, setState] = useState(null)
	const [getReason, setReason] = useState(null)
	const [isUpdating, setUpdating] = useState(false)

	useEffect(() => {
		if (getReason != null && getState != null)
			(async function () {
				try {
					setUpdating(true)
					const reminder = schedule.reminder.id
					await API.update("REMINDERS_API", reminder, {
						reason: getReason,
						result: getState ? true : false,
					})
					await API.update("SCHEDULES_API", schedule.id, {
						state: "DONE",
						dateRealisation: new Date(),
					})
					if (getState === 1)
						setButtonDisabled({
							nouveau: false,
							relance: false,
						})
					if (getState === 0)
						setButtonDisabled({
							nouveau: true,
							relance: false,
						})
					setUpdating(false)
					setRelanceSaved(true)
					//history.push("/relance/patients")
				} catch (e) {
					setReason(null)
					setState(null)
					setButtonDisabled({
						nouveau: true,
						relance: true,
					})
					toast.error("Une erreur est survenue, veuillez réessayer")
					console.error(e)
				}
			})()
	}, [getReason])

	return (
		<>
			<div className="form-group">
				<h5>
					Comment s'est passée la relance de {patient.lastName} {patient.firstName} ?
				</h5>
				<div id="raison_relance" className="btn-group btn-block">
					<button type="button" className={btnDefault + (getState && " active")} onClick={() => setState(1)}>
						Réussie
					</button>
					<button
						type="button"
						className={btnDefault + (getState === 0 && " active")}
						onClick={() => setState(0)}>
						Non réussie
					</button>
				</div>
			</div>

			<div className="form-group">
				<div id="raison_relance" className="btn-group btn-block" style={{ minHeight: "56px" }}>
					{getState != null &&
						items[getState].map((item, key) => (
							<button
								key={key}
								type="button"
								className={btnDefault + (item.reason === getReason && " active")}
								name={item.reason}
								onClick={() => {
									if (!isUpdating) setReason(item.reason)
								}}
								disabled={isUpdating}>
								{isUpdating && (
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									/>
								)}
								{!isUpdating && item.placeholder}
							</button>
						))}
				</div>
			</div>
		</>
	)
}
