import { Override } from "@audiowizard/common"
import cx from "classnames"
import { ComponentPropsWithoutRef, useMemo } from "react"
import { IMaskMixin } from "react-imask"

/** Calculate the last 2 digits (control key) of a french Social Security Number based on the first 13 digits. */
export function calculateSsnKey(ssn: string): string {
	const ssnNumber = Number(
		ssn
			.substring(0, 13)
			// Handle Corse exception: 2A -> 19, 2B -> 18
			.replace("2A", "19")
			.replace("2B", "18")
			// In case accidental A/B without 2 before it. To prevent `NaN` key
			.replace(/[AB]/g, "0")
	)
	return (97 - (ssnNumber % 97)).toString().padStart(2, "0")
}

type MaskedInputProps = ComponentPropsWithoutRef<"input">
const MaskedInput = IMaskMixin<MaskedInputProps, HTMLInputElement>(({ inputRef, ...props }) => (
	<input {...props} ref={inputRef} />
))

type SsnInputOnlyProps = Override<
	Omit<ComponentPropsWithoutRef<typeof MaskedInput>, "mask" | "onAccept" | "onComplete">,
	{
		hasError?: boolean

		value: string
		onChange: (value: string) => void
	}
>
/** French Social Security Number input (Without form group, label and error) */
export function SsnInputOnly({
	className = "form-control",
	placeholderChar = "_",
	hasError = false,

	value,
	onChange,
	...props
}: SsnInputOnlyProps): JSX.Element {
	const mask = useMemo(() => {
		const maskBase = "0 00 00 0D 000 000" // See prop `definitions` on `<MaskedInput>` for `D` pattern
		// Autocomplete last 2 digits if we can calculate it, otherwise leave placeholder chars
		const last2Char = (value?.length >= 13 ? calculateSsnKey(value) : placeholderChar.repeat(2))
			// Escape last 2 chars from pattern
			.split("")
			.map((char) => `\\${char}`)
			.join("")

		return `${maskBase} ${last2Char}`
	}, [value, placeholderChar])

	const handleChange = (newValue?: string): void => {
		if (newValue == null) return

		if (newValue.length >= 13) {
			// Gotta re-add the last 2 digits, as they are part of the pattern and not in the unmasked value
			onChange(newValue + calculateSsnKey(newValue))
		} else {
			onChange(newValue)
		}
	}

	return (
		<MaskedInput
			className={cx(className, { "is-invalid": hasError })}
			type="text"
			mask={mask}
			definitions={{ D: /[\dAB]/ }} // For departement, 2nd digit can be A/B for Corse
			lazy={false}
			placeholderChar={placeholderChar}
			unmask
			value={value}
			onAccept={handleChange}
			{...props}
		/>
	)
}

type SsnInputProps = SsnInputOnlyProps & {
	label: string

	groupClassName?: string
	labelClassName?: string

	error?: string
}
/** French Social Security Number input (With label, form-group and error) */
export function SsnInput({
	id,
	name,
	label,
	groupClassName = "",
	labelClassName,
	error,
	...props
}: SsnInputProps): JSX.Element {
	const inputId = id ?? name
	return (
		<div className={`form-group ${groupClassName}`}>
			<label htmlFor={inputId} className={labelClassName}>
				{label}
			</label>

			<SsnInputOnly id={inputId} name={name} hasError={error != null && error.length > 0} {...props} />

			{error ? (
				<small className="invalid-feedback">{error}</small>
			) : (
				<small className="form-text text-muted">
					La clé de contrôle (2 derniers chiffres) sera auto-complété.
				</small>
			)}
		</div>
	)
}
