const events: string[] = [
	"schedule",
	// "sale",
	// "inventory_order",
	// "stock",
]

const socketDispatcherOff = (socket: { off: (ev: string) => void }): void => {
	for (const event of events) {
		try {
			socket.off(event)
			console.info(`[SOCKET] [EVENT] [${event}] Unsubscribing.`)
		} catch (error) {
			console.error(error)
		}
	}
}

const socketDispatcherOn = (socket: { on: (ev: string, fn: (d: any) => void) => void }): void => {
	const socketOnHelper = (eventName: string): void => {
		try {
			console.info(`[SOCKET] [EVENT] [${eventName}] Subscribing.`)
			socket.on(eventName, (d: any) => {
				console.debug(`[EVENT] [${eventName}] Received.`)
				const event = new CustomEvent(eventName, {
					detail: d,
				})
				window.dispatchEvent(event)
			})
		} catch (error) {
			console.error(error)
		}
	}
	for (const event of events) {
		socketOnHelper(event)
	}
}

export { socketDispatcherOff, socketDispatcherOn }
