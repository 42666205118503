// ----------------------------------------------
// TEMPLATE FOR : pages/vente/billing/Facturation.jsx
// ----------------------------------------------

import ReactDOMServer from "react-dom/server"
import moment from "moment"
import { parsed } from "services/functions"
import { Status as InvoiceStatus } from "constants/invoice/status"
import { LocaleNamespace } from "config/intl/helpers"
import { UseTranslationOptions } from "react-i18next"

function buildUnAcquittedPart(store: any, t: any): JSX.Element | undefined {
	if (store?.invoice?.data?.ventes?.paiement !== "DIV")
		return (
			<p>
				<strong>Type de règlement :</strong> {store?.invoice?.data?.ventes?.paiement}
			</p>
		)
	else if (Array.isArray(store?.invoice?.data?.ventes?.payment_details)) {
		return (
			<div style={{ display: "flex", flexDirection: "column", width: "40%" }}>
				{store?.invoice?.data?.ventes?.payment_details.map((detail: any, id: number) => (
					<div key={id} style={{ marginTop: "1rem" }}>
						<span style={{ fontSize: "0.9em" }}>
							<strong>{t(`${LocaleNamespace.Invoice}:invoice.sentences.payment type`)} :</strong>{" "}
							{detail?.payment_type}
						</span>
						<br />
						<span style={{ fontSize: "0.9em" }}>
							<strong>{t(`${LocaleNamespace.Invoice}:invoice.sentences.payment amount`)} :</strong>{" "}
							{detail?.payment_amount + " €"}{" "}
						</span>
						<br />
						<span style={{ fontSize: "0.9em" }}>
							{store?.invoice?.data?.status !== InvoiceStatus.Acquitted && (
								<strong>
									{detail?.payment_date === "0000-00-00"
										? `${t(`${LocaleNamespace.Invoice}:invoice.sentences.paid`)}.`
										: `${t(
												`${LocaleNamespace.Invoice}:invoice.sentences.expected collection date`
										  )} : ${detail?.payment_date && moment(detail?.payment_date).format("L")}`}
								</strong>
							)}
						</span>
					</div>
				))}
			</div>
		)
	}
}

export const InvoiceTemplate = (
	store: { [key: string]: any },
	t: (path: string, options?: UseTranslationOptions, prePath?: string) => string
): string => {
	return ReactDOMServer.renderToStaticMarkup(
		<>
			<style
				dangerouslySetInnerHTML={{
					__html: `
            @media print {
                .no-print { display: none !important; }
            }
            html {
                -webkit-print-color-adjust: exact;
              }
			table, th, td {
				padding: 5px 10px;
				border: 1px solid #afaeae;
				border-collapse: collapse;
				font-size: 0.9em;
			}
            `,
				}}
			/>
			{/* Header */}
			<>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div>
						{store?.context?.user?.company?.logo && (
							<p style={{ textAlign: "left" }}>
								<img
									src={store?.context?.user?.company?.logo}
									alt={t("billing.__editor.context-menu.Company logo on the sales invoice")}
									width="150"
									style={{ objectFit: "contain", height: "auto" }}
								/>
							</p>
						)}
					</div>
					<div style={{ textAlign: "right" }}>
						<h3>
							<strong>
								{t(`${LocaleNamespace.Common}:commonWords.invoice id`)}
								{store?.invoice?.data?.invoiceNumber}
							</strong>
						</h3>
						<span>
							({store?.invoice?.data?.invoiceId || "--"}-{store?.invoice?.data?.documentId || "--"})
						</span>
						<br />
						<span>
							<strong>{t(`${LocaleNamespace.Common}:commonWords.date`)} :</strong>{" "}
							{store?.invoice?.data?.ventes?.complete_date &&
								moment(store?.invoice?.data?.ventes?.complete_date).format("L")}
						</span>
					</div>
				</div>
				<br />
			</>
			{/* Laboratory and patient information */}
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<ul style={{ listStyle: "none", padding: "unset", margin: "0" }}>
					{store?.context.laboratory?.label && (
						<li>
							<strong> #(context.laboratory.label)</strong>
						</li>
					)}
					{store?.context.laboratory?.adress && <li>#(context.laboratory.adress)</li>}
					{store?.context.laboratory?.city && <li>#(context.laboratory.cpo) #(context.laboratory.city)</li>}
					{store?.context.laboratory?.email && (
						<li>
							<strong>Email :</strong> #(context.laboratory.email)
						</li>
					)}
					{store?.context.laboratory?.phone && (
						<li>
							<strong>Tél. :</strong> #(context.laboratory.phone)
						</li>
					)}
				</ul>
				<ul style={{ listStyle: "none", padding: "unset", textAlign: "right", margin: "0" }}>
					{store?.invoice?.data?.patient?.nom && (
						<li>
							<strong>#(invoice.data.patient.nom) #(invoice.data.patient.prenom)</strong>
						</li>
					)}
					{store?.invoice?.data?.patient?.adresse && <li>#(invoice.data.patient.adresse)</li>}
					{store?.invoice?.data?.patient?.code_postal && (
						<li>#(invoice.data.patient.code_postal) #(invoice.data.patient.ville)</li>
					)}
					{store?.invoice?.data?.patient?.secu && (
						<li>
							<strong>{t(`${LocaleNamespace.Common}:commonWords.insurance id`)} :</strong>{" "}
							#(invoice.data.patient.secu)
						</li>
					)}
				</ul>
			</div>
			<br />
			<br />

			{/* Summary of the sale */}
			<table contentEditable={false} className="reporting-template__variable" style={{ width: "100%" }}>
				<thead>
					<tr style={{ backgroundColor: "#ececec" }}>
						{[
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.model`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.serial number`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.quantity`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.unit price without taxes`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.VAT`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.unit discount without taxes`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.unit discount with taxes`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.amount without taxes`)}`,
							`${t(`${LocaleNamespace.Invoice}:invoice.sentences.amount with taxes`)}`,
						].map((title) => (
							<th key={title}>{title}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{store?.invoice?.data?.ventes?.achats?.map((achat: any) => {
						return (
							<tr key={achat?.serial_number}>
								<td style={{ textAlign: "center" }}>
									<strong>{achat?.product_model}</strong> {achat?.size_name ?? ""}
									{!!achat?.lpp && (
										<>
											<br /> lpp: {achat?.lpp}
										</>
									)}
								</td>
								<td style={{ textAlign: "center" }}>{achat?.serial_number}</td>
								<td style={{ textAlign: "center" }}>{achat?.quantity}</td>
								<td style={{ textAlign: "center" }}>{achat?.item_unit_net_wo_discount}</td>
								<td style={{ textAlign: "center" }}>{achat?.tax_value}%</td>
								<td style={{ textAlign: "center" }}>{achat?.item_unit_discount_net ?? "--"}</td>
								<td style={{ textAlign: "center" }}>{achat?.discount ?? "--"}</td>
								<td style={{ textAlign: "center" }}>{achat?.item_total_net}</td>
								<td style={{ textAlign: "center" }}>{achat?.item_total_gross}</td>
							</tr>
						)
					})}
				</tbody>
				{store?.invoice?.data?.ventes?.comments && (
					<>
						<p>
							<strong>{t(`${LocaleNamespace.Common}:commonWords.comment`)} : </strong>
							<br />
						</p>
						<pre>{store?.invoice?.data?.ventes?.comments}</pre>
					</>
				)}
			</table>
			<br />
			{/* Summary of the transaction */}
			<div
				contentEditable={false}
				className="reporting-template__variable"
				style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
				{(store?.invoice?.data?.shouldShowDetailedPaiements || store?.invoice?.data?.status !== "ACQUITTE") &&
					buildUnAcquittedPart(store, t)}

				{!!store?.invoice?.data.amount.totalAmountHT && (
					<table style={{ borderCollapse: "collapse", width: "60%", marginLeft: "1.5rem" }}>
						<thead>
							<tr style={{ backgroundColor: "#ececec" }}>
								{[
									"(*)",
									`${t(`${LocaleNamespace.Invoice}:invoice.sentences.amount without taxes`)}`,
									`${t(`${LocaleNamespace.Invoice}:invoice.sentences.amount with taxes`)}`,
									`${t(`${LocaleNamespace.Invoice}:invoice.sentences.VAT`)}`,
									`${t(`${LocaleNamespace.Invoice}:invoice.sentences.VAT amount`)}`,
								].map((title: string) => (
									<th key={title}>{title}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{store?.invoice?.data.amount.arrayAmount?.map((amount: any, index: number) => (
								<tr key={index}>
									<td style={{ textAlign: "center" }}>{amount[0]}</td>
									<td style={{ textAlign: "center" }}>{amount[1]}</td>
									<td style={{ textAlign: "center" }}>{amount[2]}</td>
									<td style={{ textAlign: "center" }}>{amount[3]}%</td>
									<td style={{ textAlign: "center" }}>{amount[4]}</td>
								</tr>
							))}
							<tr>
								<td>Total</td>
								<td style={{ textAlign: "center" }}>
									{parsed(store?.invoice?.data.amount.totalAmountHT)}
								</td>
								<td style={{ textAlign: "center" }}>
									{parsed(store?.invoice?.data.amount.totalAmountTTC)}
								</td>
								<td style={{ textAlign: "center" }}>-</td>
								<td style={{ textAlign: "center" }}>
									{parsed(store?.invoice?.data.amount.totalAmountTVA)}
								</td>
							</tr>
							{parsed(store?.invoice?.data.amount.totalAmountHT) !== 0 && (
								<>
									<tr>
										<td colSpan={2}>
											{t(`${LocaleNamespace.Invoice}:invoice.sentences.insurance value`)}
										</td>
										<td style={{ textAlign: "center" }}>
											{store?.invoice?.data.amount.partSecu &&
												parsed(store?.invoice?.data.amount.partSecu)}
										</td>
									</tr>
									<tr>
										<td colSpan={2}>
											{t(`${LocaleNamespace.Invoice}:invoice.sentences.private insurance value`)}
										</td>
										<td style={{ textAlign: "center" }}>
											{store?.invoice?.data.amount.partMut &&
												parsed(store?.invoice?.data.amount.partMut)}
										</td>
									</tr>
								</>
							)}
							<tr>
								<td colSpan={2}>
									{t(`${LocaleNamespace.Invoice}:invoice.sentences.remain value after refund`)}
								</td>
								<td style={{ textAlign: "center" }}>
									{parsed(store?.invoice?.data.amount.restCharge)}
								</td>
							</tr>
						</tbody>
					</table>
				)}
			</div>
			<br />
			{store?.invoice?.data?.status === "ACQUITTE" && (
				<div
					style={{
						padding: "0.7rem",
						fontSize: "0.9em",
						border: "1px solid black",
						margin: "auto",
						width: "30%",
						textAlign: "center",
					}}>
					{t(`${LocaleNamespace.Invoice}:invoice.sentences.paid invoice`)}
				</div>
			)}

			<br />
			{/* Footer  */}
			<footer>
				<span style={{ textAlign: "center", fontFamily: "Nunito, sans-serif", fontSize: "0.8em" }}>
					{store.context.laboratory.legalLabel}
					{store.context.laboratory.legalAdress && " - " + store.context.laboratory.legalAdress}
					{store.context.laboratory.legalCpo && ", " + store.context.laboratory.legalCpo}
					{store.context.laboratory.legalCity && ", " + store.context.laboratory.legalCity}
					<br />
					{store.context.laboratory.siret && "SIRET: " + store.context.laboratory.siret}
					{store.context.laboratory.tva && " - TVA: " + store.context.laboratory.tva}
					{store.context.laboratory.finess && " - FINESS: " + store.context.laboratory.finess}
					{" - CODE NAF: 4774Z"}
					{store.context.laboratory.capitalStock &&
						" - CAPITAL SOCIAL: " + store.context.laboratory.capitalStock + "€"}
				</span>
			</footer>
		</>
	)
}
