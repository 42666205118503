import { Select } from "antd"
import useEffectAsync from "components/Hooks/useEffectAsync"
import _, { cloneDeep } from "lodash"
import { useRef, useState, useEffect, Dispatch, SetStateAction } from "react"
import API_Hiboutik from "services/API_Hiboutik"

type AsyncProductSelectProps = {
	setSelectedProduct: Dispatch<SetStateAction<any>>
	getSuplDetail: boolean
	stockManagementOnly: boolean
}

export const AsyncProductSelect = ({
	setSelectedProduct,
	getSuplDetail,
	stockManagementOnly,
}: AsyncProductSelectProps): JSX.Element => {
	const timeoutSearch = useRef<NodeJS.Timeout | null>(null)
	const [searchValue, setSearchValue] = useState<string>("")
	const [search, setSearch] = useState<boolean>(false)
	const [searchResult, setSearchResult] = useState<Record<string, any>[]>([])
	const [selectedResult, setSelectedResult] = useState<number | undefined>(undefined)

	useEffectAsync(async () => {
		if (!search) return
		setSearch(false)
		try {
			// [MAYBE]
			// Replace with new route « /product?product_model= »

			const result = (await API_Hiboutik.searchProductByName(searchValue))?.filter(
				(product: any) => stockManagementOnly && product.product_stock_management
			)

			setSearchResult((old) => [..._.uniqBy([...old, ...result], "product_id")])
		} catch (error) {
			console.error(error)
		}
	}, [search])

	useEffect(() => {
		if (searchValue?.length < 3) return

		if (searchResult.filter((f) => f.product_model.toLowerCase().includes(searchValue.toLowerCase()))?.length > 10)
			return
		clearTimeout(timeoutSearch.current as NodeJS.Timeout)
		timeoutSearch.current = setTimeout(() => {
			setSearch(true)
		}, 200)
	}, [searchValue])

	useEffect(() => {
		try {
			const lastSearches = window.localStorage.getItem("LAST_SEARCH_PRODUCT") ?? ""
			if (!lastSearches) return
			const lastSearchesParsed = JSON.parse(atob(lastSearches)) ?? []

			lastSearchesParsed.sort((a: Record<string, any>, b: Record<string, any>) => b.time - a.time)

			setSearchResult([
				...lastSearchesParsed,
				{ product_model: "Effacer les recherches récentes...", product_id: -1, time: -1 },
			])
		} catch (error) {
			console.error(error)
		}
	}, [])

	const addLastSearches = (product_id: number, product_model: string): void => {
		try {
			if (!product_id || !product_model) return
			const lastSearches = window.localStorage.getItem("LAST_SEARCH_PRODUCT") ?? ""
			let lastSearchesParsed: any[] = []

			if (lastSearches) lastSearchesParsed = JSON.parse(atob(lastSearches))
			if (lastSearchesParsed.find((f: any) => f.product_id === product_id)) return

			lastSearchesParsed.sort((a: Record<string, any>, b: Record<string, any>) => b.time - a.time)
			lastSearchesParsed.push({ product_id, product_model, time: new Date().getTime() })
			window.localStorage.setItem("LAST_SEARCH_PRODUCT", btoa(JSON.stringify(lastSearchesParsed.slice(0, 10))))
		} catch (error) {
			console.error(error)
		}
	}

	const handleSelected = async (value: number, option: any): Promise<void> => {
		try {
			const searchResultCopy = cloneDeep(searchResult)
			if (value === -1) {
				window.localStorage.removeItem("LAST_SEARCH_PRODUCT")
				setSearchResult((old) => old.filter((f) => !f.time))
				setSelectedResult(undefined)
				setSearchValue("")
				return
			}
			addLastSearches(value, option?.label)
			setSelectedResult(value)
			let product = searchResultCopy.find((product) => +product.product_id === +value)
			if (!product) return

			if (product.product_size_type === undefined) {
				product = (await API_Hiboutik.getProductById(product.product_id))?.[0]
			}
			if (getSuplDetail) {
				const categories = await API_Hiboutik.getAllCategories()
				const category = categories.find((cat: any) => cat.category_id === product!.product_category)
				product = { ...product, ...category }
			}

			setSelectedProduct(product)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div>
			<Select
				showSearch
				allowClear
				placeholder="Audeo B50"
				className="w-100 form-control removeantd-class"
				size="large"
				value={selectedResult}
				filterOption={true}
				optionFilterProp="label"
				loading={search}
				options={searchResult.map((m) => ({ label: m.product_model, value: m.product_id }))}
				onSearch={setSearchValue}
				searchValue={searchValue ?? ""}
				onChange={handleSelected}
			/>
		</div>
	)
}
