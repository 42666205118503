import {
	AudioWizardService,
	DoctorPrescriber,
	EventExplanation,
	EventOtoscopy,
	EventProsthetic,
	EventWorkshop,
	Laboratory,
	LaboratoryAttendance,
	OrlPrescriber,
	Patient,
	PatientEquipment,
	PatientGene,
	PatientLookingFor,
	PatientMedical,
	PatientOrigin,
	PrescriberAdvice,
	Referral,
	Reminder,
	Schedule,
	User,
	Template,
} from "@audiowizard/common"
import { createContext, Dispatch, SetStateAction } from "react"

type ReactSetter<T> = Dispatch<SetStateAction<T>>

export type UiDisplay = {
	onboarding: boolean
	gdprAgreement: boolean
	missingMandate: boolean
	agendaModal: boolean
	scheduleModal: boolean
	topBarHiboutikTokenAlert: boolean
}

export type ContextPatient = Patient & {
	advices?: PrescriberAdvice[]
}

/**
 * Pas tout le context est typé ici. Il y a des propriétés passé dans le provider dans context.js qui n'était pas indiqué ici.
 */
export type AuthContextType = {
	isAuthenticated: boolean
	setIsAuthenticated: ReactSetter<boolean>

	user: User
	setUser: ReactSetter<User>

	services: AudioWizardService[]
	setServices: ReactSetter<AudioWizardService[]>

	subscriptions: AudioWizardService[]
	setSubscriptions: ReactSetter<AudioWizardService[]>

	laboratories: Laboratory[]
	setLaboratories: ReactSetter<Laboratory[]>

	laboratory: Laboratory
	setLaboratory: ReactSetter<Laboratory>

	laboratoryAttendances: LaboratoryAttendance[]
	setLaboratoryAttendances: ReactSetter<LaboratoryAttendance[]>

	loading: boolean
	setLoading: ReactSetter<boolean>

	productRanges: unknown
	setProductRanges: ReactSetter<unknown>

	patients: Patient[]
	setPatients: ReactSetter<Patient[]>

	patient: ContextPatient
	setPatient: ReactSetter<ContextPatient>

	advice: unknown
	setAdvice: ReactSetter<unknown>

	orls: OrlPrescriber[]
	setOrls: ReactSetter<OrlPrescriber[]>

	doctors: DoctorPrescriber[]
	setDoctors: ReactSetter<DoctorPrescriber[]>

	patientEquipment: PatientEquipment
	setPatientEquipment: ReactSetter<PatientEquipment>

	patientEquipments: PatientEquipment[]
	setPatientEquipments: ReactSetter<PatientEquipment[]>

	schedule: Schedule
	setSchedule: ReactSetter<Schedule>

	patientMedicals: PatientMedical[]
	setPatientMedicals: ReactSetter<PatientMedical[]>

	patientMedical: PatientMedical
	setPatientMedical: ReactSetter<PatientMedical>

	patientLookingFors: PatientLookingFor[]
	setPatientLookingFors: ReactSetter<PatientLookingFor[]>

	eventProsthetic: EventProsthetic
	setEventProsthetic: ReactSetter<EventProsthetic>

	eventProsthetics: EventProsthetic[]
	setEventProsthetics: ReactSetter<EventProsthetic[]>

	eventOtoscopies: EventOtoscopy[]
	setEventOtoscopies: ReactSetter<EventOtoscopy[]>

	eventOtoscopy: EventOtoscopy
	setEventOtoscopy: ReactSetter<EventOtoscopy>

	eventWorkshops: EventWorkshop[]
	setEventWorkshops: ReactSetter<EventWorkshop[]>

	eventExplanations: EventExplanation[]
	setEventExplanations: ReactSetter<EventExplanation[]>

	patientGenes: PatientGene[]
	setPatientGenes: ReactSetter<PatientGene[]>

	patientOrigin: PatientOrigin
	setPatientOrigin: ReactSetter<PatientOrigin>

	patientOrigins: PatientOrigin[]
	setPatientOrigins: ReactSetter<PatientOrigin[]>

	referral: Referral
	setReferral: ReactSetter<Referral>

	isBlockingContext: boolean
	setIsBlockingContext: ReactSetter<boolean>

	reminder: Reminder
	setReminder: ReactSetter<Reminder>

	notesGeneratedText: string
	setNotesGeneratedText: ReactSetter<string>

	notesVisiteText: string
	setNotesVisiteText: ReactSetter<string>

	patientUI: boolean
	setPatientUI: ReactSetter<boolean>

	mutuelle: unknown
	setMutuelle: ReactSetter<unknown>

	mutuelles: unknown[]
	setMutuelles: ReactSetter<unknown[]>

	uiDisplay: UiDisplay
	setUiDisplay: ReactSetter<UiDisplay>

	editing: boolean | string
	setEditing: ReactSetter<boolean | string>

	templates: Template[]
	setTemplates: ReactSetter<Template[]>
}

function errorSetter(): never {
	throw new Error("Missing AuthContext.Provider")
}

const AuthContext = createContext<AuthContextType>({
	isAuthenticated: false,
	setIsAuthenticated: errorSetter,
	user: {},
	setUser: errorSetter,
	services: {},
	setServices: errorSetter,
	subscriptions: {},
	setSubscriptions: errorSetter,
	laboratories: [],
	setLaboratories: errorSetter,
	laboratory: {},
	setLaboratory: errorSetter,
	laboratoryAttendances: [],
	setLaboratoryAttendances: errorSetter,
	loading: false,
	setLoading: errorSetter,
	productRanges: [],
	setProductRanges: errorSetter,
	patients: [],
	setPatients: errorSetter,
	patient: {},
	setPatient: errorSetter,
	advice: {},
	setAdvice: errorSetter,
	orls: [],
	setOrls: errorSetter,
	doctors: [],
	setDoctors: errorSetter,
	patientEquipment: {},
	setPatientEquipment: errorSetter,
	patientEquipments: [],
	setPatientEquipments: errorSetter,
	schedule: {},
	setSchedule: errorSetter,
	patientMedicals: [],
	setPatientMedicals: errorSetter,
	patientMedical: {},
	setPatientMedical: errorSetter,
	patientLookingFors: [],
	setPatientLookingFors: errorSetter,
	eventProsthetic: {},
	setEventProsthetic: errorSetter,
	eventProsthetics: [],
	setEventProsthetics: errorSetter,
	eventOtoscopies: {},
	setEventOtoscopies: errorSetter,
	eventOtoscopy: [],
	setEventOtoscopy: errorSetter,
	eventWorkshops: {},
	setEventWorkshops: errorSetter,
	eventExplanations: [],
	setEventExplanations: errorSetter,
	patientGenes: [],
	setPatientGenes: errorSetter,
	patientOrigin: [],
	referral: {},
	setReferral: errorSetter,
	setPatientOrigin: errorSetter,
	isBlockingContext: false,
	setIsBlockingContext: errorSetter,
	reminder: {},
	setReminder: errorSetter,
	notesGeneratedText: "",
	setNotesGeneratedText: errorSetter,
	notesVisiteText: "",
	setNotesVisiteText: errorSetter,
	patientUI: false,
	setPatientUI: errorSetter,
	mutuelle: {},
	setMutuelle: errorSetter,
	mutuelles: [],
	setMutuelles: errorSetter,
	uiDisplay: null,
	setUiDisplay: errorSetter,
	editing: false,
	setEditing: errorSetter,
	templates: [],
	setTemplates: errorSetter,
} as any)

export default AuthContext
