/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useCallback } from "react"
import { debounce } from "lodash"
import axios from "axios"
import PropTypes from "prop-types"
import DOMPurify from "dompurify"
import uniqid from "uniqid"

import { getTargetsInDocumentation } from "services/api/confluence/api"

const placeholder = {
	DOCUMENTATION: "Rechercher par titre, description, texte...",
}

const OmniSearchDocumentation = ({ id, documentationPageId, setDocumentaionPageId, setActivateSamePage, arrowNav }) => {
	const [searchQuery, setSearchQuery] = useState("")
	const [omnisearchVisible, setOmnisearchVisible] = useState(false)
	const [resultList, setResultList] = useState([])
	const [currentSelection, setCurrentSelection] = useState(arrowNav ? 0 : -1)
	const [loading, setLoading] = useState(false)

	async function setInitialResultList() {
		try {
			const confluenceData = await getTargetsInDocumentation("documentation", {})

			const documentations = confluenceData?.data?.["hydra:member"]
			// console.debug(confluenceData)
			setResultList(documentations)
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		const documentationSearchContainer = document.querySelector("#documentationSearch")

		const observer = new MutationObserver((mutationList) => {
			const mutation = mutationList.find((f) => f.type === "attributes" && f.attributeName === "class")
			setOmnisearchVisible(mutation?.target?.classList.contains("show"))
		})
		observer.observe(documentationSearchContainer, {
			childList: false,
			attributes: true,
			subtree: true,
		})

		setInitialResultList()

		return () => {
			observer.disconnect()
		}
	}, [])

	useEffect(() => {
		if (!omnisearchVisible) return
		// setSearchQuery("")
		setCurrentSelection(arrowNav ? 0 : -1)
	}, [omnisearchVisible, arrowNav])

	function updateSelection(newSelection) {
		setCurrentSelection(Math.max(0, Math.min(resultList.length - 1, Math.max(0, newSelection))))
	}

	/**
	 * Handle close
	 *
	 * Important! The technology that was defined for the search bar before the following implementation is
	 * based on the software library https://preview.webpixels.io/purpose-application-ui-kit/docs/index.html.
	 * This library manages the state of some components that can only be triggered by a user action.
	 * There is no event logic or an associated API to perform certain actions.
	 * Therefore, we have to simulate a click to close the modal. In the future, for advice,
	 * this kind of obscure behavior is to be avoided absolutely. You can find this lib in public/static/js
	 */
	function handleClose() {
		const modalOverlay = document.querySelector(
			'div[data-action="omnisearch-close"][data-target="#documentationSearch"]'
		)
		if (modalOverlay) modalOverlay.click()
		setCurrentSelection(arrowNav ? 0 : -1)
		// setSearchQuery("")
		// setResultList([])
	}

	function handleKeys(e) {
		switch (e.key) {
			case "ArrowDown": {
				const nextSelection = currentSelection + 1
				updateSelection(nextSelection)
				const nextDocumentationItemFocused = document.querySelector(
					`.omnisearch-documentation__documentation-item[tabindex="${nextSelection}"]`
				)
				if (nextDocumentationItemFocused) nextDocumentationItemFocused.focus()
				break
			}
			case "ArrowUp": {
				const prevSelection = currentSelection - 1
				updateSelection(prevSelection)
				const prevDocumentationItemFocused = document.querySelector(
					`.omnisearch-documentation__documentation-item[tabindex="${prevSelection}"]`
				)
				if (prevDocumentationItemFocused) prevDocumentationItemFocused.focus()
				break
			}
			case "Enter":
				if (resultList[currentSelection]?.id) {
					if (documentationPageId && documentationPageId === resultList[currentSelection]?.id)
						setActivateSamePage(true)
					else setDocumentaionPageId(resultList[currentSelection].id)
				}
				updateSelection(0)
				handleClose()
				break
			case "Escape": {
				handleClose()
				break
			}
			default:
				break
		}
	}

	const cancellationTokenSourceRef = useRef(null) // used for cancel the GET request
	const searchRequestIdRef = useRef(null)

	/**
	 * Cancel the search documentation request
	 */
	function cancelSearchDocumentationRequest() {
		cancellationTokenSourceRef.current.cancel()
	}

	/**
	 * Used to set the Axios Cancellation Token Reference
	 */
	function setAxiosCancellationTokenRef() {
		cancellationTokenSourceRef.current = axios.CancelToken.source()
	}

	const searchDocumentation = useCallback(
		debounce(async (value) => {
			setLoading(true)

			if (searchRequestIdRef.current) {
				cancelSearchDocumentationRequest()
			}

			const requestId = uniqid() // attach a request id (prevent multiple requests)
			searchRequestIdRef.current = requestId

			setAxiosCancellationTokenRef()

			try {
				const confluenceData = await getTargetsInDocumentation(value, {
					cancelToken: cancellationTokenSourceRef.current.token,
				})

				if (searchRequestIdRef.current && searchRequestIdRef.current !== requestId) return // stop if the request id isn't the same

				setCurrentSelection(arrowNav ? 0 : -1)
				const documentations = confluenceData?.data?.["hydra:member"]
				// console.debug(confluenceData)
				setResultList(documentations)
			} catch (e) {
				console.error(e)
			} finally {
				if (searchRequestIdRef.current && searchRequestIdRef.current !== requestId) return // stop if the request id isn't the same
				setLoading(false)
			}
		}, 600),
		[]
	)

	function handleSearch(event) {
		const { value } = event?.target
		setSearchQuery(value)
		setLoading(false)

		if (value.length < 3) {
			// setResultList([])
			return
		}

		searchDocumentation(value)
	}

	function resultDisplay(page, key) {
		return (
			<button
				type="button"
				className={`btn p-0 doc-page-btn ${key === currentSelection ? "selected" : ""}`}
				data-target={"#" + id}
				onClick={() => {
					if (documentationPageId && documentationPageId === page.id) setActivateSamePage(true)
					else setDocumentaionPageId(page.id)

					handleClose()
				}}>
				<div className="ominisearch-item">
					<h5>
						<strong className="item-title">
							<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(page.title) }} />
						</strong>
					</h5>
					<div className="item-text">
						<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(page.body) }} />
					</div>
				</div>
			</button>
		)
	}

	return (
		<div id={id} className="omnisearch" onKeyDown={arrowNav ? handleKeys : undefined}>
			<div className="container">
				<form className="omnisearch-form">
					<div className="form-group">
						<div className="input-group input-group-merge">
							<div className="input-group-append">
								<span className="input-group-text doc-search">
									<i className="fad fa-search fa-1x" />
								</span>
							</div>
							<input
								value={searchQuery}
								onChange={handleSearch}
								type="text"
								name="targetText"
								className="form-control"
								placeholder={placeholder?.DOCUMENTATION || "Rechercher..."}
								autoComplete={"off"}
							/>
						</div>
					</div>
				</form>

				<div className="omnisearch-suggestions" id="omnisearch-suggestions">
					<div className="row">
						<div className="col-sm-12">
							<ul className="list-unstyled mb-0" style={{ minHeight: "384px", maxHeight: "45vh" }}>
								{loading ? (
									<div className="loading-documentation">
										<div className="overlay-loading-aw">
											<div className="overlay-loading-logo" />
											<div className="overlay-loading-text">Recherche dans le grimoire...</div>
										</div>
									</div>
								) : (
									resultList?.map((page, index) => (
										<li
											key={page.id}
											tabIndex={index}
											className={"omnisearch-documentation__documentation-item"}>
											{resultDisplay(page, index)}
										</li>
									))
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

OmniSearchDocumentation.propTypes = {
	arrowNav: PropTypes.bool,
	id: PropTypes.string.isRequired,
	setDocumentationPageId: PropTypes.func,
}

export default OmniSearchDocumentation
