const ViewSettings = ({ viewSettings, setViewSettings }) => {
	return (
		<div className="row mt-2 mb-2">
			<div className="btn-group w-100" role="group">
				<button
					type="button"
					onClick={() => setViewSettings((old) => ({ ...old, cellDuration: 15 }))}
					className={
						"btn btn-sm " + (viewSettings.cellDuration === 15 ? "btn-primary" : "btn-outline-primary")
					}>
					15m
				</button>
				<button
					type="button"
					onClick={() => setViewSettings((old) => ({ ...old, cellDuration: 20 }))}
					className={
						"btn btn-sm " + (viewSettings.cellDuration === 20 ? "btn-primary" : "btn-outline-primary")
					}>
					20m
				</button>
				<button
					type="button"
					onClick={() => setViewSettings((old) => ({ ...old, cellDuration: 30 }))}
					className={
						"btn btn-sm " + (viewSettings.cellDuration === 30 ? "btn-primary" : "btn-outline-primary")
					}>
					30m
				</button>
				<button
					type="button"
					onClick={() => setViewSettings((old) => ({ ...old, cellDuration: 60 }))}
					className={
						"btn btn-sm " + (viewSettings.cellDuration === 60 ? "btn-primary" : "btn-outline-primary")
					}>
					1h
				</button>
			</div>
			<div className="btn-group w-100 mt-2" role="group">
				<button
					type="button"
					onClick={() => setViewSettings((old) => ({ ...old, viewMode: "Week" }))}
					className={
						"btn btn-sm " + (viewSettings.viewMode === "Week" ? "btn-primary" : "btn-outline-primary")
					}>
					semaine
				</button>
				<button
					type="button"
					onClick={() => setViewSettings((old) => ({ ...old, viewMode: "Month" }))}
					className={
						"btn btn-sm " + (viewSettings.viewMode === "Month" ? "btn-primary" : "btn-outline-primary")
					}>
					mois
				</button>
			</div>
		</div>
	)
}

export default ViewSettings
