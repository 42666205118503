import ReactDOM from "react-dom"
import EntryPoint from "./components/containers/EntryPoint/EntryPoint"
import * as serviceWorker from "./serviceWorker"
import _ from "lodash"
import axios from "axios"
import "config/intl" // Internationalization w/ i18next library
import "./assets/css/antd.css"
import "./assets/scss/main.scss"
import "./assets/css/purpose.css"
// import { get as keyvalGet, set as keyvalSet } from "idb-keyval"
import CacheSystem from "./services/CacheSystem/Cache.ts"
import RealtimeNotificationSystem from "./services/RealtimeSystem/Interceptors"
import { notification } from "antd"
import preval from "preval.macro"
import { SOCKET_REALM, SOCKET_RNS_IP } from "./config"

const BUILD_DATE = preval`module.exports = new Date();`
window.BUILD_DATE = BUILD_DATE

let socket = null,
	socket_rns = null

const options = {
	reconnection: true,
	reconnectionDelay: 5000,
	reconnectionDelayMax: 15000,
	reconnectionAttempts: Infinity,
	secure: true,
}

if (SOCKET_REALM === "local") {
	options.reconnection = false
}

socket_rns = global.io(SOCKET_RNS_IP, { ...options })

socket_rns.on("disconnect", () => {
	notification.error({
		message: "Connexion perdue",
		description: "La connexion au service de notification a été perdu",
		//top: 80,
		duration: 3,
		placement: "bottom",
	})
})

function filterObjectKey(object, keysToKeep) {
	for (const key of Object.keys(object)) {
		try {
			if (![...keysToKeep].includes(key)) object[key] = "HIDDEN"
		} catch (e) {}
	}
}

// temporaly disabled sentry
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function deleteUselessData(data, url) {
	let cpy = null

	if (typeof data === "string") {
		try {
			cpy = JSON.parse(data)
		} catch (e) {
			return data
		}
	} else cpy = await _.cloneDeep(data)

	if (!cpy) return data

	try {
		let uri = new URL(url)
		if (uri.pathname === "/patients" || uri.pathname.includes("/patients/")) {
			if (cpy["hydra:member"]) {
				for (const e in cpy["hydra:member"]) {
					filterObjectKey(cpy["hydra:member"][e], ["user", "id", "numero"])
				}
			} else filterObjectKey(cpy, ["user", "id", "numero", "violations"])
		}
		if (cpy?.documents) cpy.documents = "TRUNCATED"
		if (uri.pathname === "/users" && cpy["hydra:member"] && cpy["hydra:member"][0])
			cpy["hydra:member"][0].documents = "TRUNCATED"
		if (cpy?.password) cpy.password = "HIDDEN"
	} catch (e) {
		return data
	}
	return cpy
}

const axiosErrorHandler = async (error) => {
	try {
		// Temporarily disable Sentry
		// const request = await deleteUselessData(error?.config?.data, error?.config?.url)
		// const response = await deleteUselessData(error?.response?.data, error?.config?.url)
		// error.message = `${error?.config?.method?.toUpperCase()} ${error?.response?.status} ${error?.config?.url}`
		// window.Sentry.captureException(error, (scope) => {
		// 	scope.setExtra("method", error?.config?.method)
		// 	scope.setExtra("status", error?.response?.status)
		// 	let base64Response,
		// 		base64Request = ""
		// 	try {
		// 		base64Response = btoa(JSON.stringify(response))
		// 	} catch (_void) {
		// 		base64Response = btoa(response)
		// 	}
		// 	try {
		// 		base64Request = btoa(JSON.stringify(request))
		// 	} catch (_void) {
		// 		base64Request = btoa(request)
		// 	}
		// 	scope.setExtra("response", base64Response)
		// 	scope.setExtra("request", base64Request)
		// 	scope.setTag("Type", "API")
		// })
	} catch (e) {
		// Some 'message' properties are not writable
		// if (Object.getOwnPropertyDescriptor(error, "message")?.writable) {
		// 	error.message = `${error?.config?.method?.toUpperCase()} ${error?.response?.status} ${error?.config?.url}`
		// }
		// window.Sentry.captureException(error, (scope) => {
		// 	scope.setExtra("method", error?.config?.method)
		// 	scope.setExtra("status", error?.response?.status)
		// 	scope.setTag("Type", "API")
		// })
	}
	return Promise.reject({ ...error })
}

const axiosSuccessHandler = async (success) => {
	try {
		RealtimeNotificationSystem.interceptResponse(success, socket_rns)

		success = await CacheSystem.axiosInterceptResponse(success)
	} catch (_void) {
	} finally {
		return success
	}
}

axios.interceptors.request.use(async (request) => {
	try {
		return await CacheSystem.axiosInterceptRequest(request)
	} catch (_error) {
		return request
	}
})

axios.interceptors.response.use(
	(response) => axiosSuccessHandler(response),
	(error) => axiosErrorHandler(error)
)

ReactDOM.render(<EntryPoint socket={socket} socket_rns={socket_rns} />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
	onUpdate: async (registration) => {
		// We want to run this code only if we detect a new service worker is
		// waiting to be activated.
		// Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
		if (registration && registration.waiting) {
			await registration.unregister()
			// Once the service worker is unregistered, we can reload the page to let
			// the browser download a fresh copy of our app (invalidating the cache)
			window.location.reload()
		}
	},
})
