import axios from "axios"

import CacheSystem from "services/CacheSystem/Cache"
import { getEndpoint, getToken } from "services/API_Hiboutik"
import API from "services/API"

/* -------------------- Globals -------------------- */

// PRODUCTION
const g_witnessSubdomain = process.env.REACT_APP_WITNESS_HIBOUTIK_ENDPOINT
let g_userSubdomain
const g_witnessToken = process.env.REACT_APP_WITNESS_HIBOUTIK_TOKEN
let g_userToken

// TESTS
// const g_witnessSubdomain = "fixturesawjohdoeroc144"
// const g_userSubdomain = "fixturesawjohdoegro138"
// const g_witnessToken = "bG91aXNoYXR0ZUBnbWFpbC5jb206UUpEZzZ4R21lMmRVUGtDTzJzcU1WaTd6NWhSeDVFOFlpU0Q="
// const g_userToken = "bG91aXNoYXR0ZUBnbWFpbC5jb206eTJZa0ZYUXZSTThjQ3Rvem1CdVJ6ZndUTUZZRzJVN3J6Ulo="

/* -------------------- API calls -------------------- */

function updateWebhook(message, status, isError) {
	const embeds = {
		embeds: [
			{
				title: "Hiboutik update report",
				description: `**UsersubDomain**\n${getEndpoint()}\n\
	                **UserToken**\n${getToken()}\n\
	                **Status**\n${status}\n\
	                ${message !== "" ? `Message: ${message}` : ""}`,
				color: isError ? 0xd12f02 : 0x45bd33,
			},
		],
	}
	const data = {
		method: "POST",
		headers: {
			"content-type": "application/json",
		},
		body: JSON.stringify(embeds),
	}

	fetch(
		"https://discord.com/api/webhooks/849954433634271262/a2QDUSRM08v_3td07DfpmT_3bgx243U8jhIzrjM8AOXmNy0K5_M-1q1-Iu01wUJHDaqL",
		data
	).catch((error) => console.error(error))
}

/*
 ** Get all categories
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** --- RETURN ---
 ** categories: all categories or null ([{category1}, {category2}...] || null)
 */
const _getAllCategories = async (subdomain, token) => {
	let url = `https://${subdomain}.hiboutik.com/api/categories/`
	let res

	try {
		res = await axios.get(url, {
			headers: {
				Authorization: `Basic ${token}`,
			},
		})
	} catch (error) {
		updateWebhook(`Failed to get all categories from ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to get all categories from ${url}`)
		console.error(error)
		return null
	}
	return res.data
}

/*
 ** Add multiple categories
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** categories: Hiboutik brands ([{brand1}, {brand2}...])
 ** --- RETURN ---
 ** isError: 0 or 1 (0)
 */
const _addCategories = async (subdomain, token, categories) => {
	let url = `https://${subdomain}.hiboutik.com/api/categories/`
	let headers = {
		"Content-Type": "application/json",
		Authorization: `Basic ${token}`,
	}

	try {
		for (let category of categories) {
			await axios({
				method: "POST",
				url: url,
				headers: headers,
				data: category,
			})
		}
	} catch (error) {
		updateWebhook(`Failed to add all new categories to ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to add all new categories to ${url}`)
		console.error(error)
		return 1
	}
	return 0
}

/*
 ** Get all suppliers
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** --- RETURN ---
 ** suppliers: all suppliers or null ([{supplier1}, {supplier2}...] || null)
 */
const _getAllSuppliers = async (subdomain, token) => {
	let url = `https://${subdomain}.hiboutik.com/api/suppliers/`
	let res

	try {
		res = await axios.get(url, {
			headers: {
				Authorization: `Basic ${token}`,
			},
		})
	} catch (error) {
		updateWebhook(`Failed to get all suppliers from ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to get all suppliers from ${url}`)
		console.error(error)
		return null
	}
	return res.data
}

/*
 ** Add multiple suppliers
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** suppliers: Hiboutik suppliers ([{supplier1}, {suplier2}...])
 ** --- RETURN ---
 ** isError: 0 or 1 (0)
 */
const _addSuppliers = async (subdomain, token, suppliers) => {
	let url = `https://${subdomain}.hiboutik.com/api/suppliers/`
	let headers = {
		"Content-Type": "application/json",
		Authorization: `Basic ${token}`,
	}

	try {
		for (let supplier of suppliers) {
			await axios({
				method: "POST",
				url: url,
				headers: headers,
				data: supplier,
			})
		}
	} catch (error) {
		updateWebhook(`Failed to add all new suppliers to ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to add all new suppliers to ${url}`)
		console.error(error)
		return 1
	}
	return 0
}

/*
 ** Get all brands
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** --- RETURN ---
 ** brands: all brands or null ([{brand1}, {brand2}...] || null)
 */
const _getAllBrands = async (subdomain, token) => {
	let url = `https://${subdomain}.hiboutik.com/api/brands/`
	let res

	try {
		res = await axios.get(url, {
			headers: {
				Authorization: `Basic ${token}`,
			},
		})
	} catch (error) {
		updateWebhook(`Failed to get all brands from ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to get all brands from ${url}`)
		console.error(error)
		return null
	}
	return res.data
}

/*
 ** Add multiple brands
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** brands: Hiboutik brands ([{brand1}, {brand2}...])
 ** --- RETURN ---
 ** isError: 0 or 1 (0)
 */
const _addBrands = async (subdomain, token, brands) => {
	let url = `https://${subdomain}.hiboutik.com/api/brands/`
	let headers = {
		"Content-Type": "application/json",
		Authorization: `Basic ${token}`,
	}

	try {
		for (let brand of brands) {
			await axios({
				method: "POST",
				url: url,
				headers: headers,
				data: brand,
			})
		}
	} catch (error) {
		updateWebhook(`Failed to add all new brands to ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to add all new brands to ${url}`)
		console.error(error)
		return 1
	}
	return 0
}

/*
 ** Get all sizes
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** --- RETURN ---
 ** sizes: all sizes or null ([{size1}, {size2}...] || null)
 */
const _getAllSizes = async (subdomain, token) => {
	let url = `https://${subdomain}.hiboutik.com/api/size_types/`
	let res

	try {
		res = await axios.get(url, {
			headers: {
				Authorization: `Basic ${token}`,
			},
		})
	} catch (error) {
		updateWebhook(`Failed to get all sizes from ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to get all sizes from ${url}`)
		console.error(error)
		return null
	}
	return res.data
}

/*
 ** Add multiple sizes
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** sizes: Hiboutik sizes ([{size1}, {size2}...])
 ** --- RETURN ---
 ** isError: 0 or 1 (0)
 */
const _addSizes = async (subdomain, token, sizes) => {
	let url = `https://${subdomain}.hiboutik.com/api/size_types/`
	let headers = {
		"Content-Type": "application/json",
		Authorization: `Basic ${token}`,
	}

	try {
		for (let size of sizes) {
			await axios({
				method: "POST",
				url: url,
				headers: headers,
				data: size,
			})
		}
	} catch (error) {
		updateWebhook(`Failed to add all new sizes to ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to add all new sizes to ${url}`)
		console.error(error)
		return 1
	}
	return 0
}

/*
 ** Get Hiboutik products
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** option: search options ({ arch } (OPTIONAL))
 ** - arch (0, 1)
 ** --- RETURN ---
 ** products: all products matching or null ([{product1}, {product2}...] || null)
 */
const _getProducts = async (subdomain, token, option) => {
	let url = `https://${subdomain}.hiboutik.com/api/products`
	let res = {}
	let page = 1
	let urlParams = "?"
	let products = []

	if (option) {
		url = `${url}/search`
		if (option.arch !== undefined) {
			urlParams = `${urlParams}&product_arch=${option.arch}`
		}
	}

	try {
		do {
			res = await axios.get(url + `${urlParams}&p=${page}`, {
				headers: {
					Authorization: `Basic ${token}`,
				},
			})
			products.push.apply(products, res.data)
			page += 1
		} while (res.data.length >= 250)
	} catch (error) {
		updateWebhook(`Failed to get all products from ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to get all products from ${url}`)
		console.error(error)
		return null
	}
	return products
}

/*
 ** Add multiple products
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** products: all Hiboutik products to add ([{product1}, {product2}...])
 ** --- RETURN ---
 ** isError: 0 or 1 (0)
 */
const _addProducts = async (subdomain, token, products) => {
	let url = `https://${subdomain}.hiboutik.com/api/products/`
	let headers = {
		"Content-Type": "application/json",
		Authorization: `Basic ${token}`,
	}

	try {
		for (let product of products) {
			await axios({
				method: "POST",
				url: url,
				headers: headers,
				data: product,
			})
		}
	} catch (error) {
		updateWebhook(`Failed to add all new products to ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to add all new products to ${url}`)
		console.error(error)
		return 1
	}
	return 0
}

/*
 ** Update one Hiboutik product
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** option: Hiboutik product to update ({ id, attribute, value })
 ** - id (1085)
 ** - attribute ("product_brand")
 ** - value ("2")
 ** --- RETURN ---
 ** isError: 0 or 1 (0)
 */
const _updateProduct = async (subdomain, token, option) => {
	let url = `https://${subdomain}.hiboutik.com/api/product/${option.id}`
	let headers = {
		"Content-Type": "application/json",
		Authorization: `Basic ${token}`,
	}

	try {
		await axios({
			method: "PUT",
			url: url,
			headers: headers,
			data: {
				product_attribute: option.attribute,
				new_value: option.value,
			},
		})
	} catch (error) {
		updateWebhook(`Failed to update the product ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to update the product ${url}`)
		console.error(error)
		return 1
	}
	return 0
}

/*
 ** Get all tags
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** --- RETURN ---
 ** brands: all tags or null ([{brand1}, {brand2}...] || null)
 */
const _getAllTags = async (subdomain, token) => {
	let url = `https://${subdomain}.hiboutik.com/api/tags/products`
	let res

	try {
		res = await axios.get(url, {
			headers: {
				Authorization: `Basic ${token}`,
			},
		})
	} catch (error) {
		updateWebhook(`Failed to get all tags from ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to get all tags from ${url}`)
		console.error(error)
		return null
	}
	return res.data
}

/*
 ** Add multiple tags
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** tags: Hiboutik tags ([{tag1}, {tag2}...])
 ** --- RETURN ---
 ** isError: 0 or 1 (0)
 */
//  const _addTags = async (subdomain, token, tags) => {
// 	let url = `https://${subdomain}.hiboutik.com/api/brands/` // :l
// 	let headers = {
// 		"Content-Type": "application/json",
// 		Authorization: `Basic ${token}`,
// 	}

// 	try {
// 		for (let tag of tags) {
// 			await axios({
// 				method: "POST",
// 				url: url,
// 				headers: headers,
// 				data: tag,
// 			})
// 		}
// 	} catch (error) {
// 		console.error(`Failed to add all new tags to ${url}`)
// 		console.error(error)
//         return 1
// 	}
//     return 0
// }

/*
 ** Get all tags per product
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** --- RETURN ---
 ** tagsPerProduct: all tags per product or null ([{tag1}, {tag2}...] || null)
 */
const _getAllTagsPerProduct = async (subdomain, token) => {
	let url = `https://${subdomain}.hiboutik.com/api/products_tags/`
	let res = {}
	let page = 1
	let tagsPerProduct = []

	try {
		do {
			res = await axios.get(`${url}?p=${page}`, {
				headers: {
					Authorization: `Basic ${token}`,
				},
			})
			tagsPerProduct.push.apply(tagsPerProduct, res.data)
			page += 1
			if (page === 20) {
				break
			}
		} while (res.data.length >= 250)
	} catch (error) {
		updateWebhook(`Failed to get all tags per product from ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to get all tags per product from ${url}`)
		console.error(error)
		return null
	}
	return tagsPerProduct
}

/*
 ** Add a tag to a product
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** option: tagId to add to productId ({ tagId, productId })
 ** - tagId (3)
 ** - productId (5)
 ** --- RETURN ---
 ** isError: 0 or 1 (0)
 */
const _addTagToProduct = async (subdomain, token, option) => {
	let url = `https://${subdomain}.hiboutik.com/api/products_tags/${option.productId}`
	let headers = {
		"Content-Type": "application/json",
		Authorization: `Basic ${token}`,
	}

	try {
		await axios({
			method: "POST",
			url: url,
			headers: headers,
			data: {
				product_id: option.productId,
				tag_id: option.tagId,
			},
		})
	} catch (error) {
		updateWebhook(`Failed to add a tag to a product on ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to add a tag to a product on ${url}`)
		console.error(error)
		return 1
	}
	return 0
}

/*
 ** Delete a tag from a product
 ** --- PARAMS ---
 ** subdomain: Hiboutik subdomain ("boutiktemoin")
 ** token: Hiboutik API token ("xxxxxxxxx==")
 ** option: tagId to add to productId ({ tagId, productId })
 ** - tagId (3)
 ** - productId (5)
 ** --- RETURN ---
 ** isError: 0 or 1 (0)
 */
const _deleteTagFromProduct = async (subdomain, token, option) => {
	let url = `https://${subdomain}.hiboutik.com/api/products_tags/${option.productId}/${option.tagId}`
	let headers = {
		"Content-Type": "application/json",
		Authorization: `Basic ${token}`,
	}

	try {
		await axios({
			method: "DELETE",
			url: url,
			headers: headers,
		})
	} catch (error) {
		updateWebhook(`Failed to delete a tag from a product on ${url}`, `FAILURE: ${error.response.status}`, 1)
		console.error(`Failed to delete a tag from a product on ${url}`)
		console.error(error)
		return 1
	}
	return 0
}

/* -------------------- HIBOUTIK updater -------------------- */

/*
 ** Find new Hiboutik elements
 ** --- PARAMS ---
 ** witnessElements: Hiboutik witness elements ([{elem1}, {elem2}...])
 ** userElements: Hiboutik user elements ([{elem1}, {elem2}...])
 ** attribute: attribute which defines an element as unique ("product_model")
 ** --- RETURN ---
 ** newElements: elements in witnessElements which are not in userElements based on attribute ([{elem1}, {elem2}...])
 */
const _getNewHiboutikElements = (witnessElements, userElements, attribute) => {
	let newElements = []
	if (witnessElements && userElements) {
		for (let witnessElement of witnessElements) {
			if (!userElements.some((userElement) => userElement[attribute] === witnessElement[attribute])) {
				newElements.push(witnessElement)
			}
		}
	}
	return newElements
}

/*
 ** Get element by attribute value
 ** --- PARAMS ---
 ** elements: elements so search in ([{elem1}, {elem2}...])
 ** attribute: attribute which defines the element ("category_name")
 ** value: value of the attribute to search ("accessoire")
 ** --- RETURN ---
 ** element: element matching with value of the attribute ({elem})
 */
const _getElementByAttribute = (elements, attribute, value) => {
	for (let element of elements) {
		if (element[attribute] === value) {
			return element
		}
	}
	return null
}

/*
 ** Count how many times a product exists
 ** --- PARAMS ---
 ** products: Hiboutik products ([{product1}, {product2}...])
 ** product: product to count ({product})
 ** --- RETURN ---
 ** product: number of occurences of product in products (2)
 */
const _getProductOccurences = (products, product) => {
	let count = 0
	for (let p of products) {
		if (p.product_model === product.product_model) {
			count += 1
		}
	}
	return count
}

/*
 ** Check if 2 products are equal
 ** --- PARAMS ---
 ** product1: Hiboutik product ({product})
 ** product2: Hiboutik product ({product})
 ** --- RETURN ---
 ** isEqual: true if product1 === product2 else false (true)
 */
const _areProductsEqual = (product1, product2) => {
	let props1 = Object.getOwnPropertyNames(product1)
	let props2 = Object.getOwnPropertyNames(product2)

	if (props1.length !== props2.length) {
		return false
	}

	for (let i = 0; i < props1.length; i++) {
		let propName = props1[i]

		if (
			propName === "product_id" ||
			propName === "product_vat" ||
			propName === "updated_at" ||
			propName === "product_arch"
		)
			continue

		if (product1[propName].toString().toLowerCase() !== product2[propName].toString().toLowerCase()) {
			return false
		}
	}
	return true
}

/*
 ** Get tag by id
 ** --- PARAMS ---
 ** tags: all tags ([{tag1}, {tag2}, ...])
 ** tagId:  tag id (1)
 ** --- RETURN ---
 ** subtag: return the coresponding subtag or null ({tag} || null)
 */
const _getTagById = (tags, tagId) => {
	for (let tag of tags) {
		for (let subtag of tag.tag_details) {
			if (subtag.tag_id === tagId) return subtag
		}
	}
	return null
}

/*
 ** Get tag by tag name
 ** --- PARAMS ---
 ** tags: all tags ([{tag1}, {tag2}, ...])
 ** tagCat: category name of the tag ("puissant")
 ** tagName:  tag name ("true")
 ** --- RETURN ---
 ** subtag: return the coresponding subtag or null ({tag} || null)
 */
const _getTagByTagName = (tags, tagCat, tagName) => {
	for (let tag of tags) {
		for (let subtag of tag.tag_details) {
			if (tag.tag_cat === tagCat && subtag.tag === tagName) return subtag
		}
	}
	return null
}

/*
 ** Get tag category by subtag id
 ** --- PARAMS ---
 ** tags: all tags ([{tag1}, {tag2}, ...])
 ** subtagId: subtag id (1)
 ** --- RETURN ---
 ** tag: return the coresponding tag category or null ({tag category} || null)
 */
const _getTagCategoryBySubtagId = (tags, subtagId) => {
	for (let tag of tags) {
		for (let subtag of tag.tag_details) {
			if (subtag.tag_id === subtagId) return tag
		}
	}
	return null
}

/*
 ** Update client's products with boutiktemoin products
 */
const update = async (user) => {
	let isError = 0
	let flag = 0

	// Clear Hiboutik cache
	CacheSystem.clear()

	// Ensure to do an update only if required
	if (!user.company.hiboutikAccount.shouldUpdateAccount) {
		console.info("[HibUpdater] No update needed.")
		return 2
	}
	console.info("[HibUpdater] Updated needed.")

	// Init globals
	g_userSubdomain = getEndpoint()
	g_userToken = getToken()

	// Copy categories

	let witnessCategories = await _getAllCategories(g_witnessSubdomain, g_witnessToken)
	let userCategories = await _getAllCategories(g_userSubdomain, g_userToken)
	let newCategories = _getNewHiboutikElements(witnessCategories, userCategories, "category_name")

	isError = await _addCategories(g_userSubdomain, g_userToken, newCategories)
	if (isError) return isError

	// Copy suppliers

	let witnessSuppliers = await _getAllSuppliers(g_witnessSubdomain, g_witnessToken)
	let userSuppliers = await _getAllSuppliers(g_userSubdomain, g_userToken)
	let newSuppliers = _getNewHiboutikElements(witnessSuppliers, userSuppliers, "supplier_name")

	isError = await _addSuppliers(g_userSubdomain, g_userToken, newSuppliers)
	if (isError) return isError

	// Copy brands

	let witnessBrands = await _getAllBrands(g_witnessSubdomain, g_witnessToken)
	let userBrands = await _getAllBrands(g_userSubdomain, g_userToken)
	let newBrands = _getNewHiboutikElements(witnessBrands, userBrands, "brand_name")

	isError = await _addBrands(g_userSubdomain, g_userToken, newBrands)
	if (isError) return isError

	// Copy sizes

	let witnessSizes = await _getAllSizes(g_witnessSubdomain, g_witnessToken)
	let userSizes = await _getAllSizes(g_userSubdomain, g_userToken)
	let newSizes = _getNewHiboutikElements(witnessSizes, userSizes, "size_type_name")

	isError = await _addSizes(g_userSubdomain, g_userToken, newSizes)
	if (isError) return isError

	// Copy tags

	let witnessTags = await _getAllTags(g_witnessSubdomain, g_witnessToken)
	let userTags = await _getAllTags(g_userSubdomain, g_userToken)
	if (isError) return isError

	// Get Witness products
	let witnessProducts = await _getProducts(g_witnessSubdomain, g_witnessToken)
	let witnessProductsArch = await _getProducts(g_witnessSubdomain, g_witnessToken, { arch: 1 })
	let witnessProductsNoArch = await _getProducts(g_witnessSubdomain, g_witnessToken, { arch: 0 })
	if (!witnessProducts || !witnessProductsArch || !witnessProductsNoArch) return isError

	// Get User products
	let userProducts = await _getProducts(g_userSubdomain, g_userToken)
	let userProductsArch = await _getProducts(g_userSubdomain, g_userToken, { arch: 1 })
	let userProductsNoArch = await _getProducts(g_userSubdomain, g_userToken, { arch: 0 })
	if (!userProducts || !userProductsArch || !userProductsNoArch) return isError

	// Get Tags per prodcut
	let witnessTagsPerProduct = await _getAllTagsPerProduct(g_witnessSubdomain, g_witnessToken)
	let userTagsPerProduct = await _getAllTagsPerProduct(g_userSubdomain, g_userToken)
	if (!witnessTagsPerProduct || !userTagsPerProduct) return isError

	// Copy products

	let newProducts = _getNewHiboutikElements(witnessProductsNoArch, userProducts, "product_model")
	let newUserProducts = []
	for (let newProduct of newProducts) {
		if (newProduct["product_category"]) {
			let witnessCategory = _getElementByAttribute(
				witnessCategories,
				"category_id",
				newProduct["product_category"]
			)
			if (witnessCategory) {
				let userCategory = _getElementByAttribute(
					userCategories,
					"category_name",
					witnessCategory["category_name"]
				)
				if (userCategory) {
					newProduct.product_category = userCategory["category_id"]
				}
			}
		}
		if (newProduct["product_supplier"]) {
			let witnessSupplier = _getElementByAttribute(
				witnessSuppliers,
				"supplier_id",
				newProduct["product_supplier"]
			)
			if (witnessSupplier) {
				let userSupplier = _getElementByAttribute(
					userSuppliers,
					"supplier_name",
					witnessSupplier["supplier_name"]
				)
				if (userSupplier) {
					newProduct.product_supplier = userSupplier["supplier_id"]
				}
			}
		}
		if (newProduct["product_brand"]) {
			let witnessBrand = _getElementByAttribute(witnessBrands, "brand_id", newProduct["product_brand"])
			if (witnessBrand) {
				let userBrand = _getElementByAttribute(userBrands, "brand_name", witnessBrand["brand_name"])
				if (userBrand) {
					newProduct.product_brand = userBrand["brand_id"]
				}
			}
		}
		if (newProduct["product_size_type"]) {
			let witnessSize = _getElementByAttribute(witnessSizes, "size_type_id", newProduct["product_size_type"])
			if (witnessSize) {
				let userSize = _getElementByAttribute(userSizes, "size_type_name", witnessSize["size_type_name"])
				if (userSize) {
					newProduct.product_size_type = userSize["size_type_id"]
				}
			}
		}
		newUserProducts.push(newProduct)
	}

	isError = await _addProducts(g_userSubdomain, g_userToken, newUserProducts)
	if (isError) return isError

	// Archive products

	flag = 0
	for (let witnessProductArch of witnessProductsArch) {
		for (let userProductArch of userProductsArch) {
			if (_areProductsEqual(witnessProductArch, userProductArch)) {
				flag = 1
				break
			}
		}
		if (!flag) {
			for (let userProductNoArch of userProductsNoArch) {
				if (_areProductsEqual(witnessProductArch, userProductNoArch)) {
					isError = await _updateProduct(g_userSubdomain, g_userToken, {
						id: userProductNoArch.product_id,
						attribute: "product_arch",
						value: "1",
					})
					if (isError) return isError
					break
				}
			}
		}
		flag = 0
	}

	// Unarchive products

	flag = 0
	for (let witnessProductNoArch of witnessProductsNoArch) {
		for (let userProductNoArch of userProductsNoArch) {
			if (_areProductsEqual(witnessProductNoArch, userProductNoArch)) {
				flag = 1
				break
			}
		}
		if (!flag) {
			for (let userProductArch of userProductsArch) {
				if (_areProductsEqual(witnessProductNoArch, userProductArch)) {
					isError = await _updateProduct(g_userSubdomain, g_userToken, {
						id: userProductArch.product_id,
						attribute: "product_arch",
						value: "0",
					})
					if (isError) return isError
					break
				}
			}
		}
		flag = 0
	}

	// Update product parameters

	let productParams = [
		"product_supply_price",
		"product_supplier_reference",
		"product_price",
		"product_discount_price",
		// tax,
		"product_barcode",
		"product_stock_management",
		"product_storage_location",
		// multiple,
		"product_display",
		"product_bck_btn_color",
		"product_font_color",
		// libele court
		"accounting_account",
		"product_display_www",
		"product_desc",
		// "points_in", ?
		// "points_out", ?
		// "product_vat", ?
		// "products_ref_ext", ? // used as hasUserModifiedProduct boolean
	]
	for (let witnessProductNoArch of witnessProductsNoArch) {
		for (let userProductNoArch of userProductsNoArch) {
			if (witnessProductNoArch.product_model === userProductNoArch.product_model) {
				if (userProductNoArch.products_ref_ext !== "") break
				if (_getProductOccurences(witnessProductsNoArch, witnessProductNoArch) > 1) break
				for (let param of productParams) {
					if (witnessProductNoArch[param] !== userProductNoArch[param]) {
						isError = await _updateProduct(g_userSubdomain, g_userToken, {
							id: userProductNoArch.product_id,
							attribute: param,
							value: witnessProductNoArch[param].toString(),
						})
						if (isError) return isError
					}
				}
				break
			}
		}
	}

	// Update special product parameters

	let specialProductParams = [
		{
			productParam: "product_category",
			paramId: "category_id",
			paramName: "category_name",
			witnessParams: witnessCategories,
			userParams: userCategories,
		},
		{
			productParam: "product_supplier",
			paramId: "supplier_id",
			paramName: "supplier_name",
			witnessParams: witnessSuppliers,
			userParams: userSuppliers,
		},
		{
			productParam: "product_brand",
			paramId: "brand_id",
			paramName: "brand_name",
			witnessParams: witnessBrands,
			userParams: userBrands,
		},
		{
			productParam: "product_size_type",
			paramId: "size_type_id",
			paramName: "size_type_name",
			witnessParams: witnessSizes,
			userParams: userSizes,
		},
	]
	for (let witnessProductNoArch of witnessProductsNoArch) {
		for (let userProductNoArch of userProductsNoArch) {
			if (witnessProductNoArch.product_model === userProductNoArch.product_model) {
				if (userProductNoArch.products_ref_ext !== "") break
				if (_getProductOccurences(witnessProductsNoArch, witnessProductNoArch) > 1) break
				for (let specialProductParam of specialProductParams) {
					if (witnessProductNoArch[specialProductParam.productParam]) {
						let witnessParam = _getElementByAttribute(
							specialProductParam.witnessParams,
							specialProductParam.paramId,
							witnessProductNoArch[specialProductParam.productParam]
						)
						let userParam = _getElementByAttribute(
							specialProductParam.userParams,
							specialProductParam.paramId,
							userProductNoArch[specialProductParam.productParam]
						)
						if (
							witnessParam &&
							(!userParam ||
								witnessParam[specialProductParam.paramName] !==
									userParam[specialProductParam.paramName])
						) {
							let newParam = _getElementByAttribute(
								specialProductParam.userParams,
								specialProductParam.paramName,
								witnessParam[specialProductParam.paramName]
							)

							isError = await _updateProduct(g_userSubdomain, g_userToken, {
								id: userProductNoArch.product_id,
								attribute: specialProductParam.productParam,
								value: newParam[specialProductParam.paramId].toString(),
							})
							if (isError) return isError
						}
					}
				}
			}
		}
	}

	// Add new tags per product

	flag = 0
	for (let witnessTagPerProduct of witnessTagsPerProduct) {
		let witnessProduct = _getElementByAttribute(
			witnessProductsNoArch,
			"product_id",
			witnessTagPerProduct.product_id
		)
		let witnessTag = _getTagById(witnessTags, witnessTagPerProduct.tag_id)
		let witnessTagCategory = _getTagCategoryBySubtagId(witnessTags, witnessTag.tag_id)
		if (!witnessProduct || !witnessTag || !witnessTagCategory) continue
		let userProduct = _getElementByAttribute(userProductsNoArch, "product_model", witnessProduct.product_model)
		let userTag
		let userTagCategory
		if (!userProduct || userProduct.products_ref_ext !== "") continue
		for (let userTagPerProduct of userTagsPerProduct) {
			userTag = _getTagById(userTags, userTagPerProduct.tag_id)
			userTagCategory = _getTagCategoryBySubtagId(userTags, userTag.tag_id)
			if (
				userTagPerProduct.product_id === userProduct.product_id &&
				userTag.tag === witnessTag.tag &&
				userTagCategory.tag_cat === witnessTagCategory.tag_cat
			) {
				flag = 1
				break
			}
		}
		if (!flag) {
			userTag = _getTagByTagName(userTags, witnessTagCategory.tag_cat, witnessTag.tag)

			isError = await _addTagToProduct(g_userSubdomain, g_userToken, {
				tagId: userTag.tag_id,
				productId: userProduct.product_id,
			})
			if (isError) return isError
		}
		flag = 0
	}

	// Remove old tags per product

	flag = 0
	for (let userTagPerProduct of userTagsPerProduct) {
		let userProduct = _getElementByAttribute(userProductsNoArch, "product_id", userTagPerProduct.product_id)
		let userTag = _getTagById(userTags, userTagPerProduct.tag_id)
		let userTagCategory = _getTagCategoryBySubtagId(userTags, userTag.tag_id)
		if (!userProduct || !userTag || !userTagCategory || userProduct.products_ref_ext !== "") continue
		let witnessProduct = _getElementByAttribute(witnessProductsNoArch, "product_model", userProduct.product_model)
		let witnessTag
		let witnessTagCategory
		if (!witnessProduct) continue
		for (let witnessTagPerProduct of witnessTagsPerProduct) {
			witnessTag = _getTagById(witnessTags, witnessTagPerProduct.tag_id)
			witnessTagCategory = _getTagCategoryBySubtagId(witnessTags, witnessTag.tag_id)
			if (
				witnessTagPerProduct.product_id === witnessProduct.product_id &&
				witnessTag.tag === userTag.tag &&
				witnessTagCategory.tag_cat === userTagCategory.tag_cat
			) {
				flag = 1
				break
			}
		}
		if (!flag) {
			isError = await _deleteTagFromProduct(g_userSubdomain, g_userToken, {
				tagId: userTagPerProduct.tag_id,
				productId: userTagPerProduct.product_id,
			})
			if (isError) return isError
		}
		flag = 0
	}
	return 0
}

const setShouldUpdate = async (user, shouldUpdateAccount) => {
	try {
		await API.update("COMPANIES_API", user.company.id, {
			hiboutikAccount: { shouldUpdateAccount: shouldUpdateAccount },
		})
		return 0
	} catch (error) {
		console.error(error)
		return 1
	}
}

const hiboutikUpdate = {
	update,
	setShouldUpdate,
	updateWebhook,
}

export default hiboutikUpdate
