import { ConfluencePage } from "@audiowizard/common"
import axios, { AxiosRequestConfig, AxiosPromise } from "axios"
import { CONFLUENCE_DOC_API } from "config"

export function getDocumentationContent(id: DocumentationPageIdentifier): AxiosPromise<ConfluencePage> {
	const url = new URL(CONFLUENCE_DOC_API + "/" + (id as string))

	return axios.get(url.href)
}

export function getTargetsInDocumentation(target: string, axiosConfig: AxiosRequestConfig): AxiosPromise<any> {
	const url = new URL(CONFLUENCE_DOC_API)

	url.searchParams.set("target", target)

	return axios.get(url.href, axiosConfig)
}

export enum DocumentationPageIdentifier {
	Catalogue = "35979459",
	BonLivraison = "18743544",
	Vente = "16318522",
	Comptabilite = "16285800",
	Stock = "18743655",
	Caisse = "18776217",
	Inscription = "16318467",
	SeConnecter = "16318474",
	MDPOublie = "16252936",
	MonCompte = "16318481",
	MesInformations = "16252943",
	MesParrainages = "16318491",
	MesPrescripteurs = "16318498",
	MonEntreprise = "16318505",
	MesFacturations = "16285758",
	MesImportsPatients = "16285775",
	MesImportsFabricants = "16285782",
	MesVentesEnAttente = "16318529",
	MonAbonnement = "16253007",
	MesLaboratoires = "16253043",
	FichePatient = "18776078",
	PremiereVisite = "18743343",
	EditionDevis = "18743461",
	RéalisationVente = "22511661",
	FAQ = "98539",
	RécupérationDocuments = "229900307",
	RappelSms = "249692402",
}
