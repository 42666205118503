/* eslint-disable quotes */
import axios from "axios"
import moment from "moment"
import day from "dayjs"

import API from "./API"
import { API_URL, STORE_API } from "../config"
import { rounded } from "./functions"

let hiboutikToken = ""
let pdfToken = ""
let endPoint = ""

const init = (accountName, token, pdf) => {
	if (process.env.REACT_APP_DEV_HIBOUTIK?.toLowerCase() !== "true") {
		endPoint = accountName
		hiboutikToken = token
		pdfToken = pdf
	} else {
		hiboutikToken = process.env.REACT_APP_HIBOUTIK_TOKEN
			? process.env.REACT_APP_HIBOUTIK_TOKEN
			: "bWF0aGlldS5jaGFtbWFoQGdtYWlsLmNvbTpITjZDM05XRVNWNVVPRUk5S05OM1U1TUZMSFpQSUJOUVBLVA=="
		pdfToken = process.env.REACT_APP_HIBOUTIK_PDF
			? process.env.REACT_APP_HIBOUTIK_PDF
			: "Ke5WcS58em7rUzWTsljShLbM9AHktEVaIMXPvJIz"
		endPoint = process.env.REACT_APP_HIBOUTIK_ENDPOINT ? process.env.REACT_APP_HIBOUTIK_ENDPOINT : "audiowizard"
	}
}

export const getToken = () => {
	return hiboutikToken
}
export const getEndpoint = () => {
	return endPoint
}

if (!hiboutikToken) hiboutikToken = window.localStorage.getItem("aGlib3V0b2tlbg")
if (!pdfToken) pdfToken = window.localStorage.getItem("dG9rZW5QZGY=")
if (!endPoint) endPoint = window.localStorage.getItem("aGlib3V0aWtfYWNjb3VudG5hbWU")

const categoryNames = {
	PRODUCTS: "Appareils auditifs",
	ACCESSORIES: "Accessoire",
	PILES: "Piles",
	ENTRETIEN: "Entretien",
	EMBOUT: "Embout",
	EMBOUT_REMBOURSE: "Embout rembourse",
	EMBOUT_AVEC_SN: "Embout avec SN",
	DOMES: "Dômes",
	PROTECTION_PREVENTION: "Protection et prevention",
	ECOUTEUR: "Ecouteur",
	ECOUTEUR_AVEC_SN: "Ecouteur avec SN",
}

const getCategory = () => {
	const config = {
		method: "GET",
		url: STORE_API + "categories",
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

const getCategoryIdOf = async (api) => {
	try {
		const categories = await getCategory()
		const { category_id: categoryId } =
			categories?.find((category) => {
				const categoryNameTrimmed = category?.category_name.trim()
				return categoryNameTrimmed === categoryNames[api]
			}) || {}
		return categoryId
	} catch (err) {
		throw err
	}
}

const getProductById = (id = 1) => {
	const config = {
		url: STORE_API + "products/" + id,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getProductsByCategory(categoryId) {
	let config = {
		method: "GET",
		url: STORE_API + "products/search?product_category=" + categoryId,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

async function updateProduct(productId, attribute, data) {
	let config0 = {
		method: "PUT",
		url: STORE_API + "product/" + productId,
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			product_attribute: "products_ref_ext",
			new_value: day().format("YYYY-MM-DD HH:mm:ss"),
		},
	}
	let config = {
		method: "PUT",
		url: STORE_API + "product/" + productId,
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			product_attribute: attribute,
			new_value: data,
		},
	}
	await axios(config0)

	return new Promise((resolve, reject) => {
		axios(config)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => reject(error))
	})
}

async function createProduct(product) {
	const data = {
		product_model: product.product_model,
		product_brand: product.product_brand,
		product_price: product.product_price,
		product_supply_price: product.product_supply_price,
		product_supplier: product.product_supplier,
		product_size_type: product.product_size_type,
		product_category: product.product_category,
		product_stock_management: product.product_stock_management,
		product_vat: product.product_vat,
		products_ref_ext: day().format("YYYY-MM-DD HH:mm:ss"),
	}
	let config = {
		method: "POST",
		url:
			STORE_API +
			"products/?product_model=" +
			product.product_model +
			"&product_brand=" +
			product.product_supplier_reference +
			"&product_price=" +
			product.product_price +
			"&product_stock_management=" +
			product.product_stock_management,
		headers: {
			"Content-Type": "application/json",
		},
		// SANS CETTE LIGNE HIBOUTIK RETOURNE UNE ERREUR 406
		data: data,
	}
	return new Promise((resolve, reject) => {
		axios(config)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => reject(error))
	})
}

function searchProductByName(product) {
	let config = {
		method: "GET",
		url: `${STORE_API}products/search?product_model=${product}&product_arch=0`,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getBrands() {
	let config = {
		method: "GET",
		url: STORE_API + "brands",
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

// eslint-disable-next-line no-unused-vars
function createBrand(brand) {
	let config = {
		method: "POST",
		url: STORE_API + "brands",
	}
	return axios
		.post(config.url, brand, {
			headers: {
				"content-type": "application/json",
			},
		})
		.then(async (response) => {
			return response.data
		})
}
// eslint-disable-next-line no-unused-vars
function updateBrand(brand) {
	let config = {
		method: "PUT",
		url: `${STORE_API}brands`,
	}
	return axios
		.put(config.url, brand, {
			headers: {
				"content-type": "application/json",
			},
		})
		.then(async (response) => {
			return response.data
		})
}

function getAllProductsStocks() {
	let config = {
		method: "GET",
		url: STORE_API + "stock_available/all_wh",
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getStocksForProduct(idProduct = 1) {
	let config = {
		method: "GET",
		url: STORE_API + "stock_available/product_id/" + idProduct,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getAllDeclinaisons() {
	let config = {
		method: "GET",
		url: STORE_API + "size_types",
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data?.filter((f) => f?.size_type_enabled === 1)
		})
}

function getSpecificDeclinaison(declinaisonId) {
	let config = {
		method: "GET",
		url: `${STORE_API}sizes/${declinaisonId}`,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getProductStockAvailable(declinaisonId) {
	let config = {
		method: "GET",
		url: `${STORE_API}stock_available/product_id/${declinaisonId}`,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getSuppliers() {
	let config = {
		method: "GET",
		url: `${STORE_API}suppliers`,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function createSupplier(name) {
	let config = {
		method: "POST",
		url: STORE_API + "suppliers",
		headers: {
			"Content-Type": "application/json",
		},
		// SANS CETTE LIGNE HIBOUTIK RETOURNE UNE ERREUR 406
		data: { supplier_name: name },
	}
	return new Promise((resolve, reject) => {
		axios(config)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => reject(error))
	})
}

async function updateSupplier(supplier_id, supplier_attribute, new_value) {
	return (
		await axios.put(`${STORE_API}suppliers`, {
			supplier_id,
			supplier_attribute,
			new_value,
		})
	)?.data?.["hydra:member"]
}

function deleteSupplier(id) {
	let config = {
		method: "DELETE",
		url: STORE_API + "suppliers/" + id,
		headers: {
			"Content-Type": "application/json",
		},
		// SANS CETTE LIGNE HIBOUTIK RETOURNE UNE ERREUR 406
		data: {},
	}
	return new Promise((resolve, reject) => {
		axios(config)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => reject(error))
	})
}

const getCustomer = async (customerId) => {
	const config = {
		method: "GET",
		url: STORE_API + "customer/" + customerId,
	}
	return await axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return await response.data
		})
}

const customerSearch = (query) => {
	let config = {
		method: "GET",
		url: STORE_API + "customers/search/" + query,
	}
	return axios.get(config.url, { headers: {} }).then(async (response) => {
		return response.data
	})
}

const createCategory = (name, backgroundColor) => {
	const config = {
		method: "POST",
		url: STORE_API + "categories",
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			category_name: name,
			category_bck_color: backgroundColor,
		},
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

const updateCategory = (id, name) => {
	const config = {
		url: STORE_API + "categories",
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			category_id: id,
			category_name: name,
		},
	}
	return axios
		.put(config.url, config.data, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function createInventoryOrder(orderId, invLabel) {
	let config = {
		method: "POST",
		url: STORE_API + "inventory_inputs",
		headers: {
			"Content-Type": "application/json",
		},
		// SANS CETTE LIGNE HIBOUTIK RETOURNE UNE ERREUR 406
		data: {
			stock_id: orderId,
			label: invLabel,
		},
	}
	return new Promise((resolve, reject) => {
		axios(config)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => reject(error))
	})
}

function addProductToInventoryOrder(
	inventoryId,
	productId,
	declinaison,
	quantity,
	product_serial,
	product_supply_price
) {
	const postData = {
		inventory_input_id: inventoryId,
		product_id: productId,
		quantity: quantity,
	}
	if (declinaison) postData.product_size = declinaison
	if (product_serial) postData.product_serial_number = product_serial
	if (product_supply_price) postData.product_price = product_supply_price

	let config = {
		method: "POST",
		url: STORE_API + "inventory_input_details/" + inventoryId,
		headers: {
			"Content-Type": "application/json",
		},
		data: { ...postData },
	}
	return new Promise((resolve, reject) => {
		axios(config)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => reject(error))
	})
}

function validateInventoryOrder(inventoryId) {
	let config = {
		method: "POST",
		url: STORE_API + "inventory_input_validate",
		headers: {
			"Content-Type": "application/json",
		},
		// SANS CETTE LIGNE HIBOUTIK RETOURNE UNE ERREUR 406
		data: {
			inventory_input_id: inventoryId,
		},
	}
	return new Promise((resolve, reject) => {
		axios(config)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => reject(error))
	})
}

function getAllProductTags() {
	const config = {
		method: "GET",
		url: STORE_API + "tags/products",
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getConfig(method, url, data) {
	const headers = {}
	if (method === "POST" || method === "PUT") {
		headers["Content-Type"] = "application/json"
	}

	const config = {
		method,
		url,
		headers,
		data,
	}

	return config
}

async function createCustomer(firstName, lastName) {
	try {
		const config = getConfig("POST", STORE_API + "customers", {
			customers_first_name: firstName,
			customers_last_name: lastName,
		})
		const response = await axios(config)
		if (response.status === 201) {
			return response.data
		}
		return {
			error: {
				status: response.status,
				message: "Impossible de créer l'utilisateur.",
			},
		}
	} catch (err) {
		throw err
	}
}

async function getProductSerialNumber(productId, sizeId) {
	try {
		const url = `${STORE_API}stock_available/sn/${productId}${sizeId ? `/${sizeId}` : "/0"}`
		let serialNumbers = []
		let page = 1

		while (1) {
			try {
				const result = (await axios.get(`${url}?p=${page}`))?.data

				serialNumbers = [...serialNumbers, ...result]
				if (!result.length || result.length < 250) {
					break
				}
				page++
			} catch (error) {
				console.error(error)
				break
			}
		}

		return serialNumbers
		// return axios.get(url).then(async (response) => {
		// 	return response.data
		// })
	} catch (err) {
		throw err
	}
}

async function getAvailableSerialNumbers(productId, sizeId) {
	try {
		const searchParams = `?productIdHiboutik=${productId}` + (sizeId ? `&sizeIdHiboutik=${sizeId}` : "")
		const usedSerialNumbers = new Set()
		const availableSerialNumbers = []

		const serialNumbers = await getProductSerialNumber(productId, sizeId)
		const usedProducts = await API.findAll("PATIENT_EQUIPMENTS_API", searchParams)

		for (let usedProduct of usedProducts) {
			if (usedProduct.serialNumber) {
				usedSerialNumbers.add(usedProduct.serialNumber)
			}
		}
		for (let serialNumber of serialNumbers) {
			const currentSn = serialNumber.product_serial_number

			if (!usedSerialNumbers.has(currentSn)) {
				availableSerialNumbers.push(currentSn)
			}
		}

		return availableSerialNumbers
	} catch (err) {
		throw err
	}
}

async function getAvailableSerialNumbersInWarehouse(warehouseId, productId, sizeId) {
	try {
		const searchParams = `?productIdHiboutik=${productId}` + (sizeId ? `&sizeIdHiboutik=${sizeId}` : "")
		const usedSerialNumbers = new Set()
		const availableSerialNumbers = []

		const serialNumbersInGlobalStock = await getProductSerialNumber(productId, sizeId)
		const serialNumbersInWarehouse = serialNumbersInGlobalStock.filter((p) => +p.warehouse_id === +warehouseId)
		const usedProducts = await API.findAll("PATIENT_EQUIPMENTS_API", searchParams)
		const usedProductsFromWarehouse = usedProducts.filter((p) =>
			p?.warehouseIdHiboutik ? +p.warehouseIdHiboutik === +warehouseId : 1
		)
		for (let usedProduct of usedProductsFromWarehouse) {
			if (usedProduct.serialNumber) {
				usedSerialNumbers.add(usedProduct.serialNumber)
			}
		}
		for (let serialNumber of serialNumbersInWarehouse) {
			const currentSn = serialNumber.product_serial_number

			if (!usedSerialNumbers.has(currentSn)) {
				availableSerialNumbers.push(currentSn)
			}
		}
		return availableSerialNumbers
	} catch (err) {
		throw err
	}
}

async function getAllSales(year, month, day = null, warehouse_id = 1) {
	const config = getConfig("GET", `${STORE_API}closed_sales/${warehouse_id}/${year}/${month}${day ? "/" + day : ""}`)
	return axios.get(config.url, { headers: config.headers }).then(async (response) => {
		return response.data
	})
}
// eslint-disable-next-line no-unused-vars
async function getOpenSales(storeId = 1) {
	const config = getConfig("GET", `${STORE_API}open_sales/${storeId}`)
	return axios.get(config.url, { headers: config.headers }).then(async (response) => {
		return response.data
	})
}

async function getSale(saleId) {
	try {
		const response = await axios.get(`${STORE_API}sales/${saleId}`, {
			headers: {},
		})

		if (response.status === 200) {
			const { data } = response
			if (data.length && data[0]) {
				return data[0]
			}
		}
		return null
	} catch (err) {
		throw err
	}
}

async function updateSalePaymentType(sale, paymentType) {
	if (sale.payment === paymentType) {
		return true
	}

	try {
		const payload = {
			sale_attribute: "payment",
			new_value: paymentType,
		}
		const config = getConfig("PUT", `${STORE_API}sale/${sale.sale_id}`, payload)
		const response = await axios(config)

		return response.status === 200
	} catch (err) {
		throw err
	}
}

async function addProductToSale(sale, product, quantity = 1, size = undefined) {
	try {
		const addProductPayload = {
			sale_id: sale.sale_id,
			product_id: product.product_id,
			quantity: quantity,
			product_price: rounded(product.discount ? product.product_price - product.discount : product.product_price),
			stock_withdrawal: 1,
			size_id: size ? size.size_id : undefined,
			serial_number: product.serialNumber ? product.serialNumber : undefined,
		}
		const addProductConfig = getConfig("POST", STORE_API + "sales/add_product", addProductPayload)
		const response = await axios(addProductConfig)

		return response.status === 200
	} catch (err) {
		throw err
	}
}

// The products must be an array of hiboutikProduct with a quantity attribute
// And the size (if available)
async function addProductsToSale(sale, products = []) {
	try {
		products.forEach(async (product) => {
			const size = product.size ? product.size : undefined
			await addProductToSale(sale, product, product.quantity, size)
		})
		return await getSale(sale.sale_id)
	} catch (err) {
		throw err
	}
}

async function deleteLineItem(sale, lineItemId) {
	try {
		const response = axios.delete(`${STORE_API}sale_line_item/${lineItemId}`, {
			headers: {},
		})
		return response.status === 200
	} catch (err) {
		throw err
	}
}

// The newProducts must be an array of hiboutikProduct with a quantity attribute
// And the size (if available)
async function updateSaleProducts(sale, newProducts) {
	const products = [...newProducts]
	const lineItems = sale.line_items

	try {
		if (lineItems.length === 0) {
			return addProductsToSale(sale, products)
		}

		for (let i = 0; i < lineItems.length; i++) {
			const productId = lineItems[i].product_id
			const lineItemId = lineItems[i].line_item_id
			const indexInProducts = products.findIndex((product) => product.product_id === productId)

			// If current product not present in the new products => delete it from the cart
			// Else remove it from the new product array so we don't add it again to the cart
			// Same product product if same id, same quantity and same size
			if (indexInProducts === -1) {
				await deleteLineItem(sale, lineItemId)
			} else {
				const sizeId = lineItems[i].product_size
				const quantity = lineItems[i].quantity
				const price = lineItems[i].product_price

				if (
					quantity !== products[indexInProducts].quantity ||
					price !== products[indexInProducts].product_price
				) {
					await deleteLineItem(sale, lineItemId)
				} else if (sizeId) {
					if (sizeId !== products[indexInProducts].product_size) {
						await deleteLineItem(sale, lineItemId)
					} else {
						products.splice(indexInProducts, 1)
					}
				} else {
					products.splice(indexInProducts, 1)
				}
			}
		}

		return addProductsToSale(sale, products)
	} catch (err) {
		throw err
	}
}

async function createSale(paymentType, customerId, storeId = 1) {
	const currencyCode = "EUR"

	try {
		// Create the sale object
		const salePayload = {
			store_id: storeId,
			currency_code: currencyCode,
			customer_id: customerId,
		}
		const createSaleConfig = getConfig("POST", STORE_API + "sales", salePayload)
		const createSaleResponse = await axios(createSaleConfig)

		if (createSaleResponse.status === 201) {
			// Add the payment method to the created sale
			const createdSale = createSaleResponse.data
			await updateSalePaymentType(createdSale, paymentType)

			// Fetch the updated sale and return it
			return await getSale(createdSale.sale_id)
		}
		return {
			error: {
				status: createSaleResponse.status,
				message: "Impossible d'effectuer la vente",
			},
		}
	} catch (err) {
		throw err
	}
}

async function addDivPayment(sale, divType, amount, date = undefined) {
	if (sale.payment !== "DIV") {
		throw new Error("Cannot add div values to a non-div payment")
	}

	try {
		const divPayload = {
			sale_id: sale.sale_id,
			payment_type: divType,
			payment_amount: amount,
			payment_date: date,
		}
		const config = getConfig("POST", STORE_API + "sales_payment_div", divPayload)
		const response = await axios(config)

		return response.status === 200
	} catch (err) {
		throw err
	}
}

// divPayments must be an array of object of type: { type: payment_type, value: payment_amount, date: payment_date (moment) }
async function addDivValuesToSale(sale, divPayments, secu, mutl) {
	if (sale.payment !== "DIV") {
		throw new Error("Cannot add div values to a non-div payment")
	}

	const oneYear = moment().add(1, "y")
	const oneYearFormatted = oneYear.format("YYYY-MM-DD")

	try {
		secu > 0 && (await addDivPayment(sale, "SECU", secu, oneYearFormatted))
		mutl > 0 && (await addDivPayment(sale, "MUTL", mutl, oneYearFormatted))

		divPayments.forEach(async (divPayment) => {
			if (divPayment.date.format("YYYY-MM-DD") === sale.created_at_date) {
				divPayment.date = "0000-00-00"
			}
			divPayment.value > 0 &&
				(await addDivPayment(
					sale,
					divPayment.type,
					divPayment.value,
					divPayment.date === "0000-00-00" ? divPayment.date : divPayment.date.format("YYYY-MM-DD")
				))
		})

		return getSale(sale.sale_id)
	} catch (err) {
		throw err
	}
}

// Remove all the products in the sale
async function emptySale(sale) {
	if (!sale.line_items) {
		return
	}
	const nbSalesItems = sale.line_items.length

	try {
		for (let i = 0; i < nbSalesItems; i++) {
			const currentItem = sale.line_items[i]
			await deleteLineItem(sale, currentItem.line_item_id)
		}
		return getSale(sale.sale_id)
	} catch (err) {
		throw err
	}
}

async function finishSale(sale) {
	try {
		const closeSalePayload = {
			sale_id: sale.sale_id,
		}
		const config = getConfig("POST", STORE_API + "sales/close", closeSalePayload)
		const response = await axios(config)

		return response.status === 200
	} catch (err) {
		throw err
	}
}

async function saleAddComment(sale_id, comment) {
	try {
		const data = {
			sale_id: sale_id,
			comments: comment,
		}
		const config = getConfig("POST", STORE_API + "sales/comments", data)
		const response = await axios(config)
		return response.status === 200
	} catch (err) {
		throw err
	}
}

const getAllTags = () => {
	const config = {
		url: STORE_API + "tags/products",
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

const deleteProductTags = async (product_id, tag_id) => {
	let config = {
		method: "DELETE",
		url: `${STORE_API}products_tags/${product_id}/${tag_id}`,
		headers: {
			"Content-Type": "application/json",
		},
	}
	return axios(config)
		.then(async (response) => {
			return response.data
		})
		.catch((err) => {
			console.error(err)
		})
}

const postProductTags = async (product_id, tags) => {
	return Promise.all(
		tags.map((tag) => {
			const data = {
				tag_id: tag,
			}
			let config = {
				method: "POST",
				url: STORE_API + "products_tags/" + product_id,
				headers: {
					"Content-Type": "application/json",
				},
				// SANS CETTE LIGNE HIBOUTIK RETOURNE UNE ERREUR 406
				data: data,
			}
			return axios(config)
		})
	)
}

const searchProductByNameAndCategorie = (productName = "", productCategory = 1) => {
	let config = {
		url: `${STORE_API}products/search?product_category=${productCategory}&product_model=${productName}&product_arch=0`,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getColorsByTypeId(sizeTypeId = 1) {
	let config = {
		method: "GET",
		url: STORE_API + "sizes/" + sizeTypeId,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

async function getSaleInvoice(saleId) {
	try {
		const data = {
			token: pdfToken,
			template: "invoice",
			saleId: saleId,
			account: endPoint.toLowerCase(),
		}
		const config = {
			method: "POST",
			url: `${API_URL}/hiboutik/invoice`,
			data: data,
			responseType: "blob",
		}
		const pdfBinary = await axios(config)

		return pdfBinary.data
	} catch (err) {
		throw err
	}
}

async function closeRegister(storeId, year, month, day) {
	let config = {
		method: "POST",
		url: `${STORE_API}z/closure/${storeId}/${year}/${month}/${day}`,
		headers: {
			"Content-Type": "application/json",
		},
	}
	try {
		return axios.request(config)
	} catch (err) {
		throw err
	}
}

function getAllTaxes() {
	let config = {
		method: "GET",
		url: STORE_API + "taxes",
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getAllCategories() {
	let config = {
		method: "GET",
		url: STORE_API + "categories",
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function updateSerialNumber(numeroId, data) {
	let config = {
		method: "PUT",
		url: STORE_API + "inventory_input_details/" + numeroId,
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			inventory_input_detail_id_attribute: "product_serial_number",
			new_value: data,
		},
	}
	return new Promise((resolve, reject) => {
		axios(config)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => reject(error))
	})
}

function getInventoryDetails(inventoryId) {
	let config = {
		method: "GET",
		url: STORE_API + "inventory_input_details/" + inventoryId,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function deleteSalePaymentDiv(paymentId) {
	let config = {
		method: "DELETE",
		url: STORE_API + "sales_payment_div/" + paymentId,
		headers: {
			"Content-Type": "application/json",
		},
	}
	return axios(config)
		.then(async (response) => {
			return response.data
		})
		.catch((err) => {
			console.error(err)
		})
}

const getAllPayments = (storeId) => {
	let config = {
		method: "GET",
		url: `${STORE_API}payments/due_date/${storeId}`,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

function getAllWarehouses() {
	let config = {
		method: "GET",
		url: STORE_API + "warehouses",
		headers: {
			"Content-type": "application/json",
		},
	}
	return axios(config)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.error(err)
		})
}

function getWarehouseStock(warehouseId = 1) {
	let config = {
		method: "GET",
		url: STORE_API + "stock_available/warehouse_id/" + warehouseId.toString(),
		headers: {
			"Content-type": "application/json",
		},
	}
	return axios(config)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.error(err)
		})
}

function getStockTransfers() {
	let config = {
		method: "GET",
		url: STORE_API + "stock_transfer",
		headers: {
			"Content-type": "application/json",
		},
	}
	return axios(config)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.error(err)
		})
}

function createStockTransfer(fromId, toId, label = "transfer") {
	let config = {
		method: "POST",
		url: STORE_API + "stock_transfer",
		headers: {
			"Content-type": "application/json",
		},
		data: {
			from_stock_id: fromId,
			to_stock_id: toId,
			label: label,
		},
	}
	return axios(config)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.error(err)
		})
}

function getStockTransferDetails(transferId) {
	let config = {
		method: "GET",
		url: `${STORE_API}stock_transfer/${transferId}`,
		headers: {
			"Content-type": "application/json",
		},
	}
	return axios(config)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.error(err)
		})
}

function addProductToTransfer(transferId, productId, sizeId, quantity) {
	let config = {
		method: "POST",
		url: `${STORE_API}stock_transfer_details/${transferId}`,
		headers: {
			"Content-type": "application/json",
		},
		data: {
			transfer_id: transferId,
			product_id: productId,
			product_size: sizeId,
			quantity: quantity ? quantity : 1,
		},
	}
	return axios(config)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.error(err)
		})
}

function validateStockTransfer(transferId) {
	let config = {
		method: "POST",
		url: `${STORE_API}stock_transfer_validate`,
		headers: {
			"Content-type": "application/json",
		},
		data: {
			transfer_id: transferId,
		},
	}
	return axios(config)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.error(err)
		})
}

const createCredit = (itemId, stockId) => {
	let config = {
		method: "PUT",
		url: `${STORE_API}sale_line_item_exchange/${itemId}/${stockId}`,
		headers: {
			"Content-Type": "application/json",
		},
	}
	return axios(config)
		.then((res) => res.data)
		.catch((err) => err)
}

const applyCredit = (saleId, creditCode) => {
	let config = {
		method: "POST",
		url: `${STORE_API}sales/add_credit_note`,
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			sale_id: saleId,
			credit_note_code: creditCode,
		},
	}
	return axios(config)
}

const searchProductByString = (string) => {
	if (string.length < 3) return
	let config = {
		url: `${STORE_API}products/search?product_model=${string}&product_arch=0`,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

const cashIn = async (payload) => {
	let config = {
		method: "POST",
		url: `${STORE_API}till/cash_in`,
		headers: {
			"Content-Type": "application/json",
		},
		data: payload,
	}
	return axios(config)
		.then((res) => res.data)
		.catch((err) => err)
}

const cashOut = async (payload) => {
	let config = {
		method: "POST",
		url: `${STORE_API}till/cash_out`,
		headers: {
			"Content-Type": "application/json",
		},
		data: payload,
	}
	return axios(config)
}

const getCashBook = async (date, storeId) => {
	let config = {
		url: `${STORE_API}till/${storeId}/${date.year}/${date.month}`,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

const getPaymentsReceived = async (date, storeId) => {
	let config = {
		url: `${STORE_API}z/payments_received/${storeId}/${date.year}/${date.month}/${date.day ?? ""}`,
	}
	return axios
		.get(config.url, {
			headers: {},
		})
		.then(async (response) => {
			return response.data
		})
}

// NOUVELLES FONCTIONS GÉNÉRIQUE POUR NOUVELLE VENTE

async function saleCreate(store_id) {
	const currency_code = "EUR"
	const data = {
		store_id,
		currency_code,
	}
	const createSaleConfig = getConfig("POST", STORE_API + "sales", data)
	return axios(createSaleConfig)
}

async function saleUpdate(sale_id, sale_attribute, new_value) {
	if (
		![
			"vendor_id",
			"customer_id",
			"billing_address",
			"shipping_address",
			"payment",
			"ext_ref",
			"store_id",
			"takeaway",
			"ressource_id",
			"currency",
			"pickup_date",
			"guests_number",
		].includes(sale_attribute)
	)
		return null

	const payload = {
		sale_attribute,
		new_value,
	}
	const config = getConfig("PUT", `${STORE_API}sale/${sale_id}`, payload)
	return axios(config)
}

async function saleAddProduct(sale_id, product, size_id, serial_number = null, quantity = 1, product_comments) {
	const data = {
		sale_id,
		product_id: product.product_id,
		quantity,
		product_price: rounded(product.discount ? product.product_price - product.discount : product.product_price),
		stock_withdrawal: 1,
		size_id,
		serial_number,
		product_comments,
	}
	const config = getConfig("POST", STORE_API + "sales/add_product", data)
	return axios(config)
}

async function saleUpdateProduct(line_item_id, line_item_attribute, new_value) {
	if (
		![
			"points",
			"product_comments",
			"serial_number",
			"stock_withdrawal",
			"product_price",
			"vat",
			"quantity",
		].includes(line_item_attribute)
	)
		return null

	const payload = {
		line_item_attribute,
		new_value,
	}
	const config = getConfig("PUT", `${STORE_API}sale_line_item/${line_item_id}`, payload)
	return axios(config)
}

async function saleEnd(sale_id) {
	const config = getConfig("POST", STORE_API + "sales/close", { sale_id })
	return axios(config)
}
async function saleDelete(sale_id) {
	const config = getConfig("DELETE", STORE_API + "sale/" + sale_id)
	return axios(config)
}

async function saleDeleteProduct(line_item_id) {
	const config = getConfig("DELETE", `${STORE_API}sale_line_item/${line_item_id}`)
	return axios(config)
}

async function customerCreate(customers_first_name, customers_last_name) {
	const config = getConfig("POST", STORE_API + "customers", {
		customers_first_name,
		customers_last_name,
	})
	return axios(config)
}

async function getPaymentsTypes(store_id) {
	const config = getConfig("GET", `${STORE_API}payment_types/${store_id}`)
	return axios(config)
}

async function saleDeleteDiv(paymentDetailId) {
	return axios.delete(`${STORE_API}sales_payment_div/${paymentDetailId}`, {
		headers: {},
	})
}

async function saleUpdateDiv(payment_detail_id, payment_div_attribute, new_value) {
	const payload = {
		payment_div_attribute,
		new_value,
	}
	const config = getConfig("PUT", STORE_API + "sales_payment_div/" + payment_detail_id, payload)
	return await axios(config)
}

async function saleAddDivPayment(sale_id, payment_type, payment_amount, payment_date = undefined) {
	const payload = {
		sale_id,
		payment_type,
		payment_amount,
		payment_date,
	}
	const config = getConfig("POST", STORE_API + "sales_payment_div", payload)
	return await axios(config)
}

async function saleGet(sale_id) {
	return await axios.get(`${STORE_API}sales/${sale_id}`, {
		headers: {},
	})
}

async function productGetTags(product_id) {
	return await axios.get(`${STORE_API}products_tags/${product_id}`, {
		headers: {},
	})
}

//

async function inventoryOrderAddProduct(
	inventory_input_id,
	product_id,
	product_size,
	quantity,
	product_serial_number,
	product_price
) {
	let config = {
		method: "POST",
		url: `${STORE_API}inventory_input_details/${inventory_input_id}`,
		headers: { "Content-Type": "application/json" },
		data: {
			product_id,
			quantity,
			product_size,
			product_serial_number,
			product_price,
		},
	}
	return axios(config)
}

function inventoryOrderCreate(label, stock_id, supplier_id) {
	let config = {
		method: "POST",
		url: STORE_API + "inventory_inputs",
		headers: { "Content-Type": "application/json" },
		data: { stock_id, label, supplier_id },
	}
	return axios(config)
}

function inventoryOrderValidate(inventory_input_id) {
	let config = {
		method: "POST",
		url: STORE_API + "inventory_input_validate",
		headers: { "Content-Type": "application/json" },
		data: { inventory_input_id },
	}
	return axios(config)
}

async function inventoryInputDetailUpdate(inventory_input_detail_id, inventory_input_detail_id_attribute, new_value) {
	if (!["product_serial_number", "product_price", "quantity"].includes(inventory_input_detail_id_attribute))
		return null

	const payload = {
		inventory_input_detail_id_attribute,
		new_value,
	}
	const config = getConfig("PUT", `${STORE_API}inventory_input_details/${inventory_input_detail_id}`, payload)
	return axios(config)
}

function inventoryOrderDetail(id) {
	let config = {
		method: "GET",
		url: STORE_API + "inventory_input_details/" + id,
		headers: {
			"Content-Type": "application/json",
		},
	}
	return axios(config)
}

function getAllSizes() {
	let config = {
		method: "GET",
		url: STORE_API + "sizes_pretty",
	}
	return axios.get(config.url, {
		headers: {},
	})
}

function getAllInventoryInputs() {
	let config = {
		method: "GET",
		url: STORE_API + "inventory_inputs",
	}
	return axios.get(config.url, {
		headers: {},
	})
}

const getAllProducts = async (options = {}) => {
	const urlSearchParams = new URLSearchParams()
	let productsTmp = []
	let page = 1

	urlSearchParams.set("product_arch", "0")
	urlSearchParams.set("product_stock_management", "1")

	if (options.value && options.value.length >= 2) urlSearchParams.set("product_model", options.value)
	if (options.archived) urlSearchParams.set("product_arch", options.archived)
	if (options.stock_management) {
		if (options.stock_management === "ALL") urlSearchParams.delete("product_stock_management")
		else urlSearchParams.set("product_stock_management", options.stock_management)
	}
	if (options.product_category) urlSearchParams.set("product_category", options.product_category)

	while (1) {
		try {
			let result = await axios.get(`${STORE_API}products/search?p=${page}&${urlSearchParams}`, {
				headers: {},
			})
			productsTmp = [...productsTmp, ...(result?.data || [])]
			page++
			if (!result.data || result?.data?.length < 250 || options?.all === false) break
		} catch (error) {
			console.error(error)
			break
		}
	}

	return productsTmp
}

const getAllTagsOfProducts = async () => {
	let page,
		tagsPerProduct = []

	page = 1

	while (1) {
		try {
			let result = await axios.get(STORE_API + "products_tags/?p=" + page, {
				headers: {},
			})
			tagsPerProduct = [...tagsPerProduct, ...(result?.data || [])]
			page++
			if (!result.data || result?.data?.length < 250) break
		} catch (error) {
			console.error(error)
			break
		}
	}

	return tagsPerProduct
}

const API_Hiboutik = {
	getAllTags,
	postProductTags,
	getSaleInvoice,
	searchProductByNameAndCategorie,
	updateSaleProducts,
	emptySale,
	finishSale,
	addDivValuesToSale,
	updateSalePaymentType,
	addProductsToSale,
	addProductToSale,
	createSale,
	getProductById,
	updateProduct,
	createProduct,
	searchProductByName,
	getBrands,
	getAllProductsStocks,
	getAllDeclinaisons,
	getSpecificDeclinaison,
	getProductStockAvailable,
	getSuppliers,
	createSupplier,
	updateSupplier,
	deleteSupplier,
	getCategory,
	createCategory,
	updateCategory,
	createInventoryOrder,
	addProductToInventoryOrder,
	validateInventoryOrder,
	getAllProductTags,
	getProductsByCategory,
	getAllSales,
	getOpenSales,
	getSale,
	saleAddComment,
	createCustomer,
	getColorsByTypeId,
	getStocksForProduct,
	getCustomer,
	getProductSerialNumber,
	getAvailableSerialNumbers,
	getAvailableSerialNumbersInWarehouse,
	hiboutikToken,
	pdfToken,
	endPoint,
	getEndpoint,
	getToken,
	closeRegister,
	getAllTaxes,
	getCategoryIdOf,
	updateSerialNumber,
	getInventoryDetails,
	deleteSalePaymentDiv,
	createBrand,
	updateBrand,
	getAllPayments,
	init,
	getAllWarehouses,
	getWarehouseStock,
	getStockTransfers,
	createStockTransfer,
	getStockTransferDetails,
	addProductToTransfer,
	validateStockTransfer,
	createCredit,
	applyCredit,
	deleteProductTags,
	getAllCategories,
	searchProductByString,
	cashIn,
	cashOut,
	getCashBook,
	getPaymentsReceived,
	customerSearch,
	customerCreate,
	saleCreate,
	saleUpdate,
	saleDelete,
	saleAddProduct,
	saleUpdateProduct,
	saleDeleteProduct,
	getPaymentsTypes,
	saleDeleteDiv,
	saleAddDivPayment,
	saleUpdateDiv,
	saleGet,
	saleEnd,
	productGetTags,
	getAllProducts,
	inventoryOrderAddProduct,
	inventoryOrderCreate,
	inventoryOrderValidate,
	inventoryInputDetailUpdate,
	inventoryOrderDetail,
	getAllSizes,
	getAllInventoryInputs,
	getAllTagsOfProducts,
}

export default API_Hiboutik
