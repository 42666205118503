/**
 * Check if the variable is a Primitive
 * Note: switch statement is more efficient than an if statement
 * @param variable
 */
export function isPrimitive(variable: unknown): boolean {
	switch (typeof variable) {
		case "string":
		case "number":
		case "bigint":
		case "boolean":
		case "undefined":
		case "symbol":
			return true
		default:
			// eslint-disable-next-line eqeqeq
			return variable == null
	}
}
