/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "antd"
import { useEffect, useRef, useState } from "react"
import API from "services/API"

const { Option } = Select

function AsyncUserSelect({
	setData,
	data,
	className = "",
	disabled = false,
	noAssistant,
	size = "medium",
	placeholder = "Sélectionner un ou des utilisateurs",
	onClear,
	mode = "multiple",
	onChange,
	allowClear = true,
	allowedLaboratories,
}) {
	const [usersList, setUsersList] = useState([])
	const [list, setList] = useState([])

	const [loading, setLoading] = useState(false)
	let searchTimeout = useRef(null)

	const populateOptions = (users) => {
		let options = []
		for (const user of users) {
			let name = `${user.firstName} ${user.lastName}`
			if (name.length >= 28) {
				name = `${name.slice(0, 28)}...`
			}
			options.push({
				detail: user,
				value: +user.id,
				label: name,
			})
		}
		setUsersList(options)
	}

	async function handleSearch(p) {
		try {
			setLoading(true)

			const searchParams = new URLSearchParams()
			if (p != null) {
				searchParams.set("omnisearch", p)
			}
			if (Array.isArray(allowedLaboratories)) {
				for (const laboratoryId of allowedLaboratories) searchParams.append("laboratories[]", laboratoryId)
			}

			let users = await API.findAll("USERS_API", `?${searchParams}`)
			if (noAssistant) users = users.filter((f) => !f?.roles?.includes("ROLE_ASSISTANTE"))

			setList(users)
			populateOptions(users)
			setLoading(false)
		} catch (e) {
			console.error("=> Error : ", e)
		}
	}

	useEffect(() => {
		clearTimeout(searchTimeout.current)
		searchTimeout.current = null
		searchTimeout.current = setTimeout(() => {
			handleSearch("")
		}, 100)
	}, [allowedLaboratories])

	return (
		<div className="w-100">
			<Select
				maxTagCount={2}
				allowClear={allowClear}
				mode={mode}
				//onClear={() => setData({})}
				filterOption={false}
				loading={loading}
				disabled={disabled}
				className={className}
				notFoundContent={"Aucun résultat"}
				showSearch
				placeholder={placeholder}
				size={size}
				onClear={onClear}
				onSearch={(e) => {
					clearTimeout(searchTimeout.current)
					searchTimeout.current = null
					searchTimeout.current = setTimeout(() => {
						handleSearch(e)
					}, 400)
				}}
				value={(function () {
					if (mode === "multiple") return [...(data || [])].map((v) => +v.id)
					else return data
				})()}
				onSelect={(e) => {
					if (mode === "multiple") {
						const tmp = [...(data || [])]
						const l = list.find((f) => +f.id === +e)
						if (l) tmp.push({ ...l })
						setData(tmp)
					}
				}}
				onDeselect={(e) => {
					if (mode === "multiple") {
						let tmp = [...data]
						const id = tmp.findIndex((f) => +f.id === +e)
						if (id !== -1) tmp.splice(id, 1)
						setData(tmp)
					}
				}}
				onChange={(e) => {
					if (typeof onChange === "function") onChange(e)
					if (typeof setData === "function") setData(e)
				}}>
				{usersList.map((d) => (
					<Option value={d.value} key={d.value}>
						{d.label}
					</Option>
				))}
			</Select>
		</div>
	)
}

export default AsyncUserSelect
