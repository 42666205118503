/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react"
import AuthContext from "../../contexts/AuthContext"
import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap"
import { Checkbox } from "antd"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"

import API from "../../services/API"
import { transmettreXml } from "./functionsFSV"
import {
	replaceAmcWithAdriAmc as setUserAndPatientInsurance,
	replaceAmoWithAdriAmo as setPatientSocialSecurityInDb,
} from "pages/etapes/0-premiere-visite/4-informations-patient/5-couvertures-patient/utils-covers"

export default function UpdateModal({
	isOpen,
	data,
	patient,
	setPatient,
	onCloseMethod,
	setLoading,
	multiplePatients = null,
	multiplePatientsMethod,
	updateBeneficiary,
	setUpdateBeneficiary,
	xml,
	setIdFSV,
}) {
	const history = useHistory()
	const ctx = useContext(AuthContext)
	const [dataList, setDataList] = useState([])
	const [updateList, setUpdateList] = useState([])
	const [creating, setCreating] = useState(false)
	const [busy, setBusy] = useState(false)

	const dateHandler = (date, mode) => {
		if (!date) return null
		return dayjs(date).format(mode === "aw" ? "YYYY-MM-DD" : "DD/MM/YYYY")
	}

	const compareAmc = async (newAmc) => {
		const amcs = await API.findAll("PATIENT_INSURANCE_API", "?patient=" + patient.id)
		const diffList = []
		for (const amc of newAmc) {
			const sameAmc = amcs.some(
				(oldAmc) =>
					oldAmc.amcId === amc.insuranceId &&
					oldAmc.startDate === dateHandler(amc.startDate, "aw") &&
					oldAmc.endDate === dateHandler(amc.endDate, "aw")
			)
			if (!sameAmc) {
				diffList.push({
					label: `Mutuelle ${amc.insuranceId} du ${dateHandler(amc.startDate)} au ${dateHandler(
						amc.endDate
					)}`,
					value: {
						type: "amc",
						insuranceId: amc.insuranceId,
						startDate: amc.startDate || null,
						endDate: amc.endDate || null,
					},
				})
			}
		}
		return diffList
	}

	const compareAmo = async (newAmo) => {
		const amos = await API.findAll("PATIENT_SECU_API", "?patient=" + patient.id)
		const diffList = []
		// POUR CHAQUE COUVERTURE EN CARTE, ON CHECK SI LA MEME COUVERTURE EXISTE EN BDD
		for (const amo of newAmo) {
			const sameAmo = amos.some(
				(oldAmo) =>
					oldAmo.situationCode === amo.situationCode &&
					dateHandler(oldAmo.startDate) === dateHandler(amo.startDate) &&
					dateHandler(oldAmo.endDate) === dateHandler(amo.endDate)
			)
			// SI C'EST PAS LE CAS, ELLE EST NOUVELLE => ON LA PROPOSE A l'AJOUT
			if (!sameAmo) {
				diffList.push({
					label: `Couverture sécu ${amo.situationCode} ${Number(amo.ALD) ? "en ALD" : "hors ALD"} ${
						amo.startDate && amo.endDate
							? ` valable du ${dateHandler(amo.startDate)} au ${dateHandler(amo.endDate)}`
							: "sans dates de validité"
					}`,

					value: {
						type: "amo",
						situationCode: amo.situationCode,
						codeAld: amo.ALD,
						startDate: amo.startDate || null,
						endDate: amo.endDate || null,
					},
				})
			}
		}
		return diffList
	}

	const compareAdress = () => {
		const adress = data.adresse
		const cpo = data.city?.slice(0, 5)
		const city = data.city?.slice(6)

		if (!adress || !city || !cpo) return false

		const different = adress !== patient.adress || city !== patient.city || cpo !== patient.cpo
		if (different) {
			return {
				label: `${adress}, ${cpo} - ${city}`,
				value: {
					type: "adress",
					adress,
					city,
					cpo,
				},
			}
		}
		return false
	}

	const compareSecuNumber = () => {
		if (!data?.numeroSecuCommun || !data?.cleNumeroSecuCommun) return false

		const secuNumber = data.numeroSecuCommun + data.cleNumeroSecuCommun
		if (secuNumber !== patient?.idSecu) {
			return {
				label: "Numéro de sécurité sociale: " + secuNumber,
				value: {
					type: "secuNumber",
					secuNumber,
				},
			}
		}
		return false
	}

	const compareBirthDate = () => {
		if (!data?.dateNaissanceBdd) return false

		const birthDate = data.dateNaissanceBdd
		if (!dayjs(birthDate).isSame(patient.birthDate)) {
			return {
				label: `Date de naissance: ${dayjs(birthDate).format("DD/MM/YYYY")}`,
				value: {
					type: "birthDate",
					birthDate: dayjs(birthDate).format("YYYY-MM-DD"),
				},
			}
		}
		return false
	}

	const desctructuredData = async () => {
		const list = []

		if (!patient?.idFSV || patient?.idFSV == null) {
			list.push({ label: "Activer la télétransmission pour ce patient", value: "idFSV" })
			setUpdateList([...updateList, { type: "idFSV" }])
		}

		const secuNumber = compareSecuNumber()
		const adress = compareAdress()
		const birthDate = compareBirthDate()
		const amc = await compareAmc(data.mutuelles)
		const amo = await compareAmo(data.couverturesAMO)
		if (amc.length) list.push(...amc)
		if (amo.length) list.push(...amo)
		if (secuNumber) list.push(secuNumber)
		if (adress) list.push(adress)
		if (birthDate) list.push(birthDate)

		setDataList(list)
	}

	useEffect(() => {
		if (multiplePatients || !patient?.id || !data || !isOpen) return
		desctructuredData()
	}, [data, patient, updateBeneficiary, multiplePatients])

	const handleCheckboxChange = (value) => {
		let newArray = [...updateList]
		if (!updateList.includes(value)) {
			newArray.push(value)
		} else {
			newArray.splice(newArray.indexOf(value), 1)
		}
		setUpdateList(newArray)
	}

	const confirm = async () => {
		onCloseMethod({})
		let patientCreation = {}
		let updatedPatient
		let isFsvFileToBeUpdated = patient.idFSV && updateList.filter((update) => update.type === "amo")

		if (isFsvFileToBeUpdated) await transmettreXml(xml, ctx.user, ctx.laboratory, data?.indexInCard, patient.idFSV)
		if (updateList.filter((update) => update.type === "idFSV") && !patient.idFSV)
			patientCreation = await transmettreXml(xml, ctx.user, ctx.laboratory, data?.indexInCard)

		if (!patientCreation?.error) {
			for await (const update of updateList) {
				switch (update.type) {
					case "amo":
						await setPatientSocialSecurityInDb({ ...update[0], ...data }, patient)
						break

					case "amc":
						await setUserAndPatientInsurance(update, patient)
						break

					case "idFSV":
						updatedPatient = { ...updatedPatient, idFSV: +patientCreation }
						break

					case "secuNumber":
						updatedPatient = { ...updatedPatient, idSecu: update.secuNumber }
						break

					case "birthDate":
						updatedPatient = { ...updatedPatient, birthDate: update.birthDate }
						break

					case "adress":
						updatedPatient = {
							...updatedPatient,
							adress: update.adress,
							city: update.city,
							cpo: update.cpo,
						}
						break

					default:
						console.log("Aucune donnée à mettre à jour n'a été sélectionnée.")
				}
			}

			if (updatedPatient && updateList.length !== 0) await API.update("PATIENTS_API", patient.id, updatedPatient)
		}

		ctx.setUiDisplay((old) => ({ ...old, lectureCVModal: false }))
		history.push(`/fiche-patient/${patient.id}`)
	}

	const AvailableData = () => {
		if (multiplePatients) {
			return (
				<>
					<div className="mb-4">
						<p>Plusieurs patients portant ce nom existent en base. De qui s'agit-il ?</p>
					</div>
					{multiplePatients.map((p, i) => {
						return (
							<div key={p.id} className="d-flex justify-content-between">
								<div>
									<strong>
										{p.firstName} {p.lastName}
									</strong>
									<br />
									Né le {dayjs(p.birthDate).format("L")}
									<br />
									{p.adress && p.city && p.cpo && `Habitant au ${p.adress} - ${p.city} ${p.cpo}`}
									{i + 1 !== multiplePatients.length ? <hr /> : null}
								</div>
								<Button
									className="h-10"
									color="info"
									onClick={async () => {
										await API.find("PATIENTS_API", p.id).then((data) => {
											setUpdateBeneficiary(data)
											setPatient(data)
											multiplePatientsMethod(false)
										})
									}}>
									Mettre à jour ce patient
								</Button>
							</div>
						)
					})}
				</>
			)
		} else if (dataList.length) {
			return (
				<>
					<p>Les données suivantes ne correspondent pas aux données en base:</p>
					{dataList.map((d, i) => (
						<div key={d.type} className={i > 0 ? "" : "ml-0"}>
							<Checkbox
								onChange={() => handleCheckboxChange(d.value)}
								defaultChecked={d.value === "idFSV"}
								checked={updateList.includes(d.value)}>
								{d.label}
							</Checkbox>
						</div>
					))}
				</>
			)
		} else {
			return <p>Aucune donnée à mettre à jour.</p>
		}
	}

	const ConfirmNewPatient = () => {
		setLoading(true)

		return (
			<>
				<p>Êtes-vous sûr de vouloir créer une nouvelle fiche patient ?</p>
				<p>Un ou plusieurs patients portant le même nom de famille existent déjà.</p>
			</>
		)
	}

	const Btns = () => {
		const create = async () => {
			setBusy(true)
			const idFsv = await transmettreXml(xml, ctx.user, ctx.laboratory, data?.indexInCard)
			await setIdFSV(idFsv)
			setLoading(false)
			onCloseMethod(false)
		}

		const cancel = () => {
			setBusy(true)
			setCreating(false)
			setLoading(false)
		}

		if (creating) {
			return (
				<div className="d-flex justify-content-around flex-grow-1">
					<button className="btn btn-outline-danger flex-grow-1" onClick={cancel} disabled={busy}>
						Non
					</button>
					<button onClick={create} disabled={busy} className="btn btn-outline-primary flex-grow-1">
						Oui
					</button>
				</div>
			)
		} else if (updateList.length) {
			return (
				<button
					className="btn btn-primary"
					onClick={async () => {
						setBusy(true)
						await confirm()
					}}
					disabled={busy}>
					Confirmer
				</button>
			)
		} else if (multiplePatients?.length) {
			return ctx?.user?.adeli && ctx.laboratory?.finess ? (
				<button className="btn btn-primary" onClick={() => setCreating(true)}>
					Créer un nouveau patient
				</button>
			) : (
				<div>
					La création de patient est impossible, votre {!ctx?.user?.adeli ? "numéro rpps" : "numéro finess"}{" "}
					est manquant. Veuillez lire votre CPS dans <strong>"Mes informations"</strong> pour continuer.{" "}
				</div>
			)
		} else {
			return (
				<button className="btn btn-primary" onClick={() => onCloseMethod(false)}>
					Fermer
				</button>
			)
		}
	}

	return (
		<Modal isOpen={isOpen} centered>
			<ModalHeader>
				<div className="modal-custom-header">
					<div className="modal-custom-header">Données à mettre à jour</div>
					<span
						style={{ cursor: "pointer" }}
						onClick={() => {
							onCloseMethod(false)
							setLoading(false)
						}}>
						<i className="float-right fad fa-2x fa-times-circle" />
					</span>
				</div>
			</ModalHeader>
			<ModalBody>{creating ? <ConfirmNewPatient /> : <AvailableData />}</ModalBody>
			<ModalFooter>
				<Btns />
			</ModalFooter>
		</Modal>
	)
}
