import { lazy } from "react"
import { CardWithTabs, CardItem } from "components/CardWithTabs/Card"

import ReturnFormList from "./Return/ReturnFormList"
import Equipment from "./Equipped/Equipped"
import MarketValue from "./MarketValue/MarketValue"
import ReturnForm from "./Return/ReturnForm"
import Exports from "./Exports/Exports"
import InventoryList from "./Inventory/InventoryList"
import Inventory from "./Inventory/Inventory"
import DepotList from "./Depots/Depots"

const Stock = lazy(() => import("./Stock/Stock"))

const StockRouter = (): JSX.Element => {
	return (
		<CardWithTabs urlprefix="/mon-stock">
			<CardItem name={"Stock"} url={"/stock"} component={Stock} icon="fa-warehouse-alt" />

			<CardItem name={"Appareillés"} url={"/appareilles"} component={Equipment} icon="fa-ear" />

			<CardItem name={"Dépots et ferme"} url={"/depots"} component={DepotList} icon="fa-hourglass" />

			<CardItem name={"Retours"} url={"/retours"} component={ReturnFormList} icon="fa-clock" />

			<CardItem
				name="Nouveau retour"
				url="/nouveau-retour"
				component={ReturnForm}
				icon="fa-clock"
				onlyVisibleOnExactRoute
			/>
			<CardItem
				name="Editer un retour"
				url="/retour/:id"
				component={ReturnForm}
				icon="fa-clock"
				onlyVisibleOnExactRoute
			/>

			<CardItem name={"Inventaire"} url={"/inventaire"} component={InventoryList} icon="fa-box-check" />
			<CardItem
				name={"Nouvel Inventaire"}
				url={"/nouvel-inventaire"}
				component={Inventory}
				icon="fa-box-check"
				onlyVisibleOnExactRoute
			/>
			<CardItem
				name={"Inventaire"}
				url={"/inventaire/:id"}
				component={Inventory}
				icon="fa-box-check"
				onlyVisibleOnExactRoute
			/>
			<CardItem name={"Valeur marchande"} url={"/valeur-marchande"} component={MarketValue} icon="fa-sack" />
			<CardItem name={"Export"} url={"/exports"} component={Exports} icon="fa-download" />
		</CardWithTabs>
	)
}

export default StockRouter
