import { useState } from "react"

import DOMPurify from "dompurify"

import { useMount } from "hooks/global/useMount"
import { DocumentationPageIdentifier, getDocumentationContent } from "services/api/confluence/api"

import "../../assets/scss/Modals/DocumentationModal.scss"

type DocumentationModalDataProps = {
	id: DocumentationPageIdentifier
}

export function DocumentationModalData({ id }: DocumentationModalDataProps): JSX.Element {
	const [documentationData, setDocumentationData] = useState("")
	const [loading, setLoading] = useState(false)

	useMount(fetchData)

	async function fetchData(): Promise<void> {
		setLoading(true)
		try {
			const confluenceData = await getDocumentationContent(id)
			setDocumentationData(confluenceData.data.body!)
			setLoading(false)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className="documentation-container">
			{loading ? (
				<div className="loading-documentation">
					{/* <Spinner /> */}
					<div className="overlay-loading-aw">
						<div className="overlay-loading-logo" />
						<div className="overlay-loading-text">Recherche de la documentation...</div>
					</div>
				</div>
			) : (
				<>
					<div className="d-flex justify-content-center  my-2">
						<a
							className="btn btn-outline-primary"
							href={`/documentation/${id}`}
							target="_blank"
							rel="noreferrer noopener">
							<i className="fad fa-external-link mx-1" />
							Ouvrir dans un nouvel onglet
						</a>
					</div>

					<div className="documentation-data">
						<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(documentationData) }} />
					</div>
				</>
			)}
		</div>
	)
}
