import API from "../../services/API"
import { formatDatetimeForDB } from "../../services/functions"
import { updateTodo } from "./handleTodos"
import lodash from "lodash"

async function updateDatabaseWithDiff(database, context, target) {
	if (database !== context) {
		const diff = context
			.filter((x) => {
				return database.findIndex((el) => lodash.isEqual(x, el)) === -1
			})
			.concat(
				database.filter((x) => {
					return context.findIndex((el) => lodash.isEqual(x, el)) === -1
				})
			)

		diff.forEach(async (data) => {
			data["@id"] ? await API.delete(data["@id"]) : await API.create(target, data)
		})
	}
}

export default async function updateDatabase(ressource, setRessource, api, schedule) {
	if (!ressource || ressource.length === 0 || !schedule?.["@id"]) return
	const added = ressource.filter((e) => !e["@id"])
	added.forEach((element) => {
		Object.assign(element, { schedule: schedule["@id"] })
	})
	added.forEach(async (e) => {
		await API.create(api, e)
	})
	Array.isArray(ressource) ? setRessource([]) : setRessource({})
}

export async function saveEvents(ctx) {
	try {
		await updateDatabase(ctx.eventProsthetics, ctx.setEventProsthetics, "EVENT_PROSTHETICS_API", ctx.schedule)
		await updateDatabase(ctx.eventExplanations, ctx.setEventExplanations, "EVENT_EXPLANATIONS_API", ctx.schedule)
		await updateDatabase(ctx.eventOtoscopies, ctx.setEventOtoscopies, "EVENT_OTOSCOPIES_API", ctx.schedule)

		const isSuiteVisite = ctx.schedule?.status === "SUITE_VISITE" || ctx.schedule?.detail?.action === "SUITE_VISITE"

		if (isSuiteVisite) {
			await updateDatabaseWithDiff(ctx.patient.patientOrigins, ctx.patientOrigins, "PATIENT_ORIGINS_API")
			await updateDatabaseWithDiff(ctx.patient.patientGenes, ctx.patientGenes, "PATIENT_GENES_API")
			await updateDatabaseWithDiff(ctx.patient.patientMedicals, ctx.patientMedicals, "PATIENT_MEDICALS_API")
			await updateDatabaseWithDiff(
				ctx.patient.patientLookingFors,
				ctx.patientLookingFors,
				"PATIENT_LOOKING_FORS_API"
			)

			if (ctx?.patientAcouphenes?.length) {
				ctx.patientAcouphenes.forEach(async (patientAcouphene) => {
					const tmpPatientAcouphene = {
						gene: patientAcouphene.gene,
						intensity: patientAcouphene.intensity,
						frequency: patientAcouphene.frequency,
						type: patientAcouphene.type,
						tonality: patientAcouphene.tonality,
						thi: patientAcouphene.thi,
					}
					if (tmpPatientAcouphene.gene > 0 || tmpPatientAcouphene.intensity > 0) {
						if (patientAcouphene["@id"])
							await API.update("PATIENT_ACOUPHENES_API", patientAcouphene.id, tmpPatientAcouphene)
						else await API.create("PATIENT_ACOUPHENES_API", patientAcouphene)
					} else {
						if (patientAcouphene["@id"]) await API.delete(patientAcouphene["@id"])
					}
				})
			}
			if (ctx?.patient?.id) {
				const tmpPatient = {
					firstName: ctx?.patient?.firstName,
					lastName: ctx?.patient?.lastName,
					birthDate: ctx?.patient?.birthDate,
					gender: ctx?.patient?.gender,
					profession: ctx?.patient?.profession,
					phone: ctx?.patient?.phone,
					phoneMobile: ctx?.patient?.phoneMobile,
					email: ctx?.patient?.email,
					cpo: ctx?.patient?.cpo,
					city: ctx?.patient?.city,
					adress: ctx?.patient?.adress,
					idSecu: ctx?.patient?.idSecu,
					gdprPersonalAgreement: ctx?.patient?.gdprPersonalAgreement,
					gdprMedicalAgreement: ctx?.patient?.gdprMedicalAgreement,
					gdprCommercialAgreement: ctx?.patient?.gdprCommercialAgreement,
					gdprScheduleAgreement: ctx?.patient?.gdprScheduleAgreement,
					gdprDetectionAgreement: ctx?.patient?.gdprDetectionAgreement,
				}
				await API.update("PATIENTS_API", ctx.patient.id, tmpPatient).catch((e) => {
					console.error(e)
				})
			}
		}

		let eventWorkshopIndex = 0
		// eslint-disable-next-line no-unused-vars
		for (const ear in ctx.eventWorkshops) {
			if (ear.schedule) {
				if (ctx.patient.patientEquipments.length) {
					Object.assign(ctx.eventWorkshops[ear], {
						patientEquipment: ctx.patient.patientEquipments[eventWorkshopIndex]["@id"],
					})
					eventWorkshopIndex += 1
				}
				if (ear.replacement !== true) {
					Object.assign(ctx.eventWorkshops[ear], {
						replacement: false,
					})
				}
				ear.patientEquipment && (await API.create("EVENT_WORKSHOPS_API", ctx.eventWorkshops[ear]))
			}
		}
		ctx.setEventWorkshops({
			DROITE: { afterCleaning: ["RAS"] },
			GAUCHE: { afterCleaning: ["RAS"] },
		})
		ctx.setIsBlockingContext(false)
	} catch (error) {
		console.error(error)
	}
}

export async function updateSchedule(ctx, hours, setSaved) {
	const supl = {}
	const schedule = ctx?.schedule

	// A REFAIRE SAUVEGARDE SI MODIF

	if (!schedule /*|| schedule?.alreadySaved*/) {
		if (typeof setSaved === "function") setSaved(true)
		return
	}
	try {
		if (!["VISITE", "RELANCE", "NONE"].includes(schedule.detail?.action || schedule.status)) await saveEvents(ctx)

		await updateTodo(ctx)
		if (schedule.origin === "NEW") supl.dateEnd = formatDatetimeForDB()
		supl.dateRealisation = formatDatetimeForDB()

		if (
			schedule.origin === "EXISTING" &&
			(schedule.status === "VISITE" || schedule?.detail?.action === "VISITE") &&
			schedule.premiereVisiteSchedule
		) {
			const firstVisit = schedule.premiereVisiteSchedule
			await API.update("SCHEDULES_API", firstVisit.id, {
				state: "DONE",
			})
			supl.dateOf = firstVisit.dateOf
			supl.dateEnd = firstVisit.dateEnd
		} else {
			await API.update("SCHEDULES_API", schedule.id, {
				notes: ctx.notesGeneratedText + ctx.notesVisiteText,
				hours: parseInt(hours || 0),
				state: "DONE",
				...supl,
			})
		}
		if (typeof setSaved === "function") setSaved(true)
	} catch (e) {
		console.error(e)
	}
}
