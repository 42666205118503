import { Patient, PatientInsurance } from "@audiowizard/common"
import { EquipmentCategory, EquipmentStatus } from "constants/patient/equipment"
import { c2sAmcs } from "datas/teletrans"

interface HibouTag {
	tag_id: number
	tab_label: string
}

export default function usePatientClass(
	patient: Patient & { patientInsurances: PatientInsurance[] }
): [number | undefined, boolean | undefined] {
	if (!patient) return [undefined, undefined]

	const patientHasCmu = patient.patientInsurances?.some((insurance) => c2sAmcs.includes(insurance.amcId ?? ""))

	const filteredEquipments = patient.patientEquipments?.filter(
		(equipment) =>
			[EquipmentStatus.Essai, EquipmentStatus.Vendu, EquipmentStatus.Exterieur].includes(
				equipment?.status as EquipmentStatus
			) && equipment.category === EquipmentCategory.Auditif
	)

	//@ts-ignore
	filteredEquipments?.sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())

	/**
	 * tag_id 23 -> represente la classe 1
	 * tag_id 24 -> represente la classe 2
	 * tag_label -> classe : "1" "2"
	 */

	const patientLastEquipmentClass = +(
		filteredEquipments?.[0]?.class ||
		filteredEquipments?.[0]?.customTags?.classe ||
		filteredEquipments?.[0]?.productJsonHiboutik?.class ||
		filteredEquipments?.[0]?.productJsonHiboutik?.tags?.find((tag: HibouTag) => [23, 24].includes(tag.tag_id))
			?.tag_label ||
		0
	)

	return [patientLastEquipmentClass, patientHasCmu]
}
