/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import { useState } from "react"
import { withRouter } from "react-router-dom"
import FinEvenementModalRework from "../../pages/rendez-vous/6-fin-evenement/FinEvenementModal_Rework"
import API from "../../services/API"
import OmnisearchPatient from "../effects/Omnisearch"

const RecherchePatient = ({ history }) => {
	const [showFinEvtModal, setFinEvtModal] = useState(false)

	const setFullPatient = async (data) => {
		if (data?.type !== "PATIENT") {
			return null
		}
		try {
			await API.find("PATIENTS_API", data.id)
			history.push("/fiche-patient/" + data.id)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<OmnisearchPatient
				id="patientSearch"
				titleTextSearch="Mes patients"
				//ressources={patients}
				onClick={setFullPatient}
				//target="/patients"
				//setRessources={setPatients}
				arrowNav={true}
			/>
			{showFinEvtModal && (
				<FinEvenementModalRework showFinEvtModal={() => setFinEvtModal(false)} dataCy="nouveau-patient" />
			)}
		</>
	)
}

RecherchePatient.propTypes = {
	id: PropTypes.string,
	placeholder: PropTypes.string,
}

export default withRouter(RecherchePatient)
