/* eslint-disable @typescript-eslint/ban-types */
import { useContext } from "react"
import AsyncPatientSelect from "components/utils/AsyncPatientSelect"
import AuthContext from "contexts/AuthContext"
import { Laboratory } from "@audiowizard/common"

export type FiltersType = {
	serialNumber: string
	status: string
	patient: any
	warehouseId: number
}

type FiltersProps = {
	filters: FiltersType
	setFilters: Function
}

const Filters = ({ setFilters, filters }: FiltersProps): JSX.Element => {
	const { user } = useContext(AuthContext)

	return (
		<div className="filter-container">
			<div className="row ">
				<div className="col">
					<div className="row">
						<div className="col">
							<label>Rechercher par numéro de série</label>
							<input
								value={filters.serialNumber}
								onChange={(e) => {
									const value = e.target.value
									setFilters((old: FiltersType) => ({ ...old, serialNumber: value }))
								}}
								type="text"
								className="form-control form-control-sm"
								placeholder="P50-POTATO..."
							/>
						</div>
						<div className="col">
							<label>Par patient</label>
							<br />
							<div className="antd-bootstrapped-select bootstrapped-sm">
								<AsyncPatientSelect
									bootstrapStyle={true}
									setData={(patient: any) => {
										setFilters((old: FiltersType) => ({ ...old, patient }))
									}}
								/>
							</div>
						</div>
						<div className="col">
							<label>Par statut</label>
							<select
								onChange={(e) => {
									const value = e.target.value
									setFilters((old: FiltersType) => ({ ...old, status: value }))
								}}
								value={filters.status ?? "ALL"}
								className="form-control form-control-sm">
								<option value="ALL">Tout voir</option>
								<option value="VENDU">Vendu</option>
								<option value="ESSAI">En Essai</option>
								<option value="ATTENTE">En Attente</option>
								<option value="SAV">En SAV</option>
							</select>
						</div>
						<div className="col">
							<label>Par laboratoire</label>
							<select
								disabled={filters.status === "ATTENTE"}
								onChange={(evt) => {
									const value = evt.target.value
									setFilters((old: FiltersType) => ({ ...old, warehouseId: value }))
								}}
								value={filters.warehouseId}
								className="form-control form-control-sm">
								{
									//@ts-ignore
									(user.laboratories ?? [])
										.filter((f: Laboratory) => f.warehouseIdHiboutik)
										.map((v: Laboratory, k: number) => (
											<option value={v.warehouseIdHiboutik} key={k}>
												{v.label}
											</option>
										))
								}
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Filters
