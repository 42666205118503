import { EditingState, ViewState } from "@devexpress/dx-react-scheduler"
import {
	Appointments,
	DragDropProvider,
	EditRecurrenceMenu,
	Scheduler,
	WeekView,
} from "@devexpress/dx-react-scheduler-material-ui"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import AuthContext from "../../contexts/AuthContext"
import api from "../../services/API"
import { colorFromStr, setCurrentAttendance } from "../../services/functions"

export const dayHashtable = ["DIMANCHE", "LUNDI", "MARDI", "MERCREDI", "JEUDI", "VENDREDI", "SAMEDI"]

const ModalPlageHorraire = ({ laboratoryId, user }) => {
	const queryClient = useQueryClient()
	const {
		user: userContext,
		laboratories,
		laboratory,
		setLaboratory,
		setLaboratoryAttendances,
	} = useContext(AuthContext)
	const [items, setItems] = useState([])

	const { data: laboratoryAttendancesLoc } = useQuery(
		["LABORATORY_ATTENDANCES_API", { user: user.id }],
		async () => await api.findAll("LABORATORY_ATTENDANCES_API", `?user=${user.id}`)
	)

	useEffect(() => {
		if (laboratoryAttendancesLoc == null) return

		// WeekView only supports dates, so convert the attendance times to current week's dates
		const items = laboratoryAttendancesLoc.map((attendance) => {
			// Turn attendance.day into current week's day
			const weekDay =
				attendance.day === "DIMANCHE"
					? dayjs() // Sunday must be next week
							.week(dayjs().week() + 1)
							.day(0)
					: dayjs().day(dayHashtable.indexOf(attendance.day))

			// Parse time from DB
			const startTime = dayjs(attendance.timeStart, "HH:mm")
			const endTime = dayjs(attendance.timeEnd, "HH:mm")

			const startDate = weekDay.set("hour", startTime.hour()).set("minute", startTime.minute())
			const endDate = weekDay.set("hour", endTime.hour()).set("minute", endTime.minute())

			return {
				id: attendance.id,
				"@id": attendance?.["@id"],
				day: attendance.day,
				title: attendance.laboratory.label,
				startDate: startDate.toDate(),
				endDate: endDate.toDate(),
				laboratoryId: attendance.laboratory.id,
			}
		})
		setItems(items)
	}, [laboratoryAttendancesLoc])

	useEffect(() => {
		if (!userContext || !user || userContext.id !== user.id) return
		setLaboratoryAttendances(laboratoryAttendancesLoc)
	}, [laboratoryAttendancesLoc, userContext, user])

	useEffect(() => {
		if (!userContext || !user || userContext.id !== user.id) return
		if (laboratoryAttendancesLoc == null || laboratoryAttendancesLoc.length === 0 || laboratories.length === 0)
			return

		setCurrentAttendance(laboratoryAttendancesLoc, laboratory, setLaboratory, laboratories)
	}, [laboratoryAttendancesLoc, userContext, user])

	const TableCell = ({ children, ...props }) => {
		return (
			<WeekView.TimeTableCell
				{...props}
				onClick={async () => {
					try {
						const dayOfWeek = dayHashtable[dayjs(props.startDate).day()]
						const existingAttendances = laboratoryAttendancesLoc.filter(
							(f) => f.day === dayOfWeek && f.laboratory.id !== laboratoryId
						)
						const existingAttendancesLaboratories = new Set()
						for (const a of existingAttendances) {
							existingAttendancesLaboratories.add(a.laboratory.label)
						}
						const count = [...existingAttendancesLaboratories].length

						if (count) {
							let tmpStr = "Attention, vous avez défini une plage d'horaire dans "
							tmpStr += `${count} autre${count > 1 ? "s" : ""} laboratoire${count > 1 ? "s" : ""} : `
							tmpStr += [...existingAttendancesLaboratories].join(", ")
							toast.warning(tmpStr, { toastId: "warning-attendance-alreadt-defined" })
						}

						await api.create("LABORATORY_ATTENDANCES_API", {
							day: dayOfWeek,
							timeStart: dayjs(props.startDate).format("HH:mm"),
							timeEnd: dayjs(props.endDate).format("HH:mm"),
							laboratory: "laboratories/" + laboratoryId,
							user: "users/" + user.id,
						})

						await queryClient.invalidateQueries("LABORATORY_ATTENDANCES_API")
					} catch (e) {
						console.error(e)
					}
				}}>
				{children}
			</WeekView.TimeTableCell>
		)
	}

	const CommitChanges = async ({ changed }) => {
		if (changed == null) return

		// Manual update items so the UI stays smooth
		setItems(items.map((e) => (changed[e.id] ? { ...e, ...changed[e.id] } : e)))

		for (const [id, { startDate, endDate }] of Object.entries(changed)) {
			await api.update("LABORATORY_ATTENDANCES_API", id, {
				timeStart: dayjs(startDate).format("HH:mm"),
				timeEnd: dayjs(endDate).format("HH:mm"),
				day: dayHashtable[dayjs(startDate).day()],
			})
		}
		await queryClient.invalidateQueries("LABORATORY_ATTENDANCES_API")
	}
	const Appointment = ({ children, ...props }) => {
		return (
			<Appointments.Appointment
				{...props}
				style={{
					backgroundColor: colorFromStr(props.data.title),
					opacity: +props.data.laboratoryId !== +laboratoryId ? "0.25" : "1",
				}}>
				<div
					className="laboratory-attendance-appointment"
					onClick={async () => {
						if (!props?.data?.["@id"]) return
						try {
							await api.delete(props.data["@id"])
							await queryClient.invalidateQueries("LABORATORY_ATTENDANCES_API")
						} catch (e) {
							console.error(e)
						}
					}}>
					{children}
				</div>
			</Appointments.Appointment>
		)
	}

	return (
		<Scheduler data={items} locale="FR-fr" firstDayOfWeek={1}>
			<ViewState currentDate={new Date()} />
			<EditingState onCommitChanges={CommitChanges} />
			<EditRecurrenceMenu />

			<WeekView
				cellDuration={60}
				displayName={"Semaine"}
				timeTableCellComponent={TableCell}
				startDayHour={6}
				endDayHour={22}
			/>
			<Appointments appointmentComponent={Appointment} />
			<DragDropProvider />
		</Scheduler>
	)
}

export default ModalPlageHorraire
