import { GROUP_FLAG_DATE, GROUP_FLAG_INVERT } from "./Scheduler.Grouping"

const GroupingSettings = ({ viewSettings, setViewSettings, setViewSettingsUpdate }) => {
	return (
		<div className="row mt-2 mb-2">
			<div className="btn-group w-100" role="group">
				<button
					type="button"
					className={
						"btn btn-sm btn-" + (viewSettings.options & GROUP_FLAG_DATE ? "primary" : "outline-primary")
					}
					onClick={() => {
						let options = viewSettings.options
						if (options & GROUP_FLAG_DATE) options ^= GROUP_FLAG_DATE
						else options |= GROUP_FLAG_DATE
						setViewSettings((old) => ({ ...old, options }))
						setViewSettingsUpdate(true)
					}}>
					Par Date
				</button>
				<button
					type="button"
					className={
						"btn btn-sm btn-" + (viewSettings.options & GROUP_FLAG_INVERT ? "primary" : "outline-primary")
					}
					onClick={() => {
						let options = viewSettings.options
						if (options & GROUP_FLAG_INVERT) options ^= GROUP_FLAG_INVERT
						else options |= GROUP_FLAG_INVERT
						setViewSettings((old) => ({ ...old, options }))
						setViewSettingsUpdate(true)
					}}>
					Inverser
				</button>
			</div>
		</div>
	)
}

export default GroupingSettings
