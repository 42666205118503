import { useState, useEffect, useContext, useMemo } from "react"
import AuthContext from "../../contexts/AuthContext"
import Modal from "../../components/effects/ReactModal"
import FieldWithError from "../../components/forms/FieldWithError"
import Select from "react-select"
import { toast } from "react-toastify"
import { readCarteVitale, readCPS, transmettreXml, transmettreXmlPs, consulterUtilisateur } from "./functionsFSV"
import { IsObjectEmpty, StringUpperCFirst } from "../../services/functions"
import { useHistory } from "react-router-dom"
import API from "../../services/API"
import dayjs from "dayjs"
import UpdateModal from "./UpdateModal"
import { replaceAmcWithAdriAmc as setUserAndPatientInsurance } from "pages/etapes/0-premiere-visite/4-informations-patient/5-couvertures-patient/utils-covers"
import useEffectAsync from "components/Hooks/useEffectAsync"

export default function LectureCVModal({ isOpen, mode, onClose }) {
	const history = useHistory()
	const ctx = useContext(AuthContext)
	const [cardData, setCardData] = useState()
	const [idFSV, setIdFSV] = useState()
	const [beneficiary, setBeneficiary] = useState()
	const [loading, setLoading] = useState(false)
	const [updateBeneficiary, setUpdateBeneficiary] = useState(false)
	const [updateModal, setUpdateModal] = useState()
	const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
	const [multiplePatients, setMultiplePatients] = useState()
	const [xml, setXml] = useState()
	const [cardError, setCardError] = useState()
	const [busy, setBusy] = useState(false)
	const [laboratoryValue, setLaboratoryValue] = useState(false)
	const [rppsValue, setRppsValue] = useState(false)

	const [userValue, setUserValue] = useState({
		lastName: ctx.user.lastName,
		firstName: ctx.user.firstName,
		adeli: ctx.user.adeli,
		finess: "",
		password: "",
		passwordConfirm: "",
		oldPassword: "",
	})

	const [errors, setErrors] = useState({
		firstName: "",
		lastName: "",
		password: "",
		adeli: "",
		finess: "",
		passwordConfirm: "",
		oldPassword: "",
	})

	const [situations, structures] = useMemo(() => {
		const situations = ctx.user?.adeli ? [{ value: ctx.user?.adeli, label: `${ctx.user?.adeli}` }] : []
		const structures = [
			{
				value: ctx.laboratory,
				label: `${ctx.laboratory.label} - Finess : ${ctx.laboratory.finess || "Non renseigné"}`,
				laboratoryLabel: ctx.laboratory.label,
			},
		]
		if (mode === "CPS") {
			cardData?.rpps && situations.push({ value: cardData?.rpps, label: `${cardData?.rpps} - Valeur en carte` })

			if (cardData?.structures?.length > 0) {
				cardData.structures.forEach((e) => {
					structures.push({
						value: e,
						label: `${e.nomStructure} - Finess : ${e.finess} - Valeur en carte`,
						laboratoryLabel: e.nomStructure,
					})
				})
			}
		}
		return [situations, structures]
	}, [cardData, mode])

	useEffect(() => {
		if (mode === "CPS") {
			setUserValue({
				lastName: ctx.user.lastName,
				firstName: ctx.user.firstName,
				adeli: ctx.user?.adeli || cardData?.rpps,
				finess: ctx.laboratory?.finess,
				password: "",
				passwordConfirm: "",
				oldPassword: "",
			})
		}
	}, [ctx.user, cardData])

	const startRead = async () => {
		setCardData(undefined)

		try {
			if (mode === "CPS") {
				let result = await readCPS()
				setCardData(result.result)
				setXml(result.xml)
			} else {
				let result = await readCarteVitale()
				setCardData(result.result)
				setXml(result.xml)
			}
		} catch (error) {
			ctx.setUiDisplay((old) => ({ ...old, lectureCVModal: false }))
			console.error(error)
		}
	}

	const redirect = (to) => {
		setLoading(false)
		onClose()
		history.push(to)
	}

	const createPatientInAW = async (e) => {
		try {
			const result = await API.create("PATIENTS_API", {
				firstName: e.prenom,
				lastName: e.nom,
				gender: e.sexe.toUpperCase(),
				birthDate: dayjs(e.dateNaissanceBdd).format("YYYY-MM-DD"),
				idSecu: (e.idSecu ?? e.numeroSecuCommun) + (e.idSecuKey ?? e.cleNumeroSecuCommun),
				laboratory: ctx.laboratory?.["@id"],
				lastScheduleStatus: ctx.laboratory != null ? "PREMIERE_VISITE" : null,
				cpo: e.city?.slice(0, 5),
				city: e.city?.slice(6, e.city.length),
				adress: e.adresse,
				idFSV: +idFSV,
			})
			ctx.setPatientGenes([])
			ctx.setPatientOrigins([])
			ctx.setPatientMedicals([])
			ctx.setPatientLookingFors([])
			ctx.setPatientAcouphenes([])
			ctx.setPatient({
				...result.data,
				lastScheduleStatus: "PREMIERE_VISITE",
			})
			return result.data
		} catch (e) {
			console.error(e)
		}
	}

	const createAMO = async (e, patient) => {
		e.couverturesAMO.map(async (couverture) => {
			await API.create("PATIENT_SECU_API", {
				ald: +couverture.ALD,
				codeAldForAudioprothese: false,
				situationCode: couverture.situationCode,
				regime: e.AmoGrandRegime,
				fundManager: e.AmoCaisseGestionnaire,
				systemManager: e.AmoCentreGestionnaire,
				particularSituation: "AS",
				startDate: couverture.startDate ? dayjs(couverture.startDate).format("YYYY-MM-DD") : null,
				endDate: couverture.endDate ? dayjs(couverture.endDate).format("YYYY-MM-DD") : null,
				patient: patient["@id"],
			})
		})
	}

	const createAMC = async (e, patient) => {
		if (e?.mutuelles.length) await setUserAndPatientInsurance(e?.mutuelles, patient)
	}

	useEffect(() => {
		if (!isOpen) return
		startRead()
	}, [isOpen])

	useEffectAsync(async () => {
		if (!idFSV) return
		try {
			const result = await createPatientInAW(beneficiary)
			if (result.id) {
				await createAMO(beneficiary, result)
				await createAMC(beneficiary, result)
				redirect(`/fiche-patient/${result.id}`)
			}
		} catch (error) {
			console.error(error)
			toast.error("Une erreur est survenue à la création du patient")
		}
	}, [idFSV])

	const createPatient = async (e, index, update = false) => {
		setBeneficiary(e)
		const getIdFSV = async () => {
			try {
				const resFromFsv = await transmettreXml(xml, ctx.user, ctx.laboratory, index)
				setIdFSV(resFromFsv)
			} catch (error) {
				setCardData("Lecture impossible")
				setCardError(
					`${
						error.message
							? error.message + ". Veuillez lire votre carte CPS dans la page 'Mes Informations'"
							: null
					} `
				)
				setLoading(false)
			}
		}

		if (!update) {
			const getExistingPatient = async () => {
				let searchExistingPatientWithInfo = await API.findAll(
					"PATIENTS_API",
					"?lastName=" + e.nom + "&firstName=" + e.prenom.slice(0, 1)
				)

				const handleMultipleExistingPatients = () => {
					setUpdateModalIsOpen(true)
					setUpdateModal({ ...e, indexInCard: index })
					setMultiplePatients(searchExistingPatientWithInfo)
				}

				if (searchExistingPatientWithInfo.length === 0) getIdFSV()
				else handleMultipleExistingPatients()
			}

			getExistingPatient()
		}
	}

	const handleChange = (event, key) => {
		const { value } = event

		setUserValue({ ...userValue, [key.name]: value })
		setErrors({ ...errors, [key.name]: "" })
	}

	useEffect(() => {
		if (!userValue?.structure) {
			setLaboratoryValue(structures.find((e) => e.value.finess === ctx.laboratory.finess))
			return
		}
		const userLab = userValue?.structure?.nomStructure || userValue?.structure?.label
		setLaboratoryValue(structures.find((e) => e.laboratoryLabel === userLab))
	}, [userValue.structure])

	useEffect(() => {
		if (!userValue?.adeli) return
		setRppsValue(situations.find((e) => e.value === userValue?.adeli))
	}, [userValue.adeli])

	const submitChanges = async () => {
		setErrors([])
		const newUserData = {}
		const newLaboratoryData = {}
		let userRes,
			laboRes = null

		if (!IsObjectEmpty(userValue)) {
			if (userValue.lastName !== ctx.user.lastName) newUserData.lastName = userValue.lastName
			if (userValue.firstName !== ctx.user.firstName) newUserData.firstName = userValue.firstName
			if (userValue.adeli !== ctx.user.adeli) newUserData.adeli = userValue.adeli
			if (userValue.structure?.nomStructure && userValue.structure.finess !== ctx.laboratory.finess) {
				newLaboratoryData.finess = userValue.structure.finess
			}

			if (!IsObjectEmpty(newUserData) || !IsObjectEmpty(newLaboratoryData)) {
				try {
					const isUserInFsv = await consulterUtilisateur(
						cardData.lastName,
						cardData.firstName,
						newLaboratoryData.finess || userValue.finess || cardData.structures[0].finess,
						8,
						cardData.adeli
					)

					if (isUserInFsv == null) {
						await transmettreXmlPs(xml, newLaboratoryData.finess || userValue.finess)
					}

					if (newUserData) {
						userRes = await API.update("USERS_API", ctx.user.id, newUserData)
						ctx.setUser((old) => ({ ...old, adeli: newUserData.adeli }))
					}

					if (newLaboratoryData) {
						laboRes = await API.update("LABORATORIES_API", ctx.laboratory.id, newLaboratoryData)
						ctx.setLaboratory((old) => ({ ...old, finess: newLaboratoryData.finess }))
					}
					toast.success("Vos informations ont été modifiées avec succès")
				} catch (response) {
					if (!response) return
					setErrors(response)
					if (userRes) {
						toast.success("Le rpps a été modifié avec succès")
					}
					if (laboRes) {
						toast.success("Le finess a été modifié avec succès")
					}
					toast.error("La récupération des données a échoué.")
				} finally {
					onClose()
				}
			} else onClose()
		}
	}

	const confirm = async () => {
		if (!cardData) return null
		try {
			setLoading(true)
			setBusy(true)
			if (mode === "CPS" && cardData !== "Lecture impossible") {
				await submitChanges()
			} else {
				await startRead()
			}
		} catch (error) {
			console.error(error)
			toast.error("L'ajout de données à échoué, veuillez contacter le support")
		} finally {
			setLoading(false)
			setBusy(false)
		}
	}

	const Beneficiaires = () => {
		return (
			<div>
				{cardData === undefined ? (
					<div className="overlay-loading-aw" style={{ left: "0", position: "relative" }}>
						<div className="overlay-loading-logo" />
						<div className="overlay-loading-text">Lecture de la carte vitale...</div>
					</div>
				) : cardData === "Lecture impossible" ? (
					<div className="col-sm-12 mt-4 mb-4 d-inline-block">
						<div className="alert alert-warning alert-dismissible fade show" role="alert">
							<h3>Lecture impossible</h3>
							<p>{cardError}</p>
						</div>
					</div>
				) : (
					cardData?.map((e, i) => {
						return (
							<div className="d-flex" key={`${e.prenom}-${e.nom}`}>
								<div className="col-sm-1 text-center">
									<i
										className={`fad fa-user fa-2x mr-4 text-${
											i % 2 === 0 ? "primary" : "info"
										} align-self-center`}
									/>
								</div>
								<div className="col-sm-10">
									<div
										className={`col-sm-12 row cursor-pointer ${
											updateBeneficiary?.prenom === e.prenom
												? "mt-4 bg-primary text-white"
												: "mt-2"
										} `}>
										<h3
											onClick={() => {
												setLoading(true)
												setUpdateBeneficiary(false)
												setUpdateModal(e)
												createPatient(e, i)
											}}>
											{StringUpperCFirst(e.prenom)} {e.nom}
										</h3>
									</div>
									<div className="col-sm-12">
										<ul>
											<li>
												Numéro de sécurité sociale: {e.idSecu || e.numeroSecuCommun}{" "}
												{e.idSecuKey || e.cleNumeroSecuCommun}
											</li>
											<li>
												Date de naissance: {e.dateNaissanceBdd.slice(6, 8)}/
												{e.dateNaissanceBdd.slice(4, 6)}/{e.dateNaissanceBdd.slice(0, 4)}
											</li>
										</ul>
										{updateBeneficiary && beneficiary.prenom === e.prenom && (
											<div className={`row col-sm-12 ${e.prenom && "mb-4"}`}>
												<button
													className="btn btn-info"
													role="alert"
													onClick={() => {
														onClose()
														history.push("/fiche-patient/" + ctx.patient.id)
													}}>
													Accéder au dossier patient
												</button>
											</div>
										)}
									</div>
									{i !== cardData.length - 1 && <hr className="mt-1 mb-1" />}
								</div>
							</div>
						)
					})
				)}
			</div>
		)
	}

	const ProfessionalData = () => {
		return (
			<div>
				{cardData === undefined ? (
					<div className="overlay-loading-aw" style={{ left: "0", position: "relative" }}>
						<div className="overlay-loading-logo" />
						<div className="overlay-loading-text">Récupération des données...</div>
					</div>
				) : cardData === "Lecture impossible" ? (
					<div className="col-sm-12 mt-4 mb-4 d-inline-block">
						<div className="alert alert-warning alert-dismissible fade show" role="alert">
							<h3>Lecture impossible</h3>
							<p>Assurez-vous qu'un lecteur est branché et que votre CPS est insérée.</p>
						</div>
						<div className="d-flex justify-content-center">
							<button className="btn btn-primary" onClick={startRead}>
								Réessayer
							</button>
						</div>
					</div>
				) : (
					<div>
						<div>
							<h3>Veuillez sélectionner les informations à conserver:</h3>
							<div className="row">
								<div className="col-md-6">
									<FieldWithError
										name="firstName"
										label="Prénom"
										placeholder="Votre prénom"
										dataCy="patient-input-firstName"
										onChange={(e) => handleChange(e)}
										value={cardData.firstName}
										error={errors.firstName}
									/>
								</div>
								<div className="col-md-6">
									<FieldWithError
										name="lastName"
										label="Nom"
										placeholder="Votre nom"
										dataCy="patient-input-lastName"
										onChange={(e) => handleChange(e)}
										value={cardData.lastName}
										error={errors.lastName}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 m-2">
								<label htmlFor="rpps" className="p-2">
									Rpps
								</label>
								<Select
									options={situations}
									value={rppsValue}
									placeholder={situations.find((e) => e.value === ctx.user?.adeli)?.label}
									name="adeli"
									key="adeli"
									onChange={(event, key) => {
										handleChange(event, key)
									}}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12 m-2">
								<label htmlFor="structure" className="p-2">
									Structure
								</label>
								<Select
									options={structures}
									value={laboratoryValue}
									placeholder={structures.find((e) => e.value.finess === ctx.laboratory.finess).label}
									name="structure"
									onChange={(event, key) => {
										handleChange(event, key)
									}}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		)
	}

	const Body = () => {
		if (loading) {
			return (
				<div className="overlay-loading-aw h-100" style={{ left: "0", position: "relative" }}>
					<div className="overlay-loading-logo" />
					<div className="overlay-loading-text">Récupération des données...</div>
				</div>
			)
		}
		if (mode === "CPS") return <ProfessionalData />
		return <Beneficiaires />
	}

	const confirmText = (() => {
		if (mode === "CPS") {
			return cardData ? "Confirmer" : "Relire la carte"
		} else {
			return "Relire la carte"
		}
	})()

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				title={mode === "CPS" ? "Informations sur la CPS" : "Bénéficiaires sur la carte vitale"}
				onConfirm={confirm}
				size="xl"
				confirmText={confirmText}
				confirmDisabled={busy}
				buttonType={cardData ? "primary" : "secondary"}>
				<Body />
			</Modal>
			<UpdateModal
				isOpen={updateModalIsOpen}
				data={updateModal}
				setData={setUpdateModal}
				patient={ctx.patient}
				setPatient={ctx.setPatient}
				onCloseMethod={setUpdateModalIsOpen}
				setLoading={setLoading}
				setIdFSV={setIdFSV}
				multiplePatients={multiplePatients}
				multiplePatientsMethod={setMultiplePatients}
				updateBeneficiary={updateBeneficiary}
				setUpdateBeneficiary={setUpdateBeneficiary}
				xml={xml}
			/>
		</>
	)
}
