/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useEffectAsync from "components/Hooks/useEffectAsync"
import { useRef } from "react"
import API from "services/API"
import { ViewSettings, AgendaFilter } from "./Interfaces"
import { toast } from "react-toastify"
/**
 * Loading the saved settings of the agenda from the database.
 * @param user Object of currently connected user
 * @returns Return settings object from PersonnalizationEntity
 */

export const loadAgendaSettings = async (user: any): Promise<any> => {
	try {
		const result: any[] = await API.findAll("PERSONALIZATIONS_API", `?user=${user.id}&type=AGENDA_SETTINGS`)
		return result?.[0] || {}
	} catch (error) {
		console.error(error)
	}
}

/**
 * Function used to compare changes to skip useless saving
 * @param oldSettings
 * @param agendaFilter
 * @param viewSettings
 * @param displayedUsers
 * @param displayedLaboratories
 * @returns true if no changes have been detected
 */
export const compareChanges = (
	oldSettings: any,
	agendaFilter: AgendaFilter,
	viewSettings: ViewSettings,
	displayedUsers: any[],
	displayedLaboratories: any[],
	hideLeftbar: boolean
): boolean => {
	if (viewSettings.cellDuration !== oldSettings?.viewSettings?.cellDuration) {
		return false
	}
	if (JSON.stringify(agendaFilter) !== JSON.stringify(oldSettings?.agendaFilter || {})) {
		return false
	}
	if (JSON.stringify(displayedUsers) !== JSON.stringify(oldSettings?.displayedUsers || {})) {
		return false
	}
	if (JSON.stringify(displayedLaboratories) !== JSON.stringify(oldSettings?.displayedLaboratories || {})) {
		return false
	}
	if (oldSettings.hideLeftbar !== hideLeftbar) {
		return false
	}
	return true
}

/**
 *
 * @param user Object of currently connected user
 * @param agendaFilter  Current filters settings
 * @param viewSettings Current view settings
 * @param displayedUsers Current displayed users
 * @param displayedLaboratories Current displayed laboratories
 */

export const saveAgendaSettings = async (
	user: any,
	agendaFilter: AgendaFilter,
	viewSettings: ViewSettings,
	displayedUsers: any[],
	displayedLaboratories: any[],
	hideLeftbar: boolean
): Promise<void> => {
	const oldSettings = await loadAgendaSettings(user)
	if (
		compareChanges(
			oldSettings?.settings,
			agendaFilter,
			viewSettings,
			displayedUsers,
			displayedLaboratories,
			hideLeftbar
		)
	)
		return
	try {
		const settings = {
			agendaFilter,
			viewSettings: {
				cellDuration: viewSettings.cellDuration,
			},
			displayedUsers,
			displayedLaboratories,
			hideLeftbar,
		}
		if (oldSettings?.id) {
			await API.update("PERSONALIZATIONS_API", oldSettings.id, { settings })
		} else {
			await API.create("PERSONALIZATIONS_API", { settings, type: "AGENDA_SETTINGS" })
		}
		toast("Préférence enregistrées", {
			position: "bottom-left",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			className: "toastify-small-toast",
		})
	} catch (error) {
		console.error("saveAgendaSettings", error)
	}
}

/**
 * Save the Agenda setting in the database
 * @param user Object of currently connected user
 * @param agendaFilter  Current filters settings
 * @param viewSettings Current view settings
 * @param displayedUsers Current displayed users
 * @param displayedLaboratories Current displayed laboratories
 * @param enableSaving Enable or disable saving
 */
export const useAgendaSettingSaver = (
	user: any,
	viewSettings: ViewSettings,
	agendaFilter: AgendaFilter,
	displayedUsers: any,
	displayedLaboratories: any,
	hideLeftbar: boolean,
	enableSaving: boolean
): void => {
	const timeout = useRef<NodeJS.Timeout | null>(null)
	const locked = useRef<boolean>(false)

	useEffectAsync(async () => {
		if (!enableSaving) return
		clearTimeout(timeout.current as NodeJS.Timeout)
		timeout.current = setTimeout(async () => {
			if (!locked.current) {
				locked.current = true
				await saveAgendaSettings(
					user,
					agendaFilter,
					viewSettings,
					displayedUsers,
					displayedLaboratories,
					hideLeftbar
				)
				locked.current = false
			}
		}, 1500)
	}, [enableSaving, viewSettings, agendaFilter, displayedLaboratories, displayedUsers, hideLeftbar])

	if (!enableSaving) {
		return
	}
}

/**
 * Load the Agenda setting from the database
 * @param user Object of currently connected user
 * @param agendaFilter  Current filters settings
 * @param viewSettings Current view settings
 * @param displayedUsers Current displayed users
 * @param displayedLaboratories Current displayed laboratories
 * @param enableSaving Enable or disable loading of the selected users/laboratory.
 * 						Should be false if openning the Agenda from another view than the global Agenda
 */
export const useAgendaSettingLoader = (
	user: any,
	setViewSettings: any,
	setAgendaFilter: any,
	setDisplayedUsers: any,
	setDisplayedLaboratories: any,
	setSettingsLoaded: Function,
	setHideLeftbar: Function,
	enableLoading: boolean
): void => {
	useEffectAsync(async () => {
		const result = await loadAgendaSettings(user)
		const settings = result?.settings || null

		if (!settings) {
			setSettingsLoaded(true)
			return
		}

		setAgendaFilter({ ...settings.agendaFilter })
		setViewSettings((old: any) => ({ ...old, cellDuration: settings?.viewSettings?.cellDuration || 30 }))
		if (enableLoading) {
			setDisplayedUsers([...(settings.displayedUsers || [])])
			setDisplayedLaboratories([...(settings.displayedLaboratories || [])])
		}
		setHideLeftbar(settings.hideLeftbar)
		setSettingsLoaded(true)
	}, [])
}
