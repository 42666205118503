/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useContext } from "react"
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"
import API from "../../../services/API"
import { AutoComplete, Input, Select } from "antd"
import { styleAdresseInput } from "../../../datas/items/itemsAdressePatient"

import { toast } from "react-toastify"
import AuthContext from "../../../contexts/AuthContext"
import PhoneInput from "components/forms/PhoneInput"
import CountryInput from "components/forms/CountryInput"
import LanguageInput from "components/forms/LanguageInput"
import { DateInput } from "components/forms/DateInput"
import { formatDateForDB } from "services/functions"
import dayjs from "dayjs"
import PatientClassBadge from "pages/fiche-patient/PatientClassBadge"

const { Option, OptGroup } = Select

const PatientCreationModal = ({ modalOpen, setModalOpen, selectedLaboratory, selectedUser, setPatient }) => {
	const { laboratory } = useContext(AuthContext)
	const [data, setData] = useState({ birthDate: null })
	const [selectedAddress, setSelectedAddress] = useState("")
	const [patientAddress, setPatientAddress] = useState([])
	const [saving, setSaving] = useState(false)
	const timeout = useRef(null)

	useEffect(() => {
		setData({ country: "FR", language: "fr" })
		setSelectedAddress("")
		setPatientAddress([])
	}, [modalOpen])

	const createPatient = async () => {
		if (data?.firstName?.length < 3 || data?.lastName?.length < 3 || !data?.gender) {
			toast.warn("Veuillez remplir tous les champs obligatoire")
			return
		}
		try {
			if (laboratory || selectedLaboratory) {
				setSaving(true)
				data.laboratory = selectedLaboratory ? "/laboratories/" + selectedLaboratory : laboratory["@id"]
				data.lastScheduleStatus = "PREMIERE_VISITE"
				data.user = selectedUser ? "/users/" + selectedUser : null
				const result = await API.create("PATIENTS_API", data)
				setPatient(result.data)
				setModalOpen(false)
			}
		} catch (e) {
			toast.error("Une erreur est survenue durant la création du patient")
			console.error(e)
		} finally {
			setSaving(false)
		}
	}

	const handleChange = (e, key) => {
		setData((old) => ({ ...old, [key]: e }))
	}

	const handleAutoComplete = async (value) => {
		clearTimeout(timeout.current)
		timeout.current = null
		timeout.current = setTimeout(async () => {
			try {
				const fetchAddress = await API.getAddress(value)

				setPatientAddress(
					fetchAddress?.map((a) => ({
						value: a.properties.label,
						city: a.properties.city,
						cpo: a.properties.postcode,
						adresse: a.properties.name,
					}))
				)
			} catch (error) {
				console.error(error)
			} finally {
			}
		}, 350)
	}

	const handleSelect = (value, option) => {
		setData((old) => ({ ...old, adress: option.adresse, city: option.city, cpo: option.cpo }))
	}

	return (
		<>
			<Modal isOpen={modalOpen} size={"lg"}>
				<ModalHeader>Ajouter un nouveau patient</ModalHeader>
				<ModalBody>
					<form>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label>Prénom *</label>
								<input
									type="text"
									className={"form-control" + (!data.firstName ? " border border-warning" : "")}
									placeholder=""
									onChange={(e) => {
										handleChange(e.target.value, "firstName")
									}}
									required
								/>
							</div>
							<div className="form-group col-md-6">
								<label>Nom *</label>
								<input
									type="text"
									className={"form-control" + (!data.lastName ? " border border-warning" : "")}
									placeholder=""
									onChange={(e) => {
										handleChange(e.target.value, "lastName")
									}}
									required
								/>
							</div>
						</div>

						<div className="form-row">
							<label className="w-100 ml-1" htmlFor={"completeAdress"}>
								Adresse complète
							</label>
							<AutoComplete
								className="w-100 pl-1 pr-2"
								dropdownClassName="dropdown-adress-autocomplete"
								id="completeAdress"
								options={patientAddress}
								onSearch={(value) => handleAutoComplete(value)}
								onChange={(value) => {
									setSelectedAddress(value)
								}}
								value={selectedAddress}
								onSelect={(value, option) => {
									handleSelect(value, option)
									setSelectedAddress(`${option.adresse}, ${option.cpo}, ${option.city}`)
								}}
								filterOption={patientAddress?.value}
								notFoundContent={
									selectedAddress.length >= 3 &&
									"Adresse non trouvée, veuillez renseigner les champs ci-dessous."
								}>
								<Input style={styleAdresseInput} placeholder="Indiquer l'adresse complète" />
							</AutoComplete>
						</div>
						<div className="form-row">
							<div className="form-group col-md-4">
								<label>Adresse</label>
								<input
									type="text"
									className="form-control"
									placeholder=""
									value={data?.adress || ""}
									onChange={(e) => {
										handleChange(e.target.value, "adress")
										setSelectedAddress(`${e.target.value}, ${data.cpo}, ${data.city}`)
									}}
								/>
							</div>
							<div className="form-group col-md-4">
								<label>Code postal</label>
								<input
									type="text"
									className="form-control"
									placeholder=""
									value={data?.cpo || ""}
									onChange={(e) => {
										handleChange(e.target.value, "cpo")
										setSelectedAddress(`${data.adress}, ${e.target.value}, ${data.city}`)
									}}
								/>
							</div>
							<div className="form-group col-md-4">
								<label>Ville</label>
								<input
									type="text"
									className="form-control"
									placeholder=""
									value={data?.city || ""}
									onChange={(e) => {
										handleChange(e.target.value, "city")
										setSelectedAddress(`${data.adress}, ${data.cpo}, ${e.target.value}`)
									}}
								/>
							</div>
						</div>

						<div className="form-row">
							<DateInput
								label="Date de naissance"
								groupClassName="col-md-6"
								value={data.birthDate != null ? dayjs(data.birthDate) : null}
								onChange={(date) =>
									handleChange(date != null ? formatDateForDB(date) : undefined, "birthDate")
								}
							/>
							<div className="form-group col-md-6">
								<label>Genre *</label> <br />
								<div
									className={"btn-group  btn-block" + (!data.gender ? " border border-warning" : "")}
									role="group"
									onClick={(e) => {
										handleChange(e.target.innerHTML.toUpperCase(), "gender")
									}}>
									<button
										type="button"
										className={
											"form-control btn btn-secondary " + (data?.gender === "HOMME" && "active")
										}>
										Homme
									</button>
									<button
										type="button"
										className={
											"form-control btn btn-secondary " + (data?.gender === "FEMME" && "active")
										}>
										Femme
									</button>
								</div>
							</div>
						</div>

						<div className="form-row">
							<div className="col-md-6">
								<CountryInput
									id="country"
									label="Pays"
									value={data.country}
									onChange={(value) => handleChange(value, "country")}
								/>
							</div>
							<div className="col-md-6">
								<LanguageInput
									id="language"
									label="Langue"
									value={data.language}
									onChange={(value) => handleChange(value, "language")}
								/>
							</div>
						</div>

						<div className="form-row">
							<div className="col-md-4">
								<PhoneInput
									name="phoneMobile"
									label="Téléphone mobile"
									value={data.phoneMobile}
									onChange={(value) => handleChange(value, "phoneMobile")}
								/>
							</div>
							<div className="col-md-4">
								<PhoneInput
									name="phone"
									label="Téléphone fixe"
									value={data.phone}
									onChange={(value) => handleChange(value, "phone")}
								/>
							</div>
							<div className="form-group col-md-4">
								<label>Adresse email</label>
								<input
									type="email"
									className="form-control"
									placeholder="john.doe@mail.com"
									onChange={(e) => {
										handleChange(e.target.value, "email")
									}}
								/>
							</div>
						</div>
					</form>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-block btn-outline-danger"
						disabled={saving}
						onClick={() => {
							setModalOpen(false)
						}}>
						Annuler
					</button>
					<button
						type="button"
						className="btn btn-block btn-primary"
						disabled={saving || !data.firstName || !data.lastName || !data.gender}
						onClick={() => {
							createPatient()
						}}>
						Créer le patient
					</button>
				</ModalFooter>
			</Modal>
		</>
	)
}

const SchedulePatientSearch = ({ data, setData, selectedLaboratory, selectedUser, disabled }) => {
	const [modalCreation, setModalCreation] = useState(false)
	const [loading, setLoading] = useState(false)
	const [patientList, setPatientList] = useState([])
	const [busy, setBusy] = useState(false)

	const timeout = useRef(null)

	useEffect(() => {
		API.findAll("PATIENTS_API", "?pagination=true").then((r) => {
			setPatientList(r)
		})
	}, [])

	const searchPatient = async (value) => {
		try {
			setLoading(true)

			const searchParams = new URLSearchParams()
			for (const word of value.split(/\s+/)) {
				searchParams.append("omnisearch[]", word)
			}

			const patients = await API.findAll("PATIENTS_API", `?${searchParams}`)

			setPatientList(patients)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const handleSelect = async (value) => {
		if (value === "new") {
			setModalCreation(true)
			return
		}
		try {
			setLoading(true)
			setBusy(true)

			const patient = await API.find("PATIENTS_API", value)
			setData((old) => ({ ...old, patient }))
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
			setBusy(false)
		}
	}

	return (
		<div className="schedule-type-selector">
			<Select
				size={"large"}
				showSearch
				disabled={disabled || busy}
				loading={loading}
				allowClear
				placeholder="Rechercher un patient..."
				style={{ width: "100%" }}
				filterOption={false}
				value={
					data?.patient?.id ? (
						<>
							{data?.patient?.firstName} {data?.patient?.lastName}
							<PatientClassBadge patient={data.patient} />
						</>
					) : null
				}
				onSearch={(value) => {
					if (!value.length) return
					clearTimeout(timeout.current)
					timeout.current = null
					timeout.current = setTimeout(() => {
						searchPatient(value)
					}, 350)
				}}
				onClear={() => {
					setData((old) => ({ ...old, patient: null }))
				}}
				onChange={(value) => handleSelect(value)}>
				<Option value={"new"}>
					<i className="fad fa-user-plus" /> Créer un nouveau patient
				</Option>
				<OptGroup label="Patients">
					{patientList.map((v, k) => (
						<Option value={v.id} key={k}>
							{v.firstName} {v.lastName}
						</Option>
					))}
				</OptGroup>
			</Select>
			<PatientCreationModal
				setPatient={(patient) => {
					if (patient) setData((old) => ({ ...old, patient }))
				}}
				modalOpen={modalCreation}
				setModalOpen={setModalCreation}
				selectedLaboratory={selectedLaboratory}
				selectedUser={selectedUser}
			/>
		</div>
	)
}

export default SchedulePatientSearch
