import { displayFloat, parsed } from "services/functions"

/**
 * Calculates the endings amounts by VAT rate
 * @param data
 * @return {{
 * 	restCharge: (string|number),
 * 	totalAmountHT: string,
 * 	totalAmountTVA: string,
 * 	partMut: (string|string),
 * 	partSecu: (string|string),
 * 	totalAmountTTC: string,
 * 	arrayAmount: *[]
 * }}
 */
export default function calculateTotalAmounts(data) {
	let totalAmountHT0
	let totalAmountHT5
	let totalAmountHT20
	let totalAmountTTC0
	let totalAmountTTC5
	let totalAmountTTC20
	let montantTVA0
	let montantTVA5
	let montantTVA20
	const arrayAmount = []

	const productsTva0 = data?.ventes?.achats?.filter((achat) => achat?.tax_value === 0)
	const productsTva5 = data?.ventes?.achats?.filter((achat) => achat?.tax_value === 5.5)
	const productsTva20 = data?.ventes?.achats?.filter((achat) => achat?.tax_value === 20)

	// CALCUL LE MONTANT HT
	if (productsTva0 && productsTva0.length) {
		totalAmountHT0 = Object.values(productsTva0).reduce((t, { item_total_net }) => t + parsed(item_total_net), 0)
	} else {
		totalAmountHT0 = 0
	}

	if (productsTva5 && productsTva5.length) {
		totalAmountHT5 = Object.values(productsTva5).reduce((t, { item_total_net }) => t + parsed(item_total_net), 0)
	} else {
		totalAmountHT5 = 0
	}

	if (productsTva20 && productsTva20.length) {
		totalAmountHT20 = Object.values(productsTva20).reduce((t, { item_total_net }) => t + parsed(item_total_net), 0)
	} else {
		totalAmountHT20 = 0
	}

	// CALCUL LE MONTANT TTC
	if (totalAmountHT0) {
		totalAmountTTC0 = Object.values(productsTva0).reduce(
			(t, { item_total_gross }) => t + parsed(item_total_gross),
			0
		)
	} else {
		totalAmountTTC0 = 0
	}

	if (totalAmountHT5) {
		totalAmountTTC5 = Object.values(productsTva5).reduce(
			(t, { item_total_gross }) => t + parsed(item_total_gross),
			0
		)
	} else {
		totalAmountTTC5 = 0
	}

	if (totalAmountHT20) {
		totalAmountTTC20 = Object.values(productsTva20).reduce(
			(t, { item_total_gross }) => t + parsed(item_total_gross),
			0
		)
	} else {
		totalAmountTTC20 = 0
	}

	// CALCUL LE MONTANT DE LA TVA

	if (totalAmountTTC0 || totalAmountHT0) {
		montantTVA0 = parsed(parsed(totalAmountTTC0) - parsed(totalAmountHT0))
	} else {
		montantTVA0 = 0
	}

	if (totalAmountTTC5 || totalAmountHT5) {
		montantTVA5 = parsed(parsed(totalAmountTTC5) - parsed(totalAmountHT5))
	} else {
		montantTVA5 = 0
	}

	if (totalAmountTTC20 || totalAmountHT20) {
		montantTVA20 = parsed(parsed(totalAmountTTC20) - parsed(totalAmountHT20))
	} else {
		montantTVA20 = 0
	}

	totalAmountHT0 > 0 &&
		arrayAmount.push([
			arrayAmount.length + 1,
			displayFloat(totalAmountHT0),
			displayFloat(totalAmountTTC0),
			0,
			displayFloat(montantTVA0),
		])
	totalAmountHT5 > 0 &&
		arrayAmount.push([
			arrayAmount.length + 1,
			displayFloat(totalAmountHT5),
			displayFloat(totalAmountTTC5),
			5.5,
			displayFloat(montantTVA5),
		])
	totalAmountHT20 > 0 &&
		arrayAmount.push([
			arrayAmount.length + 1,
			displayFloat(totalAmountHT20),
			displayFloat(totalAmountTTC20),
			20,
			displayFloat(montantTVA20),
		])

	const totalAmountTTC = displayFloat(
		parsed(parsed(totalAmountTTC20) + parsed(totalAmountTTC0) + parsed(totalAmountTTC5))
	)
	const totalAmountHT = displayFloat(
		parsed(parsed(totalAmountHT20) + parsed(totalAmountHT0) + parsed(totalAmountHT5))
	)
	const totalAmountTVA = displayFloat(parsed(parsed(montantTVA0) + parsed(montantTVA5) + parsed(montantTVA20)))
	const secu = data?.ventes?.payment_details?.find((detail) => detail?.payment_type === "SECU")
	const mut = data?.ventes?.payment_details?.find(
		(detail) => detail?.payment_type === "MUTL" || detail?.payment_type === "MTL"
	)

	const partSecu = secu?.payment_amount ? displayFloat(parsed(secu?.payment_amount)) : "0.00"
	const partMut = mut?.payment_amount ? displayFloat(parsed(mut?.payment_amount)) : "0.00"
	const restCharge =
		partSecu || partMut
			? displayFloat(parsed(totalAmountTTC) - parsed(partSecu || 0) - parsed(partMut || 0))
			: parsed(totalAmountTTC)

	return {
		totalAmountHT,
		totalAmountTTC,
		arrayAmount,
		partMut,
		restCharge,
		partSecu,
		totalAmountTVA,
	}
}
