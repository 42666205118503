export const garanties = [
	{
		value: "0100",
		label: "0100 - Ni exonération, ni modulation (60%)",
		garantie: "NONEXO",
		percent: 60,
		regime: "01",
	},
	{
		value: "0101",
		label: "0101 - Assuré ou bénéficiaire exonéré (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "01",
	},
	{
		value: "0102",
		label: "0102 - Assuré ou bénéficiaire exonéré (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "01",
	},
	{
		value: "0103",
		label: "0103 - Assuré ou bénéficiaire exonéré (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "01",
	},
	{ value: "0104", label: "0104 - FNS(80%)", garantie: "TXFSV", percent: 80, regime: "01" },
	{
		value: "0105",
		label: "0105 - Régime local (Alsace-Moselle) (90%)",
		garantie: "TXALMO",
		percent: 90,
		regime: "01",
	},
	{
		value: "0106",
		label: "0106 - Assuré ou bénéficiaire exonéré (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "01",
	},
	{
		value: "0107",
		label: "0107 - Assuré ou bénéficiaire exonéré (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "01",
	},
	{
		value: "0108",
		label: "0108 - Régime local (Alsace-Moselle) + FNS (90%)",
		garantie: "TXALMO",
		percent: 90,
		regime: "01",
	},
	{
		value: "0109",
		label: "0109 - Assuré ou bénéficiaire exonéré (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "01",
	},
	{
		value: "0110",
		label: "0110 - Assuré ou bénéficiaire exonéré (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "01",
	},
	{ value: "0200", label: "0200 - Régime agricole (60%)", garantie: "NONEXOACC", percent: 60, regime: "02" },
	{ value: "0201", label: "0201 - Régime agricole (100%)", garantie: "AUTREACC", percent: 100, regime: "02" },
	{ value: "0202", label: "0202 - Régime agricole (100%)", garantie: "AUTREACC", percent: 100, regime: "02" },
	{ value: "0203", label: "0203 - Régime agricole (100%)", garantie: "AUTREACC", percent: 100, regime: "02" },
	{ value: "0204", label: "0204 - Régime agricole (80%)", garantie: "AUTREACC", percent: 80, regime: "02" },
	{ value: "0205", label: "0205 - Régime agricole (90%)", garantie: "TXALMO", percent: 90, regime: "02" },
	{ value: "0206", label: "0206 - Régime agricole (100%)", garantie: "AUTREACC", percent: 100, regime: "02" },
	{ value: "0207", label: "0207 - Régime agricole (100%)", garantie: "AUTREACC", percent: 100, regime: "02" },
	{ value: "0225", label: "0225 - Régime agricole (90%)", garantie: "NONEXOACC", percent: 90, regime: "02" },
	{ value: "0226", label: "0226 - Régime agricole (100%)", garantie: "NONEXOACC", percent: 100, regime: "02" },
	{ value: "0227", label: "0227 - Régime agricole (100%)", garantie: "NONEXOACC", percent: 100, regime: "02" },
	{ value: "0300", label: "0300 - Professions indépendantes (60%)", garantie: "NONEXO", percent: 60, regime: "03" },
	{
		value: "0301",
		label: "0301 - Professions indépendantes (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "03",
	},
	{
		value: "0302",
		label: "0302 - Professions indépendantes (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "03",
	},
	{
		value: "0303",
		label: "0303 - Professions indépendantes (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "03",
	},
	{ value: "0304", label: "0304 - Professions indépendantes (80%)", garantie: "NONEXO", percent: 80, regime: "03" },
	{ value: "0400", label: "0400 - Régime SNCF (100%)", garantie: "NONEXO", percent: 100, regime: "04" },
	{ value: "0401", label: "0401 - Régime SNCF (100%)", garantie: "100SNCF", percent: 100, regime: "04" },
	{ value: "0402", label: "0402 - Régime SNCF (100%)", garantie: "100SNCF", percent: 100, regime: "04" },
	{ value: "0403", label: "0403 - Régime SNCF (100%)", garantie: "100SNCF", percent: 100, regime: "04" },
	{ value: "0404", label: "0404 - Régime SNCF (100%)", garantie: "100TOUT", percent: 100, regime: "04" },
	{ value: "0405", label: "0405 - Régime SNCF (100%)", garantie: "100TOUT", percent: 100, regime: "04" },
	{ value: "0406", label: "0406 - Régime SNCF (100%)", garantie: "100TOUT", percent: 100, regime: "04" },
	{ value: "0407", label: "0407 - Régime SNCF (100%)", garantie: "100TOUT", percent: 100, regime: "04" },
	{ value: "0408", label: "0408 - Régime SNCF (90%)", garantie: "TXALMO", percent: 90, regime: "04" },
	{ value: "0409", label: "0409 - Régime SNCF (80%)", garantie: "TXFSV", percent: 80, regime: "04" },
	{ value: "0410", label: "0410 - Régime SNCF (80%)", garantie: "TXFSV", percent: 80, regime: "04" },
	{ value: "0411", label: "0411 - Régime SNCF (90%)", garantie: "TXALMO", percent: 90, regime: "04" },
	{ value: "0412", label: "0412 - Régime SNCF (100%)", garantie: "100TOUT", percent: 100, regime: "04" },
	{ value: "0413", label: "0413 - Régime SNCF (100%)", garantie: "100TOUT", percent: 100, regime: "04" },
	{ value: "0414", label: "0414 - Régime SNCF (60%)", garantie: "NONEXO", percent: 60, regime: "04" },
	{ value: "0415", label: "0415 - Régime SNCF (100%)", garantie: "100TOUT", percent: 100, regime: "04" },
	{ value: "0500", label: "0500 - Régime RATP (60%)", garantie: "NONEXO", percent: 60, regime: "05" },
	{ value: "0501", label: "0501 - Régime RATP (100%)", garantie: "100TOUT", percent: 100, regime: "05" },
	{ value: "0502", label: "0502 - Régime RATP (100%)", garantie: "100TOUT", percent: 100, regime: "05" },
	{ value: "0503", label: "0503 - Régime RATP (100%)", garantie: "100TOUT", percent: 100, regime: "05" },
	{ value: "0504", label: "0504 - Régime RATP (80%)", garantie: "TXFSV", percent: 80, regime: "05" },
	{ value: "0700", label: "0700 - C.A.N.S.S.M (100%)", garantie: "100TOUT", percent: 100, regime: "07" },
	{ value: "0900", label: "0900 - Banque de France (60%)", garantie: "NONEXO", percent: 60, regime: "09" },
	{ value: "0901", label: "0901 - Banque de France (100%)", garantie: "100TOUT", percent: 100, regime: "09" },
	{ value: "0902", label: "0902 - Banque de France (100%)", garantie: "100TOUT", percent: 100, regime: "09" },
	{ value: "0903", label: "0903 - Banque de France (100%)", garantie: "100TOUT", percent: 100, regime: "09" },
	{ value: "0904", label: "0904 - Banque de France (100%)", garantie: "100TOUT", percent: 100, regime: "09" },
	{
		value: "1000",
		label: "1000 - C.R.P.C.E.N (Régime notarial) (85%)",
		garantie: "NONEXO",
		percent: 85,
		regime: "10",
	},
	{
		value: "1001",
		label: "1001 - C.R.P.C.E.N (Régime notarial) (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "10",
	},
	{
		value: "1002",
		label: "1002 - C.R.P.C.E.N (Régime notarial) (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "10",
	},
	{
		value: "1003",
		label: "1003 - C.R.P.C.E.N (Régime notarial) (100%)",
		garantie: "100TOUT",
		percent: 100,
		regime: "10",
	},
	{
		value: "1004",
		label: "1004 - C.R.P.C.E.N (Régime notarial) (60%)",
		garantie: "NONEXO",
		percent: 60,
		regime: "10",
	},
	{ value: "9000", label: "9000 - Ministres des cultes (60%)", garantie: "NONEXO", percent: 60, regime: "90" },
	{ value: "9001", label: "9001 - Ministres des cultes (100%)", garantie: "100TOUT", percent: 100, regime: "90" },
	{ value: "9002", label: "9002 - Ministres des cultes (100%)", garantie: "100TOUT", percent: 100, regime: "90" },
	{ value: "9003", label: "9003 - Ministres des cultes (100%)", garantie: "100TOUT", percent: 100, regime: "90" },
	{ value: "9004", label: "9004 - Ministres des cultes (80%)", garantie: "TXFSV", percent: 80, regime: "90" },
	{ value: "9020", label: "9020 - Ministres des cultes (60%)", garantie: "NONEXO", percent: 60, regime: "90" },
	{ value: "9021", label: "9021 - Ministres des cultes (60%)", garantie: "NONEXO", percent: 60, regime: "90" },
	{ value: "9022", label: "9022 - Ministres des cultes (60%)", garantie: "NONEXO", percent: 60, regime: "90" },
	{ value: "9023", label: "9023 - Ministres des cultes (60%)", garantie: "NONEXO", percent: 60, regime: "90" },
	{ value: "9024", label: "9024 - Ministres des cultes (60%)", garantie: "NONEXO", percent: 60, regime: "90" },
	// 	{ value: "1600", garantie: "NONEXO", percent: 60, regime: "16" },
	// { value: "1601", garantie: "100TOUT", percent: 100, regime: "16" },
	// { value: "1602", garantie: "100TOUT", percent: 100, regime: "16" },
	// { value: "1603", garantie: "100TOUT", percent: 100, regime: "16" },
]
