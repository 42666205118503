import { useContext } from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import AuthContext from "../contexts/AuthContext"

type PrivateRouteProps = RouteProps & {
	allowPatientUI?: boolean
}
function PrivateRoute({ allowPatientUI = false, ...props }: PrivateRouteProps): JSX.Element {
	const { isAuthenticated, patientUI } = useContext(AuthContext)
	if (!isAuthenticated) return <Redirect to="/login" />

	// Limite l'accès aux pages en mode patientUI
	if (!allowPatientUI && patientUI) return <Redirect to="/salle-attente/consentement-rgpd" />

	return <Route {...props} />
}

export default PrivateRoute
