import { BarcodeOutlined, FileAddOutlined, UserOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { getIdFromIri } from "services/functions"

export const SerialNumberResults = ({ product }: { product: any }): JSX.Element => {
	const logisticProduct = product?.logisticProduct?.logistic ?? product?.patientEquipment?.logisticProduct?.logistic

	const SerialNumber = (): JSX.Element => {
		if (product.type === "history") {
			return (
				<div className="">
					<del className="font-weight-bold">{product?.oldSerialNumber}</del>
					{" → "}
					<span className="font-weight-bold">{product?.serialNumber}</span>
				</div>
			)
		}
		return <span className="font-weight-bold">{product?.serialNumber}</span>
	}

	const PatientEquipment = (): JSX.Element | null => {
		const patientEquipment = product.patientEquipment
		const patient = patientEquipment?.patient

		const label: { [key: string]: string } = {
			VENDU: "Vendu à",
			ESSAI: "En essai sur",
			SAV: "En SAV sur",
		}

		if (patientEquipment) {
			return (
				<Link to={"/fiche-patient/" + getIdFromIri(patient["@id"])}>
					<UserOutlined />
					{label?.[patientEquipment.status] ?? patientEquipment.status} {patient?.lastName}{" "}
					{patient?.firstName}
				</Link>
			)
		}
		return null
	}

	const InventoryOrder = (): JSX.Element | null => {
		if (logisticProduct) {
			return (
				<Link to={"/bon-de-livraison/" + getIdFromIri(logisticProduct["@id"])}>
					<FileAddOutlined /> {logisticProduct.label}
				</Link>
			)
		}
		return null
	}

	const ReturnForm = (): JSX.Element | null => {
		if (product?.logisticProduct?.productReturnForm) {
			return (
				<Link to={"/mon-stock/retour/" + getIdFromIri(product.logisticProduct.productReturnForm["@id"])}>
					<FileAddOutlined /> {product.logisticProduct.productReturnForm.label}
				</Link>
			)
		}
		return null
	}

	return (
		<div className="ml-3 d-flex item ">
			<BarcodeOutlined className="mt-1  mr-1" />
			<div className="d-flex flex-column">
				<div>
					<span className="ml-1">{product?.model}</span>
					{product?.sizeName && <span className="ml-1">({product?.sizeName?.trim()})</span>}
					{product.type === "available" && <> (en stock)</>}
				</div>
				<SerialNumber />
				<PatientEquipment />
				<InventoryOrder />
				<ReturnForm />
			</div>
		</div>
	)
}
