import { Select } from "antd"

const { Option } = Select

const ScheduleStatusSelect = ({ data, setData, loading }) => {
	const handleChange = (state, caca) => {
		setData((old) => ({ ...old, state }))

		try {
			let classes = caca?.children?.props?.children?.[0]?.props?.className
			let fontawesomeSvg = document.querySelector("svg.antd-icon-option")
			let classesSvg = fontawesomeSvg.classList

			classes = classes.split(" ")
			classes = classes.find((f) => f.includes("fa-"))
			fontawesomeSvg.classList.remove(classesSvg[1])
			fontawesomeSvg.classList.add(classes)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className="schedule-type-selector">
			<Select
				size={"large"}
				showSearch
				disabled={loading}
				placeholder="Status du rendez-vous..."
				style={{ width: "100%" }}
				optionFilterProp="children"
				onChange={handleChange}
				value={data.state}>
				<Option value="WAITING">
					<span className="select-state-item text-info">
						<i className="fad fa-hourglass-start antd-icon-option" />
						En attente
					</span>
				</Option>
				<Option value="ARRIVAL">
					<span className="select-state-item text-primary font-weight-bold">
						<i className="fad fa-clinic-medical antd-icon-option" /> Arrivé
					</span>
				</Option>
				<Option value="DONE">
					<span className="select-state-item text-primary">
						<i className="fad fa-check-circle antd-icon-option" /> Terminé
					</span>
				</Option>
				<Option value="CANCELLED">
					<span className="select-state-item text-warning">
						<i className="fad fa-times-circle antd-icon-option" /> Annulé
					</span>
				</Option>
				<Option value="MISSING">
					<span className="select-state-item text-danger">
						<i className="fad fa-exclamation-circle antd-icon-option" /> Manqué
					</span>
				</Option>
			</Select>
		</div>
	)
}

export default ScheduleStatusSelect
