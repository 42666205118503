/* eslint-disable react-hooks/exhaustive-deps */
import { HiboutikProductEntity, PatientEquipment } from "@audiowizard/common"
import { Table } from "antd"
import useEffectAsync from "components/Hooks/useEffectAsync"
import useCustomTitle from "components/Hooks/useTitle"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import { useQuery } from "react-query"
import API from "services/API"
import ColumnEquipped from "./Columns"
import Filters, { FiltersType } from "./Filters"
import "../style.scss"

const statusLabel: any = {
	VENDU: "Vendu",
	ATTENTE: "En Attente",
	ESSAI: "En Essai",
}

const Appareille = (): JSX.Element => {
	const { laboratory } = useContext(AuthContext)

	useCustomTitle("Stock | Patient Appareillés")

	const timeout = useRef<any>(null)
	const [needRefresh, setNeedRefresh] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)

	const [data, setData] = useState<any[]>([])
	const [filters, setFilters] = useState<FiltersType>({
		serialNumber: "",
		status: "ALL",
		patient: {},
		warehouseId: laboratory.warehouseIdHiboutik ?? 1,
	})

	const [pagination, setPagination] = useState({ totalItems: 1, currentPage: 1, itemPerPage: 10 })

	const [csvConfig, setCsvConfig] = useState<Record<string, any>>({ loading: false })
	const csvLinkRef = useRef()

	const { data: productCatalog } = useQuery(["PRODUCT_CATALOG"], async (): Promise<HiboutikProductEntity[]> => {
		return await API.findAll<HiboutikProductEntity[]>("PRODUCTS_API", "?pagination=false")
	})

	const columns = useMemo(() => {
		return ColumnEquipped(productCatalog, statusLabel)
	}, [productCatalog, statusLabel])

	useEffect(() => {
		clearTimeout(timeout.current)
		timeout.current = setTimeout(() => {
			setPagination((old) => ({ ...old, currentPage: 1 }))
			setNeedRefresh(true)
		}, 550)
	}, [filters])

	useEffectAsync(async () => {
		if (!needRefresh) return

		try {
			setLoading(true)
			let strFilters = `&page=${pagination.currentPage}&itemsPerPage=${pagination.itemPerPage}`

			if (filters.serialNumber !== "") strFilters += `&serialNumber=${filters.serialNumber}`
			if (filters.status !== "ALL") strFilters += `&status=${filters.status}`
			if (filters.patient.id) strFilters += `&patient=${filters.patient.id}`

			if (filters.warehouseId && filters.status !== "ATTENTE")
				strFilters += `&warehouseIdHiboutik=${filters.warehouseId}`

			const result = await API.findAll<PatientEquipment[]>(
				"PATIENT_EQUIPMENTS_API",
				"?pagination=true" + strFilters,
				true
			)

			setData(result?.["hydra:member"].map((v: any) => ({ ...v, key: v.id })))
			setPagination((old) => ({ ...old, totalItems: result?.["hydra:totalItems"] }))
		} catch (e) {
		} finally {
			setLoading(false)
			setNeedRefresh(false)
		}
	}, [needRefresh])

	const ExportToCsv = (res: any[]): void => {
		const data: any[] = res.map((e: any) => ({
			createdAt: dayjs(e.createdAt).format("DD/MM/YYYY HH:mm"),
			patient: `${e.patient?.lastName?.toUpperCase()} ${e.patient?.firstName}`,
			productName: e.productJsonHiboutik?.product_model,
			productSizeName: e.sizeIdHiboutik,
			statut: statusLabel?.[e?.status] ?? e?.status,
			serialNumber: e.serialNumber ?? "(Pas de numéro de série)",
		}))
		const headers: Record<string, any> = [
			{
				label: "Ajouté le",
				key: "createdAt",
			},
			{
				label: "Patient",
				key: "patient",
			},
			{
				label: "Produit",
				key: "productName",
			},
			{
				label: "Déclinaison",
				key: "productSizeName",
			},
			{
				label: "Numéro de série",
				key: "serialNumber",
			},
			{
				label: "Statut",
				key: "statut",
			},
		]
		setCsvConfig({
			loading: false,
			headers,
			data,
			filename: "Export_Patient_Appareille_" + dayjs().format("DDMMYYYY_HHmm") + ".csv",
		})

		setTimeout(() => {
			// @ts-ignore
			csvLinkRef.current?.link.click()
		}, 200)
	}

	const ExportAllEquipment = async (): Promise<void> => {
		try {
			setCsvConfig({ loading: true })
			const res = await API.findAll(
				"PATIENT_EQUIPMENTS_API",
				`?pagination=false&warehouseIdHiboutik=${filters.warehouseId}`
			)
			ExportToCsv(res)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<>
			<div className="cardtabs-subtitle">Filtres</div>
			<Filters setFilters={setFilters} filters={filters} />

			<div className="cardtabs-subtitle">Liste des patients appareillés</div>
			<Table
				columns={columns as any}
				dataSource={data}
				loading={loading}
				pagination={{
					position: ["bottomLeft"],
					showSizeChanger: true,
					total: pagination.totalItems,
					pageSize: pagination.itemPerPage,
					current: pagination.currentPage,
					onChange: (currentPage: number, itemPerPage?: number) => {
						setPagination((old) => ({ ...old, currentPage, itemPerPage: itemPerPage ?? 10 }))
						setNeedRefresh(true)
					},
				}}
			/>
			{csvConfig.data && (
				<CSVLink
					// @ts-ignore
					ref={csvLinkRef}
					data={csvConfig.data}
					headers={csvConfig.headers}
					separator={";"}
					filename={csvConfig.filename}
					className="hidden"
					target="_blank"
				/>
			)}
			<div className="btn-group w-100">
				<button
					type="button"
					className="btn btn-secondary btn-sm"
					onClick={() => ExportToCsv(data)}
					disabled={csvConfig.loading}>
					Exporter le tableau actuel en CSV
				</button>
				<button
					type="button"
					className="btn btn-secondary btn-sm"
					onClick={ExportAllEquipment}
					disabled={csvConfig.loading}>
					Exporter la liste complète des patients appareillés en CSV
				</button>
			</div>
		</>
	)
}

export default Appareille
