/* eslint-disable react-hooks/exhaustive-deps */
import { ServiceRef } from "components/hoc/withRequiredServices"
import dayjs from "dayjs"
import useHasServices from "components/Hooks/useHasServices"
import { hasTeletransService } from "pages/FSV/utilsFSV"
import PatientUIToggleModal from "pages/salle-attente/PatientUIToggleModal"
import { useContext, useMemo, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Alert, Button } from "reactstrap"
import AuthContext from "../../contexts/AuthContext"
import API from "../../services/API"
import { haveRole } from "../../services/functions"
import SpinnerAW from "../utils/SpinnerAW"
import DropdownCompta from "./DropdownCompta"
import DropDownMenu from "./DropDownMenu"
import { AlertBuildVersion } from "./TopbarUpdateAlert"
import Spotlight from "./Spotlight/Spotlight"

const MissingMandateAlert = () => {
	const { user, services } = useContext(AuthContext)
	const [loading, setLoading] = useState(false)

	const hasMandate = user.company.audioWizardContract?.hasMandate
	const freePeriodDateEnd = useMemo(
		() => dayjs(services.find((s) => s.isMain && s.type === "SUBSCRIPTION").freePeriodDateEnd),
		[services]
	)

	const redirectToGocardless = async () => {
		setLoading(true)

		try {
			const res = await API.goCardlessMandateNew(user?.company?.audioWizardContract?.id)

			window.location = res.data.redirectFlowUrl
		} finally {
			setLoading(false)
		}
	}

	if (hasMandate) return ""
	if (loading) return <SpinnerAW text="Redirection vers GoCardless..." />

	return (
		<Alert color="warning" data-cy="no-mandate-alert" className="text-center w-100 m-0" fade={false}>
			{haveRole(user, "ROLE_MANAGER") ? (
				<span className="lead">
					Pour compléter la mise à jour vous devez saisir votre IBAN.{" "}
					<a
						href="https://www.youtube.com/watch?v=ES-azMQYPK0"
						rel="noopener noreferrer"
						target="_blank"
						className="text-primary">
						Découvrez pourquoi
					</a>
				</span>
			) : (
				<span className="lead">Contactez-votre responsable pour terminer la mise à jour</span>
			)}
			{freePeriodDateEnd.isValid() && (
				<span className="lead">
					Vous devez le faire avant le :{" "}
					<time dateTime={freePeriodDateEnd.format("YYYY-MM-DD")} className="font-weight-bold">
						{freePeriodDateEnd.format("L")}
					</time>
					.
				</span>
			)}{" "}
			{haveRole(user, "ROLE_MANAGER") && (
				<Button color="link" size="sm" onClick={redirectToGocardless} data-cy="redirect-to-gocardless">
					<span className="lead text-info alert-link">Saisir mon IBAN</span>
				</Button>
			)}
		</Alert>
	)
}

const TopBar = () => {
	const history = useHistory()
	const { user, laboratory, patientUI, setUiDisplay, uiDisplay, setPatient, subscriptions } = useContext(AuthContext)
	const marqueBlancheServiceStatus = useHasServices(ServiceRef.MarqueBlanche)
	const [patientUIModal, setPatientUIModal] = useState(false)
	let visitedPatients = window.localStorage.getItem("visitedPatients")

	const shouldBeHidden = !user?.gdprAgreement || uiDisplay?.onboarding

	if (shouldBeHidden) {
		return (
			<nav className="navbar navbar-horizontal navbar-expand-lg navbar-dark bg-gradient-primary ">
				<div className="container" />
			</nav>
		)
	} else
		return (
			<>
				<AlertBuildVersion />
				<MissingMandateAlert />
				{uiDisplay.topBarHiboutikTokenAlert && (
					<Alert color="warning" data-cy="no-hibtoken-alert" className="text-center w-100 m-0">
						Votre compte réclame une intervention du support pour pouvoir accéder à la partie vente
					</Alert>
				)}
				<nav className="navbar navbar-horizontal navbar-expand-lg navbar-dark bg-gradient-primary">
					<div className="container">
						<Link className="navbar-brand" to={patientUI ? history.location.pathname : "/"}>
							{marqueBlancheServiceStatus && user.company.whiteLabel?.isActive ? (
								<img alt={`Logo ${user.company.label}`} src={user.company.whiteLabel.logo} />
							) : (
								<>
									<img
										alt="Logo AudioWizard"
										src="/static/img/brand/audowizard-logo-white4.png"
										className="d-none d-lg-block"
									/>
									<img
										alt="Logo AudioWizard"
										src="/static/img/icons/LOGO-AUDIO-WIZARD.svg"
										className="img-white d-lg-none"
									/>
								</>
							)}
						</Link>
						<Spotlight />
						<div className="navbar-nav flex-row align-items-center" id="navbar-primary">
							{!patientUI && (
								<>
									{visitedPatients && (
										<a
											title="Dernier patient visité"
											className="nav-link pr-lg-0 text-white"
											href="#!"
											onClick={(e) => {
												e.preventDefault()
												visitedPatients = JSON.parse(visitedPatients)
												history.push(`/fiche-patient/${visitedPatients[0]}`)
											}}>
											<i style={{ fontSize: "2rem" }} className="fad fa-user" />
										</a>
									)}
									<a
										title="Aide"
										className="nav-link pr-lg-0 text-white"
										href="#!"
										data-action="omnisearch-open"
										data-target="#documentationSearch">
										<i style={{ fontSize: "2rem" }} className="fad fa-info-circle" />
									</a>
									<a
										title="Chercher un patient ou un produit"
										className="nav-link pr-lg-0 text-white"
										href="#!"
										data-action="omnisearch-open"
										data-target="#patientSearch">
										<i style={{ fontSize: "2rem" }} className="fad fa-search" />
									</a>
									<a
										title="Lire une carte vitale"
										className="nav-link pr-lg-0 text-white"
										href="#!"
										onClick={(e) => {
											if (hasTeletransService(subscriptions)) {
												setUiDisplay((old) => ({ ...old, lectureCVModal: true, mode: "CV" }))
												e.preventDefault()
											} else
												history.push(
													haveRole(user, "MANAGER") ? "/mon-compte/mon-abonnement" : "/"
												)
										}}>
										<i style={{ fontSize: "2rem" }} className="fad fa-address-card" />
									</a>

									<div
										title="Ouvrir l'agenda"
										className="nav-link pr-lg-0 text-white cursor-pointer"
										onContextMenu={(e) => {
											e.preventDefault()
										}}
										onMouseDown={(e) => {
											if (![1, 2].includes(e.button)) return
											e.preventDefault()
											window.open(
												"/agenda",
												"Agenda AudioWizard",
												"scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,\n" +
													"width=1280,height=720,left=0,top=0"
											)
										}}
										onClick={(e) => {
											e.preventDefault()
											setUiDisplay((old) => ({ ...old, agendaModal: true }))
										}}>
										<i style={{ fontSize: "2rem" }} className="fad fa-calendar" />
									</div>
									<a
										title="Créer un nouveau rendez-vous"
										className="nav-link pr-lg-0 text-white"
										href="#!"
										onClick={(e) => {
											setUiDisplay((old) => ({ ...old, scheduleModal: true }))
											e.preventDefault()
										}}>
										<i style={{ fontSize: "2rem" }} className="fad fa-calendar-plus" />
									</a>
									<Link
										className="nav-link pr-lg-0 text-white"
										onClick={() => {
											if (!window.location.href.includes("fiche-patient")) setPatient({})
										}}
										to="/vente"
										title="Accéder à la vente rapide">
										<i style={{ fontSize: "2rem" }} className="fad fa-cart-plus" />
									</Link>
									<DropdownCompta history={history} />
									<ul className="navbar-nav ml-lg-auto ">
										<li className="nav-item dropdown dropdown-animate custom-topbar-pill">
											<a
												className="nav-link pr-lg-0"
												href="#!"
												role="button"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
												data-cy="topbar-dropdown">
												<div className="media media-pill d-flex flex-column align-items-center justify-content-center">
													{user && (
														<>
															<div style={{ fontWeight: "bold" }}>
																{user.firstName} {user.lastName}
															</div>
															<div
																className="d-none d-sm-block"
																style={{
																	fontSize: "12px",
																	overflowY: "hidden",
																	textOverflow: "ellipsis",
																	marginTop: "-6px",
																}}>
																{laboratory.error
																	? "Aucun laboratoire"
																	: laboratory.label}
															</div>
														</>
													)}
												</div>
											</a>

											<DropDownMenu />
										</li>
									</ul>

									{/* <TopbarIcon /> */}
								</>
							)}

							{!user.roles.includes("ROLE_FSV") && (
								<div className="custom-control custom-switch ml-3 d-none d-sm-block">
									<input
										type="checkbox"
										className="custom-control-input"
										id="customSwitch1"
										checked={patientUI}
										onChange={() => setPatientUIModal(true)}
									/>
									<label
										className="custom-control-label text-white"
										htmlFor="customSwitch1"
										data-cy="salle-d-attente-toggle"
									/>
								</div>
							)}
						</div>
					</div>
				</nav>

				<PatientUIToggleModal open={patientUIModal} onClose={() => setPatientUIModal(false)} />
			</>
		)
}

export default TopBar
