// Based on https://docs.google.com/spreadsheets/d/1nU1BY8A8z12kmCu9i6wtg2NL4R9oZ4M3-zdyaDtT1Q4/edit#gid=0

const productionUrl = ["app.audiowizard.fr", "app2.audiowizard.fr", "app.audiopourtous.fr", "app.sonoly.fr"]

export function getApiUrl(): string {
	const location = document.location.href

	return "https://api-secour.audiowizard.fr"

	// Prod
	if (productionUrl.some((url) => location.includes(url))) return "https://api.audiowizard.fr"

	// Dev
	if (process.env.REACT_APP_DEV_LOCAL) return "http://127.0.0.1:8000"

	// Reserved preprod for Pastel, branch is "pastel-backend" (Sylvain - 2022-02-03)
	if (location.includes("pastel--audiowizard")) return "https://preprod.audiowizard.fr"

	// Reserved preprod for Main, branch is "main" (Florian)
	if (location.includes("main--audiowizard")) return "https://preprod2.audiowizard.fr"

	// Reserved preprod for Doctolib, branch is "feature/doctolib" (Florian - 2022-01-27)
	if (location.includes("netlify-main-preprod6--audiowizard")) return "https://preprod6.audiowizard.fr"

	// stock-refacto preprod (Sylvain)
	if (location.includes("slopez-total-stock-destruction--audiowizard")) return "https://preprod9.audiowizard.fr"

	// Reserved preprod for AW Support, branch is "master" (Olivier - 2022-05-02)
	if (location.includes("audiowizard.netlify.app")) return "https://preprod5.audiowizard.fr"

	return "https://preprod.audiowizard.fr"
}
