import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap"

type ConfirmWithModalData = {
	title?: string
	text?: string | ReactNode

	cancelLabel?: string
	okLabel?: string
	hideCancel?: boolean
	hideConfirm?: boolean
	textAlign?: string
}

// Stock la fonction dans une variable global pour pouvoir appeler confirmWithModal depuis n'importe où dans le code
let closure: (data: ConfirmWithModalData) => Promise<boolean> = () => {
	throw new Error("ConfirmModalContainer does not exist or has not been mounted yet.")
}

/**
 * Bloque et affiche un modal de confirmation.
 * @returns  `true` si l'utilidateur clique sur le bouton "Oui", `false` si l'utilisateur clique sur le bouton "Non".
 *
 * @example
 * const hasConfirmed = (await confirmWithModal({text: "Continuer ?"}))
 * if(!hasConfirmed) return // Ne pas continuer si l'utilisateur clique sur "Non"
 *
 * doStuff()
 */
export async function confirmWithModal(data: ConfirmWithModalData): Promise<boolean> {
	return closure(data)
}

/**
 * Permet d'afficher la modal de confirmation. Il faut qu'il ne soit mounted qu'une seule fois dans l'application.
 * @example
 * function App() {
 *   return (
 *     <>
 *       <MyPages />
 *       <MyOtherContent />
 *
 *       <ConfirmModalContainer />
 *     </>
 *   );
 * }
 */
export function ConfirmModalContainer(): JSX.Element {
	const [data, setData] = useState<ConfirmWithModalData>({
		title: "",
		text: "",
		hideCancel: false,
		hideConfirm: false,
		textAlign: "center",
	})
	const [open, setOpen] = useState(false)
	const resolveRef = useRef<(value: boolean) => void>()

	useEffect(() => {
		if (ConfirmModalContainer.hasBeenMounted) throw new Error("ConfirmModalContainer must only be mounted once.")
		ConfirmModalContainer.hasBeenMounted = true

		closure = (data) => {
			setOpen(true)
			setData(data)

			return new Promise((resolve) => {
				// Stock resolve dans un useRef pour pouvoir resolve la promise depuis les onClick des bouttons
				resolveRef.current = resolve
			})
		}
		return () => {
			ConfirmModalContainer.hasBeenMounted = false
		}
	}, [])

	const confirm = useCallback(() => {
		resolveRef.current!(true)
		setOpen(false)
	}, [])

	const cancel = useCallback(() => {
		resolveRef.current!(false)
		setOpen(false)
	}, [])

	const onKeyDownEvent = (e: React.KeyboardEvent<HTMLElement>): void => {
		if (!open) return
		if (e.key === "Enter") confirm()
		if (e.key === "Escape") cancel()
	}

	const Body = (): JSX.Element => {
		if (!data.text) {
			return <></>
		}

		return (
			<ModalBody className={"text-" + data.textAlign} onKeyDown={onKeyDownEvent}>
				{
					typeof data.text === "string"
						? data.text?.length > 0 && <p>{data.text}</p> // string
						: data.text // ReactNode
				}
			</ModalBody>
		)
	}
	return (
		<Modal isOpen={open} centered>
			<ModalHeader className={typeof data.title === "string" ? "text-center" : ""}>{data.title}</ModalHeader>
			<Body />
			<ModalFooter>
				<div className="d-flex justify-content-around flex-grow-1">
					{!data?.hideCancel && (
						<button className="btn btn-outline-danger flex-grow-1" onClick={cancel}>
							{data?.cancelLabel || "Non"}
						</button>
					)}
					{!data?.hideConfirm && (
						<button onClick={confirm} className="btn btn-primary flex-grow-1">
							{data?.okLabel || "Oui"}
						</button>
					)}
				</div>
			</ModalFooter>
		</Modal>
	)
}
// Pour throw une erreur si on mount 2 fois le composant
ConfirmModalContainer.hasBeenMounted = false
