import {
	keys as idbKeys,
	get as idbGet,
	set as idbSet,
	clear as idbClear,
	del as idbDel,
	createStore as idbCreateStore,
} from "idb-keyval"
import RULES from "./Rules"
import { Rules } from "./Interface"
import { AxiosRequestConfig } from "axios"

const cacheSystem = idbCreateStore("CacheSystem", "CacheSystem")
const cacheSystemValidity = idbCreateStore("CacheSystemValidity", "CacheSystemValidity")

const clear = (): void => {
	try {
		idbClear()
		idbClear(cacheSystem)
		idbClear(cacheSystemValidity)
	} catch (error) {
		console.error(error)
	}
}

const testRoutes = (url: string, regexList: RegExp[]): boolean => {
	try {
		for (const regex of regexList) {
			if (regex.test(url)) {
				return true
			}
		}
	} catch (error) {
		console.error(error)
	}
	return false
}

const isAllowed = (url: string, method: string): number => {
	try {
		for (const ruleid in RULES) {
			const rule: Rules = RULES[ruleid]
			switch (method) {
				case "get":
					if (rule.get && rule.get.test(url)) {
						return +ruleid
					}
					break
				case "put":
					if (rule.put && rule.put.test(url)) {
						return +ruleid
					}
					break
				case "delete":
					if (rule.delete && rule.delete.test(url)) {
						return +ruleid
					}
					break
				case "post":
					if (rule.post && rule.post.test(url)) {
						return +ruleid
					}
					break
				default:
					return -1
			}
		}
	} catch (error) {
		console.error(error)
	}
	return -1
}

const get = async (request: any, maxValidity: number): Promise<any> => {
	try {
		const now: number = new Date().getTime()
		const b64 = btoa(request.url)
		const validity = await idbGet(b64, cacheSystemValidity)
		const data = await idbGet(b64, cacheSystem)

		if (!validity || !data) return request
		const diff: number = Math.round((now - validity.getTime()) / 1000 / 60)
		if (diff >= maxValidity) {
			idbDel(b64, cacheSystemValidity)
			idbDel(b64, cacheSystem)
			return request
		}

		request.adapter = () => {
			return Promise.resolve({
				...request,
				config: { ...request },
				data: data,
			})
		}
	} catch (error) {
		console.error(error)
	}
	return request
}

const axiosInterceptResponse = async (success: { [key: string]: any }): Promise<any> => {
	try {
		if (success.config.method !== "get") return success
		const allowedId = isAllowed(success.config.url, success.config.method)
		if (allowedId === -1) return success

		const b64 = btoa(success.config.url)
		const data = await idbGet(b64, cacheSystem)

		if (!data) {
			await idbSet(b64, success.data, cacheSystem)
			await idbSet(b64, new Date(), cacheSystemValidity)
		}
	} catch (error) {
		console.error(error)
	}
	return success
}

const deleteFromCache = async (ruleId: number): Promise<void> => {
	try {
		const availableCacheKeys = await idbKeys(cacheSystem)
		const availableValidityKeys = await idbKeys(cacheSystemValidity)

		for (const key of availableCacheKeys) {
			const value = atob(key as string)

			if (testRoutes(value, RULES[ruleId].clear)) {
				await idbDel(key, cacheSystem)
			}
		}

		for (const key of availableValidityKeys) {
			const value = atob(key as string)
			if (testRoutes(value, RULES[ruleId].clear)) {
				await idbDel(key, cacheSystemValidity)
			}
		}
	} catch (error) {
		console.error(error)
	}
}

const axiosInterceptRequest = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
	try {
		const allowedId: number = isAllowed(request.url!, request.method!)

		if (allowedId === -1) return request

		switch (request.method) {
			case "get":
				return await get(request, RULES[allowedId].validity)
			case "put":
				await deleteFromCache(allowedId)
				break
			case "delete":
				await deleteFromCache(allowedId)
				break
			case "post":
				await deleteFromCache(allowedId)
				break
			default:
				break
		}
	} catch (error) {
		console.error(error)
	} finally {
		return request
	}
}

const CacheSystem = {
	clear,
	axiosInterceptRequest,
	axiosInterceptResponse,
}

export default CacheSystem
