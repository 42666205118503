import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { cloneDeep, get, isNil, omit } from "lodash"
import { oneLineTrim } from "common-tags"
import { Editor as TinyMCEEditor } from "tinymce"
import { ContextMenuContents } from "components/commons/Editor/Editor.types"
import { LocaleNamespace } from "config/intl/helpers"
import AuthContext from "contexts/AuthContext"
import { isPrimitive } from "utils/types/helpers/isPrimitive"
import { capitalizeFirstLetter } from "utils/types/primitives/String/capitalizeFirstLetter"
import { ContextEditorProps } from "./ContextEditor"

/**
 * Custom hook that allows us to variabilize our content
 * @param initialContent
 * @param customStore
 * @param localeNS
 */
export function useContextEditorVariabilizer(
	initialContent: ContextEditorProps["content"],
	customStore?: { [key: string]: any },
	localeNS?: LocaleNamespace
): (
	| string
	| ((
			_content: ContextEditorProps["content"],
			_customStore?: { [key: string]: any },
			_localeNS?: LocaleNamespace | undefined
	  ) => string)
)[] {
	const authContext = useContext(AuthContext)
	const { t } = useTranslation(localeNS || LocaleNamespace.Context)
	const store = customStore || authContext

	const content = variabilize(initialContent, store, t)

	/**
	 * Provide a method to variabilize a content
	 * @param _content
	 * @param _customStore
	 * @param _localeNS
	 */
	function _variabilize(
		_content: ContextEditorProps["content"],
		_customStore?: { [key: string]: any },
		_localeNS?: LocaleNamespace
	): string {
		const _store = _customStore || store
		const _translator = _localeNS ? (...args: any[]) => t(args[0], args.slice(1)) : t
		return variabilize(_content, _store, _translator)
	}

	return [content, _variabilize]
}

export enum VariabilizerFilter {
	Empty = "empty",
}

/**
 * Mustache variabilizer function
 * @param content
 * @param store
 * @param translator
 */
export function variabilize(
	content: ContextEditorProps["content"],
	store: { [key: string]: any } | null,
	translator: (namespace: string) => string
): string {
	if (!store) throw new Error("Context editor store is null")
	const { convert } = new Variabilizer(store, translator)

	// Process content if it is a function
	let _content: string = typeof content === "function" ? content(store, translator) : content

	// This regex allows us to use a mustache syntax like and extract the content between #()
	// Manages a pipe operator too
	const regex =
		/#\(\s*(?<path>[a-z0-9_.\d[\]"']*)\s*\|*\s*(?<filter_name>empty)*\s*["']*(?<filter_value>[^)]*)*["']*\)/gi

	// We replace each double brackets and their content with a template variable content
	_content = _content.replace(regex, (match, p1, p2, p3, offset, string, groups) =>
		convert(groups.path, groups.filter_name, groups.filter_value)
	)

	return _content
}

export interface IVariabilizer {
	convert: (path: string | string[], filterName?: VariabilizerFilter, filterValue?: string) => string
}

/**
 * Variabilizer
 * @description Build the content of an Editor context menu item
 */
export class Variabilizer implements IVariabilizer {
	private readonly _store: Record<string, any>
	private readonly _translator: any

	constructor(store: Record<string, any>, translator: (namespace: string) => string) {
		this._store = store
		this._translator = translator
		this.convert = this.convert.bind(this)
	}

	/**
	 * Converts a path to the content of an Editor context menu item
	 * @param path
	 * @param filterName
	 * @param filterValue
	 */
	public convert(path: string | string[], filterName?: VariabilizerFilter, filterValue?: string): string {
		const _path = Array.isArray(path) ? path.join(".") : path,
			value = get(this._store, path)

		const label = this._defineLabel(_path, value || filterValue)
		return ReportingEditorItemBuilder.buildContent(_path, label, value || filterValue, this._translator)
	}

	/**
	 * Define a label through the type of the value.
	 * If value isn't primitive type, we get the property __selfTranslation in our translation file for the current path property.
	 * @param path
	 * @param value
	 * @private
	 */
	private _defineLabel(path: string, value: any): string {
		let label = path

		// This regex allows us to remove the bracket notation for access properties of JavaScript object if it is a number
		// Example:
		// titans.[2].strength => titans.strength
		// titans.["2"] 	   => titans
		const regex1 = /(\.*\[["']*\d*["']*\])/g
		label = label.replace(regex1, "")

		// This regex allows us to concat the bracket notation into dot-notation
		const regex2 = /\.*\[["'](?<key>[a-z0-9_]*)["']\]/gi
		label = label.replace(regex2, (match, p1, offset, string, groups) => groups.key)

		const translatorPath: string = isPrimitive(value) ? label : `${label}.__selfTranslation`
		return capitalizeFirstLetter(this._translator(translatorPath))
	}
}

/**
 * Reporting editor item builder
 * @description Provides an interface to the programmer for building Editor context menu items from a store
 */
export class ReportingEditorItemBuilder {
	private readonly _store: Record<string, any>
	private readonly _translator: any // TODO: type
	public items: ContextMenuContents[] | null
	private _editor: () => TinyMCEEditor
	private _paths: string[]
	private _ignoredPaths: string[]
	private _internalStore: Record<string, any> | null
	private _currentPath: string | null

	constructor(
		editor: () => TinyMCEEditor,
		translator: (namespace: string) => string,
		paths: string[],
		ignoredPaths: string[],
		store: Record<string, any>
	) {
		this._editor = editor
		this._translator = translator
		this._paths = paths
		this._ignoredPaths = ignoredPaths
		this._store = store
		this._internalStore = null
		this._currentPath = null
		this.items = null
		this._buildSpecItem = this._buildSpecItem.bind(this)
		this._buildAction = this._buildAction.bind(this)
	}

	/**
	 * Build the content
	 * Don't add value if empty to prevent the presence of the value in the document.
	 * Use the zero-width non-joiner non-printing character (&zwnj;) to force the browser to render our empty span elements
	 * @param path
	 * @param label
	 * @param value
	 * @param translator
	 */
	public static buildContent(
		path: string,
		label: string,
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		value: any,
		translator: (namespace: string) => string
	): string {
		const noValue = isNil(value) || (isNaN(parseInt(value)) && !value) // If undefined, null, or falsy value (excepts for number)

		// Special process for company logo
		if (/data-company-logo/gim.test(value)) return value

		// Special process for image value
		const isImage: number = path.search(/logo|image|img*$/gi)
		if (isImage !== -1) {
			return `<img src="${value}" alt="${label}">`
		}

		return oneLineTrim`
			<span class="reporting-template__variable" data-key="${path}" data-empty="${noValue}" data-tooltip="${label}" contenteditable="false">
				${
					noValue
						? `<span class="reporting-template__variable-value" data-value="${translator(
								"common:commonWords.empty"
						  )}">&zwnj;</span>`
						: `<span class="reporting-template__variable-value">${value}</span>`
				}
			</span>`
	}

	/**
	 * Build path
	 * @param {string} path - Current path
	 * @param {string} chunk - Next value to concat
	 * @private
	 * @example
	 * 	 _buildPath("patient.information", "address");
	 *     // return patient.information.address
	 * @return {string}
	 */
	private static _buildPath(path: string, chunk: string | number): string {
		if (!isNaN(Number(chunk))) return `${path}[${chunk}]`
		else return path.concat(".", chunk as string)
	}

	/**
	 * Remove the JSON-LD Properties in an object
	 * Warning: mutate the object!
	 * @param obj
	 */
	public static removeJsonLdProperties<O extends Record<string, any>>(obj: O): O {
		for (const [key, value] of Object.entries(obj)) {
			if (/@/g.test(key)) delete obj[key]
			else if (!isPrimitive(value)) ReportingEditorItemBuilder.removeJsonLdProperties(value)
		}
		return obj
	}

	/**
	 * Build the items
	 */
	public build(): ReportingEditorItemBuilder {
		this._buildInternalStore()._buildItems()
		return this
	}

	/**
	 *
	 * @private
	 */
	private _buildItems(): ReportingEditorItemBuilder {
		if (!this._internalStore) return this
		const _items = []
		for (const [key, value] of Object.entries(this._internalStore)) {
			const path: string = key
			const label = this._defineLabel(path, value)
			const _item = this._buildSpecItem(path, label, value)
			_items.push(_item)
		}
		// @ts-ignore
		this.items = _items
		return this
	}

	/**
	 * Build an pre-object of values with merged path, that we use to build our items
	 * @private
	 * @example
	 * 	 If we have an array of path like so: ["laboratory.information", "laboratory.relatives"],
	 * 	 we build an object like so: { laboratory: { information: <any>, relatives: <any> } }
	 */
	private _buildInternalStore(): ReportingEditorItemBuilder {
		let build = {}

		// Import all
		if (this._paths.includes("*")) {
			build = cloneDeep(this._store)
		} else {
			const sortedPaths = this._paths.sort() // Regardless of the position of the paths in the list
			for (const path of sortedPaths) {
				const storedValue = get(this._store, path)
				const chunks: string[] = path.split(".")
				chunks.reduce((acc: Record<string, any>, chunk, index) => {
					const value = index === chunks.length - 1 ? storedValue : {}
					return (acc[chunk] = acc[chunk] || value)
				}, build) // use preBuild object reference to construct our pre-build object
			}
		}

		ReportingEditorItemBuilder.removeJsonLdProperties(build) // remove json-ld props

		if (this._ignoredPaths?.length) build = omit(build, this._ignoredPaths) // slow method
		// if (this._ignoredPaths?.length) build = omitBy(build, this._ignoredPaths); // slow method

		this._internalStore = build // update
		return this
	}

	/**
	 * Define a label through the type of the value.
	 * If value isn't primitive type, we get the property __selfTranslation in our translation file for the current path property.
	 * TODO: actually, the value can be an object or not, and be null. Find a better solution to handle the i18n warning
	 * @param path
	 * @param value
	 * @private
	 */
	private _defineLabel(path: string, value: any): string {
		const _path: string = path.replace(/\[\d*\]/, "") // replace the numbers and the brackets to find the correct translation path
		const translatorPath: string = isPrimitive(value) ? _path : `${_path}.__selfTranslation`
		const preTranslation: string = this._translator(translatorPath)
		const translation: string = /returned an object instead of string/i.test(preTranslation)
			? this._translator(`${_path}.__selfTranslation`)
			: preTranslation
		return capitalizeFirstLetter(translation)
	}

	/**
	 * Build the spec of an context menu item
	 * This method is recursive if needed
	 * @param path
	 * @param label - The calculated label
	 * @param value
	 * @private
	 */
	private _buildSpecItem(path: string, label: string, value: any): { [key: string]: any } {
		if (isPrimitive(value)) {
			return {
				text: label,
				disabled: isNil(value),
				onAction: this._buildAction(path, label, value),
			}
		} else {
			return {
				type: "submenu",
				text: label,
				disabled: isNil(value),
				getSubmenuItems: () =>
					Object.entries(value).map(([k, v]) => {
						const isItem = isNaN(Number(k)) // check if it is an item of an array
						const _path = ReportingEditorItemBuilder._buildPath(path, k)
						const label: string = isItem ? this._defineLabel(_path, v) : k
						return this._buildSpecItem(_path, label, v)
					}),
			}
		}
	}

	/**
	 * Build the item onAction callback
	 * @param path
	 * @param label
	 * @param value
	 * @private
	 */
	private _buildAction(path: string, label: string, value: any) {
		return () => {
			// Use the attribute contenteditable to prevent edition on this span
			// Use the non-printing character zero-width non-joiner to shwo the span
			// Manage the label display in CSS to prevent his presence in plain text
			this._editor().insertContent(ReportingEditorItemBuilder.buildContent(path, label, value, this._translator))
		}
	}
}
