import { variabilize } from "components/containers/ContextEditor/ContextEditor.helpers"
import { LocaleNamespace } from "config/intl/helpers"
import AuthContext from "contexts/AuthContext"
import { useTranslation } from "hooks/specific/useTranslation"
import { useContext } from "react"
import { buildContextStore } from "./ContextStoreFactory"
import { ReturnFormTemplate } from "./Template"

export function useGenerateDocument(): Promise<string> {
	const store = useContext(AuthContext)
	const t = useTranslation(LocaleNamespace.Pages) // @ts-ignore

	return async function generate(inputData: Record<string, any>): Promise<string> {
		const cs = buildContextStore(store, t, inputData)
		const content = variabilize(ReturnFormTemplate, cs, t)

		return content
	}
}
