import { User } from "@audiowizard/common"
import axios, { AxiosError, AxiosResponse } from "axios"
import { AuthContextType } from "contexts/AuthContext"
import jwtDecode, { JwtPayload } from "jwt-decode"
import { API_SANTE_URL, LOGIN_API, REFRESH_TOKEN, USERS_API } from "../config"
import SuperTokensLock from "browser-tabs-lock"

// api-annuaire-sante //
async function fetchHealthcareToken(): Promise<string> {
	const res = await axios.post<{ token: string }>(API_SANTE_URL + "/login_check", {
		username: "mathieu.chammah@gmail.com",
		password: "AAS@2020!",
	})

	globalThis.localStorage.setItem("authTokenHealth", res.data.token)

	return res.data.token
}

export async function getTokenHealthcare(): Promise<string> {
	const cachedToken = globalThis.localStorage.getItem("authTokenHealth")

	if (cachedToken != null) {
		// Check if token is expired
		const { exp: expiration } = jwtDecode<{ exp: number }>(cachedToken)
		if (expiration * 1000 > new Date().getTime()) {
			return cachedToken
		}
	}

	//Token doesn't exist in cache or is expired, fetch it and cache it
	const token = await fetchHealthcareToken()
	globalThis.localStorage.setItem("authTokenHealth", token)
	return token
}

// AudioWizard //
function setAxiosToken(token: string): void {
	// @ts-ignore
	axios.defaults.headers["Authorization"] = "Bearer " + token
}

export async function authenticate(credentials: { username: string; password: string }): Promise<void> {
	const res = await axios.post<{ token: string; refresh_token: string }>(LOGIN_API, credentials)

	globalThis.localStorage.setItem("authToken", res.data.token)
	globalThis.localStorage.setItem("authTokenRefresh", res.data.refresh_token)
	setAxiosToken(res.data.token)
}

export function logout(): void {
	globalThis.localStorage.clear()
	globalThis.sessionStorage.clear()
	// @ts-ignore
	delete axios.defaults.headers["Authorization"]
}

export function reloadApp(): void {
	logout()
	document.location.reload()
}

function getToken(): string | null {
	return global.window.localStorage.getItem("authToken")
}

export async function initAuth(
	ctx: AuthContextType,
	setLoading: (value: boolean) => void,
	setLoadingSteps: (value: number) => void
): Promise<void> {
	try {
		const token = getToken()

		if (token) {
			const jwt = jwtDecode<JwtPayload>(token)
			let beforeExp = jwt.exp! - Date.now() / 1000
			beforeExp = beforeExp < 0 ? 0 : beforeExp
			if (beforeExp <= 300 && beforeExp >= 60) {
				await refreshToken()
				setLoadingSteps(1)
			} else if (beforeExp < 59) {
				setLoading(false)
				setLoadingSteps(5)
			} else {
				setAxiosToken(token)
				setLoadingSteps(1)
			}
		} else {
			setLoading(false)
			setLoadingSteps(5)
		}
	} catch (e) {
		console.error(e)
		setLoading(false)
		setLoadingSteps(5)
		ctx.setIsAuthenticated(false)
	}
}

const refreshLock = new SuperTokensLock()
let refreshingPromise: Promise<string | undefined> | null = null
export async function refreshToken(): Promise<string | undefined> {
	if (window.localStorage.getItem("authTokenRefresh") == null) {
		reloadApp()
		return
	}

	// Share refreshingPromise between function calls to avoid race conditions and fetchting multiple times
	if (refreshingPromise == null) {
		refreshingPromise = new Promise(async (resolve) => {
			// Try to acquire lock 10 times before giving up
			let retryCount = 0
			while (!(await refreshLock.acquireLock("refreshToken", 10000)) && retryCount < 10) {
				retryCount++
			}

			try {
				const res = await axios.post(REFRESH_TOKEN, {
					refresh_token: window.localStorage.getItem("authTokenRefresh"), // Get authTokenRefresh from localStorage in case other tab changed it
				})

				window.localStorage.setItem("authToken", res.data.token)
				window.localStorage.setItem("authTokenRefresh", res.data.refresh_token)

				setAxiosToken(res.data.token)
				resolve(res.data.token)
			} catch {
				reloadApp()
			} finally {
				await refreshLock.releaseLock("refreshToken")
				refreshingPromise = null
			}
		})
	}

	return refreshingPromise
}

export async function fetchUser(): Promise<User> {
	const res = await axios.get(USERS_API + "/me")
	return res.data
}

/** In case of expired auth token, will refresh the token and retry the request. */
async function refreshTokenInterceptor(
	err: AxiosError & { config: { _refreshTokenRetry?: boolean } }
): Promise<AxiosResponse | AxiosError> {
	// Ignore non expired token errors or if already retried request
	if (
		err?.response?.status !== 401 ||
		err?.response?.data?.message !== "Expired JWT Token" ||
		err.config._refreshTokenRetry
	) {
		throw err
	}

	const token = await refreshToken()
	if (token != null) {
		// Manually modify Authorization header for this request as `axios.defaults.headers` wouldn't affect it
		err.config.headers!["Authorization"] = `Bearer ${token}`
	}
	err.config._refreshTokenRetry = true

	return await axios.request(err.config)
}

axios.interceptors.response.use(undefined, refreshTokenInterceptor)

// Update axios header when localStorage is updated by different tab
window.addEventListener("storage", ({ key, newValue }) => {
	if (key !== "authToken" || newValue == null) return

	setAxiosToken(newValue)
})
