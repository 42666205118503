import { PatientEquipment } from "@audiowizard/common"
import dayjs from "dayjs"

export const getSerialNumbersForProduct = (
	availableSerialNumbers: Record<string, any>[],
	productId: number
): Record<string, any>[] => {
	return availableSerialNumbers.filter((f) => +f.id === productId)
}

export const getPatientEquipment = (
	patientEquipment: PatientEquipment[],
	sizeId: number,
	serialNumber: string
): PatientEquipment | undefined => {
	return patientEquipment.find(
		(f) => f.sizeIdHiboutik && +f.sizeIdHiboutik === +sizeId && f.serialNumber === serialNumber
	)
}

export const isSerialnumberAlreadyListed = (data: Record<string, any>[], equipment: PatientEquipment): boolean => {
	return !!data.find(
		(f) =>
			equipment.productIdHiboutik &&
			+f.productId === +equipment.productIdHiboutik &&
			(equipment.sizeIdHiboutik ? +f.sizeId === +equipment.sizeIdHiboutik : 1) &&
			equipment.serialNumber &&
			f.serialNumber === equipment.serialNumber
	)
}

const statusLabel: { [index: string]: string } = {
	ESSAI: "En essai",
	SAV: "En SAV",
	ATTENTE: "En attente",
	VENDU: "Vendu",
}

export const pushEquipmentData = (
	databaseData: Record<string, any>,
	product: Record<string, any>,
	equipment: PatientEquipment | undefined
): Record<string, any> => {
	if (!equipment || Object.keys(equipment)?.length === 0) return {}

	const size = databaseData.current.sizes?.[product?.product_size_type]

	return {
		patient: `${equipment?.patient?.lastName} ${equipment?.patient?.firstName}`,
		equipmentStatus: statusLabel?.[equipment.status as string] ?? equipment.status,
		sizeId: equipment.sizeIdHiboutik,
		serialNumber: equipment.serialNumber,
		tryoutDate: equipment?.dateTrial ? dayjs(equipment.dateTrial).format("DD/MM/YYYY") : null,
		soldDate: equipment?.datePurchase ? dayjs(equipment.datePurchase).format("DD/MM/YYYY") : null,
		sizeName: size?.sizes?.[equipment?.sizeIdHiboutik as number]?.size_name,
	}
}

export const pushLogisticProductData = (logistic: Record<string, any>): Record<string, any> => {
	if (!logistic)
		return {
			productStatus: "Inconnu",
		}

	const dateMissed =
		logistic?.depotDateEnd && dayjs().startOf("day").isAfter(dayjs(logistic.depotDateEnd).startOf("day"))

	const getDate = (dateObject: any): string | null => {
		if (dateObject?.date) return dayjs(dateObject.date).format("DD/MM/YYYY")
		else if (dateObject) return dayjs(dateObject).format("DD/MM/YYYY")
		else return null
	}

	const getStates = (logistic: Record<string, any>): string => {
		const status: string[] = []

		if (logistic.isDepot) {
			status.push("En dépot")
		}
		if (dateMissed) {
			status.push("Date dépassée")
		}
		if (!logistic.isDepot && (logistic.depotDateEnd || logistic.depotDate)) {
			status.push("Passé en ferme")
		} else if (!logistic.isDepot) {
			status.push("En ferme")
		}

		return status.join(", ")
	}

	return {
		productStatus: getStates(logistic),
		creditReceivedDate: getDate(logistic?.creditReceivedDate),
		returnedDate: getDate(logistic?.returnDate),
		receivedDate: getDate(null),
		depotEndDate: getDate(logistic?.depotDateEnd),
	}
}

export const pushProductData = (product: Record<string, any>): Record<string, any> => {
	return {
		productBrand: product?.brandName?.trim(),
		productSupplier: product?.supplierName?.trim(),
		productCategory: product?.categoryName?.trim(),
		productModel: product.model?.trim(),
		productId: product.id,
		sizeId: null,
		sizeName: null,
		serialNumber: null,
		equipmentStatus: null,
		productStatus: null,
		depotStartDate: null,
		patient: null,
		creditReceivedDate: null,
		returnedDate: null,
		receivedDate: null,
		tryoutDate: null,
		soldDate: null,
		quantity: null,
	}
}
