import dayjs from "dayjs"
import axios from "axios"
import { API_URL } from "../../../config"

async function agendaGetSchedules(year, month, monthAgenda, userId, laboratoryId) {
	//let req_uri = `${API_URL}/schedules/list?date_of[after]=${year}-${month}-01&date_of[before]=${year}-${month}-31`
	let req_uri = `${API_URL}/schedules/agenda?year=${year}&month=${month}`

	const currDate = dayjs(new Date(year, month - 1, 1, 0, 0, 0, 0)).format("YYYY-MM")

	if (monthAgenda.find((f) => f?.dateOf?.includes(currDate))) return

	if (Array.isArray(userId) && userId.length) {
		req_uri += `&user=${[...userId.map((m) => m.id)].join("-")}`
	}
	if (Array.isArray(laboratoryId) && laboratoryId.length) {
		req_uri += `&laboratory=${[...laboratoryId.map((m) => m.id)].join("-")}`
	}
	try {
		const result = await axios.get(req_uri)

		const filtered = result.data["hydra:member"].filter(
			(e) => /\S/.test(e?.patient?.firstName) && /\S/.test(e?.patient?.lastName)
		)
		if (!filtered?.length) {
			monthAgenda.push({
				dateOf: currDate,
			})
			return
		}
		for (const e of filtered) {
			if (e.status !== "RELANCE") {
				let title = ""

				if (e?.patient) title = `${e?.patient?.lastName} ${e?.patient?.firstName}`
				else title = "Sans patient"

				monthAgenda.push({
					...e,
					laboratoryId: [e?.laboratory?.id],
					userId: [e?.user?.id],
					startDate: dayjs(e.dateOf).toDate(),
					endDate: e?.dateEnd ? dayjs(e.dateEnd).toDate() : dayjs(e.dateOf).toDate(),
					title,
				})
			}
		}
	} catch (e) {
		console.error(e)
	}
}

export { agendaGetSchedules }
