import { SyncOutlined } from "@ant-design/icons"
import { Modal, ModalBody } from "reactstrap"

const LoadingModal = ({ isOpen, message }: { isOpen: boolean; message: string }): JSX.Element => {
	return (
		<Modal isOpen={isOpen} centered size="sm" className="modal-loading">
			<ModalBody className="modal-loading-body">
				<div className="modal-loading-message">{message}</div>

				<div className="modal-loading-spinner">
					<SyncOutlined spin />
				</div>
				<div className="modal-loading-info">
					Merci de ne pas fermer la page, cette opération peut prendre un certain temps
				</div>
			</ModalBody>
		</Modal>
	)
}

export default LoadingModal
