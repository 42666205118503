import { displayFloat, parsed } from "../../services/functions"
import _ from "lodash"

/**
 * Calcule le somme du prix HT et du prix TTC, et renvoi le montant TVA.
 */
function getAmounts(products) {
	if (products == null) return { HT: 0, TTC: 0, montantTVA: 0 }

	const totals = products.reduce(
		(totals, { item_total_net, item_total_gross }) => {
			totals.HT += parsed(item_total_net)
			totals.TTC += parsed(item_total_gross)

			return totals
		},
		{ HT: 0, TTC: 0 }
	)

	const montantTVA = parsed(parsed(totals.TTC) - parsed(totals.HT))
	return {
		HT: totals.HT,
		TTC: totals.TTC,
		montantTVA,
	}
}

export default function calcul(data) {
	const taxes = _.groupBy(data?.ventes?.achats, (a) => a?.tax_value) // prettier-ignore

	let totalAmountHT = 0
	let totalAmountTTC = 0
	let totalAmountTVA = 0

	const arrayAmount = []

	for (const tax in taxes) {
		const { HT, TTC, montantTVA } = getAmounts(taxes[tax])

		arrayAmount.push([arrayAmount.length + 1, displayFloat(HT), displayFloat(TTC), tax, displayFloat(montantTVA)])

		totalAmountHT += parsed(HT)
		totalAmountTTC += parsed(TTC)
		totalAmountTVA += parsed(montantTVA)
	}

	totalAmountHT = displayFloat(totalAmountHT)
	totalAmountTTC = displayFloat(totalAmountTTC)
	totalAmountTVA = displayFloat(totalAmountTVA)

	const secu = data?.ventes?.payment_details?.find((detail) => detail?.payment_type === "SECU")
	const mut = data?.ventes?.payment_details?.find((detail) => ["MUTL", "MTL", "CMU"].includes(detail?.payment_type))

	const partSecu = secu?.payment_amount ? displayFloat(parsed(secu?.payment_amount)) : "0.00"
	const partMut = mut?.payment_amount ? displayFloat(parsed(mut?.payment_amount)) : "0.00"
	const restCharge =
		partSecu || partMut
			? displayFloat(parsed(totalAmountTTC) - parsed(partSecu || 0) - parsed(partMut || 0))
			: parsed(totalAmountTTC)

	return {
		totalAmountHT,
		totalAmountTTC,
		arrayAmount,
		partMut,
		restCharge,
		partSecu,
		totalAmountTVA,
	}
}
