/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react"
import moments from "moment"
import { toast } from "react-toastify"
import { SCHEDULES_API } from "../../../config"
import axios from "axios"
import { formatDatetimeForDB } from "../../../services/functions"
import AuthContext from "../../../contexts/AuthContext"
import AgendaModal from "../Agenda/ModalAgendaContainer"
import dayjs from "dayjs"
import { createTodo } from "../handleTodos.js"
import API from "../../../services/API"

import ScheduleTypeSelect from "./Schedule.TypeSelect"
import SchedulePatientSearch from "./Schedule.PatientSelect"
import ScheduleStatusSelect from "./Schedule.StatusSelect"
import AsyncLaboratorySelect from "../../../components/utils/AsyncLaboratorySelect"
import AsyncUserSelect from "../../../components/utils/AsyncUserSelectMultiple"
import { Editor } from "@tinymce/tinymce-react"
import { confirmWithModal } from "../../../components/effects/ConfirmModalFunction"

const ScheduleUserSelect = ({ user, fromAgenda, setSelectedUser, allowedLaboratories, loading }) => {
	return (
		<div className="schedule-type-selector">
			<AsyncUserSelect
				data={user}
				mode="simple"
				className="w-100"
				size={"large"}
				placeholder="Sélectionner un utilisateur..."
				allowClear={false}
				disabled={loading || fromAgenda}
				setData={setSelectedUser}
				allowedLaboratories={allowedLaboratories}
			/>
		</div>
	)
}

const ScheduleLaboratorySelect = ({ laboratory, fromAgenda, setSelectedLaboratory, loading }) => {
	return (
		<div className="schedule-type-selector">
			<AsyncLaboratorySelect
				allowClear={false}
				size={"large"}
				disabled={loading || fromAgenda}
				data={laboratory}
				className="w-100"
				mode="simple"
				setData={setSelectedLaboratory}
			/>
		</div>
	)
}

export default function NewScheduleSelector({
	selectedDate,
	selectedPatient,
	selectedType,
	setShowModal,
	editMode,
	needRefresh,
	showAll,
	newScheduleSaved,
	saveOk,
	fromNewSchedule,
	currentLaboratory,
	currentUser,
	fromAgenda,
	setCurrentlyEditedAppointment,
	currentlyEditedAppointment,
}) {
	const ctx = useContext(AuthContext)

	const [evtData, setEvtData] = useState({
		patient: ctx.patient,
		startDate: new Date(),
		startTime: new Date(),
		state: "WAITING",
		fromAgenda,
	})

	const [showModalAgenda, setModalAgenda] = useState(false)
	const [dateFromAgenda, setDateFromAgenda] = useState([])
	const [loading, setLoading] = useState(false)

	const [selectedLaboratory, setSelectedLaboratory] = useState(null)
	const [selectedUser, setSelectedUser] = useState(null)

	const [defaultStartDate, setStartDate] = useState(null)

	const [saving, setSaving] = useState(false)

	const loadExistingSchedule = async (id) => {
		try {
			setLoading(true)
			const data = {
				fromAgenda,
			}

			const result = await axios.get(SCHEDULES_API + "/" + id)

			const startDate = dayjs(result.data.dateOf).toString()
			const startTime = dayjs(result.data.dateOf).format("HH:mm")

			let endDate = dayjs(result.data.dateOf).toString()
			let endTime = dayjs(result.data.dateOf).format("HH:mm")

			if (result?.data?.dateEnd) {
				endDate = dayjs(result.data.dateEnd).toString()
				endTime = dayjs(result.data.dateEnd).format("HH:mm")
			}

			if (result?.data?.patient) {
				data.patient = {
					id: result.data.patient.id,
					lastName: result.data.patient.lastName,
					firstName: result.data.patient.firstName,
				}
			}
			setEvtData({
				id: result?.data?.id,
				startDate: startDate,
				endDate: endDate,
				startTime: startTime,
				endTime: endTime,
				note: result.data.notes,
				preScheduleNote: result.data.preScheduleNote,
				status: result.data.status,
				state: result.data.state,
				type: result.data.type,
				...data,
			})
			if (result?.data?.user?.["@id"]) {
				const splitted = result.data.user["@id"].split("/users/")
				setSelectedUser(+splitted?.[splitted?.length - 1])
			} else {
				setSelectedUser(result?.data?.user?.id)
			}
			setSelectedLaboratory(+result?.data?.laboratory?.id)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const saveSchedule = async () => {
		if (!evtData.endDate || !evtData.endTime || !evtData.startDate || !evtData.startTime) {
			toast.error("Veuillez renseigner une date")
			return
		}
		if (!evtData.status && !editMode) {
			toast.error("Veuillez renseigner le type de rendez-vous")
			return
		}
		// if (!evtData?.patient?.id) {
		// 	toast.error("Veuillez renseigner le patient")
		// 	return
		// }

		let startDate = new Date(evtData.startDate)
		let endDate = new Date(evtData.endDate)

		let endTime = evtData?.endTime && evtData.endTime.split(":")
		let startTime = evtData.startTime.split(":")
		evtData.status = evtData?.status?.toUpperCase()

		const date_of = new Date(
			startDate.getFullYear(),
			startDate.getMonth(),
			startDate.getDate(),
			startTime[0],
			startTime[1]
		)
		const end_of = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime[0], endTime[1])

		const data = {
			dateEnd: formatDatetimeForDB(end_of),
			dateOf: formatDatetimeForDB(date_of),
			status: evtData.status,
			state: evtData.state || "WAITING",
			type: evtData.type || "CLASSIQUE",
			preScheduleNote: evtData.preScheduleNote || null,
		}

		if (selectedUser) data.user = "/users/" + selectedUser
		if (selectedLaboratory) data.laboratory = "/laboratories/" + selectedLaboratory

		if (evtData.patient?.id) data.patient = "/patients/" + evtData.patient.id
		else data.patient = null

		const create = async () => {
			try {
				setSaving(true)
				createTodo(ctx, data)
				await axios.post(SCHEDULES_API, data)
				toast.success("Le rendez-vous a été ajouté")

				if (evtData?.patient?.id && (selectedUser || selectedLaboratory)) {
					try {
						await API.update("PATIENTS_API", evtData.patient.id, {
							laboratory: selectedLaboratory ? "/laboratories/" + selectedLaboratory : null,
							mainUser: selectedUser ? "/users/" + selectedUser : null,
						})
					} catch (e) {
						console.error(e)
					}
				}
				typeof needRefresh === "function" && needRefresh()
				if (typeof saveOk === "function") saveOk(true)
				if (typeof newScheduleSaved === "function") {
					newScheduleSaved({
						...data,
						success: true,
					})
				}
				setShowModal()

				setSaving(false)
			} catch (e) {
				if (typeof saveOk === "function") saveOk(false)

				if (typeof newScheduleSaved === "function") {
					newScheduleSaved({
						...data,
						success: false,
					})
				}
				setSaving(false)
				toast.error("Une erreur est survenue durant l'ajout de l'évènement")
				console.error("=>", e)
			}
		}

		const edit = async (id) => {
			try {
				setSaving(true)
				if (data?.id) delete data.id

				await axios.put(SCHEDULES_API + "/" + id, data)
				toast.success("Le rendez-vous a été modifié avec succès")
				if (typeof needRefresh === "function") needRefresh()
				if (typeof saveOk === "function") saveOk(false)
				setSaving(false)
				setShowModal(false)
			} catch (e) {
				toast.error("Une erreur est survenue durant la modification de l'évènement")
				console.error("=>", e)
				setSaving(false)
			}
		}
		if (editMode) edit(editMode)
		else create()
	}

	function setDates(data) {
		if (data && data.length === 1) {
			setEvtData((old) => ({ ...old, startDate: data[0] }))
			setEvtData((old) => ({ ...old, startTime: moments(data[0]).format("HH:mm") }))
			setEvtData((old) => ({ ...old, endDate: data[0] }))
			setEvtData((old) => ({ ...old, endTime: moments(data[0]).add(15, "minute").format("HH:mm") }))
		}
		if (data && data.length === 2) {
			setEvtData((old) => ({ ...old, startDate: data[0] }))
			setEvtData((old) => ({ ...old, startTime: moments(data[0]).format("HH:mm") }))
			setEvtData((old) => ({ ...old, endDate: data[1] }))
			setEvtData((old) => ({ ...old, endTime: moments(data[1]).format("HH:mm") }))
		}
	}

	useEffect(() => {
		;(async function () {
			setLoading(true)
			if (editMode) {
				if (currentlyEditedAppointment?.id === editMode) {
					setEvtData(currentlyEditedAppointment)
					setDates([currentlyEditedAppointment?.startDate, currentlyEditedAppointment?.endDate])
				} else await loadExistingSchedule(editMode)
			} else {
				if (!currentUser?.id && !currentLaboratory?.id) {
					setSelectedLaboratory(ctx.laboratory.id)
					setSelectedUser(ctx.user.id)
				}
			}
			setDates(selectedDate)
			setLoading(false)
		})()
	}, [])

	useEffect(() => {
		;(async function () {
			if (!editMode && fromNewSchedule && ctx?.patient?.["@id"])
				setEvtData((old) => ({ ...old, patient: ctx.patient }))
		})()
	}, [ctx?.patient])

	useEffect(() => {
		currentLaboratory && setSelectedLaboratory(currentLaboratory)
	}, [currentLaboratory])

	useEffect(() => {
		currentUser && setSelectedUser(currentUser)
	}, [currentUser])

	useEffect(() => {
		setEvtData((old) => ({ ...old, user: selectedUser }))
		setEvtData((old) => ({ ...old, laboratory: selectedLaboratory }))
	}, [selectedLaboratory, selectedUser])

	useEffect(() => {
		let _startDate = evtData?.startDate ?? null

		if (evtData?.status === "APPAREILLE" && evtData?.type?.includes("OBLIGATOIRE_")) {
			const time = evtData.type.split("_")[1]
			const startDate = dayjs().add(time, "month")
			_startDate = startDate.toDate()
		}

		setStartDate(_startDate)
	}, [evtData])

	useEffect(() => {
		setDates(dateFromAgenda)
	}, [dateFromAgenda])

	const printStartDateAndTime = () => {
		try {
			const mom = dayjs(evtData.startDate).format("ddd DD MMM YYYY")
			const mom_end = dayjs(evtData.endDate).format("ddd DD MMM YYYY")
			const dat = mom.split(" ")
			const dat_end = mom_end.split(" ")
			const s_time = evtData.startTime.split(":")
			const s_time_end = evtData.endTime.split(":")
			const res = (
				<>
					Du <span className="dayDate">{dat[1]}</span> <span className="monthName">{dat[2]}</span> {dat[3]} à{" "}
					<span className="hour">{s_time[0]}</span>h<span className="minutes">{s_time[1]}</span> au{" "}
					<span className="dayDate">{dat_end[1]}</span> <span className="monthName">{dat_end[2]}</span>{" "}
					{dat_end[3]} à <span className="hour">{s_time_end[0]}</span>h
					<span className="minutes">{s_time_end[1]}</span>
				</>
			)
			return res
		} catch (e) {
			return <></>
		}
	}

	return (
		<>
			<div className="modal-agenda">
				<div className="container">
					<div className="title">
						Planification du rendez-vous{" "}
						{evtData.patient?.firstName && `pour ${evtData.patient.firstName} ${evtData.patient.lastName}`}
					</div>
					<div className="content newSchedule">
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Patient</div>
							<div className="col">
								<SchedulePatientSearch
									disabled={
										loading || (!editMode && fromNewSchedule && evtData?.patient?.id ? true : false)
									}
									data={evtData}
									setData={setEvtData}
									selectedLaboratory={selectedLaboratory}
									selectedUser={selectedUser}
								/>
							</div>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Type *</div>
							<div className="col">
								<ScheduleTypeSelect data={evtData} setData={setEvtData} loading={loading} />
							</div>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Status</div>
							<div className="col">
								<ScheduleStatusSelect data={evtData} setData={setEvtData} loading={loading} />
							</div>
						</div>

						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Laboratoire</div>
							<div className="col">
								<ScheduleLaboratorySelect
									laboratory={selectedLaboratory}
									fromAgenda={fromAgenda}
									fromNewSchedule={fromNewSchedule}
									setSelectedLaboratory={setSelectedLaboratory}
									loading={loading}
								/>
							</div>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Utilisateur</div>
							<div className="col">
								<ScheduleUserSelect
									user={selectedUser}
									fromAgenda={fromAgenda}
									fromNewSchedule={fromNewSchedule}
									setSelectedUser={setSelectedUser}
									allowedLaboratories={[selectedLaboratory]}
									loading={loading}
								/>
							</div>
						</div>
						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Date et heure *</div>
							<div className="col">
								<button
									onClick={() => {
										if (evtData.startDate && evtData.endDate) {
											if (fromAgenda) {
												typeof setCurrentlyEditedAppointment === "function" &&
													setCurrentlyEditedAppointment(evtData)
												setShowModal(false)
											} else {
												setStartDate(evtData.startDate)
												setModalAgenda(true)
											}
										} else setModalAgenda(true)
									}}
									type="button"
									disabled={fromAgenda || loading}
									className="btn btn-outline-primary btn-block btn-sm">
									{evtData.startDate && evtData.endDate
										? printStartDateAndTime(evtData)
										: "Sélectionner une plage horaire"}
								</button>
							</div>
							{/* <div className="col col-3">
								<button
									type="button"
									disabled={true}
									className="btn btn-outline-light btn-block btn-sm">
									toute la journée
								</button>
							</div> */}
						</div>

						<div className="evt-notes-container">
							<h5>Notes</h5>

							{/* <textarea
								value={evtData.preScheduleNote ?? ""}
								onChange={(e) => {
									const preScheduleNote = e.target.value
									setEvtData((old) => ({ ...old, preScheduleNote }))
								}}
								className="evt-txtarea"π
								placeholder="Note pour le rendez-vous..."></textarea> */}

							{!loading && (
								<Editor
									apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
									//disabled={loading}
									initialValue={null}
									init={{
										height: 200,
										menubar: false,
										plugins: [
											"advlist autolink lists link image charmap print preview anchor",
											"searchreplace visualblocks code fullscreen",
											"insertdatetime media table paste",
										],
										toolbar:
											"undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ",
										language: "fr_FR",
										browser_spellcheck: true,
										content_style: "p { margin: 0.5em 0; } ", // reduce margin between p
									}}
									value={evtData.preScheduleNote || ""}
									onEditorChange={(text) => {
										setEvtData((old) => ({ ...old, preScheduleNote: text }))
									}}
								/>
							)}
						</div>

						{/* <div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Utilisateur *</div>
							<div className="col">
								<ScheduleUserSelect />
							</div>
						</div>

						<div className="row scheduling-part">
							<div className="col col-3 scheduling-title">Laboratoire *</div>
							<div className="col">
								<ScheduleLaboratorySelect />
							</div>
						</div> */}

						<div className="evt-footer">
							{evtData.state !== "DONE" && evtData.id && (
								<button
									className="btn btn-sm btn-danger"
									type="button"
									onClick={async () => {
										try {
											const hasConfirmed = await confirmWithModal({
												title: (
													<>
														Voulez-vous vraiment supprimer ce rendez-vous ? Toute trace sera
														effacée,
														<strong> cette action est définitive et irréversible</strong>.
													</>
												),
											})
											if (!hasConfirmed) return

											setSaving(true)
											const res = API.delete(`/schedules/${evtData.id}`)
											if (typeof needRefresh === "function") needRefresh(true)
											if (typeof setShowModal === "function") setShowModal(false)
										} catch (error) {
											console.error(error)
										} finally {
											setSaving(false)
										}
									}}>
									Supprimer
								</button>
							)}

							<button
								type="button"
								disabled={loading || saving}
								className="btn btn-sm btn-warning"
								onClick={() => {
									setShowModal(false)
								}}>
								{evtData.id ? "Annuler les modifications" : "Annuler et fermer"}
							</button>
							<button
								type="button"
								className="btn btn-sm btn-primary"
								style={{ filter: saving ? "grayscale(100%)" : "none" }}
								onClick={() => {
									if (!saving) {
										typeof setCurrentlyEditedAppointment === "function" &&
											setCurrentlyEditedAppointment({})
										saveSchedule(true)
									}
								}}
								disabled={loading || saving}>
								{saving
									? "Enregistrement..."
									: `${evtData.id ? "Enregistrer les modifications" : "Créer le rendez-vous"}`}
							</button>
						</div>
					</div>
				</div>
			</div>

			{showModalAgenda && (
				<AgendaModal
					onlyAvailable={true}
					selectedDate={setDateFromAgenda}
					fromNewSchedule={true}
					setShow={() => setModalAgenda(false)}
					setSelectedLaboratory={setSelectedLaboratory}
					setSelectedUser={setSelectedUser}
					defaultStartDate={defaultStartDate}
					appointmentFromScheduleSelector={evtData}
					currentLaboratory={selectedLaboratory}
					currentUser={selectedUser}
				/>
			)}
		</>
	)
}
