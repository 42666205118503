import { HIB } from "./Routes"
import { Rules } from "./Interface"

const RULES: Rules[] = [
	{
		get: HIB.PRODUCTS,
		put: HIB.PRODUCT,
		post: /(.*).hiboutik.com\/api\/products\/?(.*)?/,
		delete: HIB.PRODUCT,
		clear: [HIB.PRODUCTS],
		validity: 240,
	},
	{
		get: HIB.PRODUCTS_TAGS,
		post: /(.*).hiboutik.com\/api\/products_tags\/\d+/,
		clear: [HIB.PRODUCTS_TAGS],
		validity: 240,
	},
	{
		get: HIB.SIZES,
		clear: [HIB.SIZES, HIB.PRODUCTS],
		validity: 240,
	},
	{
		get: HIB.BRANDS,
		clear: [HIB.BRANDS, HIB.PRODUCTS],
		validity: 240,
	},
	{
		get: HIB.CATEGORIES,
		clear: [HIB.CATEGORIES, HIB.PRODUCTS],
		validity: 240,
	},
	{
		get: HIB.SIZES_PRETTY,
		clear: [HIB.SIZES_PRETTY],
		validity: 240,
	},
	{
		get: HIB.SIZES_TYPES,
		clear: [HIB.SIZES_TYPES],
		validity: 240,
	},
	{
		get: HIB.SIZES,
		clear: [HIB.SIZES],
		validity: 240,
	},
	{
		get: HIB.SUPPLIERS,
		clear: [HIB.SUPPLIERS],
		validity: 240,
	},
	{
		get: HIB.WAREHOUSES,
		clear: [HIB.WAREHOUSES],
		validity: 240,
	},
	// {
	// 	get: API.PRODUCTS_LOCAL,
	// 	delete: API.PRODUCTS_LOCAL,
	// 	clear: [API.PRODUCTS_LOCAL],
	// 	validity: 5,
	// },
]

export default RULES
