import axios from "axios"

// Http client instance w/o Authorization header
export const GlobalClient = axios.create({
	transformRequest: [
		function (data, headers) {
			delete headers!["Authorization"]
			return data
		},
	],
})
