import { colorFromStr } from "services/functions"
import API from "../../services/API"

export const colorToHex = (color) => {
	const r = (color >> 16) & 255
	const g = (color >> 8) & 255
	const b = color & 255

	return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`
}

export const hexToDec = (hex) => {
	return parseInt(hex.toString().substring(1), 16)
}

export const getColor = (color, type) => {
	if (color === 1621148) {
		return colorFromStr(type)
	}
	return colorToHex(color)
}

const getSchedulesType = async () => {
	const result = {}

	try {
		const res = await API.findAll("SCHEDULE_TYPES")

		for (const item of res) {
			result[item.scheduleStatus] = {
				color: getColor(item.backgroundColor, item.scheduleStatus),
				customLabel: item?.label,
				label: item?.label,
				time: item.duration,
				action: item.action,
				scheduleInterval: item.scheduleInterval,
			}
		}
	} catch (error) {
		console.error(error)
	}

	return result
}

export default getSchedulesType
