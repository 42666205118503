import React, { ChangeEvent, Dispatch, SetStateAction } from "react"
import { getAvailableBrands, getAvailableCategories, getAvailableSuppliers } from "../Shared/Utils"

export type FiltersProps = {
	data: any[]
	filters: Record<string, any>
	setFilters: Dispatch<SetStateAction<any[]>>
}

const Filters = ({ data, filters, setFilters }: FiltersProps): JSX.Element => {
	const handleChangeSelect = (event: ChangeEvent<HTMLSelectElement>, filterKey: string): void => {
		const value = event.target.value
		if (value === "ALL") {
			setFilters((old) => ({ ...old, [filterKey]: null }))
		} else {
			setFilters((old) => ({ ...old, [filterKey]: Number.isInteger(value) ? +value : value }))
		}
	}

	const handleChangeInput = (event: ChangeEvent<HTMLInputElement>, filterKey: string): void => {
		const value = event.target.value
		setFilters((old) => ({ ...old, [filterKey]: Number.isInteger(value) ? +value : value }))
	}

	return (
		<>
			<div className="row">
				<div className="col form-group">
					<label>Par catégorie</label>
					<select
						className="form-control form-control-sm"
						onChange={(evt) => handleChangeSelect(evt, "categoryId")}>
						<option value={"ALL"}>Tout voir</option>
						{getAvailableCategories(data).map((value, key) => (
							<option key={key} value={value.id}>
								{value.label}
							</option>
						))}
					</select>
				</div>
				<div className="col form-group">
					<label>Par marque</label>
					<select
						className="form-control form-control-sm"
						onChange={(evt) => handleChangeSelect(evt, "brandId")}>
						<option value={"ALL"}>Tout voir</option>
						{getAvailableBrands(data).map((value, key) => (
							<option key={key} value={value.id}>
								{value.label}
							</option>
						))}
					</select>
				</div>
				<div className="col form-group">
					<label>Par fournisseur</label>
					<select
						className="form-control form-control-sm"
						value={filters.supplierId}
						onChange={(evt) => handleChangeSelect(evt, "supplierId")}>
						<option value={"ALL"}>Tout voir</option>
						{getAvailableSuppliers(data).map((value, key) => (
							<option key={key} value={value.id}>
								{value.label}
							</option>
						))}
					</select>
				</div>
				{/* <div className="col form-group">
					<label>Par laboratoire</label>
					<select
						className="form-control form-control-sm"
						value={filters.warehouseId}
						onChange={(evt) => handleChangeSelect(evt, "warehouseId")}>
						{user.laboratories?.map((value, key) => (
							<option key={key} value={value.warehouseIdHiboutik}>
								{value.label}
							</option>
						))}
					</select>
				</div> */}
			</div>
			<div className="row ">
				<div className="col form-group">
					<label>Par statut</label>
					<select
						className="form-control form-control-sm"
						onChange={(evt) => handleChangeSelect(evt, "state")}>
						<option value="ALL">Tout voir</option>
						<option value="FERME">En ferme</option>
						<option value="DEPOT">En dépôt</option>
						<option value="DEPOT_DEPASSE">En dépôt dépassé</option>
						{/* <option value=""></option> */}
					</select>
				</div>

				<div className="col form-group">
					<label>Par numéro de série</label>
					<input
						type="text"
						className="form-control form-control-sm"
						placeholder="P90-98797BEF..."
						onChange={(evt) => handleChangeInput(evt, "serialNumber")}
					/>
				</div>
				<div className="col form-group">
					<label>Par bon de livraison</label>
					<input
						type="text"
						className="form-control form-control-sm"
						placeholder="B-324B21..."
						onChange={(evt) => handleChangeInput(evt, "logisticFormLabel")}
					/>
				</div>
				<div className="col form-group">
					<label>Par date limite de dépôt</label>
					<input
						type="date"
						className="form-control form-control-sm"
						onChange={(evt) => handleChangeInput(evt, "depositDate")}
					/>
				</div>
			</div>
		</>
	)
}

export default Filters
