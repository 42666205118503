import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import * as ApiRoutes from "../config"
import * as AuthApi from "./AuthApi"
import _ from "lodash"
import { Company, DocumentSendMailRequestData, User } from "@audiowizard/common"

export type ApiTarget = keyof typeof ApiRoutes

export type CollectionResponse<T extends unknown[]> = {
	"hydra:member": T
	"hydra:totalItems": number
}

function findAll<T extends unknown[]>(
	target: ApiTarget,
	condition?: string,
	raw?: false,
	config?: AxiosRequestConfig
): Promise<T>
function findAll<T extends unknown[]>(
	target: ApiTarget,
	condition?: string,
	raw?: true,
	config?: AxiosRequestConfig
): Promise<CollectionResponse<T>>
async function findAll<T extends unknown[]>(
	target: ApiTarget,
	condition?: string,
	raw = false,
	config?: AxiosRequestConfig
): Promise<T> {
	const response = await axios.get(condition ? ApiRoutes[target] + condition : ApiRoutes[target], config)

	if (raw === true) {
		return response.data
	} else {
		return response.data["hydra:member"] ? response.data["hydra:member"] : response.data
	}
}

async function countAll(target: ApiTarget, condition?: string): Promise<number> {
	const response = await axios.get(condition ? ApiRoutes[target] + condition : ApiRoutes[target])

	return response.data["hydra:totalItems"] ? +response.data["hydra:totalItems"] : 0
}

// TARGET = PATIENTS_API
async function custom<T>(target: string): Promise<T> {
	const response = await axios.get(ApiRoutes["API_URL"] + target)

	return response.data
}

const sante = async <T>(endpoint: string, params: string): Promise<T> => {
	params = params?.toString()
	return AuthApi.getTokenHealthcare().then(async (token) => {
		let paramsWithSlash = params
		if (params?.length && params.charAt(0) !== "?") {
			paramsWithSlash = `/${params}`
		}
		const response = await axios.get(ApiRoutes["API_SANTE_URL"] + endpoint + paramsWithSlash, {
			headers: { Authorization: "Bearer " + token },
		})

		return await response.data
	})
}

// TARGET = PATIENTS_API
async function mail<T>(target: ApiTarget, data: unknown): Promise<T> {
	const response = await axios.post(ApiRoutes[target], data)

	return await response.data
}

//findFiltered("PATIENT_MEDICALS", "patient", patient['@id'])
/**
 * @deprecated Utilisez `API.findAll(target, "?field=parameter")` à la place
 */
async function findFiltered<T>(target: ApiTarget, field: string, parameter: string): Promise<T> {
	const response = await axios.get(ApiRoutes[target] + "?" + field + "=" + parameter)

	return await response.data["hydra:member"]
}

/**
 * @deprecated Utilisez `API.findAll(target, "?field=parameter")` à la place
 */
async function findCustom<T>(target: ApiTarget, parameter = null): Promise<T> {
	const response = await axios.get(ApiRoutes[target] + "?" + parameter)

	return await response.data["hydra:member"]
}

async function find<T>(target: ApiTarget, id: number | string, config?: AxiosRequestConfig): Promise<T> {
	const response = await axios.get(ApiRoutes[target] + "/" + id, config)

	return await response.data
}

async function update<T>(target: ApiTarget, id: number | string, data: unknown): Promise<AxiosResponse<T>> {
	const response = await axios.put(ApiRoutes[target] + "/" + id, data, {
		headers: { "content-type": "application/ld+json" },
	})

	return response
}

async function create<T>(target: ApiTarget, data: unknown): Promise<AxiosResponse<T>> {
	const response = await axios.post(ApiRoutes[target], data)

	return response
}

async function updateDocument<T>(id: number, document: { [key: string]: any }): Promise<AxiosResponse<T>> {
	const data = new FormData()

	if (document.file)
		data.append("file", document.file, `${_.deburr(document.filename.replaceAll(" ", "-"))}.${document.extension}`)
	if (document.filename) data.append("filename", document.filename)
	if (document.company) data.append("company", document.company)
	if (document.laboratory) data.append("laboratory", document.laboratory)
	if (document.laboratories) data.append("laboratories", document.laboratories)
	if (document.config) data.append("config", JSON.stringify(document.config))
	if (document.label) data.append("label", document.label)
	if (document.extension) data.append("extension", document.extension)
	if (document.type) data.append("type", document.type)
	if (document.status) data.append("status", document.status)
	if (document.patient) {
		// Get Iri if document.patient is Patient entity
		const patientIri = typeof document.patient === "string" ? document.patient : document.patient["@id"]
		data.append("patient", patientIri)
	}
	if (document.numero) data.append("numero", document.numero)
	if (document.seller) data.append("seller", document.seller)
	if (document.hasSignature) data.append("hasSignature", document.hasSignature)
	if (document.hasSignature) data.append("hasSignatureQuoteSteps", JSON.stringify(document.hasSignatureQuoteSteps))

	// This form-data force an override on Symfony's side to treat POST request as PUT request.
	// This is needed because PUT multipart/form-data are not handled the same way as POST.
	data.append("_method", "put")

	const response = await axios.post(`${ApiRoutes.DOCUMENTS_API}/${id}/update_file`, data)

	return response
}

async function createDocument<T>(document: { [key: string]: any }): Promise<AxiosResponse<T>> {
	const data = new FormData()

	document.file &&
		data.append("file", document.file, `${_.deburr(document.filename.replaceAll(" ", "-"))}.${document.extension}`)

	document.filename && data.append("filename", document.filename)
	document.company && data.append("company", document.company)
	document.laboratory && data.append("laboratory", document.laboratory)
	document.laboratories && data.append("laboratories", JSON.stringify(document.laboratories))
	document.config && data.append("config", JSON.stringify(document.config))
	document.label && data.append("label", document.label)
	document.extension && data.append("extension", document.extension)
	document.type && data.append("type", document.type)
	document.status && data.append("status", document.status)
	document.patient && data.append("patient", document.patient)
	document.numero && data.append("numero", document.numero)
	document.seller && data.append("seller", document.seller)
	document.hasSignature && data.append("hasSignature", document.hasSignature)
	document.hasSignature && data.append("hasSignatureQuoteSteps", JSON.stringify(document.hasSignatureQuoteSteps))

	const response = await axios.post(ApiRoutes.DOCUMENTS_API, data)

	return response
}

async function documentSendMail(documentId: number, sendMailValue: DocumentSendMailRequestData): Promise<void> {
	await axios.post(`${ApiRoutes["DOCUMENTS_API"]}/${documentId}/send_mail`, sendMailValue) // axios directement pour faire un POST sur une route custom
}

async function deleteRessource<T>(target: string): Promise<T | AxiosResponse<unknown>> {
	return await axios.delete(ApiRoutes["API_URL"] + target)
}

export interface APIAdresseGouvFr {
	type: string
	geometry: {
		type: string
		coordinates: number[]
	}
	properties: {
		label: string
		score: number
		id: string
		type: string
		name: string
		postcode: string
		citycode: string
		x: number
		y: number
		population?: number
		city: string
		context: string
		importance: number
	}
}

async function getAddress(value: string): Promise<APIAdresseGouvFr[]> {
	const fetchAddress = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}&type=&autocomplete=1`, {
		method: "GET",
		redirect: "follow",
	})
		.then((response) => response.json())
		.then((result) => result.features)
		.catch((error) => console.error("error", error))

	return fetchAddress
}

async function goCardlessMandateNew(
	audioWizardContractId?: number
): Promise<AxiosResponse<{ redirectFlowUrl: string }>> {
	// Fix rapide, pour récupérer l'audiowizardContractId si il est pas dispo depuis le context
	if (audioWizardContractId == null) {
		audioWizardContractId = (await AuthApi.fetchUser())!.company!.audioWizardContract!.id!
	}

	return await axios.post(`${ApiRoutes["AUDIO_WIZARD_CONTRACTS_API"]}/${audioWizardContractId}/mandate/new`, {}) // On doit envoyer un object vide pour ne pas avoir une erreur de api platform
}
async function goCardlessMandateValidate(
	audioWizardContractId: number,
	redirectFlowId: string,
	services: string[] = []
): Promise<void> {
	await axios.post(`${ApiRoutes["AUDIO_WIZARD_CONTRACTS_API"]}/${audioWizardContractId}/mandate/validate`, {
		redirectFlowId,
		services,
	})
}

function updateCompanyLogo(companyId: number, file: File): Promise<AxiosResponse<Company>> {
	const data = new FormData()
	data.append("logo", file)

	return axios.post(`${ApiRoutes.COMPANIES_API}/${companyId}/logo`, data)
}
function updateUserSignature(userId: number, file: File): Promise<AxiosResponse<User>> {
	const data = new FormData()
	data.append("signature", file)

	return axios.post(`${ApiRoutes.USERS_API}/${userId}/signature`, data)
}

function updateWhiteLabelLogo(whiteLabelId: number, logo: File): Promise<AxiosResponse> {
	const data = new FormData()
	data.append("logo", logo)

	return axios.post(`${ApiRoutes.WHITE_LABELS_API}/${whiteLabelId}/logo`, data)
}

// Iri api utility

async function updateWithIri<T>(iri: string, data: Record<string, any>): Promise<AxiosResponse<T>> {
	return await axios.put(ApiRoutes.API_URL + iri, data)
}

async function get<T>(iri: string, query?: string): Promise<T> {
	const response = await axios.get(ApiRoutes.API_URL + iri + (query ?? ""))

	return response.data["hydra:member"] ?? response.data
}

const getHiboutik = async <T>(endpoint: string, id: number | null = null): Promise<T> => {
	let path = endpoint

	if (id) {
		path += `/${id}`
	}
	const response = await axios.get(ApiRoutes.API_URL + "/store/" + path)

	return response?.data
}

const API = {
	findAll,
	countAll,
	delete: deleteRessource,
	find,
	update,
	updateWithIri,
	create,
	findFiltered,
	findCustom,
	custom,
	mail,
	sante,
	getAddress,
	createDocument,
	updateDocument,
	documentSendMail,
	goCardlessMandateNew,
	goCardlessMandateValidate,
	updateCompanyLogo,
	updateUserSignature,
	updateWhiteLabelLogo,
	get,
	getHiboutik,
}
export default API
