import { HiboutikProductEntity, LogisticProduct, PatientEquipment } from "@audiowizard/common"
import { cloneDeep } from "lodash"
import uuid from "react-uuid"

export type AvailableSerialNumberType = {
	"@id": string
	"@type": string
	brandId: number
	brandName?: string
	categoryId: number
	categoryName?: string
	id: number
	inventoryInputDetailId: number
	patientEquipment?: PatientEquipment | PatientEquipment[]
	logisticProduct?: LogisticProduct
	key: string
	quantityAvailable?: number
	sizeId?: number
	sizeName?: string
	serialNumber?: string
}

export const extractAvailableSerialnumber = (products: HiboutikProductEntity[]): any[] => {
	const tmp: any[] = cloneDeep(products ?? [])
	const result: any[] = []

	for (const product of tmp) {
		if (!product.stockManagement || !product?.stockAvailable?.length) continue

		for (const item of product.stockAvailable) {
			delete product.stockAvailable
			delete product.sizes
			delete product.quantityAvailable
			delete product.sizeDetails
			delete item["@id"]
			delete item["@type"]
			if (!item.serialNumber) continue
			result.push({
				...product,
				...item,
				key: uuid(),
			})
		}
	}

	return result
}

export const extractAvailableWithoutSerialnumber = (products: any[]): any[] => {
	const tmp: any[] = cloneDeep(products ?? [])
	const result: any[] = []

	for (const product of tmp) {
		if (!product.stockManagement || !product?.stockAvailable?.length) continue

		for (const item of product.stockAvailable) {
			delete product.stockAvailable
			delete product.sizes
			delete item["@id"]
			delete item["@type"]
			delete product.quantityAvailable
			delete product.sizeDetails
			if (item.serialNumber) continue
			result.push({
				...product,
				...item,
				key: uuid(),
			})
		}
	}
	return result
}
