import { Laboratory, User } from "@audiowizard/common"
import { useContext, useEffect } from "react"
import AuthContext from "../../contexts/AuthContext"

function useNotifierSystem(
	fn: (type: string, res: { [key: string]: any }, currentUser?: User, laboratory?: Laboratory) => void,
	dependencies: string[]
): typeof useNotifierSystem {
	const { user, laboratory } = useContext(AuthContext)

	const handleEvent = (e: any): void => {
		console.info(`[JAVASCRIPT] [EVENT] [${e.type}] Received.`)
		fn(e.type, e.detail, user, laboratory)
	}

	useEffect(() => {
		for (const dependency of dependencies) {
			console.info(`[JAVASCRIPT] [EVENT] [${dependency}] Listen.`)
			window.addEventListener(dependency, handleEvent)
		}

		return () => {
			for (const dependency of dependencies) {
				window.removeEventListener(dependency, handleEvent)
				console.info(`[JAVASCRIPT] [EVENT] [${dependency}] Cleared.`)
			}
		}
		// eslint-disable-next-line
	}, [user, laboratory])

	return useNotifierSystem
}

export default useNotifierSystem
