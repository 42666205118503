/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react"
import { Link } from "react-router-dom"
import AuthContext from "../../contexts/AuthContext"
import * as AuthApi from "../../services/AuthApi"
import { clearServiceWorkerCache, haveRole } from "../../services/functions"
import CacheSystem from "../../services/CacheSystem/Cache.ts"
import { urlTeletransmission } from "pages/FSV/utilsFSV"
import dayjs from "dayjs"

const DropDownMenu = () => {
	const { user, laboratory } = useContext(AuthContext)

	const handleLogout = (event) => {
		event.preventDefault()
		CacheSystem.clear()
		AuthApi.logout()
		window.location.href = "/"
	}

	return (
		<div>
			<div
				className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow"
				aria-labelledby="navbar-primary_dropdown_1">
				<Link to="/mon-compte/mes-informations" className="dropdown-item">
					<i className="fad fa-user" />
					<span>Mes Informations</span>
				</Link>
				{haveRole(user, "ROLE_MANAGER") && (
					<Link to="/mon-compte/mon-equipe" className="dropdown-item">
						<i className="fad fa-users" />
						<span>Mon équipe</span>
					</Link>
				)}
				{haveRole(user, "ROLE_MANAGER") && (
					<Link to="/mon-compte/marque-blanche" className="dropdown-item">
						<i className="fad fa-copyright" />
						<span>Marque blanche</span>
					</Link>
				)}
				<Link to="/mon-compte/mes-invitations" className="dropdown-item">
					<i className="fad fa-users" />
					<span>Mes invitations</span>
				</Link>
				<Link to="/mon-compte/mes-prescripteurs" className="dropdown-item">
					<i className="fad fa-users" />
					<span>Mes Prescripteurs</span>
				</Link>
				<Link to="/mon-compte/mes-mutuelles" className="dropdown-item">
					<i className="fad fa-user-md" />
					<span>Mes Mutuelles</span>
				</Link>
				<Link to="/mon-compte/mes-laboratoires" className="dropdown-item">
					<i className="fad fa-hospital" />
					<span>Mes Laboratoires</span>
				</Link>
				<Link to="/mon-compte/mes-parrainages" className="dropdown-item">
					<i className="fad fa-trophy" />
					<span>Mes Parrainages</span>
				</Link>
				<Link to="/mon-compte/catalogue" className="dropdown-item">
					<i className="fad fa-cog" />
					<span>Mes Catalogues</span>
				</Link>
				{haveRole(user, "ROLE_MANAGER") && (
					<Link to="/mon-compte/mon-entreprise" className="dropdown-item">
						<i className="fad fa-building" />
						<span>Mon Entreprise</span>
					</Link>
				)}
				<Link to="/documents/my" className="dropdown-item">
					<i className="fad fa-folder" />
					<span>Mes Documents</span>
				</Link>
				{!haveRole(user, "ROLE_ASSISTANT") && (
					<Link to="/stats" className="dropdown-item">
						<i className="fad fa-chart-line" />
						<span>Statistiques</span>
					</Link>
				)}
				<Link to="/mon-compte/edition/gabarits" className="dropdown-item">
					<i className="fad fa-file-pdf" />
					<span>Mes modèles</span>
				</Link>
				{haveRole(user, "ROLE_MANAGER") && (
					<>
						<Link to="/mon-compte/mes-personnalisations" className="dropdown-item">
							<i className="fad fa-file-pdf" />
							<span>Mes personnalisations</span>
						</Link>
						<Link to="/mon-compte/mon-abonnement" className="dropdown-item">
							<i className="fad fa-file-invoice" />
							<span>Mon Abonnement</span>
						</Link>

						<Link to="/mon-compte/rappel-sms" className="dropdown-item">
							<i className="fad fa-sms" />
							<span>Mes rappels SMS</span>
						</Link>
					</>
				)}
				<a
					href={urlTeletransmission(user.adeli, laboratory.finess, user.company.id)}
					target="_blank"
					rel="noopener noreferrer"
					className="dropdown-item">
					<i className="fad fa-cloud-upload-alt" />
					<span>Mes télétransmissions</span>
				</a>
				<Link to="/faq" className="dropdown-item">
					<i className="fad fa-question-square" />
					<span>F.A.Q.</span>
				</Link>

				<div className="dropdown-divider" />

				{haveRole(user, "ROLE_MANAGER") && (
					<Link to="/settings" className="dropdown-item">
						<i className="fad fa-cog" />
						<span>Paramètres de l'application</span>
					</Link>
				)}

				<Link
					to="#"
					onClick={async () => {
						clearServiceWorkerCache()
					}}
					className="dropdown-item text-warning">
					<i className="fad fa-database" />
					<span>Supprimer le cache</span>
				</Link>

				<Link to="/se-connecter" onClick={handleLogout} className="dropdown-item text-danger">
					<i className="fad fa-sign-out-alt" />
					<span>Déconnexion</span>
				</Link>
				<div className="dropdown-divider" />
				{window.BUILD_DATE && (
					<div style={{ fontSize: "12px" }}>
						Dernière mise à jour : {dayjs(window.BUILD_DATE).format("DD/MM/YY à HH:mm")}{" "}
					</div>
				)}
			</div>
		</div>
	)
}

DropDownMenu.propTypes = {}

export default DropDownMenu
