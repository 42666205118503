/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useContext, useMemo } from "react"
import { agendaGetSchedules } from "./Utils"
import dayjs from "dayjs"
import "./Agenda.scss"
import {
	ViewState,
	EditingState,
	GroupingState,
	IntegratedGrouping,
	IntegratedEditing,
} from "@devexpress/dx-react-scheduler"
import {
	Scheduler,
	WeekView,
	Appointments,
	AppointmentTooltip,
	DragDropProvider,
	EditRecurrenceMenu,
	MonthView,
	AllDayPanel,
	Resources,
	GroupingPanel,
} from "@devexpress/dx-react-scheduler-material-ui"
import { Link } from "react-router-dom"
import { SCHEDULES_API } from "config"
import axios from "axios"
import { formatDatetimeForDB, formatPhoneNumber } from "../../../services/functions"
import API from "../../../services/API"
//import { dispatchFromAgenda } from "../ScheduleDispatch"
import AuthContext from "../../../contexts/AuthContext"
import ScheduleStartModal from "../ScheduleStartModal"
import AsyncUserSelect from "../../../components/utils/AsyncUserSelectMultiple"
import AsyncLaboratorySelect from "../../../components/utils/AsyncLaboratorySelect"
import ModalScheduling from "../SchedulingModal/Modal.Scheduling"
import { groupViewHandler, GROUP_FLAG_USER, GROUP_FLAG_LABORATORY, GROUP_FLAG_DATE } from "./Scheduler.Grouping"

// Components Overides
import ComponentTableCell from "./Component.TableCell"
import ComponentAppointement from "./Component.Appointment"

// Filters and Settings
import GroupingSettings from "./Settings.Grouping"
import ViewSettings from "./Settings.View"
import DateSelector from "./Settings.Dates"
import FiltersSettings from "./Settings.Filters"
import bankHolliday from "../../../datas/bankHolliday2025.json"

// Notifications
import useNotifierSystem from "../../../services/RealtimeSystem/Hook"
import { useAgendaSettingLoader, useAgendaSettingSaver } from "./CustomSettings"
import getSchedulesType from "../getScheduleType"
import { toast } from "react-toastify"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"
const HeaderTooltip = ({ ...props }) => {
	return <></>
}

function Dada({
	setShow,
	onlyAvailable,
	fromNewSchedule,
	selectedDate,
	currentLaboratory,
	currentUser,
	defaultStartDate,
	fromAgenda,
	appointmentFromScheduleSelector,
	setSelectedLaboratory,
	setSelectedUser,
}) {
	const ctx = useContext(AuthContext)
	const [schedulesTypes, setSchedulesTypes] = useState({})

	// Filters and settings states

	const [agendaFilter, setAgendaFilter] = useState({
		birthday: true,
		missed: true,
		cancelled: true,
		done: true,
		ferie: true,
	})
	const [viewSettingsUpdate, setViewSettingsUpdate] = useState(false)
	const [viewSettings, setViewSettings] = useState({
		cellDuration: 30,
		groupsAvailable: [{ resourceName: "Chargement" }],
		groupsResources: [
			{
				fieldName: "Chargement",
				title: "Chargement",
				instances: [
					{
						text: "Chargement...",
						id: 1,
					},
				],
			},
		],
		viewMode: "Week",
		// needRefresh: false,
		primaryGroup: "laboratoryId",
		grouping: GROUP_FLAG_LABORATORY,
		options: 0x0,
	})
	const [currentDate, setCurrentDate] = useState(dayjs().format("YYYY-MM-DD"))
	const [disabledDrag, setDisabledDrag] = useState(new Set([]))
	const [displayedUsers, setDisplayedUsers] = useState([])
	const [displayedLaboratories, setDisplayedLaboratories] = useState([])
	// const [availableLaboratories, setAvailableLaboratories] = useState([])
	// const [availableUsers, setAvailableUsers] = useState([])

	// Loadings states
	const [canUpdateView, setCanUpdateView] = useState(false)
	const [needRefresh, setNeedRefresh] = useState(false)
	const [loading, setLoading] = useState(true)
	const [settingsLoaded, setSettingsLoaded] = useState(false)

	// Items states
	const [MonthAgenda, setMonthAgenda] = useState([])
	const [items, setItems] = useState([])
	const [displayedItems, setDisplayedItems] = useState([])

	// Modals states
	const [showModal, setShowModal] = useState(null)
	const [confirmModal, setConfirmModal] = useState({
		show: false,
		title: "",
		content: "",
		btnConfirm: "Oui",
		btnCancel: "Annuler",
		result: false,
		fctConfirm: () => {},
		fctCancel: () => {},
	})
	const [editModal, setEditModal] = useState({
		id: -1,
		show: false,
	})

	// Other states
	const [currentMonthStats, setCurrentMonthStats] = useState({})
	const [currentlyEditedAppointmentOrigin, setCurrentlyEditedAppointmentOrigin] = useState({})
	const [currentlyEditedAppointment, setCurrentlyEditedAppointment] = useState({})
	const [selectedNewAppointment, setSelectedNewAppointment] = useState({})
	const [currentAttendences, setCurrentAttendence] = useState([])

	const timeOut = useRef(null)

	const [hideLeftbar, setHideLeftbar] = useState(false)

	useNotifierSystem(
		(type, data) => {
			// temporairement, recharger l'agenda en entier
			// a faire : ajouter / modifier uniquement les bonnes cases
			// mais d'abord, je vais aider Olivier sur la télétrans
			if (["post", "put", "delete"].includes(data.method)) {
				setMonthAgenda([])
				setNeedRefresh(true)
			}
		},
		["schedule"]
	)

	useAgendaSettingSaver(
		ctx.user,
		viewSettings,
		agendaFilter,
		displayedUsers,
		displayedLaboratories,
		hideLeftbar,
		fromAgenda && !fromNewSchedule ? true : false
	)

	useAgendaSettingLoader(
		ctx.user,
		setViewSettings,
		setAgendaFilter,
		setDisplayedUsers,
		setDisplayedLaboratories,
		setSettingsLoaded,
		setHideLeftbar,
		fromAgenda && !fromNewSchedule ? true : false
	)

	useEffect(() => {
		if (!settingsLoaded) return
		const laboratories = displayedLaboratories.length ? displayedLaboratories.map((m) => m.id) : [ctx.laboratory.id]
		const users = displayedUsers.length ? displayedUsers.map((m) => m.id) : [ctx.user.id]

		;(async () => {
			const res = await API.findAll("LABORATORY_ATTENDANCES_API")
			setCurrentAttendence(
				res.filter(
					(f) =>
						users.map((m) => m).includes(f.user.id) && laboratories.map((m) => m).includes(f.laboratory.id)
				)
			)
		})()
	}, [displayedLaboratories, displayedUsers, settingsLoaded])

	const getAgenda = async () => {
		const date = new Date(currentDate)
		const sy = date.getFullYear(),
			sm = date.getMonth() + 1
		if (!date || !sm || !sy) return

		clearTimeout(timeOut.current)
		timeOut.current = setTimeout(async () => {
			try {
				setCanUpdateView(false)
				await getSchedules(
					sy,
					sm,
					displayedUsers.length ? displayedUsers : [ctx.user],
					displayedLaboratories.length ? displayedLaboratories : [ctx.laboratory]
				)
			} catch (error) {
			} finally {
				setCanUpdateView(true)
				setNeedRefresh(false)
			}
		}, 1000)
	}

	useEffect(() => {
		if (!settingsLoaded) return //debugger
		;(async function () {
			const types = await getSchedulesType()
			setSchedulesTypes(types)
		})()
		// if (!needRefresh) setNeedRefresh(true)
		if (window.location.href.includes("/agenda")) {
			document.title = "Agenda"
		}
	}, [settingsLoaded])

	useEffect(() => {
		if (!needRefresh || !settingsLoaded) return
		//debugger
		getAgenda()
	}, [needRefresh, displayedUsers, displayedLaboratories, settingsLoaded])

	useEffect(() => {
		if (!settingsLoaded) return

		if (fromNewSchedule && currentUser && currentLaboratory) {
			setDisplayedUsers([{ id: +currentUser }])
			setDisplayedLaboratories([{ id: +currentLaboratory }])
			if (!needRefresh) setNeedRefresh(true)
		}
	}, [fromNewSchedule, currentUser, currentLaboratory, settingsLoaded])

	useEffect(() => {
		if (!settingsLoaded) return
		let tmp = items
		if (!agendaFilter.missed) tmp = tmp.filter((f) => f.state !== "MISSING")
		if (!agendaFilter.cancelled) tmp = tmp.filter((f) => f.state !== "CANCELLED")
		if (!agendaFilter.done) tmp = tmp.filter((f) => f.state !== "DONE")
		if (!agendaFilter.ferie) tmp = tmp.filter((f) => f.type !== "FERIE")
		setDisplayedItems(tmp)
	}, [agendaFilter, items, settingsLoaded])

	// const activeFilters = (f) => {
	// 	if (!agendaFilter.missed) tmp = tmp.filter((f) => f.state !== "MISSING")
	// 	if (!agendaFilter.cancelled) tmp = tmp.filter((f) => f.state !== "CANCELLED")
	// 	if (!agendaFilter.done) tmp = tmp.filter((f) => f.state !== "DONE")
	// 	if (!agendaFilter.ferie) tmp = tmp.filter((f) => f.type !== "FERIE")
	// }

	useEffect(() => {
		try {
			if (!currentlyEditedAppointment?.id && currentlyEditedAppointmentOrigin?.id) {
				const appointment = items.findIndex((e) => e.id === currentlyEditedAppointmentOrigin.id)
				const tmp = [...items]
				//debugger

				if (appointment !== -1) {
					tmp[appointment] = currentlyEditedAppointmentOrigin
					setItems(tmp)
					setCurrentlyEditedAppointmentOrigin({})
				}
			} else {
				const appointment = items.findIndex((e) => (e?.id ? e.id === currentlyEditedAppointment.id : 0))
				if (appointment !== -1 && !currentlyEditedAppointment?.noSaveOrigin) {
					if (!currentlyEditedAppointment?.startDate) {
						setCurrentlyEditedAppointment({ ...items[appointment] })
					}
					setCurrentlyEditedAppointmentOrigin({ ...items[appointment] })
				}
				//debugger
			}
		} catch (e) {
			console.error(e)
		}
	}, [currentlyEditedAppointment])

	useEffect(() => {
		if (appointmentFromScheduleSelector && defaultStartDate) {
			setCurrentDate(dayjs(defaultStartDate).toDate())
		}
		//debugger
	}, [appointmentFromScheduleSelector])

	useEffect(() => {
		setNeedRefresh(true)
	}, [currentDate])

	useEffect(() => {
		;(async function () {
			if (!canUpdateView) return
			//setCanUpdateView(false)
			setLoading(true)
			const tmp = [],
				disabled = new Set([])

			let availableUsers = [],
				availableLaboratories = []
			for (const d of MonthAgenda || []) {
				let alreadyInAgenda = -1
				availableUsers.push(d.user)
				availableLaboratories.push(d.laboratory)
				if (!d?.patient) {
					d.title = schedulesTypes?.[d?.status]?.label || "Sans patient"
				}
				if (d.state === "DONE") disabled.add(d.id)
				if (d.state === "DONE" && d.type === "PREMIERE_VISITE") {
					alreadyInAgenda = tmp.findIndex(
						(e) =>
							e.startDate.toString() === d.startDate.toString() &&
							e.endDate.toString() === d.endDate.toString() &&
							e.state === "DONE" &&
							e.type === "VISITE"
					)
				}
				if (d.state === "DONE" && d.type === "VISITE" && alreadyInAgenda === -1) {
					alreadyInAgenda = tmp.findIndex(
						(e) =>
							e.startDate.toString() === d.startDate.toString() &&
							e.endDate.toString() === d.endDate.toString() &&
							e.state === "DONE" &&
							e.type === "PREMIERE_VISITE"
					)
				}
				if (alreadyInAgenda === -1 && d?.id) tmp.push(d)
			}

			const currentDateYear = dayjs(currentDate).year()
			const bankHollidays = Object.keys(bankHolliday)
				.filter((f) => {
					const bankDayYear = dayjs(f).year()
					return currentDateYear >= bankDayYear && currentDateYear <= bankDayYear
				})
				.reduce((obj, key) => {
					return {
						...obj,
						[key]: bankHolliday[key],
					}
				}, {})

			for (const bankDay in bankHollidays) {
				const id = tmp.length + new Date().getTime()
				disabled.add(id)
				tmp.push({
					id: id,
					type: "FERIE",
					title: bankHolliday[bankDay],
					startDate: new Date(bankDay),
					endDate: new Date(bankDay).setHours(23),
					allDay: true,
					state: "DONE",
					laboratoryId: displayedLaboratories.length
						? [...displayedLaboratories.map((v) => v.id)]
						: [ctx.laboratory.id],
					userId: displayedUsers.length ? [...displayedUsers.map((v) => v.id)] : [ctx.user.id],
				})
			}
			setItems(tmp)

			const currentMonth = tmp.filter((f) => f?.startDate?.getMonth() === new Date().getMonth())
			setCurrentMonthStats({
				cancelled: currentMonth.filter((f) => ["MISSING", "CANCELLED"].includes(f.state))?.length,
				done: currentMonth.filter((f) => f.state === "DONE")?.length,
				waiting: currentMonth.filter((f) => f.state === "WAITING")?.length,
			})
			setDisabledDrag(disabled)
			setLoading(false)
			setViewSettingsUpdate(true)
			try {
				const element = document.querySelector(".SCROLL-HERE")
				element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
			} catch (_error) {}
		})()
	}, [canUpdateView])

	useEffect(() => {
		if (!viewSettingsUpdate || !settingsLoaded) return
		let dUser = [],
			dLab = [],
			settings = {
				primaryGroup: "laboratoryId",
				grouping: GROUP_FLAG_LABORATORY,
			}

		dLab = displayedLaboratories.length ? [...displayedLaboratories] : [ctx.laboratory]
		dUser = displayedUsers.length ? [...displayedUsers] : [ctx.user]

		if (displayedUsers.length && !displayedLaboratories.length)
			settings = { grouping: GROUP_FLAG_USER, primaryGroup: "userId" }
		else if (displayedLaboratories.length && displayedUsers.length)
			settings.grouping = GROUP_FLAG_USER | GROUP_FLAG_LABORATORY

		if ((displayedLaboratories.length || displayedUsers.length) && !fromNewSchedule)
			settings.options = viewSettings.options | GROUP_FLAG_DATE

		groupViewHandler({ ...viewSettings, ...settings }, setViewSettings, dUser, dLab)
		setViewSettingsUpdate(false)
	}, [displayedLaboratories, displayedUsers, ctx.user, ctx.laboratory, viewSettingsUpdate, settingsLoaded])

	const getSchedules = async (year, month, userId, laboratoryId) => {
		try {
			let res = [...MonthAgenda]

			setLoading(true)
			await agendaGetSchedules(year, month, res, userId, laboratoryId)

			if (month === 1) await agendaGetSchedules(year - 1, 12, res, userId, laboratoryId)
			else if (month === 12) await agendaGetSchedules(year + 1, 1, res, userId, laboratoryId)
			else if (month > 1) await agendaGetSchedules(year, month - 1, res, userId, laboratoryId)

			if (month < 12) await agendaGetSchedules(year, month + 1, res, userId, laboratoryId)

			res = res.filter((thing, index, self) => {
				if (thing.id) return index === self.findIndex((t) => +t.id === +thing.id)
				else return true
			})
			setMonthAgenda(res)
			setLoading(false)
		} catch (e) {
			console.error(e)
		}
	}

	const CommitChanges = async ({ _added, changed, _deleted }) => {
		if (changed) {
			const tmp = items.map((e) => (changed[e.id] ? { ...e, ...changed[e.id] } : e))
			if (currentlyEditedAppointment?.id && changed[currentlyEditedAppointment?.id]) {
				setCurrentlyEditedAppointment((old) => ({
					...old,
					startDate: changed[currentlyEditedAppointment?.id].startDate,
					endDate: changed[currentlyEditedAppointment?.id].endDate,
					noSaveOrigin: true,
				}))
			}

			setItems(tmp)
			for (const e of Object.keys(changed)) {
				if (e !== "NEW_APPOINTMENT") {
					const data = {
						dateOf: formatDatetimeForDB(changed[e].startDate),
						dateEnd: formatDatetimeForDB(changed[e].endDate),
					}
					if (changed[e].laboratoryId) data.laboratory = `/laboratories/${changed[e]?.laboratoryId}`
					if (changed[e].userId) data.user = `/users/${changed[e]?.userId}`
					await axios.put(SCHEDULES_API + "/" + e, data)

					const indexMonthAgenda = MonthAgenda.findIndex((f) => +f.id === +e)

					if (indexMonthAgenda !== -1) {
						MonthAgenda[indexMonthAgenda].dateOf = data.dateOf
						MonthAgenda[indexMonthAgenda].dateEnd = data.dateEnd
						MonthAgenda[indexMonthAgenda].endDate = dayjs(data.dateEnd).toDate()
						MonthAgenda[indexMonthAgenda].startDate = dayjs(data.dateOf).toDate()
						setMonthAgenda(MonthAgenda)
					}
				}
			}
		}
		return items
	}

	const ActionButton = ({ action, label, icon, className = "", loadingOnClick, newTab, link }) => {
		const dismissTooltip = () => {
			try {
				document.querySelector("div.MuiPopover-root > div").click()
			} catch (e) {
				console.error(e)
			}
		}
		if (link)
			return (
				<>
					<Link to={link} target={newTab ? "child" : "_self"}>
						<div
							className={"appointment-action " + className}
							onClick={() => {
								dismissTooltip()
								typeof action === "function" && action()
							}}>
							<i className={"fad fa-2x " + icon} />
							<span>{label}</span>
						</div>
					</Link>
				</>
			)
		else
			return (
				<>
					<div
						className={"appointment-action " + className}
						onClick={() => {
							dismissTooltip()
							typeof action === "function" && action()
						}}>
						<i className={"fad fa-2x " + icon} />
						<span>{label}</span>
					</div>
				</>
			)
	}

	const ContentTooltip = ({ ...props }) => {
		if (props.appointmentData.type === "FERIE") return <></>
		const today = dayjs().format("dddd DD MMM YYYY")
		const data = props?.appointmentData

		const isSelected =
			currentlyEditedAppointment?.id === data?.id || appointmentFromScheduleSelector?.id === data?.id
		const appointmentNotDone =
			isSelected || ["WAITING", "ARRIVAL"].includes(data?.state) || (data?.state == null && data?.note === false)

		const dismissTooltip = () => {
			try {
				document.querySelector("div.MuiPopover-root > div").click()
			} catch (e) {
				console.error(e)
			}
		}

		const startDate = dayjs(data?.startDate)
		const endDate = dayjs(data?.endDate)
		const dayStart = startDate.format("dddd DD MMM YYYY")
		const dayEnd = endDate.format("dddd DD MMM YYYY")
		const timeStart = startDate.format("HH[h]mm")
		const timeEnd = endDate.format("HH[h]mm")
		const timeDuration = endDate.diff(startDate, "minute")
		const timeDurationH = Math.floor(timeDuration / 60)
		const timeDurationM = timeDuration % 60

		let timeDurationStr = ""
		if (timeDuration >= 60) timeDurationStr = `${timeDurationH}h${timeDurationM}m`
		else timeDurationStr = `${timeDurationM} minutes`

		let date = ""
		if (today === dayStart && dayStart === dayEnd) date = `${timeStart} - ${timeEnd} (${timeDurationStr})`
		else if (dayStart === dayEnd) {
			date = (
				<>
					{dayStart}, {timeStart} - {timeEnd}
					<br />({timeDurationStr})
				</>
			)
		}

		return (
			<>
				<div
					className="agenda-appointment-tooltip"
					style={{ borderColor: schedulesTypes?.[data?.status]?.color }}>
					<div className="agenda-appointment-tooltip-infos">
						{(data?.state === "MISSING" || data?.state === "CANCELLED") && (
							<div>Rendez vous annulé ou manqué</div>
						)}
						<div className="patient-name">{data?.title}</div>
						<div className="patient-supl-data">
							<span className="ml-1 mr-1 	">
								{data?.patient?.phone && formatPhoneNumber(data.patient.phone)}
							</span>
							<span className="ml-1 mr-1 	">
								{data?.patient?.phoneMobile && formatPhoneNumber(data.patient.phoneMobile)}
							</span>
						</div>
						<div className="appointment-type">{schedulesTypes?.[data?.status]?.label}</div>
						<div className="appointment-date">{date}</div>
						<div className="appointment-note">{data?.preScheduleNotes}</div>
					</div>

					<div className="agenda-appointment-tooltip-icons">
						{/* {appointmentNotDone && !isSelected && (
							<ActionButton
								label="Débuter le rendez-vous"
								icon="fa-calendar-check"
								action={() => {
									setLoading(true)
									dispatchFromAgenda(ctx, setConfirmModal, history, data)
								}}
								loadingOnClick={true}
							/>
						)} */}

						{data.state === "WAITING" && (
							<ActionButton
								label="Rendez-vous arrivé"
								action={async () => {
									try {
										await API.update("SCHEDULES_API", data.id, { state: "ARRIVAL" })
										setMonthAgenda([])
										setNeedRefresh(true)
									} catch (error) {
										console.error(error)
									}
								}}
								icon="fa-check"
							/>
						)}
						{data.id === "NEW_APPOINTMENT" && (
							<>
								<div
									className="appointment-action"
									onClick={() => {
										dismissTooltip()
										if (fromNewSchedule) {
											selectedDate([data?.startDate, data?.endDate])

											//setSelectedLaboratory(data?.laboratoryId || ctx?.laboratory?.id)
											//setSelectedUser(data?.userId || ctx?.user?.id)
											setShow()
										} else {
											setShowModal([data?.startDate, data?.endDate])
											setSelectedNewAppointment({
												user: data?.userId?.length ? data?.userId[0] : data?.userId,
												laboratory: data?.laboratoryId?.length
													? data?.laboratoryId[0]
													: data?.laboratoryId,
											})
										}
									}}>
									<i className="fad fa-2x fa-calendar-plus" />
									<span>Créer un nouveau rendez-vous</span>
								</div>
								<div
									className="appointment-action"
									onClick={() => {
										dismissTooltip()
										const newAppointment = items.findIndex((e) => e.id === "NEW_APPOINTMENT")
										const tmp = [...items]
										tmp.splice(newAppointment, 1)
										setItems(tmp)
										setSelectedNewAppointment({})
									}}>
									<i className="fad fa-2x fa-calendar-times" />
									<span>Annuler</span>
								</div>
							</>
						)}

						{data.id !== "NEW_APPOINTMENT" && data.patient && !isSelected && (
							<ActionButton
								label="Aller sur la fiche patient"
								link={`/fiche-patient/${data?.patient?.id}`}
								newTab={window.location.href.includes("/agenda")}
								action={() => {
									if (ctx.uiDisplay?.agendaModal)
										ctx.setUiDisplay((old) => ({ ...old, agendaModal: false }))
								}}
								icon="fa-user"
							/>
						)}

						{data.id !== "NEW_APPOINTMENT" && !isSelected && (
							<ActionButton
								label="Dupliquer le rendez-vous"
								newTab={window.location.href.includes("/agenda")}
								action={async () => {
									await API.create("SCHEDULES_API", {
										dateEnd: data.dateEnd,
										dateOf: data.dateOf,
										laboratory: `/laboratories/${data.laboratory.id}`,
										patient: data?.patient?.id && `/patients/${data.patient.id}`,
										preScheduleNote: data.preScheduleNote,
										state: data.state,
										status: data.status,
										type: data.type,
										user: `/users/${data.user.id}`,
									})
									setMonthAgenda([])
									setNeedRefresh(true)
									toast.success("Le rendez-vous a été dupliqué")
								}}
								icon="fa-copy"
							/>
						)}

						{!isSelected && data.id !== "NEW_APPOINTMENT" && (
							<div
								className="appointment-action"
								onClick={() => {
									try {
										document.querySelector("div.MuiPopover-root > div").click()
									} catch (e) {
										console.error(e)
									}
									if (fromAgenda) setEditModal({ show: true, id: data?.id })
								}}>
								<i className="fad fa-2x fa-pen" />
								<span>Éditer</span>
							</div>
						)}

						{appointmentNotDone && (
							<ActionButton
								label={isSelected ? "Enregistrer" : "Sélectionner et déplacer"}
								icon={isSelected ? "fa-save" : "fa-arrows-alt"}
								className={isSelected ? "font-weight-bold" : ""}
								action={async () => {
									if (isSelected) {
										if (appointmentFromScheduleSelector?.id === data?.id) {
											selectedDate([data?.startDate, data?.endDate])
											setShow()
										} else if (data?.id !== "NEW_APPOINTMENT") {
											try {
												const changes = {
													dateOf: formatDatetimeForDB(data?.startDate),
													dateEnd: formatDatetimeForDB(data?.endDate),
												}
												await API.update("SCHEDULES_API", data?.id, changes)
												setCurrentlyEditedAppointmentOrigin({})
												setCurrentlyEditedAppointment({})
												setMonthAgenda([])
												setNeedRefresh(true)
											} catch (e) {
												console.error(e)
											}
										}
									} else setCurrentlyEditedAppointment({ id: data.id })
								}}
							/>
						)}
						{isSelected && (
							<ActionButton
								label="Annuler"
								icon="fa-undo-alt"
								className="font-weight-bold"
								action={() => setCurrentlyEditedAppointment({})}
							/>
						)}
					</div>
				</div>
			</>
		)
	}

	// Custom components for DxScheduler
	const TableCell = ({ children, ...props }) => {
		// return <></>
		return (
			<ComponentTableCell
				currentlyEditedAppointment={currentlyEditedAppointment}
				appointmentFromScheduleSelector={appointmentFromScheduleSelector}
				items={items}
				setItems={setItems}
				setCurrentlyEditedAppointment={setCurrentlyEditedAppointment}
				children={children}
				props={props}
				schedulesTypes={schedulesTypes}
				currentAttendences={currentAttendences}
				bankHolliday={bankHolliday}
			/>
		)
	}

	const Appointment = ({ children, style, ...props }) => {
		return (
			<ComponentAppointement
				//c]olors={colors}
				schedulesTypes={{ ...schedulesTypes, ECHEC_APPAREILLAGE: { color: "#d4d6da" } }}
				currentlyEditedAppointment={currentlyEditedAppointment}
				appointmentFromScheduleSelector={appointmentFromScheduleSelector}
				children={children}
				style={style}
				props={props}
			/>
		)
	}

	return (
		<>
			{showModal && (
				<ModalScheduling
					showAll={!fromNewSchedule}
					fromNewSchedule={fromNewSchedule}
					selectedDate={showModal}
					setShowModal={() => setShowModal(null)}
					needRefresh={() => {
						setMonthAgenda([])
						setNeedRefresh(new Date() + Math.random())
					}}
					currentLaboratory={selectedNewAppointment?.laboratory || currentLaboratory}
					currentUser={selectedNewAppointment?.user || currentUser}
					fromAgenda={fromAgenda}
					setCurrentlyEditedAppointment={setCurrentlyEditedAppointment}
				/>
			)}

			{editModal.show && (
				<ModalScheduling
					fromNewSchedule={fromNewSchedule}
					editMode={editModal.id}
					setShowModal={() => {
						setEditModal((old) => ({ ...old, show: false }))
					}}
					needRefresh={() => {
						setMonthAgenda([])
						setNeedRefresh(new Date() + Math.random())
					}}
					// currentLaboratory={currentLaboratory}
					// currentUser={currentUser}
					fromAgenda={fromAgenda}
					setCurrentlyEditedAppointment={setCurrentlyEditedAppointment}
					currentlyEditedAppointment={currentlyEditedAppointment}
				/>
			)}

			{(canUpdateView === false || loading === true) && (
				<div className="loader-overlay">
					<div className="spinner-border" role="status">
						{" "}
					</div>
				</div>
			)}

			<ScheduleStartModal
				setUiDisplay={ctx.setUiDisplay}
				confirmModal={confirmModal}
				setConfirmModal={setConfirmModal}
			/>

			<div>
				<div className="row">
					<div className={hideLeftbar ? "d-none" : "col-3 agenda-leftbar d-none d-sm-block"}>
						<div className="agenda-configuration">
							<div className="row" />
							{!fromNewSchedule && (
								<GroupingSettings
									viewSettings={viewSettings}
									setViewSettings={setViewSettings}
									setViewSettingsUpdate={setViewSettingsUpdate}
								/>
							)}{" "}
							<ViewSettings viewSettings={viewSettings} setViewSettings={setViewSettings} />
							<DateSelector currentDate={currentDate} setCurrentDate={setCurrentDate} />
							<FiltersSettings agendaFilter={agendaFilter} setAgendaFilter={setAgendaFilter} />
							<div className="calendar-infos">
								<h5>Pour le mois de {dayjs().format("MMMM")}</h5>
								<div className="info-line">
									<b>{currentMonthStats.done}</b> rendez-vous effectué
								</div>
								<div className="info-line">
									<b>{currentMonthStats.waiting}</b> rendez-vous en attente
								</div>
								<div className="info-line">
									<b>{currentMonthStats.cancelled}</b> rendez-vous annulé
								</div>
							</div>
						</div>
					</div>

					<div className="col">
						{!fromNewSchedule && (
							<div className="row agenda-row-userlabo d-none d-sm-flex">
								<div className="col">
									<AsyncLaboratorySelect
										setData={(d) => {
											setMonthAgenda([])
											if (
												ctx.laboratory?.id &&
												+d?.[0] !== +ctx.laboratory.id &&
												+d?.[0]?.id !== +ctx.laboratory.id &&
												!displayedLaboratories.includes(+ctx.laboratory.id) &&
												!displayedLaboratories.find((f) => +f.id === +ctx.laboratory.id)
											) {
												d.push(ctx.laboratory)
											}
											setDisplayedLaboratories(d)
											setNeedRefresh(true)
										}}
										onClear={() => {
											setDisplayedLaboratories([])
											setNeedRefresh(true)
										}}
										data={displayedLaboratories}
										className="w-100"
									/>
								</div>
								<div className="col">
									<AsyncUserSelect
										setData={(data) => {
											setMonthAgenda([])
											setDisplayedUsers(data)
											setNeedRefresh(true)
										}}
										onClear={() => {
											setDisplayedUsers([])
											setNeedRefresh(true)
										}}
										data={displayedUsers}
										allowedLaboratories={
											displayedLaboratories.length ? displayedLaboratories.map((v) => v.id) : null
										}
										className="w-100"
									/>
								</div>
							</div>
						)}

						<div className="row">
							<div className="schedule-calendar">
								<Scheduler data={displayedItems} locale="FR-fr">
									<ViewState currentDate={currentDate} currentViewName={viewSettings.viewMode} />
									<EditingState onCommitChanges={CommitChanges} />
									<GroupingState
										grouping={viewSettings.groupsAvailable}
										groupByDate={() => {
											return viewSettings.options & GROUP_FLAG_DATE ? true : false
										}}
									/>

									<WeekView
										displayName={"Week"}
										startDayHour={6}
										endDayHour={22}
										timeTableCellComponent={TableCell}
										excludedDays={[0]}
										cellDuration={viewSettings.cellDuration}
									/>

									<MonthView displayName={"Month"} />
									{/* <DayView displayName={"Journée"} startDayHour={7} endDayHour={20} /> */}

									<Appointments appointmentComponent={Appointment} />
									<Resources
										data={viewSettings.groupsResources}
										mainResourceName={viewSettings.primaryGroup}
									/>
									<AllDayPanel />

									<IntegratedGrouping />
									<IntegratedEditing />
									<EditRecurrenceMenu />

									<AppointmentTooltip
										contentComponent={ContentTooltip}
										headerComponent={HeaderTooltip}
									/>
									{!fromNewSchedule && <GroupingPanel />}
									<DragDropProvider
										allowDrag={({ id }) => !disabledDrag.has(id)}
										allowResize={({ id }) => !disabledDrag.has(id)}
									/>
								</Scheduler>
							</div>
						</div>
						<div className="hidebar" onClick={() => setHideLeftbar((old) => !old)}>
							{hideLeftbar ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Dada
