import { NavLink } from "react-router-dom"

type NavLinkButtonInGroupProps = {
	to: string
	icon: string
	text: string
	onClick?: () => void
}
export default function NavLinkButtonInGroup({
	to,
	icon,
	text,
	onClick = () => undefined,
}: NavLinkButtonInGroupProps): JSX.Element {
	return (
		<NavLink
			to={to}
			onClick={onClick}
			data-cy={`${text}-nav`}
			className="btn text-sm w-100 nav-in-group"
			activeClassName="active"
			role="button"
			style={{ color: "#eff2f7" }}>
			<span className="d-flex justify-content-start align-items-center ">
				<span className="btn-inner--icon d-none d-lg-block fa-x" style={{ paddingRight: "5%", width: "25%" }}>
					<i className={"fad fa-" + icon} />
				</span>
				<span className="btn-inner--icon d-block ml-2 slim-bar-no-text">{text}</span>
			</span>
		</NavLink>
	)
}
