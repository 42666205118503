import React from "react"
import "./BootstrapTable.scss"

type ColumnRenderCallbackType = (d: any, row?: any) => void

export type ColumnDataType = {
	width?: string
	title: string
	dataIndex: string
	render?: ColumnRenderCallbackType
}

type CallbackMouseEvent = (event: React.MouseEvent, data: any) => void
type TableProps = {
	loading?: boolean
	data: any[]
	columns: ColumnDataType[]
	onRowClick?: CallbackMouseEvent
}

const BootstrapTable = ({ loading, data, columns, onRowClick }: TableProps): JSX.Element => {
	// const [tableData, setTableData] = useState<any[]>([])

	// useEffect(() => {
	// 	if (loading) {
	// 		setTableData([])
	// 	}
	// }, [data])

	const hasCustomRender = (dataValue: any, columnDataIndex: ColumnDataType): any => {
		const value = dataValue?.[columnDataIndex.dataIndex]

		if (typeof columnDataIndex.render === "function") {
			return columnDataIndex.render(value, dataValue)
		} else return value
	}

	const handleRowClick = (event: React.MouseEvent, dataValue: any): void => {
		if (typeof onRowClick !== "function") return
		onRowClick(event, dataValue)
	}

	return (
		<>
			{/* <BootstrapPaginator /> */}

			<table className="table table-striped table-hover table-bordered">
				<thead>
					<tr style={{ backgroundColor: "var(--secondary)", borderRadius: "5px" }}>
						{columns.map((v: any, k: number) => (
							<th style={{ width: v.width ?? "fit-content" }} key={k}>
								{v.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{loading && (
						<tr>
							<td>Chargement....</td>
						</tr>
					)}
					{data.map((dataValue: any, dataKey: number) => (
						<tr
							key={dataKey}
							onClick={(element) => handleRowClick(element, dataValue)}
							className={onRowClick ? "cursor-pointer" : ""}>
							{columns.map((columnDataIndex: any, columnKey: number) => (
								<td key={`${dataKey}-${columnDataIndex.dataIndex}-${columnKey}`}>
									{hasCustomRender(dataValue, columnDataIndex)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			{/* <BootstrapPaginator /> */}
		</>
	)
}
export default BootstrapTable
