import { Patient, PatientInsurance } from "@audiowizard/common"
import { EquipmentClass } from "constants/patient/equipment"
import usePatientClass from "hooks/specific/usePatientClass"
import { findActiveAm } from "pages/FSV/utilsFSV"
import { useMemo } from "react"

interface PatientClassBadgeProps {
	patient: Patient & {
		patientInsurances: PatientInsurance[]
	}
}

function PatientClassBadge({ patient }: PatientClassBadgeProps): JSX.Element {
	const [patientLastEquipmentClass, patientHasCmu] = usePatientClass(patient)

	const inactiveCmu = useMemo(() => {
		if (patientHasCmu) {
			return findActiveAm(patient.patientInsurances) ? false : true
		}
	}, [patientHasCmu])

	return (
		<>
			{patientLastEquipmentClass === EquipmentClass.FirstClass && (
				<span className="ml-3 badge badge-xs badge-pill badge-soft-info">Classe 1</span>
			)}

			{patientLastEquipmentClass === EquipmentClass.SecondClass && (
				<span className="ml-3 badge badge-xs badge-pill badge-soft-primary">Classe 2</span>
			)}

			{patientHasCmu && (
				<span className="ml-3 badge badge-xs badge-pill badge-soft-warning">{`C2S ${
					inactiveCmu ? " - expirée" : ""
				}`}</span>
			)}
		</>
	)
}

export default PatientClassBadge
