import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"
import { fallbackLng, LocaleNamespace } from "./helpers"

i18n.use(Backend)
	.use(initReactI18next)

	.init({
		backend: {
			loadPath: "/static/locales/{{lng}}/{{ns}}.json",
		},
		debug: false,
		defaultNS: LocaleNamespace.Common,
		fallbackLng: fallbackLng,
		fallbackNS: [LocaleNamespace.Context, LocaleNamespace.Common], // Warning: The fallback NS Context is more used than common (cf. editors)!
		interpolation: {
			escapeValue: false,
		}, // @ts-ignore
		lng: navigator.language || navigator.userLanguage,
		load: "languageOnly",
		ns: Object.values(LocaleNamespace),
		react: {
			wait: true,
		},
	})

export default i18n // imported in root (src/index.js|ts)
