/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import API from "services/API"
import { debounce as _debounce } from "lodash"
import { Editor } from "@tinymce/tinymce-react"
import "../style.scss"
import { ProductReturnForm } from "@audiowizard/common"

type InformationProps = {
	formData: ProductReturnForm
	setFormData: React.Dispatch<React.SetStateAction<ProductReturnForm>>
	busy: boolean
}

const Details = ({ busy, formData, setFormData }: InformationProps): JSX.Element => {
	const updateApi = async (key: string, value: string | null): Promise<void> => {
		try {
			let result: any = {}
			if (formData.id) {
				result = await API.update<ProductReturnForm>("PRODUCT_RETURN_FORM_API", formData.id, { [key]: value })
			} else {
				result = await API.create<ProductReturnForm>("PRODUCT_RETURN_FORM_API", { [key]: value })
				window.history.pushState("", "", "/mon-stock/retour/" + result.data.id)
			}
			setFormData((old: ProductReturnForm) => ({ ...old, ...result.data }))
		} catch (error) {
			console.error(error)
		}
	}

	const updateApiDebounce = _debounce(updateApi, 200)

	return (
		<>
			<div className="row">
				<input
					disabled={busy}
					type="text"
					onChange={(evt) => {
						const label = evt.target.value
						setFormData((old: ProductReturnForm) => ({ ...old, label }))
					}}
					onBlur={() => {
						updateApiDebounce("label", formData.label!)
					}}
					defaultValue={formData.label || ""}
					className="form-control form-control-sm"
					placeholder="Label"
				/>
			</div>
			<div className="row mt-2">
				<div className="w-100">
					<Editor
						apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
						init={{
							placeholder: "Note",
							height: 200,
							menubar: false,
							plugins: [
								"advlist autolink lists link image charmap print preview anchor",
								"insertdatetime media table paste textcolor",
							],
							toolbar:
								"undo redo | forecolor | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ",
						}}
						value={formData.notes || ""}
						onEditorChange={(notes) => {
							setFormData((old: ProductReturnForm) => ({ ...old, notes }))
						}}
						onBlur={() => {
							updateApiDebounce("notes", formData.notes!)
						}}
					/>
				</div>
			</div>
		</>
	)
}
export default Details
