/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, ReactElement } from "react"
import { Route, RouteProps, NavLink, useLocation } from "react-router-dom"
import "./style.scss"

export type CardItemProps = {
	name: string
	url: string
	component: RouteProps["component"]
	icon?: string
	exact?: boolean
	onlyVisibleOnExactRoute?: boolean
	disabled?: boolean
}

export type CardWithTabsProps = {
	urlprefix: string
	children: ReactElement<CardItemProps> | ReactElement<CardItemProps>[]
}

export const CardItem = ({ name, url, component, icon, exact, disabled }: CardItemProps): JSX.Element | null => {
	return null
}

export const CardWithTabs = ({ urlprefix, children }: CardWithTabsProps): JSX.Element | null => {
	const location = useLocation()

	const isVisible = (data: CardItemProps): boolean => {
		if (data.onlyVisibleOnExactRoute && urlprefix + data.url === location.pathname) return true
		else if (!data.onlyVisibleOnExactRoute) return true
		else return false
	}

	const tabs: CardItemProps[] = useMemo(() => {
		return React.Children.map(children, ({ props }) => {
			return {
				name: props.name,
				url: props.url,
				component: props.component,
				icon: props.icon,
				exact: props.exact ?? true,
				onlyVisibleOnExactRoute: props.onlyVisibleOnExactRoute ?? false,
				disabled: props.disabled,
			}
		})
	}, [location.pathname])

	return (
		<>
			<div className="card card-fluid">
				<div className="card-header d-flex justify-content-between align-items-center p-0 CardWithTabs">
					<div className="card-header-tab-container">
						{tabs.map((v, key) => (
							<NavLink
								strict
								key={key}
								to={urlprefix + v.url}
								title={v.name}
								activeClassName="card-header-tab-item-active"
								className={
									"card-header-tab-item " +
									(isVisible(v) ? "d-inline-block " : "d-none ") +
									(v.disabled ? " disabled " : "")
								}>
								{v.icon && <i className={"mx-auto fad " + v.icon} />}
								<span className="d-none d-xl-inline tab-title ml-2">{v.name}</span>
							</NavLink>
						))}
					</div>
				</div>
				<div className="card-body card-tab-body">
					{tabs.map((v, key) => (
						<Route exact={v.exact} path={urlprefix + v.url} key={key} component={v.component} />
					))}
				</div>
			</div>
		</>
	)
}
