import { ConfigProvider } from "antd"
import { defaultAntdThemeVars } from "app"
import { hexColorDarken, hexColorLighten } from "utils/math/hexToHsl"

interface PurposeTheme {
	primaryColor: string
	primaryHalfTrans: string
	primaryGradient: string
	primaryDark: string
	primaryLight: string
	secondaryColor: string
	secondaryLight: string
	secondaryDark: string
	loadingLogo: string
	loadingLogoAnimated: string
}

interface AntdTheme {
	primaryColor: string
	secondaryColor: string
}

export const setExtraPurpose = (purposeTheme: PurposeTheme): void => {
	const {
		primaryColor,
		primaryDark,
		primaryLight,
		secondaryColor,
		secondaryDark,
		secondaryLight,
		primaryHalfTrans,
		primaryGradient,
		loadingLogo,
		loadingLogoAnimated,
	} = purposeTheme

	document.documentElement.style.setProperty("--primary", primaryColor)
	document.documentElement.style.setProperty("--primary-dark", primaryDark)
	document.documentElement.style.setProperty("--primary-light", primaryLight)

	document.documentElement.style.setProperty("--info", secondaryColor)
	document.documentElement.style.setProperty("--info-dark", secondaryDark)
	document.documentElement.style.setProperty("--info-light", secondaryLight)

	document.documentElement.style.setProperty("--primary-gradient", primaryGradient)
	document.documentElement.style.setProperty("--primary-half-transparent", primaryHalfTrans)

	document.documentElement.style.setProperty("--loading-logo", loadingLogo)
	document.documentElement.style.setProperty("--loading-logo-animated", loadingLogoAnimated)
}

const applyAntdTheme = (andtTheme: AntdTheme, loadingLogo: string): void => {
	const { primaryColor, secondaryColor } = andtTheme

	ConfigProvider.config({
		theme: {
			...defaultAntdThemeVars,
			primaryColor: primaryColor,
			infoColor: secondaryColor,
		},
	})

	setExtraPurpose({
		primaryColor: primaryColor,
		primaryDark: hexColorDarken(primaryColor, 10),
		primaryLight: hexColorLighten(primaryColor, 10),
		secondaryColor: secondaryColor,
		secondaryDark: hexColorDarken(secondaryColor, 10),
		secondaryLight: hexColorLighten(secondaryColor, 10),
		primaryHalfTrans: `${primaryColor}80`,
		primaryGradient: primaryColor,
		loadingLogo: `url("${loadingLogo}")`,
		loadingLogoAnimated: `url("${loadingLogo}")`,
	})
}

export default applyAntdTheme
