function safeJsonParse(str) {
	try {
		return [null, JSON.parse(str)]
	} catch (err) {
		return [err]
	}
}

class localStorage {
	static get(key) {
		try {
			let data = window.localStorage.getItem(key)
			if (!data) return {}
			data = atob(data)
			const [err, result] = safeJsonParse(data)
			if (err) return data
			return result ? result : {}
		} catch (error) {
			console.error("localStorage.get(", key, ") ", error)
		}
	}

	static set(key, value) {
		try {
			if (typeof value === "object") value = JSON.stringify(value)
			window.localStorage.setItem(key, btoa(value))
		} catch (error) {
			console.error("localStorage.set(", key, ", ", value, ") ", error)
		}
	}

	static update(key, value) {
		const oldData = localStorage.get(key)
		if (typeof oldData !== "object") return null
		if (!oldData) {
			localStorage.set(key, value)
			return null
		}
		const updated = Object.assign(oldData, value)
		localStorage.set(key, updated)
	}

	static del(key) {
		window.localStorage.removeItem(key)
	}

	static clear() {
		window.localStorage.clear()
	}
}

export default localStorage
