import dayjs from "dayjs"
import API from "services/API"
import { IsObjectEmpty, formatDateForDB } from "services/functions"
import { dateFilter } from "pages/FSV/utilsFSV"

/**
 * Array of objects. Each object represents a state insurance and has an amcId, a label, a text alert for display information and
 * a method for calculating class 1 and class 2 reimbursements.
 *
 * Each object method takes:
 * @param {any} amo Social security's reimbursement amount
 * @param {any} product Product data coming from both getProductLpp() and productJsonHiboutik
 */
export const amcLabels = [
	{
		id: "88888888",
		label: "C2S",
		alert: "Le patient bénéficie de la cmu. Les prix et codes lpp classe 1 sont automatiquement adaptés.",
		getAmcAmounts: (amo, product, isDomTom = null) => {
			const { age, product_price } = product
			let basePriceUnder20 = 1400
			if (isDomTom) {
				basePriceUnder20 = 1680
			}
			const minor = product_price > basePriceUnder20 ? basePriceUnder20 - +amo : product_price - +amo
			if (age === ">20") {
				return { classOne: 800 - +amo, classTwo: 800 - +amo }
			} else if (age === "<=20") return { classOne: minor, classTwo: minor }
		},
	},
	{
		id: "99999997",
		label: "C2S",
		alert: "Le patient bénéficie de la cmu. Les prix et codes lpp classe 1 sont automatiquement adaptés.",
		getAmcAmounts: (amo, product, isDomTom = null) => {
			const { age, product_price } = product
			let basePriceUnder20 = 1400
			if (isDomTom) {
				basePriceUnder20 = 1680
			}
			const minor = product_price > basePriceUnder20 ? basePriceUnder20 - +amo : product_price - +amo
			if (age === ">20") {
				return { classOne: 800 - +amo, classTwo: 800 - +amo }
			} else if (age === "<=20") return { classOne: minor, classTwo: minor }
		},
	},
	{
		id: "92006089",
		label: "Camieg/Mutieg",
		alert: "Le patient bénéficie de la Camieg. Les montants de remboursement sont automatiquement adaptés.",
		getAmcAmounts: (amo, product) => {
			const { age, classe } = product
			if (age === ">20") {
				return +classe === 1
					? { classOne: 950 - +amo, classTwo: 1700 }
					: { classOne: 950, classTwo: 1700 - +amo }
			} else if (age === "<=20")
				return +classe === 1
					? { classOne: 1160 - +amo, classTwo: 1700 }
					: { classOne: 1160, classTwo: 1700 - +amo }
		},
	},
	{
		id: "27000000",
		label: "MGEN",
		alert: "Le patient bénéficie de la MGEN.",
		getAmcAmounts: () => false,
	},
	{
		id: "31744217",
		label: "SV GU 31744217",
		getAmcAmounts: () => false,
	},
	{
		id: "317442176",
		label: "SV GU 317442176",
		getAmcAmounts: () => false,
	},
	{
		id: "35235886",
		label: "SV GU 35235886",
		getAmcAmounts: () => false,
	},
	{
		id: "352358865",
		label: "SV GU 352358865",
		getAmcAmounts: () => false,
	},
	{
		id: "40128530",
		label: "SV GU 40128530",
		getAmcAmounts: () => false,
	},
	{
		id: "401285309",
		label: "SV GU 401285309",
		getAmcAmounts: () => false,
	},
	{
		id: "40359626",
		label: "SV GU 40359626",
		getAmcAmounts: () => false,
	},
	{
		id: "403596265",
		label: "SV GU 403596265",
		getAmcAmounts: () => false,
	},
	{
		id: "408975415",
		label: "SV GU 408975415",
		getAmcAmounts: () => false,
	},
	{
		id: "444518492",
		label: "SV GU 444518492",
		getAmcAmounts: () => false,
	},
	{
		id: "449571256",
		label: "SV GU 449571256",
		getAmcAmounts: () => false,
	},
	{
		id: "538518473",
		label: "SV GU 538518473",
		getAmcAmounts: () => false,
	},
	{
		id: "77567196",
		label: "SV GU 77567196",
		getAmcAmounts: () => false,
	},
	{
		id: "775671969",
		label: "SV GU 775671969",
		getAmcAmounts: () => false,
	},
	{
		id: "784411134",
		label: "SV GU 784411134",
		getAmcAmounts: () => false,
	},
]

export const caisseSplit = (amo) => {
	amo["regime"] = amo?.structure?.slice(0, 2)
	amo["fundManager"] = amo?.structure?.slice(2, 5)
	amo["systemManager"] = amo?.structure?.slice(5, 9)
}

// POUR L'AMO CETTE FONCTION EST APPELEE DANS amoCheck(), POUR l'AMC DANS handleCouvertureUpdate() / handleCouvertureCreate()
export const checkDates = (am, error, allAms, activeAm) => {
	if (am.hasOwnProperty("situationCode") && !allAms) return
	if (am.hasOwnProperty("insuranceId") && !allAms) return

	const compareDates = (start, end) => dayjs(start).isAfter(end)

	const compareWithExistingAm = () => {
		if (am.mode === "AMO") {
			if (!activeAm) return false
			for (const amo of allAms) {
				if (
					am.id !== activeAm?.id &&
					(dayjs(am.startDate).isBetween(amo.startDate, amo.endDate, null, "[]") ||
						dayjs(am.endDate).isBetween(amo.startDate, amo.endDate, null, "[]"))
				)
					return true
			}
		} else if (am.mode === "AMC") {
			if (!activeAm) return false
			for (const amc of allAms) {
				if (
					am.id !== activeAm?.id &&
					(dayjs(am.startDate).isBetween(amc.startDate, amc.endDate, null, "[]") ||
						dayjs(am.endDate).isBetween(amc.startDate, amc.endDate, null, "[]"))
				)
					return true
			}
		}
		return false
	}

	if (compareWithExistingAm()) {
		error.startDate = "Une couverture existe déjà sur cette période"
		error.endDate = "Une couverture existe déjà sur cette période"
	} else {
		if (compareDates(am.startDate, am.endDate))
			error.startDate = "La date de début de validité ne peut pas être ultérieure à la date de fin"

		if (compareDates(dayjs().format("YYYY-MM-DD"), am.endDate))
			error.endDate = "La date de fin de validité ne peut pas être antérieure à la date du jour"

		if (!am?.startDate) error.startDate = "Une date de début est nécessaire"
		if (!am?.endDate) error.endDate = "Une date de fin est nécessaire"
	}
}

export const amoCheck = (amo, allAmos, activeAmo) => {
	let error = {}
	if (amo.situationCode === "")
		error.situationCode = "Veuillez renseigner un code exonération, si le patient n'en a pas, sélectionnez 0100"
	if (!amo.structure) error.structure = "Veuillez renseigner une caisse gestionnaire pour la couverture"

	checkDates(amo, error, allAmos, activeAmo)
	return Object.keys(error).length !== 0 && error
}

export const amcCheck = (amc, allAmcs, activeAmc) => {
	let error = {}
	if (amc?.amcId?.length > 10) error.amcId = "L'immatriculation doit être composée de 10 caractères maximum"
	checkDates(amc, error, allAmcs, activeAmc)
	return error
}

export const createPecTodo = async (data, patient) => {
	// on regarde si la creation est necessaire
	if (!data.isTP) return
	// si oui => on contrôle si un todo n'existe pas déjà
	let search = `status=TODO&patient=${patient.id}&type=DEMANDE_PEC`
	const result = await API.findCustom("TODOS_API", search)
	if (
		(data.classOneCover == null || data.classOneCover === "" || data.classOneCover === "0") &&
		(data.classTwoCover == null || data.classTwoCover === "" || data.classTwoCover === "0")
	) {
		// si pas de todo existant on le créé :
		if (result.length === 0) {
			await API.create("TODOS_API", {
				label: `Faire une demande PEC ${data?.name ? `auprès de ${data?.name}` : ""}`,
				status: "TODO",
				target: "ALL",
				laboratories: [patient.laboratory["@id"]],
				patient: patient["@id"],
				type: "DEMANDE_PEC",
				realisationDate: formatDateForDB(),
			})
		}
	} else {
		if (result.length !== 0) {
			API.update("TODOS_API", result[0].id, {
				status: "DONE",
				label: "Demande PEC réalisée",
			})
		}
	}
}

const getDatesForAm = (fromAdri = {}) => {
	let start,
		end = ""

	if (typeof fromAdri?.startDate === "string") start = fromAdri?.startDate
	else start = !IsObjectEmpty(fromAdri?.dateDeb) ? dayjs(fromAdri?.dateDeb).format("YYYY-MM-DD") : null

	if (typeof fromAdri?.endDate === "string") end = fromAdri?.endDate
	else end = !IsObjectEmpty(fromAdri?.dateFin) ? dayjs(fromAdri?.dateFin).format("YYYY-MM-DD") : null

	return { start, end }
}

const getCvDatesForAm = (fromCV) => {
	let start,
		end = ""

	if (!fromCV?.startDate) start = fromCV?.startDate
	else start = dayjs(fromCV?.dateDeb).format("YYYY-MM-DD")

	if (!fromCV?.endDate) end = fromCV?.endDate
	else end = dayjs(fromCV?.dateFin).format("YYYY-MM-DD")

	return { start, end }
}

const formatDateForTeletrans = (date) => {
	if (!date) return null
	if (!dayjs.isDayjs(date)) return dayjs(date).format("YYYY-MM-DD")
	return formatDateForDB(date)
}

/**
 * Function to create new amc covers in two steps:
 * - It checks if the cover already exists in UserInsurance.
 * If so we'll use it's id for the new PatientInsurance entry. If not we'll create the UserInsurance and use it's id.
 * - It deletes existing PatientInsurances for the patient and creates new amcs from data.
 *
 * @param {any} adriData New amc data, can be array with multiple amcs or a single amc in an object
 * @param {any} patient Object with patient data
 */
export const replaceAmcWithAdriAmc = async (adriData, patient) => {
	const allAmcs = (await API.findAll("PATIENT_INSURANCE_API", "?patient=" + patient.id)) || []

	const getLabel = (adriData, amcId) =>
		amcLabels.find((amcLabel) => amcLabel?.id === amcId)?.label ||
		adriData?.label ||
		adriData?.name ||
		`GU ${amcId}`

	const getUserAmcId = async (amc) => {
		const amcId = amc?.amcId || adriData?.GU?.mutnum
		const label = getLabel(amc, amcId)
		const userInsurance = await API.findAll(
			"USER_INSURANCES_API",
			`?amcId=${amcId}&label=${label}&pagination=false`
		)
		// we filter again because label search on backend is only partial.
		const preciseUserInsurance = userInsurance.find((ins) => ins.label === label)?.["@id"]

		if (preciseUserInsurance) {
			amc["userInsurance"] = preciseUserInsurance
		} else {
			const newUserAmc = await API.create("USER_INSURANCES_API", amc)
			amc["userInsurance"] = newUserAmc?.data?.["@id"]
		}
		return amc
	}

	// check dans allAmcs la couverture qui est valide et qui a un coverNumber valide.
	// Stocker le coverNumber et l'enregistrer pour la nouvelle couverture valide.
	const activeAmc = allAmcs.find((m) => dateFilter(m.startDate, m.endDate)) || null
	let { coverDate, coverDateInquiry } = activeAmc || {}

	allAmcs.map(async (amc) => await API.delete(amc["@id"]))

	// some amcs from card or Adri may not have isTp value. Therefore the conditional operator is needed in str?.toLowerCase()
	const stringToBool = (str) => (str?.toLowerCase() === "true" ? true : false)

	if (Array.isArray(adriData)) {
		for (let i = 0; i < adriData.length; i++) {
			try {
				const dates = getDatesForAm(adriData[i])
				let amcId =
					adriData[i]?.GU?.mutnum ||
					adriData[i]?.GS?.rnm ||
					adriData[i]?.insuranceId ||
					adriData[i]?.rnm ||
					adriData[i]?.amcId
				let label = getLabel(adriData, amcId)
				// only amc in card is cmu and it has no isCmu key. So we return true. If data comes from adri but it's not a cmu, isCmu is false.
				let cmuValue = adriData[i].hasOwnProperty("isCmu") ? stringToBool(adriData[i]?.isCmu) : true

				const amcFromAdri = {
					amcId,
					label,
					// "isTp" here is fine
					isTP: stringToBool(adriData[i]?.isTp) ?? true,
					isC2S: cmuValue,
					isMutnum: cmuValue,
					isRNM: !cmuValue,
					startDate: formatDateForTeletrans(dates.start),
					endDate: formatDateForTeletrans(dates.end),
					coverDate: formatDateForTeletrans(coverDate),
					coverDateInquiry: formatDateForTeletrans(coverDateInquiry),
					classOneCover: adriData[i]?.classOneCover || null,
					classTwoCover: adriData[i]?.classTwoCover || null,
					patient: `/patients/${patient.id}`,
				}

				const amcWithUserId = await getUserAmcId(amcFromAdri)
				const patientInsurance = await API.create("PATIENT_INSURANCE_API", amcWithUserId)

				return patientInsurance
			} catch (error) {
				console.error(error)
			}
		}
	} else {
		try {
			const dates = getDatesForAm(adriData)
			let amcId =
				adriData?.GU?.mutnum || adriData?.GS?.rnm || adriData?.insuranceId || adriData?.rnm || adriData?.amcId
			let label = getLabel(adriData, amcId)
			let cmuValue = adriData.hasOwnProperty("isCmu") ? stringToBool(adriData?.isCmu) : true

			if (!coverDate) coverDate = adriData?.coverDate

			const amcFromAdri = {
				amcId,
				label,
				// "isTP" here is fine
				isTP: adriData?.isTP ?? true,
				isC2S: adriData?.isC2S ?? cmuValue,
				isMutnum: adriData?.isMutnum ?? cmuValue,
				isRNM: adriData?.isRNM ?? !cmuValue,
				startDate: formatDateForTeletrans(dates.start),
				endDate: formatDateForTeletrans(dates.end),
				coverDate: formatDateForTeletrans(coverDate),
				coverNumber: adriData?.coverNumber,
				coverDateInquiry: formatDateForTeletrans(coverDateInquiry),
				classOneCover: adriData?.classOneCover || null,
				classTwoCover: adriData?.classTwoCover || null,
				patient: patient["@id"],
			}

			const amcWithUserId = await getUserAmcId(amcFromAdri)
			const patientInsurance = await API.create("PATIENT_INSURANCE_API", amcWithUserId)

			return patientInsurance
		} catch (error) {
			console.error(error)
		}
	}
}

/**
 * Function to create new amo covers. First it deletes existing amo covers for the patient
 *
 * @param {any} adriData New amo data, can be array with multiple amos or a single amo in an object
 * @param {any} patient Object with patient data
 */
export const replaceAmoWithAdriAmo = async (adriData, patient) => {
	const allAmos = await API.findAll("PATIENT_SECU_API", "?patient=" + patient.id)
	allAmos.map(async (amo) => await API.delete(amo["@id"]))

	const codeExceptions = ["08", "91", "92", "93", "94", "95", "99"]

	const getSituationCode = (data) => {
		const situation = data?.codeSituation || data?.situationCode || data?.couverturesAMO?.[0]?.situationCode
		if (situation.length === 4) return situation
		else if (codeExceptions.includes(data?.tiers?.grandRegime)) {
			return `0${situation}`
		} else {
			return `${data?.tiers?.grandRegime[0]}${situation}`
		}
	}

	if (Array.isArray(adriData)) {
		for (let i = 0; i < adriData.length; i++) {
			try {
				const dates = getDatesForAm(adriData[i])

				const amoFromAdri = {
					regime: adriData[i]?.tiers.grandRegime,
					fundManager: adriData[i]?.tiers.codeCaisse,
					systemManager: adriData[i]?.tiers.codeCentre,
					situationCode: getSituationCode(adriData[i]),
					ald: +adriData[i]?.codeAld,
					particularSituation: "AS",
					startDate: formatDateForTeletrans(dates.start),
					endDate: formatDateForTeletrans(dates.end),
					patient: patient["@id"],
				}
				await API.create("PATIENT_SECU_API", amoFromAdri)
			} catch (error) {
				console.error(error)
			}
		}
	} else {
		try {
			let amoFromAdri = {}

			if (adriData?.couverturesAMO?.length) {
				for (const amo of adriData?.couverturesAMO) {
					const dates = getCvDatesForAm(amo)

					amoFromAdri = {
						regime: adriData.AmoGrandRegime,
						fundManager: adriData.AmoCaisseGestionnaire,
						systemManager: adriData.AmoCentreGestionnaire,
						situationCode: getSituationCode(amo),
						ald: +amo?.ALD ?? 0,
						particularSituation: "AS",
						startDate: formatDateForTeletrans(dates.start),
						endDate: formatDateForTeletrans(dates.end),
						patient: patient["@id"],
					}
					await API.create("PATIENT_SECU_API", amoFromAdri)
				}
			} else {
				const dates = getCvDatesForAm(adriData)

				amoFromAdri = {
					regime: adriData.tiers.grandRegime,
					fundManager: adriData.tiers.codeCaisse,
					systemManager: adriData.tiers.codeCentre,
					situationCode: getSituationCode(adriData),
					ald: +adriData.codeAld,
					particularSituation: "AS",
					startDate: formatDateForTeletrans(dates.start),
					endDate: formatDateForTeletrans(dates.end),
					patient: patient["@id"],
				}
				await API.create("PATIENT_SECU_API", amoFromAdri)
			}
		} catch (error) {
			console.error(error)
		}
	}
}
