import { DateInput } from "components/forms/DateInput"
import { useContext, useState } from "react"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import AuthContext from "../../../contexts/AuthContext"
import itemsRaisonDeRelance from "../../../datas/FinEvenement/itemsRaisonDeRelance.json"
import API from "../../../services/API"
import { formatDatetimeForDB } from "../../../services/functions"

const btnDefault = "btn btn-outline-info "

function Relance({ history, newSchedule, setNewSchedule }) {
	const { patient } = useContext(AuthContext)
	const [isLoading, setLoading] = useState(false)

	function hideModal() {
		try {
			document.querySelector("#relance-modal .container").classList.add("modal-content-hide")
			document.querySelector("#relance-modal").classList.add("modal-hide")
			setTimeout(() => {
				setNewSchedule({})
				history.push("/mes-patients/relance")
			}, 400)
		} catch (e) {}
	}

	async function createReminder() {
		setLoading(true)
		const data = {
			hours: 0,
			dateOf: newSchedule.dateOf,
			status: "RELANCE",
			step: 0,
			patient: patient["@id"],
			reason: newSchedule.reason,
			type: null,
			state: "WAITING",
		}
		const todoData = {
			label: "Effectuer la relance",
			target: "ALL",
			laboratories: [patient.laboratory["@id"]],
			patient: patient["@id"],
			realisationDate: newSchedule.dateOf,
			type: "RELANCE",
			status: "TODO",
		}

		const results = {
			schedule: null,
			todo: null,
			patient: null,
			originalStatus: patient.lastScheduleStatus,
		}

		try {
			results.schedule = await API.create("SCHEDULES_API", data)
			results.todo = await API.create("TODOS_API", todoData)
			results.patient = await API.update("PATIENTS_API", patient.id, {
				lastScheduleStatus: "RELANCE",
			})
			setLoading(false)
			hideModal()
		} catch (e) {
			toast.error("Impossible d'enregistrer les modifications")
			try {
				if (results?.schedule?.data?.id) await API.delete("/schedules/" + results.schedule.data.id)
				if (results?.todo?.data?.id) await API.delete("/todos/" + results.todo.data.id)
				setLoading(false)
			} catch (e2) {
				console.error(e2)
				setLoading(false)
			}
			console.error(e)
		}
	}

	return (
		<>
			<div className="modal-agenda" id="relance-modal">
				<div className={"container small"}>
					<div className="title">Relance</div>
					<div className="content">
						<div className={"finEvt-relancegroup"}>
							<div className="form-group">
								<div className="mt-4">
									<h5>Raison de la relance</h5>
								</div>
								<div id="raison_relance" className="btn-group">
									{itemsRaisonDeRelance.map((v, index) => (
										<button
											key={index}
											type="button"
											className={btnDefault + (newSchedule?.reason === v.name && " active")}
											name={v.name}
											onClick={(evt) => {
												evt.persist()
												setNewSchedule((old) => ({ ...old, reason: evt.target.name }))
											}}>
											{v.value}
										</button>
									))}
								</div>
							</div>
						</div>
						<div className="finEvt-relancegroup-date">
							<DateInput
								id="relance-date"
								label="Sélectionner la date de la relance"
								disabled={newSchedule.reason == null}
								onChange={(date) =>
									setNewSchedule((old) => ({ ...old, dateOf: formatDatetimeForDB(date) }))
								}
							/>
						</div>
						<div className={"finEvt-relancegroup-ok"}>
							<button
								type="button"
								className="btn btn-block btn-outline-primary"
								onClick={() => createReminder()}
								disabled={isLoading || newSchedule.dateOf == null}>
								{isLoading && (
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									/>
								)}
								{!isLoading && <>Enregistrer</>}
							</button>
							<button
								type="button"
								className="btn btn-block btn-outline-danger"
								onClick={() => {
									hideModal()
								}}>
								Annuler
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withRouter(Relance)
