import { ContainerOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

export const ProductsResults = ({ product }: { product: any }): JSX.Element => {
	return (
		<Link to={"/stock/fiche-produit/" + product.id} className="item">
			<div className="ml-3 d-flex align-items-center">
				<ContainerOutlined className=" mr-1" />
				{product?.model}
			</div>
		</Link>
	)
}
