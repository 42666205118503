/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react"
import { API_URL } from "../../../config"
import axios from "axios"
import { Select } from "antd"
// import customType from "../SchedulesCustomTypes.json"
// import types from "../SchedulesDefaultTypes.json"
import getSchedulesType from "../getScheduleType"
import dayjs from "dayjs"
import usePatientClass from "hooks/specific/usePatientClass"
import { EquipmentClass } from "constants/patient/equipment"

const { Option } = Select

const ScheduleTypeSelect = ({ data, setData, loading, placeholder, allowClear, generic }) => {
	const [nbEssai, setNbEssai] = useState(0)
	const [, setMandatoryData] = useState({})
	const [scheduleTypes, setSchedulesType] = useState({})
	const [patientClass, patientHasCmu] = usePatientClass(data.patient)

	const patientCategory = useMemo(() => {
		let categoryKeyForReference

		switch (patientClass) {
			case EquipmentClass.FirstClass:
				categoryKeyForReference = "class1"
				break
			case EquipmentClass.SecondClass:
				categoryKeyForReference = "class2"
				break
			default:
				categoryKeyForReference = ""
				break
		}

		if (patientHasCmu) {
			categoryKeyForReference += "Cmu"
		}

		return categoryKeyForReference
	}, [patientClass, patientHasCmu])

	useEffect(() => {
		;(async function () {
			const types = await getSchedulesType()
			setSchedulesType(types)
		})()
	}, [])

	// function hashCode(str) {
	// 	let hash = 0
	// 	for (let i = 0; i < str.length; i++) {
	// 		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	// 	}
	// 	let colour = "#"
	// 	for (let i = 0; i < 3; i++) {
	// 		let value = (hash >> (i * 8)) & 0xff
	// 		colour += ("00" + value.toString(16)).substr(-2)
	// 	}
	// 	return colour
	// }

	// const itm = {
	// 	ADAPTATION: [2],
	// 	APPAREILLE: [4],
	// 	CONTROLE: [4],
	// 	VISITE: [1, 3],
	// 	SUITE_VISITE: [3],
	// 	ESSAI: [2],
	// 	RELANCE: [0, 1, 2, 3, 4],
	// }

	function getLastAndNextMandatory(schedule) {
		let schedules = [...schedule]
		let lastMandatory = "NONE"
		let nextMandatory = "OBLIGATOIRE_3"
		let last = 0

		for (let i = 0, len = schedules.length; i < len; i++) {
			if (schedules[i].type) {
				try {
					let n = parseInt(schedules[i].type.split("_")[1])
					if (schedules[i].type === "OBLIGATOIRE_12" && last < n) {
						lastMandatory = "OBLIGATOIRE_12"
						nextMandatory = "CLASSIQUE"
						last = n
					}
					if (schedules[i].type === "OBLIGATOIRE_6" && last < n) {
						lastMandatory = "OBLIGATOIRE_6"
						nextMandatory = "OBLIGATOIRE_12"
						last = n
					}
					if (schedules[i].type === "OBLIGATOIRE_3" && last < n) {
						lastMandatory = "OBLIGATOIRE_3"
						nextMandatory = "OBLIGATOIRE_6"
						last = n
					}
				} catch (e) {
					console.error(e)
				}
			}
		}
		return {
			last: lastMandatory,
			next: nextMandatory,
		}
	}

	// Récupération de l'état des rendez-vous du patient : nombre de rdv essai éffectué, nb de rdv de controle obligatoire
	useEffect(() => {
		;(async function () {
			if (generic) {
				return
			}
			try {
				if (data?.patient?.id) {
					let results = await axios.get(
						API_URL + "/schedules/list?status=ESSAI&patient_id=" + data?.patient?.id
					)
					setNbEssai(results.data["hydra:member"].length)
				} else setNbEssai(0)
			} catch (e) {
				console.error(e)
			}
			try {
				if (data?.patient?.id) {
					let results = await axios.get(
						API_URL + "/schedules/list?status=APPAREILLE&patient_id=" + data?.patient?.id
					)
					const mandatory = getLastAndNextMandatory(results?.data["hydra:member"])
					const labels = {
						OBLIGATOIRE_3: "obligatoire à 3 mois",
						OBLIGATOIRE_6: "obligatoire à 6 mois",
						OBLIGATOIRE_12: "obligatoire à 12 mois",
					}
					setMandatoryData({
						label: labels?.[mandatory?.next] || "",
						value: mandatory?.next || "",
					})
				}
			} catch (e) {
				console.error(e)
			}
		})()
	}, [data?.patient])

	const handleChange = (value) => {
		const tmp = {
			startDate: data.fromAgenda ? data.startDate : null,
			endDate: data.fromAgenda ? data.startDate : null,
		}

		if (!value) {
			setData({})
			return
		}
		tmp.status = value
		tmp.type = "CLASSIQUE"
		if (value.includes("OBLIGATOIRE_")) {
			tmp.status = "APPAREILLE"
			tmp.type = value
		}

		const nextScheduleDelay = scheduleTypes?.[value].scheduleInterval[patientCategory]

		if (!data.fromAgenda && nextScheduleDelay) {
			tmp.startDate = dayjs().add(nextScheduleDelay, "month").toDate()
		}

		if (data.startDate && data.endDate) {
			const time_range_value = scheduleTypes?.[value]?.time || null

			if (time_range_value) {
				const new_end_date = dayjs(data.startDate).add(time_range_value, "minute")
				tmp.endDate = new_end_date.toDate()
				tmp.endTime = new_end_date.format("HH:mm")
			}
		}

		setData((old) => ({ ...old, ...tmp }))
	}

	return (
		<div className="schedule-type-selector">
			<Select
				size={"large"}
				showSearch
				allowClear={allowClear}
				placeholder={placeholder || "Type de rendez-vous..."}
				style={{ width: "100%" }}
				value={data.status === "APPAREILLE" && data.type?.includes("OBLIGATOIRE_") ? data.type : data.status}
				onChange={(value) => {
					handleChange(value)
				}}
				disabled={loading}
				filterOption={(input, option) => {
					const label = scheduleTypes?.[option.value]
					return (label?.customLabel || label.label)?.toLocaleLowerCase()?.includes(input.toLocaleLowerCase())
				}}>
				{Object.entries(scheduleTypes)
					.filter((f) => !f[1]?.hidden)
					.map((v, k) => (
						<Option value={v[0]} key={k}>
							<div className="schedule-line-option">
								<div className="option-color" style={{ backgroundColor: v[1].color }} />
								<div className="option-label">
									{v[1]?.customLabel || v[1].label}
									{!generic && v[0] === "ESSAI" ? ` numéro ${nbEssai + 1}` : ""}{" "}
									{!data.fromAgenda && v[1].scheduleInterval[patientCategory] && (
										<small>
											<b>
												( rendez-vous théorique dans {v[1].scheduleInterval[patientCategory]}{" "}
												mois )
											</b>
										</small>
									)}
								</div>
							</div>
						</Option>
					))}
				{/* <OptGroup label="Conseillé">
					{types.map((v, k) => (
						<Option value={v.type} key={k}>
							<div className="schedule-line-option">
								<div
									className={"option-color schedule-color-type-" + v.type}
								></div>{" "}
								<div className="option-label">
									{v.label}
									{v.type === "ESSAI" ? ` numéro ${nbEssai + 1}` : ""}
								</div>
							</div>
						</Option>
					))}

					<Option value={mandatoryData.value} key={9876}>
						<div className="schedule-line-option">
							<div className="option-color" style={{ backgroundColor: hashCode("CONTROLE") }}></div>{" "}
							<div className="option-label">Contrôle {mandatoryData.label}</div>
						</div>
					</Option>
				</OptGroup> */}

				{/* <OptGroup label="Autre">
					{customType.map((v, k) => (
						<Option value={v.type} key={k + 100}>
							<div className="schedule-line-option">
								<div className="option-color" style={{ backgroundColor: hashCode(v.label) }}></div>{" "}
								<div className="option-label">{v.label}</div>
							</div>
						</Option>
					))}
				</OptGroup> */}
			</Select>
		</div>
	)
}

export default ScheduleTypeSelect
