import useEffectAsync from "components/Hooks/useEffectAsync"
import { useState, useRef, MouseEvent } from "react"
import Axios from "axios"
import { clearServiceWorkerCache } from "../../services/functions"
import dayjs from "dayjs"
import { CLIENT_VERSION_API } from "config"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import { Alert } from "reactstrap"

export const AlertBuildVersion = (): JSX.Element => {
	const [showBanner, setShowBanner] = useState<boolean>(false)
	const [needRefresh, setNeedRefresh] = useState<boolean>(true)
	const timeout = useRef<NodeJS.Timeout | null>(null)

	useEffectAsync(async () => {
		if (!needRefresh) return
		if (!document.location.href.includes("app.audiowizard.fr")) return

		try {
			const result: Record<string, any> = await Axios.get(CLIENT_VERSION_API)

			//@ts-ignore
			const currentBuildDate = dayjs(window.BUILD_DATE)
			const targetBuildDate = dayjs(result.data.createdAt)
			const diffMinutes = targetBuildDate.diff(currentBuildDate, "minute")
			const diffDays = targetBuildDate.diff(currentBuildDate, "day")
			const diffBuildtime = dayjs().diff(targetBuildDate, "minute")

			if (diffDays > 0 || (diffMinutes > 0 && diffBuildtime >= 20)) {
				setShowBanner(true)
			}
		} catch (error) {
			console.error(error)
			setShowBanner(false)
		} finally {
			setNeedRefresh(false)
			clearTimeout(timeout.current as NodeJS.Timeout)
			timeout.current = null
			setTimeout(() => {
				setNeedRefresh(true)
			}, 60 * 60 * 1000)
		}
	}, [needRefresh])

	const handleUpdate = async (ev: MouseEvent): Promise<void> => {
		ev.preventDefault()

		const resmodal = await confirmWithModal({
			title: "Confirmer la mise à jour",
			text: (
				<>
					Voulez-vous appliquer la mise à jour maintenant ? <br />
					Votre page sera rechargée et les informations de la page courante ne seront pas enregistrées.
				</>
			),
			okLabel: "Mettre à jour",
			cancelLabel: "Plus tard",
		})
		if (!resmodal) return
		await clearServiceWorkerCache()
	}
	return (
		<>
			<Alert color="warning" className="text-center w-100 m-0 text-lg" hidden={!showBanner}>
				Une nouvelle mise à jour pour l'application est disponible,&nbsp;
				<a className="font-weight-bold text-underline" href="#nop" onClick={handleUpdate}>
					cliquez ici pour effectuer la mise à jour
				</a>
			</Alert>
		</>
	)
}
