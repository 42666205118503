import _ from "lodash"
import { toast } from "react-toastify"
import ListeLPP from "./ListeLPP.json"

// pile BiOTONE => sivantos

/*
function keysToLowerCase(object) {
	const tmp = {}

	for (const key of Object.keys(object)) {
		tmp[key.toLowerCase()] = object[key]
	}
	return tmp
}
*/

export const limit = {
	10: 10,
	312: 7,
	13: 5,
	675: 3,
}

/*
	Sonova
	Sivantos
	Biotone Technologie médicale
	Starkey
	Gn Hearing
	Bernafon
	Widex
	Prodition
*/

function handleSanitize(lpp) {
	const tmp = { ...lpp }
	if (!tmp?.fournisseur) return tmp
	const supplier = tmp.fournisseur.toLocaleUpperCase()

	if (["BIOTONE TECH"].includes(supplier)) tmp.fournisseur = "Biotone Technologie médicale"
	return tmp
}

function getCorrectedLPPs() {
	const tmp = []
	const regexPile = new RegExp(/PILES *.+ (\d+)-*.+/)

	for (let lpp of ListeLPP) {
		lpp = handleSanitize(lpp)
		if (
			lpp?.categorie === "AUDITIF" &&
			(lpp?.classe.includes("PILES") || lpp?.classe.includes("ECOUTEUR")) &&
			!lpp?.oreille
		) {
			let categorie = null
			let model = null

			if (lpp?.classe.includes("PILES")) {
				categorie = "PILE"
			} else categorie = "ECOUTEUR"

			try {
				const matches = lpp?.classe.match(regexPile)
				model = matches.length === 2 ? matches[1] : null
			} catch (_void) {}
			tmp.push({
				categorie: categorie,
				description: lpp.classe,
				amount: lpp.amount,
				fournisseur: lpp.fournisseur,
				lpp: lpp.lpp,
				model,
				limit: limit[model] ?? null,
			})
		} else if (lpp?.categorie === "AUDITIF" && lpp?.classe.includes("EMBOUT")) {
			tmp.push({
				categorie: "EMBOUT",
				description: lpp.classe,
				amount: lpp.amount,
				fournisseur: lpp.fournisseur,
				lpp: lpp.lpp,
				age: lpp.age,
			})
		} else {
			tmp.push(lpp)
		}
	}
	return tmp
}

/**
 * Returns a data object for a product (including lpp and class). If the product is missing a necessary info,
 * a toast pops and an empty object is returned
 * @param {string} type
 * @param {object} product
 * @param {number} age
 * @param {object} suplData
 */

const energizerBatterySupplier = ["BIOTONE", "KRYS"]

const safeCompare = (value, haystack) => {
	const deburrValue = _.deburr(value?.toUpperCase())

	if (Array.isArray(haystack)) {
		return haystack.some((comparedValue) => deburrValue.includes(_.deburr(comparedValue.toUpperCase())))
	}

	return deburrValue.includes(_.deburr(haystack?.toUpperCase()))
}

export function getProductLPP(type, product, age, suplData = {}) {
	if (!product?.product_id || !product?.product_model) return null

	const lpps = getCorrectedLPPs()
	let lpp = null
	let ageKey = (function () {
		if (type === "EMBOUT") {
			if (age < 2) return "<2"
			if (age >= 2 && age < 20) return ">=2 && <20"
			if (age >= 20) return ">=20"
			if (age > 20) return ">20"
		} else {
			if (age <= 20) return "<=20"
			if (age > 20) return ">20"
		}
		return -1
	})()

	if (!product?.category_name && suplData?.categories && product?.product_category) {
		const category = suplData.categories.find((f) => f.category_id === product.product_category)
		product.category_name = category?.category_name ?? "Catégorie Inconnu"
	}

	if (!product?.class && suplData?.tags && product?.tags?.length) {
		const tag = suplData.tags.find((f) => f.tag_cat.includes("class"))
		const tag_class = tag.tag_details.find((t) =>
			product.tags.find(
				(p) => (p.tag_id ? +p.tag_id === +t.tag_id : 0) || (p.tag_cat ? +p.tag_cat === +t.tag_cat : 0)
			)
		)
		product.class = tag_class?.tag
	}
	if (!product?.supplier_name && suplData?.suppliers && product?.product_supplier) {
		const supplier = suplData.suppliers.find((f) => f.supplier_id === product?.product_supplier)
		product.supplier_name = supplier?.supplier_name ?? "Fournisseur Inconnu"
	}

	if (type === "AUDITIF" && product.cmu) {
		product.class = "2"
	}

	if (product.category_name === "Ancrage osseux") type = "ANCRAGE OSSEUX"

	// Fix temporaire rapide, refacto en cours avec Olivier
	const checkFournisseurTmpFix = (lpp) => {
		let fournisseursArr = new Set()
		const supplierName = product?.supplier_name

		if (
			type === "AUDITIF" &&
			_.deburr(product?.supplier_name?.toUpperCase()).includes("BIOTONE TECHNOLOGI") &&
			lpp?.fournisseur === "Biotone Technologie médicale"
		) {
			return true
		}

		for (const lpp of lpps) {
			if (safeCompare(lpp?.description, "PILES")) fournisseursArr.add(lpp.fournisseur)
		}

		if (type === "PILE") {
			if (safeCompare(supplierName, energizerBatterySupplier)) {
				return safeCompare(lpp?.fournisseur, "ENERGIZER")
			}
			if (safeCompare(supplierName, "PHONAK")) {
				return safeCompare(lpp?.fournisseur, "SONOVA")
			}
			if (safeCompare(supplierName, "NEWSON")) {
				return safeCompare(lpp?.fournisseur, "VARTA")
			}
			for (const val of fournisseursArr) {
				if (safeCompare(supplierName, val)) {
					return safeCompare(lpp?.fournisseur, val)
				}
			}
		}

		return safeCompare(lpp?.fournisseur, product?.supplier_name)
	}

	// Temporaire, nous allons refacto Soon

	const isTailored = () => {
		let supplierName = product?.supplier_name?.toLowerCase()
		return (
			(product.product_model?.toLowerCase()?.includes("sur") &&
				product.product_model?.toLowerCase()?.includes("mesure")) ||
			!lpps.find((e) => e?.fournisseur?.toLowerCase()?.includes(supplierName))
		)
	}

	const EmboutSurMesure = (f) => {
		let supplierName = product?.supplier_name?.toLowerCase()

		if (isTailored()) {
			supplierName = "sur-mesure"
		}
		return f?.fournisseur?.toLowerCase().includes(supplierName)
	}

	if (product?.category_name?.toUpperCase().includes("IMPLANT")) {
		const cat = product?.category_name.toUpperCase().split(" ")?.[0]
		return lpps.find((f) => f.categorie.includes(cat))
	} else if (type === "EMBOUT") {
		const foundLpp = lpps.find(
			(f) => f.categorie.includes(type?.toUpperCase()) && f?.age === ageKey && EmboutSurMesure(f)
		)
		if (isTailored()) {
			foundLpp.new_supplier_name = "SUR-MESURE"
			foundLpp.supplier_name = "SUR-MESURE"
		}
		return foundLpp
	} else if (type.includes("ANCRAGE OSSEUX")) {
		return lpps.find((f) => product.product_model.toUpperCase().includes(f?.classe))
	} else {
		lpp = lpps.find(
			(f) =>
				type &&
				f &&
				f?.categorie?.includes(type?.toUpperCase()) &&
				checkFournisseurTmpFix(f) &&
				(type === "AUDITIF" ? +f?.classe === +product.class : 1) &&
				(type === "AUDITIF" ? f?.oreille === product.ear : 1) &&
				(type === "AUDITIF" ? f?.age === ageKey : 1) &&
				(type === "PILE" ? product.product_model.includes(f?.model) : 1) &&
				(type === "ECOUTEUR"
					? f?.fournisseur?.toUpperCase().includes(product?.supplier_name?.toUpperCase())
					: 1)
		)
	}

	if (!lpp) {
		toast.error(`Pas de code lpp trouvé pour le produit ${product?.product_model} de ${product?.supplier_name}`, {
			toastId: product?.product_model,
		})
		console.error(
			"Debug LPP ",
			product?.category_name,
			product?.class,
			product.ear,
			product?.supplier_name,
			suplData
		)
	}

	return lpp
}
