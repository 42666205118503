/* eslint-disable react-hooks/exhaustive-deps */
import {
	AfterSale,
	Ear,
	EventExplanation,
	EventOtoscopy,
	EventProsthetic,
	EventWorkshop,
	Laboratory,
	LaboratoryAttendance,
	Patient,
	PatientEquipment,
	PatientGene,
	Personalization,
	Reminder,
	Schedule,
	User,
	UserRole,
} from "@audiowizard/common"
import dayjs from "dayjs"
import { parsePhoneNumber } from "libphonenumber-js/max"
import _ from "lodash"
import moment from "moment"
import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { RouteComponentProps } from "react-router-dom"
import { toast } from "react-toastify"
import AuthContext from "../contexts/AuthContext"
import API, { ApiTarget } from "./API"
import CacheSystem from "./CacheSystem/Cache"
import { calculateSsnKey } from "components/forms/SsnInput"
import { dayHashtable } from "pages/mon-compte/Modal.PlageHorraire"
import { clearAllHiboutikCache } from "pages/Stock-Management/Shared/Utils"

/**
 * Détermine si on est en environnement de développement
 */
export function isDevLocal(): boolean {
	return !document.location.href.includes("app.audiowizard.fr")
}

/**
 *	Resolve la promise après l'écoulement d'au moins `ms` millisecondes
 *	@example
 *	console.log("Maintenant")
 *	await sleep(1000)
 *  console.log("Une seconde plus tard")
 */
export function sleep(ms: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Force la valeur de AuthContext.isBlockingContext à true
 */
export const BlockingContext = (): void => {
	const { isBlockingContext, setIsBlockingContext } = useContext(AuthContext)

	useEffect(() => {
		setIsBlockingContext(true)
	}, [isBlockingContext, setIsBlockingContext])
}

/**
 * Supprime de l'array tous les élément qui ont `element[key] === value`
 *
 * @example removeFromArray([{ a: 1, b: "string"}, { a: 2, b: "thing" }], "a", 1) // [{ a: 2, b: "thing" }]
 */
export const removeFromArray = <T, K extends keyof T>(arr: T[], key: K, value: T[K]): T[] => {
	return arr.filter((element) => element[key] !== value)
}

/**
 * Trouve la première ressource qui n'est pas dans `originalItems`. La comparaison est faite sur `originalItem.value === ressource.label`
 */
export const findCustomItem = <T extends { label: unknown }, K extends keyof T>(
	ressources: T[],
	originalItems: { value: unknown }[],
	targetName?: K,
	targetValue?: T[K]
): T | null => {
	let filteredRessources = [...ressources]
	if (targetName && targetValue) {
		filteredRessources = ressources.filter((ressource) => {
			return ressource[targetName] === targetValue
		})
	}

	const custom = filteredRessources.filter((ressource) => {
		return !originalItems.map((item) => item.value).includes(ressource.label)
	})

	if (custom.length) {
		return custom[0]
	}

	return null
}

/**
 * Met à jour un state array react via `setRessource`.
 * Se base sur `@id` ou `label` pour trouver l'élément à remplacer.
 */
export const updateCustomRessource = async <T extends { "@id"?: string; label?: string }, K extends keyof T>(
	customRessource: T,
	ressource: T[],
	setRessource: Dispatch<SetStateAction<T[]>>,
	removeTarget?: K,
	removeValue?: T[K]
): Promise<void> => {
	try {
		if (customRessource["@id"]) {
			const ressourceUpdated = ressource.filter((res) => {
				return res["@id"] !== customRessource["@id"]
			})
			setRessource(ressourceUpdated)
		} else {
			if (customRessource.label!.trim() === "") {
				let newRessource = [...ressource]

				if (removeTarget && removeValue) {
					newRessource = removeFromArray(newRessource, removeTarget, removeValue)
				}
				setRessource([...newRessource, { ...customRessource }])
			} else {
				const newRessource = removeFromArray(ressource, "label", customRessource.label)
				setRessource(newRessource)
			}
		}
	} catch (err) {
		throw err
	}
}

/**
 * @param birthDate Date d'anniversaire
 * @returns L'age basé sur birthDate.
 *
 * @example
 * // On est le 2021-01-01
 * age("2000-01-01") === 21
 */
export const age = (birthDate: string | Date | dayjs.Dayjs): number => {
	return dayjs().diff(birthDate, "years")
}

/**
 *
 * @param {Moment|Dayjs|string} date
 * @returns {string} date formatté au format français (DD/MM/YYYY)
 */
export const frenchDate = (date: string | Date | moment.Moment | dayjs.Dayjs): string => {
	if (dayjs.isDayjs(date)) return date.format("L")

	return moment(date).format("L")
}

export const HandleChange = <T extends Record<string, unknown>>(
	event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	element: T,
	setElement: Dispatch<SetStateAction<T>>
): void => {
	const value = event.target.value
	setElement({ ...element, [event.target.name]: value })
}

/**
 * Checks if a French Security Social Number is valid
 * @param {string} value french ssn without spaces
 * @returns {boolean}
 */
export function ssnValidate(value: string): boolean {
	if (!value || value.length !== 15) {
		return false
	}

	// If ssn starts with 7 or 8, it means that it is a temporary number.
	// It's structure is different and we can't verify it for now. So we only calculate if the key is ok.
	if (value[0] === "7" || value[0] === "8") {
		const calculatedKey = calculateSsnKey(value)
		return calculatedKey === value.slice(13)
	}
	// If ssn has "99" in positions 5 and 6, it means that the patient is born abroad.
	// Therefore he may have a lunar birth date with a month numbered above 12, so we must ignore all checks except if the key is ok.
	else if (value.slice(5, 7) === "99") {
		const calculatedKey = calculateSsnKey(value)
		return calculatedKey === value.slice(13)
	}

	const pattern = /^([1278])(\d{2})(0[1-9]|1[0-2]|20)(\d{2}|2[AB])(\d{3})(\d{3})(\d{2})/i
	const match = pattern.exec(value)
	if (!match) {
		return false
	}
	const ssn = {
		gender: match[1],
		year: match[2],
		month: match[3],
		department: match[4],
		city: match[5],
		certificate: match[6],
		key: match[7],
	}

	if (ssn.certificate === "000" || Number(ssn.key) > 97) {
		return false
	}

	if (ssn.department === "2A") {
		ssn.department = "19"
	} else if (ssn.department === "2B") {
		ssn.department = "18"
	} else if (ssn.department === "97") {
		ssn.department += ssn.city[0]
		if (Number(ssn.department) < 970 || Number(ssn.department) >= 989) {
			return false
		}
		ssn.city = ssn.city.substring(1)
		if (Number(ssn.city) < 1 || Number(ssn.city) > 90) {
			return false
		}
	} else if (Number(ssn.city) < 1 || Number(ssn.city) > 990) {
		return false
	}

	const insee =
		ssn.gender +
		ssn.year +
		ssn.month +
		ssn.department.replace("A", "0").replace("B", "0") +
		ssn.city +
		ssn.certificate
	if (97 - (Number(insee) % 97) !== Number(ssn.key)) {
		return false
	}

	return true
}

/**
 * @param {string} phoneNumber
 * @returns {boolean}
 * @returns Si le numéro de téléphone est un numéro de portable ou non (En France c'est 06 et 07)
 */
export const isMobilePhone = (phoneNumber: string): boolean => {
	if (typeof phoneNumber !== "string" || phoneNumber.length === 0) return false

	return parsePhoneNumber(phoneNumber, "FR").getType() === "MOBILE"
}

/**
 * Renvoie le numéro national (06 xx xx) si français, sinon renvoie le numéro international (+33 6 xx)
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
	if (typeof phoneNumber !== "string" || phoneNumber.length === 0) return ""

	const phone = parsePhoneNumber(phoneNumber, "FR")
	return phone.country === "FR" ? phone.formatNational() : phone.formatInternational()
}

/**
 * Renvoie le numéro de téléphone au format international
 */
export const convertPhoneNumber = (phoneNumber: string): string | null => {
	// La validation de taille ne passe que pour les numéros français, c'est bof bof
	if (!phoneNumber || typeof phoneNumber !== "string" || phoneNumber?.length === 0 || phoneNumber?.length < 9)
		return null

	const phone = parsePhoneNumber(phoneNumber, "FR")
	return phone.isValid() ? (phone.number as string) : null
}

// TODO cette fonction n'a rien à faire ici. Elle devrait se trouver dans MailRelance
export const SendMail = (patient: Patient, user: User, reminder: Reminder, schedule: Schedule): true => {
	// Accès au DOM directe dans une fonction utilitaire ? Beurk !
	const htmlArray = Array.from(document.querySelector("[role='textbox']")!.childNodes)

	const html = htmlArray.join("")

	const mailData = {
		to: patient.email,
		user: user,
		content: html,
		token: "kBnqO@A&0xGyPH*]kC#}VgO*EYX3&Fd2)<Y>tV<dU;n1GS{%b<lazccTp^gCi",
	}

	API.mail("MAIL_COMMERCIAL_API", mailData)
		.then((data) => data === "OK" && toast.success("Mail envoyé à " + patient.email))
		.catch((data) => {
			toast.error("Error: " + data)
		})
	reminder = { ...reminder, text: html, schedule: schedule["@id"] as any /* Iri */ }
	API.create("REMINDERS_API", reminder)

	return true
}

/**
 * Capitalize le premier caractère de `text`
 *
 * @example StringUpperCFirst("un deux TRois") == "Un deux TRois"
 */
export const StringUpperCFirst = (text: string): string => {
	if (text) return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
	return ""
}

/**
 * @returns un tableau contenant les entiers de 0 à `int`
 *
 * @example GetArrayOfInt(10) // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
 */
export const GetArrayOfInt = (int: number): number[] => {
	const arr = []
	for (let number = 0; number <= int; number += 1) {
		arr.push(number)
	}
	return arr
}

/**
 *
 * @param separator Séparateur entre le jour, le mois et l'année
 * @param fullnameMonth Affiche le nom complet du mois (01 -> Janvier)
 * @param date Date à formatter
 * @returns La date formatté au format français (DD/MM/YYYY)
 *
 * @deprecated Préférer `frenchDate()`
 */
export const FormatDate = (
	separator: string,
	fullnameMonth: boolean,
	date?: string | number | Date | moment.Moment
): string => {
	return moment(date || new Date()).format(`DD${separator}${fullnameMonth ? "MMMM" : "MM"}${separator}YYYY`)
}

/**
 * @returns date formatted (YYYY-MM-DD)
 */
export const formatDateForDB = (date: string | number | Date | moment.Moment | dayjs.Dayjs): string => {
	if (dayjs.isDayjs(date)) return date.format("YYYY-MM-DD")

	return moment(date || new Date()).format("YYYY-MM-DD")
}
/**
 * @returns datetime formatted (ISO 8601)
 */
export const formatDatetimeForDB = (date: string | number | Date | moment.Moment | dayjs.Dayjs): string => {
	if (dayjs.isDayjs(date)) return date.toISOString()

	return moment(date || new Date()).toISOString()
}

export const Salutation = (patient: Patient, setText: (value: string) => void): void => {
	const html = patient.gender === "FEMME" ? "Bonjour Madame " : "Bonjour Monsieur " + patient.lastName + ",</br></br>"

	setText(html)
}

/**
 * @deprecated Utiliser les fonctions `API` directement.
 */
export const Fetch = async <T extends unknown[]>(target: string, set: (value: T) => void): Promise<T> => {
	let apiTarget: ApiTarget
	switch (target) {
		case "prescribers":
			apiTarget = "PRESCRIBERS_API"
			break
		case "productRanges":
			apiTarget = "PRODUCT_RANGES_API"
			break
		case "laboratoryAttendances":
			apiTarget = "LABORATORY_ATTENDANCES_API"
			break
		case "patients":
			apiTarget = "PATIENTS_API"
			break
		case "products":
			apiTarget = "PRODUCTS_API"
			break
		case "laboratories":
			apiTarget = "LABORATORIES_API"
			break
		case "referrals":
			apiTarget = "REFERRALS_API"
			break
		case "mutuelles":
			apiTarget = "INSURANCES_API"
			break
		default:
			throw new Error(`Invalid target value '${target}'`)
	}
	const data = await API.findAll<T>(apiTarget, "?order[id]=DESC")
	set(data)
	return data
}

/**
 * @example [{a: 1}, {a: 2}, {a: 0}].sort(SortByProperty("a")) // [{"a": 0}, {"a": 1}, {"a": 2}]
 */
export const SortByProperty = <T, K extends keyof T>(property: K): ((x: T, y: T) => number) => {
	return function (x, y) {
		return x[property] === y[property] ? 0 : x[property] > y[property] ? 1 : -1
	}
}

export const SimpleSubmit = (
	event: React.FormEvent<HTMLFormElement>,
	history: RouteComponentProps["history"],
	next: string
): true => {
	event.preventDefault()
	history.push(next)
	return true
}

/* eslint-disable no-unused-vars */
// fix temporaire
export const IsObjectEmpty = (object: Record<string, unknown>): boolean => {
	for (const key in object) {
		if (object.hasOwnProperty(key)) return false
	}
	return true
}

type GetHistorySchedule = Schedule & {
	eventProsthetics: EventProsthetic[]
	eventOtoscopies: EventOtoscopy[]
	eventExplanations: EventExplanation[]
	eventWorkshops: { GAUCHE: EventWorkshop; DROITE: EventWorkshop }[]
	patientEquipments: PatientEquipment[]
	thisSchedule: Schedule
}
// TODO: Refaire les types de cette fonction. J'ai mis trop d'`any`
export const GetHistory = (
	schedule: GetHistorySchedule,
	setText: (value: string) => void | undefined,
	patient: Patient & { patientGenes: PatientGene[] }
): string | void => {
	const content: any = {}

	const eventGenes = patient?.patientGenes?.filter(
		(gene) =>
			gene.history &&
			gene.history.length &&
			gene.history[gene.history.length - 1] &&
			gene.history[gene.history.length - 1].hasOwnProperty("schedule") &&
			gene.history[gene.history.length - 1].schedule.hasOwnProperty("@id") &&
			gene.history[gene.history.length - 1].schedule["@id"] === schedule.thisSchedule["@id"]
	)
	const newEventOtoscopies = schedule?.eventOtoscopies?.length
		? schedule?.eventOtoscopies?.filter((otoscopie) => !otoscopie.hasOwnProperty("@id"))
		: []

	const newEventProsthetics: any = {}
	if (schedule?.eventProsthetics?.length) {
		schedule.eventProsthetics.forEach((prosthetic) => {
			if (!prosthetic.hasOwnProperty("@id")) {
				if (newEventProsthetics.hasOwnProperty(prosthetic.type)) {
					newEventProsthetics[prosthetic.type!].push(prosthetic)
				} else {
					newEventProsthetics[prosthetic.type!] = [prosthetic]
				}
			}
		})
	}

	const newEventExplanations = schedule?.eventExplanations?.length
		? schedule?.eventExplanations?.filter((explanation) => !explanation.hasOwnProperty("@id"))
		: []

	const startSchedule = (): void => {
		if (
			newEventOtoscopies.length ||
			Object.keys(newEventProsthetics).length ||
			schedule?.eventWorkshops["DROITE" as any]?.operations?.length ||
			schedule?.eventWorkshops["GAUCHE" as any]?.operations?.length ||
			newEventExplanations.length ||
			eventGenes?.length
		) {
			content.start = "<p>Lors du dernier rendez-vous,</p>"
		} else content.start = "<p>Il n'y a pas eu d'évenements lors de ce rendez-vous.</p>"
	}

	// TODO Faire eventGenesSchedules
	const eventOtoscopieSchedule = (): void => {
		content.otoscopie = ""
		newEventOtoscopies.forEach((otoscopie) => {
			content.otoscopie += "<p>Nous avons réalisé une otoscopie "

			content.otoscopie += otoscopie?.ear === "BILATERAL" ? "aux deux oreilles" : "à l'oreille " + otoscopie.ear

			content.otoscopie += " et nous avons détecté qu'il "
			// eslint-disable-next-line default-case
			switch (otoscopie.value) {
				case "RAS":
					content.otoscopie += "n'y avait rien à signaler.</p>"
					break
				case "BOUCHON":
					content.otoscopie += "y avait un encombrement.</p>"
					break
				case "DEPOT":
					content.otoscopie += "y avait un dépôt suspect.</p>"
					break
				case "TYMPAN":
					content.otoscopie += "y avait un tympan suspect.</p>"
					break
				case "CORPS ETRANGER":
					content.otoscopie += "y avait un corps étranger.</p>"
					break
				case "IRRITATION":
					content.otoscopie += "y avait une irritation.</p>"
					break
				case "EMBOUT DOULOUREUX":
					content.otoscopie += "y avait un embout douloureux.</p>"
					break
			}
		})
	}

	const eventWorkshopsSchedule = (): void => {
		if (schedule?.eventWorkshops) {
			content.workshop = ""
			const workshop = schedule.eventWorkshops
			const operations = [
				"FILTRE",
				"MICRO",
				"CONTACT",
				"ECOUTEUR",
				"DOME",
				"EMBOUT",
				"COQUE",
				"HAUTPARLEUR",
				"COUDE",
				"TUBE",
				"TIROIR",
				"FIL",
			]

			const operationsSentences: any = {
				FILTRE: [
					"changé le filtre de l’écouteur droite",
					"changé le filtre de l’écouteur gauche",
					"changé les filtres des deux écouteurs",
				],
				MICRO: ["aspiré le micro droit", "aspiré le micro gauche", "aspiré les micros"],
				CONTACT: [
					"nettoyé les contacts piles de l’appareil droit",
					"nettoyé les contacts piles de l’appareil gauche",
					"nettoyé les contacts piles des deux appareils",
				],
				ECOUTEUR: ["changé l’écouteur droit", "changé l’écouteur gauche", "changé les deux écouteurs"],
				DOME: ["changé le dôme droit", "changé le dôme gauche", "changé les deux dômes"],
				EMBOUT: ["changé l’embout droit", "changé l’embout gauche", "changé les deux embouts"],
				COQUE: ["changé la coque droite", "changé la coque gauche", "changé les deux coques"],
				HAUTPARLEUR: [
					"nettoyé le haut-parleur droit",
					"nettoyé le haut-parleur gauche",
					"nettoyé les deux haut-parleurs",
				],
				COUDE: ["changé le coude droit", "changé le coude gauche", "changé les deux coudes"],
				TUBE: ["<changé le tube droit", "<changé le tube gauche", "<changé les deux tubes"],
				TIROIR: [
					"changé le tiroir piles droit",
					"changé le tiroir piles gauche",
					"changé les deux tiroirs piles",
				],
				FIL: [
					"changé le fil d’extraction droit",
					"changé le fil d’extraction gauche",
					"changé les fils d’extraction des deux appareils",
				],
			}

			const earOperations: any[] = []
			if (workshop["DROITE" as any]?.operations?.length) {
				earOperations.push("DROITE")
			}
			if (workshop["GAUCHE" as any]?.operations?.length) {
				earOperations.push("GAUCHE")
			}

			if (earOperations?.length) {
				content.workshop += "<p>Lors de la maintenance de l’appareil nous avons : "
			}

			operations.forEach((operation, index) => {
				const earsAffected: any[] = []
				earOperations.forEach((ear) => {
					if (ArrayHasValue(workshop[ear].operations!, operation)) {
						earsAffected.push(ear)
					}
				})
				if (ArrayHasValue(earsAffected, "DROITE") && ArrayHasValue(earsAffected, "GAUCHE")) {
					content.workshop += operationsSentences[operation][2]
				} else if (ArrayHasValue(earsAffected, "GAUCHE")) {
					content.workshop += operationsSentences[operation][1]
				} else if (ArrayHasValue(earsAffected, "DROITE")) {
					content.workshop += operationsSentences[operation][0]
				}
				if (
					(ArrayHasValue(earsAffected, "DROITE") || ArrayHasValue(earsAffected, "GAUCHE")) &&
					index !== operations.length - 1
				) {
					content.workshop += ", "
				}
			})

			if (earOperations?.length) {
				content.workshop += "</p>"
			}

			const afterCleaning = ["RAS", "AVANT", "ARRIERE", "FAIBLE", "DISTORSION", "HAUTPARLEUR"]

			const afterCleaningSentences: any = {
				RAS: [
					"il n’y a rien à signaler sur l’appareil droit",
					"il n’y a rien à signaler sur l’appareil gauche",
					"il n’y a rien à signaler sur les deux appareils",
				],
				AVANT: [
					"le micro avant droit est hors service",
					"le micro avant gauche est hors service",
					"les micros avant sont hors services",
				],
				ARRIERE: [
					"le micro arrière droit est hors service",
					"le micro arrière gauche est hors service",
					"les micros arrière sont hors services",
				],
				FAIBLE: ["l’appareil droit est faible", "l’appareil gauche est faible", "les appareils sont faibles"],
				DISTORSION: ["l’appareil droit distord", "l’appareil gauche distord", "les appareils distordent"],
				HAUTPARLEUR: [
					"le haut parleur droit est HS",
					"le haut parleur gauche est HS",
					"les hauts parleurs des deux appareils sont HS",
				],
			}

			const earAfterCleaning: any[] = []
			if (workshop["DROITE" as any]?.afterCleanings?.length) {
				earAfterCleaning.push("DROITE")
			}
			if (workshop["GAUCHE" as any]?.afterCleanings?.length) {
				earAfterCleaning.push("GAUCHE")
			}

			if (earAfterCleaning?.length) {
				content.workshop += "<p>Après le nettoyage : "
			}

			afterCleaning.forEach((cleaning, index) => {
				const earsAffected: any[] = []
				earAfterCleaning.forEach((ear) => {
					if (ArrayHasValue(workshop[ear].afterCleanings!, cleaning)) {
						earsAffected.push(ear)
					}
				})
				if (ArrayHasValue(earsAffected, "DROITE") && ArrayHasValue(earsAffected, "GAUCHE")) {
					content.workshop += afterCleaningSentences[cleaning][2]
				} else if (ArrayHasValue(earsAffected, "GAUCHE")) {
					content.workshop += afterCleaningSentences[cleaning][1]
				} else if (ArrayHasValue(earsAffected, "DROITE")) {
					content.workshop += afterCleaningSentences[cleaning][0]
				}
				if (
					(ArrayHasValue(earsAffected, "DROITE") || ArrayHasValue(earsAffected, "GAUCHE")) &&
					index !== afterCleaning.length - 1
				) {
					content.workshop += ", "
				}
			})

			if (earAfterCleaning?.length) {
				content.workshop += "</p>"
			}
		}
	}

	const getExplanationComprehension = (comprehension: "BIEN" | "REVOIR" | "MAL" | string): string => {
		switch (comprehension) {
			case "BIEN":
				return "bien compris"
			case "REVOIR":
				return "à revoir"
			case "MAL":
				return "mal compris"
			default:
				return ""
		}
	}

	const eventExplanationsSchedule = (): void => {
		let counter = 0
		if (schedule?.eventExplanations?.length) {
			schedule.eventExplanations.forEach((explanation, index) => {
				if (explanation["@id"]) return

				if (counter === 0) content.explanation = "<p>Nous avons expliqué certains points: </p>"

				switch (explanation.subject) {
					case "ENTRETIEN":
						content.explanation += `<p>- L'entretien de l'appareil: ${getExplanationComprehension(
							explanation.comprehension!
						)}</p>`
						break
					case "MANIPULATION":
						content.explanation += `<p>- Manipulation des piles et/ou du chargeur: ${getExplanationComprehension(
							explanation.comprehension!
						)}</p>`
						break
					case "MISE_EN_PLACE":
						content.explanation += `<p>- Mise en place sur l'oreille: ${getExplanationComprehension(
							explanation.comprehension!
						)}</p>`
						break
					case "CONNEXION_BT":
						content.explanation += `<p>- Connexion bluetooth: ${getExplanationComprehension(
							explanation.comprehension!
						)}</p>`
						break
					case "BOBINE":
						content.explanation += `<p>- Bobine T: ${getExplanationComprehension(
							explanation.comprehension!
						)}</p>`
						break
					default:
						content.explanation += ""
						break
				}
				counter += 1
			})
		}
	}

	const getProstheticTypeText = (type: "AUDIOMETRIE" | "CHAMP_LIBRE" | "CDM" | "SPATIALISATION"): string => {
		switch (type) {
			case "AUDIOMETRIE":
				return "Audiométrie"
			case "CHAMP_LIBRE":
				return "Champ Libre"
			case "CDM":
				return "Chaîne de Mesure"
			case "SPATIALISATION":
				return "Spatialisation"
			default:
				return ""
		}
	}

	const eventProstheticsSchedule = (): void => {
		if (Object.keys(newEventProsthetics).length) {
			content.prosthetic = "<p>Nous avons effectué les tests suivants: </p>"

			Object.keys(newEventProsthetics).forEach((type) => {
				const prosthetics = newEventProsthetics[type]

				if (prosthetics.length) {
					const typeText = getProstheticTypeText(type as any)

					content.prosthetic += `<p>- ${typeText}: `
					prosthetics.forEach((prosthetic: any, index: number) => {
						switch (prosthetic.type) {
							case "AUDIOMETRIE":
								switch (prosthetic.test) {
									case "TC":
										content.prosthetic += `Tonale casque (${prosthetic.ear})`
										break
									case "TO":
										content.prosthetic += `Tonale osseuse (${prosthetic.ear})`
										break
									case "IS":
										content.prosthetic += `In Situ (${prosthetic.ear})`
										break
									case "VC":
										content.prosthetic += `Vocale casque (${prosthetic.ear})`
										break
									case "VO":
										content.prosthetic += `Vocale osseuse (${prosthetic.ear})`
										break
									case "I":
										content.prosthetic += `Insert (${prosthetic.ear})`
										break
									default:
										content.prosthetic += ""
										break
								}
								break
							case "CHAMP_LIBRE":
								switch (prosthetic.test) {
									case "TAA":
										content.prosthetic += `Tonale avec appareils (${prosthetic.ear})`
										break
									case "TSA":
										content.prosthetic += `Tonale sans appareils (${prosthetic.ear})`
										break
									case "VCAA":
										content.prosthetic += `Vocale dans le calme avec appareils (${prosthetic.ear})`
										break
									case "VCSA":
										content.prosthetic += `Vocale dans le calme sans appareils (${prosthetic.ear})`
										break
									case "VBAA":
										content.prosthetic += `Vocale dans le bruit avec appareils (${prosthetic.ear})`
										break
									case "VBSA":
										content.prosthetic += `Vocale dans le bruit sans appareils (${prosthetic.ear})`
										break
									default:
										content.prosthetic += ""
										break
								}
								break
							case "CDM":
								switch (prosthetic.test) {
									case "C":
										content.prosthetic += "Coupleur"
										break
									case "MIV":
										content.prosthetic += "MIV"
										break
									default:
										content.prosthetic += ""
										break
								}
								if (prosthetic.method || prosthetic.adjustment) {
									content.prosthetic += "("
									if (prosthetic.method) content.prosthetic += `méthode: ${prosthetic.method}`
									if (prosthetic.adjustment) {
										if (prosthetic.method) content.prosthetic += ", "
										content.prosthetic += `réglage: ${prosthetic.adjustment}`
									}
									content.prosthetic += ")"
								}
								break
							case "SPATIALISATION":
								switch (prosthetic.test) {
									case "LS":
										content.prosthetic += "Localisation Spatiale"
										break
									case "EIA":
										content.prosthetic += "Equilibrage inter-aural"
										break
									default:
										content.prosthetic += ""
										break
								}
								break
							default:
								content.prosthetic += ""
								break
						}
						if (index !== prosthetics.length - 1) content.prosthetic += ", "
					})
					content.prosthetic += "</p>"
				}
			})
		}
	}

	const genesHistory = (): void => {
		if (patient?.patientGenes) {
			content.genes = ""
			patient.patientGenes.forEach((gene) => {
				if (gene.history && gene.history.length) {
					gene.history.forEach((h: any) => {
						if (
							gene.label &&
							h.past >= 0 &&
							h.next >= 0 &&
							h.hasOwnProperty("schedule") &&
							h.schedule.hasOwnProperty("@id") &&
							h.schedule["@id"] === schedule.thisSchedule["@id"]
						) {
							content.genes +=
								"<p>La gêne " +
								gene.label +
								" a évolué d'un niveau de confort de " +
								h.past +
								" à " +
								h.next +
								"</p>"
						}
					})
				}
			})
		}
	}

	const hoursSchedule = (): void => {
		if (schedule.hours) {
			content.hours = "<p>Le patient a porté ses appareils " + schedule.hours + " heures en moyenne</p>"
		}
	}

	startSchedule()
	eventOtoscopieSchedule()
	eventWorkshopsSchedule()
	eventProstheticsSchedule()
	genesHistory()
	hoursSchedule()
	eventExplanationsSchedule()

	if (setText) {
		setText(Object.values(content).join(" "))
	} else {
		return Object.values(content).join(" ")
	}
}

/**
 * @deprecated Utilisez `array.includes()` à la place
 */
export const ArrayHasValue = <T>(array: T[], value: T): boolean => {
	return array.includes(value)
}

export const findProductsFromHiboutikId = (arr: string[], source: { product_id: number }[]): unknown[] => {
	const val = arr.map((e) => e.substring(e.lastIndexOf("/") + 1))

	const res = val.map((v) => source?.filter((p) => p.product_id === parseInt(v)))
	return res.flat()
}

// TODO: Typer hiboutik et typer cette fonction correctement
export const getCustomTags = (
	product: { product_model: unknown; tags: { tag_id: number }[] },
	tags: { tag_details: { tag_id: number; tag: unknown }[]; tag_cat: string }[]
): unknown | undefined => {
	if (product.product_model && tags.length) {
		const tagsProduct = product.tags
		const objectProductLeftTag: any = {}
		tags &&
			tags.forEach((tag) => {
				tagsProduct &&
					tagsProduct.forEach((productTag) => {
						const tagLookingFor = tag.tag_details.filter((detail) => detail.tag_id === productTag.tag_id)

						if (tagLookingFor.length) {
							Object.assign(objectProductLeftTag, { [tag.tag_cat]: tagLookingFor[0].tag })
						}
					})
			})
		return objectProductLeftTag.type ? objectProductLeftTag : {}
	}
}

export const haveRole = (givenUser: Pick<User, "roles">, givenRole: UserRole): boolean => {
	if (givenUser.roles == null || givenUser.roles.length === 0) return false

	// TODO: Choisir un des deux et enlever le double rôle
	if (givenRole === "ROLE_ASSISTANT" || givenRole === ("ROLE_ASSISTANTE" as UserRole)) {
		return givenUser.roles.includes("ROLE_ASSISTANT") || givenUser.roles.includes("ROLE_ASSISTANTE" as UserRole)
	}
	return givenUser.roles.includes(givenRole)
}

export const patientAlreadyEquiped = (patient: Patient): boolean => {
	const equipments = patient.patientEquipments!.filter(
		(e) => e.category === "AUDITIF" && !["AVOIR"].includes(e.status!)
	)
	const left = equipments.filter((e) => e.ear === "GAUCHE")
	const right = equipments.filter((e) => e.ear === "DROITE")
	if (left.length < 1 && right.length < 1) {
		return false
	} else {
		if (right.length >= 1 && left.length >= 1) {
			return true
		} else {
			return false
		}
	}
}

export const isEquiped = (patient: Patient, side: "GAUCHE" | "DROITE"): boolean => {
	let equipments = []
	let left = []
	let right = []
	const countBySide = [0, 0]
	if (patient && patient.patientEquipments && patient.patientEquipments.length) {
		equipments = patient.patientEquipments.filter((e) => e.category === "AUDITIF" && !["AVOIR"].includes(e.status!))
		left = equipments.filter((e) => e.ear === "GAUCHE")
		right = equipments.filter((e) => e.ear === "DROITE")
		countBySide[0] = left.length
		countBySide[1] = right.length
	}
	if (side === "GAUCHE" && countBySide[0] >= 1) {
		return true
	} else if (side === "DROITE" && countBySide[1] >= 1) {
		return true
	} else {
		return false
	}
}

export const prescriberCategory = (apiSantePrescriber: { libelleSavoirFaire: string }): "DOCTOR" | "ORL" | null => {
	if (
		apiSantePrescriber.libelleSavoirFaire === "Qualifié en Médecine Générale" ||
		apiSantePrescriber.libelleSavoirFaire === "Médecine Générale" ||
		apiSantePrescriber.libelleSavoirFaire === "Médecine du travail" ||
		apiSantePrescriber.libelleSavoirFaire === "Pédiatrie" ||
		apiSantePrescriber.libelleSavoirFaire === "Santé publique et médecine sociale" ||
		apiSantePrescriber.libelleSavoirFaire === "Spécialiste en Médecine Générale" ||
		apiSantePrescriber.libelleSavoirFaire === "Médecine interne"
	) {
		return "DOCTOR"
	} else if (
		apiSantePrescriber.libelleSavoirFaire === "Oto-rhino-laryngologie" ||
		apiSantePrescriber.libelleSavoirFaire === "O.R.L et chirurgie cervico faciale"
	) {
		return "ORL"
	} else {
		return null
	}
}

export const equiped = (
	equipments: PatientEquipment[]
): {
	mouthpiece: Ear[]
	earphone: Ear[]
	device: Ear[]
	renewableDevices: Ear[]
} => {
	const equipmentsObject = {
		mouthpiece: new Set<Ear>(),
		earphone: new Set<Ear>(),
		device: new Set<Ear>(),
		renewableDevices: new Set<Ear>(),
	}

	for (const e of equipments) {
		if (["VENDU", "EXTERIEUR"].includes(e.status!)) {
			e.category === "AUDITIF" && equipmentsObject.renewableDevices.add(e.ear!)
		}
		if (!["AVOIR"].includes(e.status!)) {
			e.category === "ECOUTEURS" && equipmentsObject.earphone.add(e.ear!)
			e.category === "EMBOUTS" && equipmentsObject.mouthpiece.add(e.ear!)
			e.category === "AUDITIF" && equipmentsObject.device.add(e.ear!)
		}
	}

	return {
		mouthpiece: Array.from(equipmentsObject.mouthpiece),
		earphone: Array.from(equipmentsObject.earphone),
		device: Array.from(equipmentsObject.device),
		renewableDevices: Array.from(equipmentsObject.renewableDevices),
	}
}

export const displayFloat = (value: string | number, precision = 2): string => {
	// @ts-expect-error: parseFloat can take number
	return parseFloat(value).toFixed(precision)
}
export const parsed = (value: string | number, precision = 2): number => {
	// @ts-expect-error: parseFloat can take number
	return parseFloat(parseFloat(value).toFixed(precision))
}

export const rounded = (value: string | number, precision = 2): number => {
	// @ts-expect-error: parseFloat can take number
	return parseFloat(parseFloat(value).toFixed(precision)) || 0
}

export const FetchAllSav = async (options?: { [key: string]: any }): Promise<AfterSale[]> => {
	let filters = ""

	if (options?.patient) filters += `&patient=${options.patient}`
	const afterSalesReq = await API.findAll<any[]>("SAV_API", "?pagination=false" + filters)
	return afterSalesReq.flat().filter((s) => s.status !== "RÉPARÉ")
}

export const findOnlyPersonalizedTemplateId = (
	personalizedTemplates: Personalization[] | Personalization,
	type: unknown
): number => {
	let tmp: Personalization
	if (Array.isArray(personalizedTemplates) && personalizedTemplates.length >= 1) tmp = personalizedTemplates[0]
	else tmp = personalizedTemplates as Personalization

	return tmp?.templates?.find((template: any) => template.type === type)?.templateId || null
}

const currencyFormatter = new Intl.NumberFormat("fr", { style: "currency", currency: "EUR" })
/**
 * @example formatCurrency(10) === "10,00 €"
 * @example formatCurrency(9.99) === "9,99 €"
 */
export function formatCurrency(amount: number): string {
	return currencyFormatter.format(amount)
}

export const objectToFormData = (obj: Record<string, string | Blob>): FormData =>
	Object.entries(obj).reduce((formData, [key, val]) => {
		formData.append(key, val)
		return formData
	}, new FormData())

// Base64 //

// https://gist.github.com/jonleighton/958841
// Converts an ArrayBuffer directly to base64, without any intermediate 'convert to string then
// use window.btoa' step. According to my tests, this appears to be a faster approach:
// http://jsperf.com/encoding-xhr-image-data/5

/*
MIT LICENSE
Copyright 2011 Jon Leighton
Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
export function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
	let base64 = ""
	const encodings = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"

	const bytes = new Uint8Array(arrayBuffer)
	const byteLength = bytes.byteLength
	const byteRemainder = byteLength % 3
	const mainLength = byteLength - byteRemainder

	let a, b, c, d
	let chunk

	// Main loop deals with bytes in chunks of 3
	for (let i = 0; i < mainLength; i = i + 3) {
		// Combine the three bytes into a single integer
		chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

		// Use bitmasks to extract 6-bit segments from the triplet
		a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
		b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
		c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
		d = chunk & 63 // 63       = 2^6 - 1

		// Convert the raw binary segments to the appropriate ASCII encoding
		base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
	}

	// Deal with the remaining bytes and padding
	if (byteRemainder === 1) {
		chunk = bytes[mainLength]

		a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

		// Set the 4 least significant bits to zero
		b = (chunk & 3) << 4 // 3   = 2^2 - 1

		base64 += encodings[a] + encodings[b] + "=="
	} else if (byteRemainder === 2) {
		chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

		a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
		b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4

		// Set the 2 least significant bits to zero
		c = (chunk & 15) << 2 // 15    = 2^4 - 1

		base64 += encodings[a] + encodings[b] + encodings[c] + "="
	}

	return base64
}
function b64ToUint6(char: number): number {
	return char > 64 && char < 91
		? char - 65
		: char > 96 && char < 123
		? char - 71
		: char > 47 && char < 58
		? char + 4
		: char === 43
		? 62
		: char === 47
		? 63
		: 0
}
// https://developer.mozilla.org/en-US/docs/Glossary/Base64
export function base64ToArrayBuffer(base64: string): ArrayBuffer {
	const sB64Enc = base64.replace(/[^A-Za-z0-9+/]/g, "")
	const nInLen = sB64Enc.length
	const nOutLen = (nInLen * 3 + 1) >> 2
	const taBytes = new Uint8Array(nOutLen)

	for (let nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
		nMod4 = nInIdx & 3
		nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (6 * (3 - nMod4))
		if (nMod4 === 3 || nInLen - nInIdx === 1) {
			for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
				taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255
			}
			nUint24 = 0
		}
	}

	return taBytes
}

export const setCurrentAttendance = (
	items: LaboratoryAttendance[],
	oldLaboratory: Laboratory | null | undefined,
	setLaboratory: Dispatch<SetStateAction<Laboratory>>,
	laboratories: Laboratory[],
	isInSignUp = false
): void => {
	if (!laboratories.length) {
		setLaboratory({} as Laboratory)

		// Don't show error toast during sign up
		if (!isInSignUp) {
			toast.error("Aucun laboratoire n'est associé à votre compte")
		}
		return
	}

	const now = dayjs()
	const todayDay = dayHashtable[now.day()]
	const todayAttendances = items.filter((f) => f.day === todayDay)

	const currentAttendance = todayAttendances.find((attendance) =>
		now.isBetween(dayjs(attendance.timeStart, "HH:mm"), dayjs(attendance.timeEnd, "HH:mm"), null, "[)")
	)

	const defaultLaboratory = (): void => {
		const previousLaboratoryIri = window.localStorage.getItem("currentLaboratoryIri")
		const previousLaboratory = laboratories.find((l) => l["@id"] === previousLaboratoryIri)

		if (previousLaboratory) {
			setLaboratory(previousLaboratory)
			toast.warn(
				`Aucune présence sur les laboratoires, vous êtes considéré par défaut sur ${previousLaboratory.label}`
			)
		}
	}

	if (currentAttendance == null) {
		defaultLaboratory()
		return
	}

	const currentLab = laboratories.find((f) => f.id === currentAttendance.laboratory!.id)
	if (!currentLab) {
		defaultLaboratory()
		return
	}

	if (oldLaboratory?.id === currentLab.id) return // Don't show toast if laboratory hasn't changed
	setLaboratory(currentLab)
	toast.success(`Vous êtes actuellement sur le laboratoire ${currentLab?.label}`)
}

/**
 * @returns Une couleur hashé depuis `str`.
 */
export const colorFromStr = (str: string): string => {
	let hash = 0
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}
	let color = "#"
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff
		color += ("00" + value.toString(16)).substr(-2)
	}
	return color
}

/**
 * Moyen pour changer la valeur d'un element React, pour pouvoir DispatchEvent dessus
 * https://github.com/facebook/react/issues/10135#issuecomment-314441175
 *
 */
export const setNativeValue = (element: Element, value: string): void => {
	const valueSetter = Object.getOwnPropertyDescriptor(element, "value")!.set
	const prototype = Object.getPrototypeOf(element)
	const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, "value")!.set

	if (valueSetter && valueSetter !== prototypeValueSetter) {
		prototypeValueSetter!.call(element, value)
	} else {
		valueSetter!.call(element, value)
	}
}

/**
 *	Recherche si `string` contient `searchQuery`.
 *  Les accents et la case sont ignorés.
 *
 * @example partialSearch("Espagne", "és") === true
 * @example partialSearch("Jean", "aN") === true
 * @example partialSearch("Louis", "oi") === false
 */
export function partialSearch(string: string, searchQuery: string): boolean {
	const insensitiveString = _.deburr(string).toLowerCase()
	const insensitiveSearchQuery = _.deburr(searchQuery).toLowerCase()

	return insensitiveString.includes(insensitiveSearchQuery)
}

/**
 * Clear Service Worker cache to force build update
 */
export async function clearServiceWorkerCache(): Promise<void> {
	if ("serviceWorker" in navigator) {
		const registrations = await navigator.serviceWorker.getRegistrations()
		for (const registration of registrations) {
			registration.unregister()
		}
	}
	await clearAllHiboutikCache()

	CacheSystem.clear()
	window.location.reload()
}

export function removeSpecialChars(str: string): string {
	if (!str) return ""
	return str
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/[^a-z0-9 :;.,()+]+/gi, "")
}

export const getIdFromIri = (iri: string | null | undefined): string | null => {
	if (iri == null) return null
	return /\/.*\/(\d+)/.exec(iri)?.[1] ?? null
}
