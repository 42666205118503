import axios from "axios"

let token = ""
let endPoint = ""

const init = (accountName, hiboutikToken) => {
	if (process.env.REACT_APP_DEV_HIBOUTIK?.toLowerCase() !== "true") {
		endPoint = accountName
		token = hiboutikToken
	} else {
		token = process.env.REACT_APP_HIBOUTIK_TOKEN
			? process.env.REACT_APP_HIBOUTIK_TOKEN
			: "bWF0aGlldS5jaGFtbWFoQGdtYWlsLmNvbTpITjZDM05XRVNWNVVPRUk5S05OM1U1TUZMSFpQSUJOUVBLVA=="
		endPoint = process.env.REACT_APP_HIBOUTIK_ENDPOINT ? process.env.REACT_APP_HIBOUTIK_ENDPOINT : "audiowizard"
	}
}

const HIBOUTIK_API = "https://" + endPoint + ".hiboutik.com/api/"

const hiboutik = {
	ALL_PRODUCTS: HIBOUTIK_API + "products/search/",
	ALL_BRANDS: HIBOUTIK_API + "brands/",
	STOCK_AVAILABLE: HIBOUTIK_API + "stock_available/",
}

if (!token) token = window.localStorage.getItem("aGlib3V0b2tlbg")
if (!endPoint) endPoint = window.localStorage.getItem("aGlib3V0aWtfYWNjb3VudG5hbWU")

function getUrl(target) {
	const url = "https://" + endPoint + ".hiboutik.com/api/"

	const targets = {
		ALL_PRODUCTS: url + "products/search/",
		ALL_BRANDS: url + "brands/",
		STOCK_AVAILABLE: url + "stock_available/",
	}

	return targets[target]
}

const customRequest = async (url, method, data = {}) => {
	const config = {
		method: method,
		headers: {
			Authorization: "Basic " + token,
		},
	}
	return new Promise(async (resolve, reject) => {
		axios(url, config, data)
			.then(async (response) => {
				if (response.data) resolve(response.data)
				resolve(response)
			})
			.catch((e) => {
				reject(e)
			})
	})
}

const get = async (target, page, params = {}) => {
	page = parseInt(page)
	if (isNaN(page) || page < 0) {
		console.error(`[API Hiboutik] [get] [${target}] Page is not a number or is invalid`)
		return []
	}
	let url_params = Object.keys(params)
		.map((key) => `${key}=${params[key]}`)
		.join("&")
	let url = getUrl(target) + `?p=${page}` + (url_params.length > 1 ? `&${url_params}` : "")

	return customRequest(url, "GET")
}

const getAll = async (target = "CUSTOM", params = {}, url = "") => {
	let page = 1
	const results = []
	let result = []

	if (target !== "CUSTOM" && !hiboutik.hasOwnProperty(target)) return

	while (1) {
		try {
			if (target === "CUSTOM") result = await customRequest(url, "GET")
			else result = await get(target, page, params)

			const len = result.length
			if (len) results.push(...result)
			if (len === 250) page++
			if (!len || len < 250) break
		} catch (e) {
			console.error(e)
			break
		}
	}
	return results
}

const getAllProducts = async () => {
	const products = await getAll("ALL_PRODUCTS", { product_arch: 0 })
	return products
}

const getAllBrands = async () => {
	const brands = await getAll("ALL_BRANDS", { product_arch: 0 })
	return brands
}

const getStock = async (type = "ALL", product_id = null, size_id = null, page = null) => {
	let url = getUrl("STOCK_AVAILABLE")

	if (type === "ALL") url += "all_wh"
	else if (type === "PRODUCT" && product_id != null) url += `product_id/${product_id}`
	else if (type === "PRODUCT_SIZE" && product_id != null && size_id != null)
		url += `product_id_size/${product_id}/${size_id}`
	else {
		console.error(`[API Hiboutik] [getStock] [${type}] Invalid parameters`)
		return []
	}

	if (type === "ALL" && page == null) return await customRequest(url, "GET")
	if (parseInt(page) >= 1) url += `?p=${page}`
	return customRequest(url, "GET")
}

const ApiHiboutikRework = {
	init,
	customRequest,
	get,
	getAll,
	getAllProducts,
	getAllBrands,
	getStock,
}

export default ApiHiboutikRework
