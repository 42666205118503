/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { withRouter } from "react-router"
import AuthContext from "../../../contexts/AuthContext"
import ActionRelance from "../../Schedules/Relances/ActionRelance"
import RelanceModal from "../../Schedules/Relances/RelanceModal"
import AgendaModal from "../../Schedules/Agenda/ModalAgendaContainer"
import { updateSchedule } from "../../Schedules/saveEvent"
import API from "../../../services/API"
import ModalScheduling from "../../Schedules/SchedulingModal/Modal.Scheduling"
import { convertPhoneNumber } from "services/functions"
import { toast } from "react-toastify"

function FinEvenementModalRework({ history, hours = 0 }) {
	const ctx = useContext(AuthContext)
	const [isButtonDisabled, setButtonDisabled] = useState({
		nouveau: false,
		relance: false,
	})

	const [newSchedule, setNewSchedule] = useState({
		reason: null,
		status: null,
		dateOf: null,
	})

	const selectedSchedule = {
		status: ctx?.schedule?.status || null,
		id: ctx?.schedule?.id || null,
	}

	const [openAgendaModal, setAgendaModal] = useState(false)
	const [openNewScheduleSelector, setOpenNewScheduleSelector] = useState(false)
	const [hasSaved, setSaved] = useState(false)
	const [newScheduleSaved, setNewScheduleSaved] = useState({ success: false })
	const [oldSave, setOldSave] = useState(false)
	const [relanceSaved, setRelanceSaved] = useState(false)

	useEffect(() => {
		if (selectedSchedule?.status === "RELANCE")
			setButtonDisabled({
				nouveau: true,
				relance: true,
			})
		else
			setButtonDisabled({
				nouveau: false,
				relance: false,
			})
	}, [])

	function createPatient() {
		return new Promise(async (resolve, reject) => {
			try {
				if (ctx.laboratory)
					Object.assign(ctx.patient, {
						laboratory: ctx.laboratory["@id"],
						lastScheduleStatus: "PREMIERE_VISITE",
					})
				if (ctx.patient.idSecu === "") ctx.patient.idSecu = null

				ctx.patient.phone = convertPhoneNumber(ctx.patient.phone)
				ctx.patient.phoneMobile = convertPhoneNumber(ctx.patient.phoneMobile)

				const result = await API.create("PATIENTS_API", { ...ctx.patient, attendants: [] })
				ctx.setPatientGenes([])
				ctx.setPatientOrigins([])
				ctx.setPatientMedicals([])
				ctx.setPatientLookingFors([])
				ctx.setPatientAcouphenes([])

				ctx.setPatient({
					...result.data,
					lastScheduleStatus: "PREMIERE_VISITE",
				})

				resolve({
					...result.data,
					lastScheduleStatus: "PREMIERE_VISITE",
				})
			} catch (e) {
				toast.error("Création du patient échoué")
				console.error(e)
				reject(e)
			}
		})
	}

	useEffect(() => {
		;(async function () {
			if (selectedSchedule?.alreadySaved) return
			try {
				if (selectedSchedule.status !== "RELANCE") {
					if (!ctx.patient.createdAt) {
						await createPatient()
					}

					await updateSchedule(ctx, hours, null)
					setOldSave(true)
				}
			} catch (e) {}
		})()
	}, [])

	useEffect(() => {
		;(async function () {
			if (selectedSchedule.status === "RELANCE" && relanceSaved) {
				await updateSchedule(ctx, hours, null)
				setOldSave(true)
			}
		})()
	}, [relanceSaved])

	const redirect = {
		APPAREILLE: () => history.push("/mes-patients/appareille"),
		ESSAI: () => history.push("/mes-patients/essai"),
		VISITE: () => history.push("/mes-patients/"),
		SUITE_VISITE: () => history.push("/mes-patients/suite_visite"),
		ADAPTATION: () => history.push("/premiere-visite/commande-appareils"),
		PREMIERE_VISITE: () => history.push(`/fiche-patient/${ctx.patient.id}`),
		RELANCE: () => history.push("/mes-patients/relance"),
	}

	useEffect(() => {
		if (newScheduleSaved.success) {
			ctx.setSchedule(null)
			if (redirect[newScheduleSaved?.status]) redirect[newScheduleSaved.status]()
			else history.push(`/fiche-patient/${ctx.patient.id}`)
		}
	}, [hasSaved, newScheduleSaved])

	return (
		<div className="finevt-modal">
			<div className="finevt-content">
				{openAgendaModal && (
					<AgendaModal
						setShow={() => setAgendaModal(false)}
						fromAgenda={false}
						onlyAvailable={false}
						fromNewSchedule={true}
					/>
				)}

				{openNewScheduleSelector && (
					<ModalScheduling
						fromNewSchedule={true}
						newScheduleSaved={setNewScheduleSaved}
						setShowModal={() => setOpenNewScheduleSelector(false)}
						saveOk={setSaved}
					/>
				)}
				{newSchedule.status === "RELANCE" && (
					<RelanceModal newSchedule={newSchedule} setNewSchedule={setNewSchedule} />
				)}
				{selectedSchedule.status === "RELANCE" && (
					<ActionRelance
						patient={ctx.patient}
						schedule={ctx.schedule}
						setRelanceSaved={setRelanceSaved}
						setButtonDisabled={setButtonDisabled}
					/>
				)}
				<br />
				<button
					type="button"
					className="btn btn-block btn-primary"
					disabled={
						!oldSave ||
						isButtonDisabled.nouveau ||
						(selectedSchedule?.status === "RELANCE" && !relanceSaved)
					}
					onClick={() => {
						setOpenNewScheduleSelector(true)
					}}>
					<i className="float-left fad fa-plus" />
					Planifier un nouveau rendez-vous
				</button>

				<button
					type="button"
					className="btn btn-block btn-info"
					onClick={() => {
						if (newSchedule.status === "RELANCE") setNewSchedule({})
						else setNewSchedule((old) => ({ ...old, status: "RELANCE" }))
					}}
					disabled={
						!oldSave ||
						isButtonDisabled.relance ||
						(selectedSchedule?.status === "RELANCE" && !relanceSaved)
					}>
					<i className="float-left fad fa-clock" />
					Mettre le patient en relance
				</button>
				<button
					disabled={!oldSave}
					type="button"
					className="btn btn-block btn-outline-danger mt-5"
					onClick={() => {
						if (ctx?.patient?.id && window?.location?.href?.includes(`/fiche-patient/${ctx.patient.id}`))
							history.push("/")
						else if (ctx?.patient?.id) history.push(`/fiche-patient/${ctx.patient.id}`)
						else history.push("/")
					}}>
					<i className="float-left fad fa-times-circle" />
					Ne rien faire
				</button>
			</div>
		</div>
	)
}

export default withRouter(FinEvenementModalRework)
