/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext } from "react"
import Agenda from "./Agenda"
import AuthContext from "../../../contexts/AuthContext"

export default function AgendaModal({
	setShow,
	onlyAvailable,
	fromNewSchedule,
	selectedDate,
	setSelectedLaboratory,
	setSelectedUser,
	defaultStartDate,
	fromAgenda,
	appointmentFromScheduleSelector,
	currentLaboratory,
	currentUser,
}) {
	const [fullscreen, setFullscreen] = useState(true)
	const { laboratory, user } = useContext(AuthContext)

	return (
		<div className="modal-agenda">
			<div className={"container" + (fullscreen ? " full-screen" : "")}>
				<div className="title ">
					Agenda
					<span className="close-btn" onClick={() => setShow()}>
						fermer
					</span>
					<span className="full-btn" onClick={() => setFullscreen(fullscreen ? false : true)}>
						{fullscreen ? "réduire" : "agrandir"}
					</span>
				</div>
				<div className="content">
					<Agenda
						currentLaboratory={currentLaboratory || laboratory.id}
						currentUser={currentUser || user.id}
						setShow={setShow}
						selectedDate={selectedDate}
						onlyAvailable={onlyAvailable}
						fromNewSchedule={fromNewSchedule}
						defaultStartDate={defaultStartDate}
						fromAgenda={fromAgenda}
						appointmentFromScheduleSelector={appointmentFromScheduleSelector}
						setSelectedLaboratory={setSelectedLaboratory}
						setSelectedUser={setSelectedUser}
					/>
				</div>
			</div>
		</div>
	)
}

AgendaModal.defaultProps = {
	onlyAvailable: false,
	fromAgenda: false,
}
