const ViewSettings = ({ agendaFilter, setAgendaFilter }) => {
	return (
		<>
			<div className="calendar-filter">
				<h5>Filtres</h5>

				<div className="filter">
					<div className="filter-switch">
						<input
							type="checkbox"
							checked={agendaFilter?.missed}
							onChange={() => setAgendaFilter((old) => ({ ...old, missed: !old.missed }))}
						/>
					</div>
					<div className="filter-color missed" />
					<div className="filter-label">Manqué</div>
				</div>

				<div className="filter">
					<div className="filter-switch">
						<input
							type="checkbox"
							checked={agendaFilter?.cancelled}
							onChange={() => setAgendaFilter((old) => ({ ...old, cancelled: !old.cancelled }))}
						/>
					</div>
					<div className="filter-color cancelled" />
					<div className="filter-label">Annulé</div>
				</div>

				<div className="filter">
					<div className="filter-switch">
						<input
							type="checkbox"
							checked={agendaFilter?.done}
							onChange={() => setAgendaFilter((old) => ({ ...old, done: !old.done }))}
						/>
					</div>
					<div className="filter-color done" />
					<div className="filter-label">Terminé</div>
				</div>

				<div className="filter">
					<div className="filter-switch">
						<input
							type="checkbox"
							checked={agendaFilter?.ferie}
							onChange={() => setAgendaFilter((old) => ({ ...old, ferie: !old.ferie }))}
						/>
					</div>
					<div className="filter-color bank-day" />
					<div className="filter-label">Jour férié</div>
				</div>
			</div>
		</>
	)
}

export default ViewSettings
