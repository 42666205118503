import { SearchOutlined } from "@ant-design/icons"
import { EquipmentHistory, HiboutikProductEntity, Patient, PatientEquipment } from "@audiowizard/common"
import AuthContext from "contexts/AuthContext"
import { AvailableSerialNumberType, extractAvailableSerialnumber } from "pages/Stock-Management/StockUtils"
import { useContext, useEffect, useRef, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import API from "services/API"
import { removeSpecialChars } from "services/functions"
import { PatientResults } from "./PatientResults"
import { ProductsResults } from "./ProductsResults"
import { SerialNumberResults } from "./SerialNumberResults"
import "./Spotlight.scss"

type SearchResultsType = {
	[key: string]: any
	patients?: Patient[]
	serialNumbers?: any[]
	products?: any[]
}

const CategoryName: { [key: string]: any } = {
	patients: "Patients",
	serialNumbers: "Numéros de série",
	products: "Produits",
}

const DisplayResult = ({ category, result }: { category: string; result: any }): JSX.Element => {
	if (category === "patients") return <PatientResults patient={result} />
	else if (category === "serialNumbers") return <SerialNumberResults product={result} />
	else if (category === "products") return <ProductsResults product={result} />
	else return <></>
}
/**
 * A REFAIRE AU NIVEAU DU BACKEND
 */

const Spotlight = (): JSX.Element => {
	const [searchValue, setSearchValue] = useState<string>("")
	const [querySearch, setQuerySearch] = useState<string>("")
	const timeoutSearch = useRef<NodeJS.Timeout | null>(null)
	const checkFocusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
	const resultDivRef = useRef<HTMLDivElement>(null)
	const inputSearchRef = useRef<HTMLInputElement>(null)
	const queryClient = useQueryClient()
	const { laboratory } = useContext(AuthContext)

	const searchPatients = async (search: string): Promise<Patient[]> => {
		const searchParams = []

		for (const word of search.split(/\s+/)) {
			searchParams.push(word)
		}

		return await API.findAll<Patient[]>(
			"PATIENTS_API",
			`?omnisearch[]=${searchParams.join("&omnisearch[]=")}&itemsPerPage=15`
		)
	}

	const searchPatientEquipment = async (search: string): Promise<PatientEquipment[]> => {
		return await API.findAll<PatientEquipment[]>("PATIENT_EQUIPMENTS_API", `?serialNumber=${search}`)
	}

	const searchEquipmentHistory = async (search: string): Promise<any[]> => {
		return (
			await API.findAll<EquipmentHistory[]>(
				"EQUIPMENTS_HISTORY",
				`?serialNumber=${search}&itemsPerPage=30&order[createdAt]=desc`
			)
		).filter((history: any) => history.newSerialNumber && history.patientEquipment)
	}

	const { data: productCatalog, isLoading: catalogLoading } = useQuery(
		"SPOTLIGHT_PRODUCT_CATALOG",
		async () => {
			return await API.findAll<HiboutikProductEntity[]>("PRODUCTS_API", "?pagination=false")
		},
		{
			cacheTime: 160,
		}
	)

	const { data: availableSerialNumber, isLoading: serialNumberLoading } = useQuery(
		["SPOTLIGHT_AVAILABLE_SERIALNUMBER", laboratory?.warehouseIdHiboutik],
		async () => {
			const stockAvailable = await API.findAll<HiboutikProductEntity[]>(
				"STOCK_AVAILABLE_API",
				`?warehouseId=${laboratory?.warehouseIdHiboutik ?? 1}`
			)
			return extractAvailableSerialnumber(stockAvailable) as AvailableSerialNumberType[]
		}
	)

	// TODO: A typer après release (remplacer les any)
	const getProductDetailFromCatalog = (
		catalog: HiboutikProductEntity[],
		producId: number | undefined,
		sizeId: null | number | undefined
	): Record<string, any> => {
		if (!producId) {
			return {}
		}
		const productDetail = catalog.find((catalog) => +catalog.id === +producId)
		let sizeDetail = {}
		if (sizeId && sizeId !== 0) {
			//@ts-ignore Temporarily ignore
			sizeDetail = productDetail?.sizeDetails?.find((size: any) => +size.sizeId === +sizeId)
		}
		return { ...productDetail, ...sizeDetail }
	}

	const getSerialNumbers = (
		productCatalog: HiboutikProductEntity[],
		serialNumbers: AvailableSerialNumberType[],
		patientEquipment: PatientEquipment[],
		equipmentHistory: EquipmentHistory[]
	): any[] => {
		let resultSerialNumbers: any[] = []

		if (serialNumbers.length) {
			resultSerialNumbers = [
				...resultSerialNumbers,
				...serialNumbers.map((sn: any) => ({ ...sn, type: "available" })),
			]
		}

		if (equipmentHistory.length) {
			const history = equipmentHistory[0]
			resultSerialNumbers.push({
				type: "history",
				oldSerialNumber: history.serialNumber,
				serialNumber: history.patientEquipment?.serialNumber,
				patientEquipment: history.patientEquipment,
				...getProductDetailFromCatalog(
					productCatalog,
					history.patientEquipment?.productIdHiboutik,
					history.patientEquipment?.sizeIdHiboutik
				),
			})
		}

		if (patientEquipment.length) {
			const result = patientEquipment
				.filter((equipment: PatientEquipment) => {
					return !resultSerialNumbers.some((result) => result?.patientEquipment?.["@id"] === equipment["@id"])
				})
				.map((equipment: PatientEquipment) => ({
					type: "patientEquipment",
					...getProductDetailFromCatalog(
						productCatalog,
						equipment?.productIdHiboutik,
						equipment?.sizeIdHiboutik
					),
					patientEquipment: equipment,
				}))
			resultSerialNumbers = [...resultSerialNumbers, ...result]
		}

		return resultSerialNumbers
	}

	const compareValue = (stra?: string, strb?: string): boolean => {
		if (!stra && !strb) return true
		if (!stra || !strb) return false
		return removeSpecialChars(stra).toLowerCase().includes(removeSpecialChars(strb).toLowerCase())
	}

	const { data: searchResults, isLoading: searchLoading } = useQuery(
		["SPOTLIGHT_SEARCH", querySearch],
		async (): Promise<SearchResultsType> => {
			if (!searchValue.length) return {}

			const patients = await searchPatients(querySearch)

			const patientEquipment = await searchPatientEquipment(querySearch)
			const equipmentHistory = await searchEquipmentHistory(querySearch)

			const serialNumbersAvailable = availableSerialNumber
				?.filter((product) => compareValue(product.serialNumber, querySearch))
				.slice(0, 5)

			const products = productCatalog
				?.filter((product: any) => compareValue(product.model, querySearch))
				.slice(0, 5)

			const serialNumbers = getSerialNumbers(
				productCatalog ?? [],
				serialNumbersAvailable ?? [],
				patientEquipment,
				equipmentHistory
			)

			return { patients, serialNumbers, products }
		}
	)

	useEffect(() => {
		clearTimeout(timeoutSearch.current as NodeJS.Timeout)
		timeoutSearch.current = setTimeout(() => {
			setQuerySearch(searchValue)
		}, 200)
	}, [searchValue])

	useEffect(() => {
		checkFocusTimeoutRef.current = setInterval(() => {
			if (inputSearchRef.current === document.activeElement) return
			// @ts-ignore
			resultDivRef.current!.style.visibility = "hidden"
			// setSearchValue("")
		}, 400)

		return () => {
			clearInterval(checkFocusTimeoutRef.current as NodeJS.Timeout)
		}
	}, [])

	const isSearchEmpty = Object.values(searchResults ?? {}).every((category) => !category.length)

	return (
		<div className="spotlight">
			<div className="spotlight-input-group">
				<SearchOutlined className="prepend" />
				<input
					ref={inputSearchRef}
					disabled={catalogLoading || serialNumberLoading}
					id="spotlightFocusSearch"
					type="text"
					placeholder="Rechercher patient, produit, numéro de série..."
					value={searchValue}
					onChange={(event) => {
						setSearchValue(event.target.value ?? "")
					}}
					onFocus={(event) => {
						//@ts-ignore
						resultDivRef.current!.style.visibility = "visible"
						queryClient.invalidateQueries("SPOTLIGHT_AVAILABLE_SERIALNUMBER")
						event.target.select()
					}}
				/>
			</div>

			<div className="result" ref={resultDivRef}>
				{searchLoading ? "Recherche..." : ""}

				{isSearchEmpty && !searchLoading && <>Aucun résultat</>}

				{Object.keys(searchResults ?? {})
					.filter((cat) => searchResults![cat].length)
					.map((cat: string, keyCat: number) => {
						return (
							<div className="" key={keyCat}>
								<div className="category-name">{CategoryName[cat] ?? cat}</div>
								{searchResults![cat].map((result: any, resultKey: number) => (
									<DisplayResult key={resultKey} category={cat} result={result} />
								))}
							</div>
						)
					})}
			</div>
		</div>
	)
}

export default Spotlight
