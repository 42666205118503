import { Select } from "antd"
import useEffectAsync from "components/Hooks/useEffectAsync"
import { useState, Dispatch, SetStateAction } from "react"
import API_Hiboutik from "services/API_Hiboutik"

type AsyncSizeSelectProps = {
	setSelectedSize: Dispatch<SetStateAction<any>>
	disabled: boolean
	productSizeType: number | null
}

export const AsyncSizeSelect = ({ productSizeType, setSelectedSize, disabled }: AsyncSizeSelectProps): JSX.Element => {
	const [searchValue, setSearchValue] = useState<string>("")
	const [searchResult, setSearchResult] = useState<Record<string, any>[]>([])

	const [loading, setLoading] = useState<boolean>(false)

	useEffectAsync(async () => {
		if (!productSizeType || disabled) return
		try {
			setLoading(true)
			const sizes = (await API_Hiboutik.getAllSizes())?.data ?? []

			const sizeDetail = sizes[productSizeType]

			setSearchResult(Object.values(sizeDetail?.sizes))
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}, [productSizeType])

	const handleSelected = async (value: number, option: any): Promise<void> => {
		try {
			const size = searchResult.find((size) => +size.size_id === +value)
			if (!size) return
			setSelectedSize(size)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div>
			<Select
				disabled={disabled || loading || !searchResult.length}
				showSearch
				allowClear
				placeholder={"Déclinaison..."}
				className="w-100"
				size="large"
				filterOption={true}
				optionFilterProp="label"
				loading={loading}
				options={searchResult.map((m) => ({ label: m.size_name, value: m.size_id }))}
				onSearch={setSearchValue}
				searchValue={searchValue ?? ""}
				onChange={handleSelected}
			/>
		</div>
	)
}
