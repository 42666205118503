/* eslint-disable react-hooks/exhaustive-deps */
import DocumentationModal from "components/effects/DocumentationModal"
import OmniSearchDocumentation from "components/effects/OmnisearchDocumentation"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import * as React from "react"
import { withRouter } from "react-router-dom"
import { DocumentationModalData } from "./DocumentationModalData"

function SearchDocumentation() {
	const [documentationPageId, setDocumentationPageId] = useState(null)
	// used to reopen the same modal data given that the id do not change after the first activation
	const [activateSamePage, setActivateSamePage] = useState(false)
	const [showDocumentationModal, setShowDocumentationModal] = React.useState(false)

	useEffect(() => {
		if (documentationPageId) setShowDocumentationModal(true)
		setActivateSamePage(false)
	}, [documentationPageId, activateSamePage])

	function handleClose() {
		setShowDocumentationModal(false)
	}

	return (
		<>
			<OmniSearchDocumentation
				arrowNav={true}
				id="documentationSearch"
				setDocumentaionPageId={setDocumentationPageId}
				setActivateSamePage={setActivateSamePage}
				documentationPageId={documentationPageId}
			/>
			{showDocumentationModal && (
				<DocumentationModal
					title="Informations"
					icon="info-circle"
					isOpen={showDocumentationModal}
					onClose={handleClose}>
					<DocumentationModalData id={documentationPageId} />
				</DocumentationModal>
			)}
		</>
	)
}

SearchDocumentation.propTypes = {
	id: PropTypes.string,
	placeholder: PropTypes.string,
}

export default withRouter(SearchDocumentation)
