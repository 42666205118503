import { freeAccess, ServiceRef } from "components/hoc/withRequiredServices"
import AuthContext from "contexts/AuthContext"
import { useContext } from "react"

/**
 * @returns Si l'utilisateur a tous les requiredServices actif.
 *
 * @example const hasBasicService = useHasServices(ServiceRef.Basic)
 * @example const hasTeletransmissionService = useHasServices(ServiceRef.Teletransmission)
 * @example const hasBasicAndTeletransmissionServices = useHasServices(ServiceRef.Basic, ServiceRef.Teletransmission)
 */
export default function useHasServices(...requiredServices: ServiceRef[]): boolean {
	const { services } = useContext(AuthContext)

	return requiredServices.every((r) => services.find((s) => s.ref === r)?.isActive || freeAccess.includes(r))
}
