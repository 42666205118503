import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"
import { LogisticProduct, ProductReturnForm } from "@audiowizard/common"
import { Table } from "antd"
import useEffectAsync from "components/Hooks/useEffectAsync"
import useCustomTitle from "components/Hooks/useTitle"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { useContext, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import uuid from "react-uuid"
import API from "services/API"
import { formatDatetimeForDB, getIdFromIri } from "services/functions"
import { extractAvailableSerialnumber } from "../StockUtils"
import Filters from "./Filters"

const DepotList = (): JSX.Element => {
	useCustomTitle("Stock | Liste des dépôts")

	const { laboratory } = useContext(AuthContext)
	const history = useHistory()

	const timeoutFilters = useRef<NodeJS.Timeout | null>(null)

	const [isBusy, setIsBusy] = useState(false)

	const [logisticProcucts, setLogisticProducts] = useState<any[]>([])
	const [pagination, setPagination] = useState<Record<string, any>>({ totalItems: 0, page: 1 })

	const [needRefresh, setNeedRefresh] = useState<boolean>(true)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [filters, setFilters] = useState<Record<string, any>>({
		warehouseId: laboratory.warehouseIdHiboutik,
		onlyAvailable: true,
		onlyDeposit: true,
	})

	const { data: availableStock } = useQuery(
		["AVAILABLE_STOCK", filters.warehouseId],
		async (): Promise<any[]> => {
			return await API.findAll("STOCK_AVAILABLE_API", `?warehouseId=${filters.warehouseId}`)
		},
		{
			cacheTime: 0,
		}
	)

	const { data: productCatalog } = useQuery(["PRODUCT_CATALOG"], async (): Promise<any[]> => {
		return await API.findAll("PRODUCTS_API", "?pagination=false")
	})

	const getLogisticProducts = async (): Promise<any[]> => {
		let filter = ""

		if (filters.serialNumber) {
			filter += `&productSerialNumber=${filters.serialNumber}`
		}

		if (filters.onlyMissed) {
			filter += `&depotDateEnd[before]=${formatDatetimeForDB(dayjs())}`
		} else if (filters.onlyDeposit) {
			filter += "&isDepot=1&isReturned=0"
		} else {
			filter += "&isDepot=0"
		}

		const result = await API.findAll(
			"LOGISTIC_PRODUCTS_API",
			`?logistic.stockIdHiboutik=${filters.warehouseId}${filter}&page=${pagination.page}`,
			true
		)

		setPagination((old) => ({ ...old, totalItems: result["hydra:totalItems"] }))
		return result["hydra:member"]
	}

	useEffectAsync(async () => {
		if (!productCatalog?.length || !availableStock?.length || !needRefresh) return

		setNeedRefresh(false)
		try {
			setIsLoading(true)
			let products = await getLogisticProducts()
			const availableSerialNumber = extractAvailableSerialnumber(availableStock ?? [])

			const isProductAvailable = (product: LogisticProduct): boolean => {
				const available = availableSerialNumber.find((sn) => sn.logisticProduct?.["@id"] === product["@id"])

				if (!available) {
					const correspondingSn = availableSerialNumber.find(
						(sn) =>
							sn.serialNumber === product.productSerialNumber &&
							+sn.id === +product.productId! &&
							(sn.sizeId ? sn.sizeId === product.productSizeId : true)
					)
					return correspondingSn ? true : false
				}
				return available ? true : false
			}

			products = products.map((product, i) => {
				const details = productCatalog.find((catalog) => +catalog.id === +product.productId)
				const sizeName = details?.sizeDetails?.find(
					(size: any) => +size.sizeId === product.productSizeId
				)?.sizeName
				const stockAvailable = isProductAvailable(product)

				return {
					...details,
					sizeName,
					serialNumber: product.productSerialNumber,
					logisticProduct: product,
					fromLogisticProduct: true,
					stockAvailable,
					key: uuid(),
				}
			})

			if (filters.onlyAvailable) {
				products = products.filter((product) => product.stockAvailable)
			}

			setPagination((old) => ({ page: 1, totalItems: products.length }))
			setLogisticProducts(products)
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading(false)
		}
	}, [productCatalog, availableStock, needRefresh])

	useEffect(() => {
		clearTimeout(timeoutFilters.current as NodeJS.Timeout)
		timeoutFilters.current = setTimeout(() => {
			setNeedRefresh(true)
			setPagination((old) => ({ ...old, page: 1 }))
		}, 350)
	}, [filters])

	const columns = [
		{
			title: "Bon de livraison",
			dataIndex: "logisticProduct",
			render: (lp: LogisticProduct) => (
				<Link
					to={"/bon-de-livraison/" + getIdFromIri(lp?.logistic?.["@id"])}
					style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
					{
						//@ts-ignore
						lp?.logistic?.label
					}
				</Link>
			),
		},
		{
			title: "Bon de retour",
			dataIndex: "logisticProduct",

			render: (lp: LogisticProduct) => {
				if (lp?.productReturnForm) {
					return (
						<Link
							//@ts-ignore
							to={"/mon-stock/retour/" + getIdFromIri(lp?.productReturnForm?.["@id"])}
							style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
							{
								//@ts-ignore
								lp?.productReturnForm?.label
							}
						</Link>
					)
				} else {
					return "Aucun"
				}
			},
		},
		{
			title: "Modèle",
			dataIndex: "model",
			render: (model: string, record: Record<string, any>) => (
				<>
					<div className="font-weight-bold">{model}</div>
					<div style={{ opacity: ".7" }}>{record.sizeName ?? "Pas de déclinaison"}</div>
				</>
			),
		},
		{
			title: "Numéro de série",
			dataIndex: "serialNumber",
		},

		{
			title: "Appareillé",
			dataIndex: "logisticProduct",
			render: (logisticProduct: LogisticProduct) => {
				const patientEquipment = logisticProduct?.patientEquipment ?? []

				if (patientEquipment?.length > 1) {
					return "Sur plusieurs patients"
				} else if (patientEquipment?.length === 1) {
					return (
						<>
							En {patientEquipment[0].status!.toLowerCase()} sur
							<br />
							{patientEquipment[0].patient?.firstName} {patientEquipment[0].patient?.lastName}
						</>
					)
				} else {
					return "-"
				}
			},
		},
		{
			title: "Date de fin de dépôt",
			dataIndex: "logisticProduct",
			render: (lp: LogisticProduct) => {
				if (!lp?.depotDateEnd) return "-"

				if (dayjs(lp?.depotDateEnd).isBefore(dayjs())) {
					return <span className="text-danger">{dayjs(lp?.depotDateEnd).format("DD/MM/YYYY")}</span>
				}
				return dayjs(lp?.depotDateEnd).format("DD/MM/YYYY")
			},
			className: "col-2",
		},
		{
			title: "Disponible",
			dataIndex: "stockAvailable",
			align: "center",
			render: (stockAvailable: boolean) => {
				return stockAvailable ? (
					<CheckCircleFilled style={{ fontSize: "22px", color: "var(--primary)" }} />
				) : (
					<CloseCircleFilled style={{ fontSize: "22px", color: "var(--danger-light)" }} />
				)
			},
		},
		{
			title: " ",
			className: "text-center",
			dataIndex: "logisticProduct",
			render: (logisticProduct: any, record: Record<string, any>) => {
				if (!logisticProduct.depotDateEnd) return <></>
				return (
					<input
						type="checkbox"
						style={{ width: "22px", height: "22px" }}
						className="cursor-pointer"
						checked={record?.isChecked ?? false}
						value={record?.isChecked ?? false}
						disabled={logisticProduct?.productReturnForm && record.isDepot}
						onChange={(evt) => {
							const value = evt.target.checked
							const index = logisticProcucts.findIndex((p) => p.key === record.key)
							const tmp = [...logisticProcucts]
							tmp[index].isChecked = value
							setLogisticProducts(tmp)
						}}
					/>
				)
			},
		},
	]

	const checkedProduct = logisticProcucts.filter((p) => p.isChecked)
	const uncheckEverything = (): void => {
		setLogisticProducts((old) => old.map((product) => ({ ...product, isChecked: false })))
	}

	const handleReturn = async (): Promise<void> => {
		try {
			setIsBusy(true)
			const createdReturnForm = await API.create<ProductReturnForm>("PRODUCT_RETURN_FORM_API", {
				label: "Retour automatique depuis la page dépôt",
			})

			for (const product of checkedProduct) {
				await API.updateWithIri(product.logisticProduct["@id"], {
					productReturnForm: createdReturnForm?.data["@id"],
				})
			}
			history.push(`/mon-stock/retour/${createdReturnForm.data?.id}`)
			uncheckEverything()
		} catch (error) {
			console.error(error)
		} finally {
			setNeedRefresh(true)
			setIsBusy(false)
		}
	}

	const handleRemoveDeposit = async (): Promise<void> => {
		try {
			setIsBusy(true)
			for (const product of checkedProduct) {
				if (product.logisticProduct.isDepot) {
					await API.updateWithIri(product.logisticProduct["@id"], { isDepot: false })
				}
			}
			uncheckEverything()
		} catch (error) {
			console.error(error)
		} finally {
			setNeedRefresh(true)
			setIsBusy(false)
		}
	}

	const handleRemoveFerme = async (): Promise<void> => {
		try {
			setIsBusy(true)
			for (const product of checkedProduct) {
				if (!product.logisticProduct.isDepot && product.logisticProduct.depotDateEnd) {
					await API.updateWithIri(product.logisticProduct["@id"], { isDepot: true })
				}
			}
			uncheckEverything()
		} catch (error) {
			console.error(error)
		} finally {
			setNeedRefresh(true)
			setIsBusy(false)
		}
	}

	return (
		<>
			<div className="cardtabs-subtitle">Filtres</div>
			<Filters filters={filters} setFilters={setFilters} />
			<div className="cardtabs-subtitle mt-4">Appareils en dépôts</div>
			<Table
				//@ts-ignore
				columns={columns}
				dataSource={logisticProcucts}
				loading={isLoading}
				pagination={{
					total: pagination.totalItems,
					pageSize: 10,
					onChange: (page: number) => {
						setPagination((old) => ({ ...old, page }))
						setNeedRefresh(true)
					},
				}}
			/>
			<div className="btn-group float-right">
				{filters.onlyDeposit && (
					<>
						<button
							onClick={handleRemoveDeposit}
							disabled={isBusy || !checkedProduct?.length}
							type="button"
							className={
								"btn btn-outline btn-sm " + (!!checkedProduct?.length && " btn-outline-primary")
							}>
							Passer les appareils sélectionnés en ferme
						</button>

						<button
							onClick={handleReturn}
							disabled={
								isBusy ||
								!checkedProduct?.length ||
								checkedProduct.some((product) => product?.logisticProduct?.productReturnForm) ||
								checkedProduct.some((product) => product?.logisticProduct?.patientEquipment?.length)
							}
							type="button"
							className={
								"btn btn-outline btn-sm " + (!!checkedProduct?.length && " btn-outline-warning")
							}>
							Créer un retour
						</button>
					</>
				)}

				{!filters.onlyDeposit && (
					<button
						onClick={handleRemoveFerme}
						disabled={
							isBusy ||
							!checkedProduct?.length ||
							checkedProduct.some((product) => product?.logisticProduct?.productReturnForm)
						}
						type="button"
						className={"btn btn-outline btn-sm " + (!!checkedProduct?.length && " btn-outline-warning")}>
						Remettre en dépôt
					</button>
				)}
			</div>
		</>
	)
}

export default DepotList
