import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const ScheduleStartModal = ({ confirmModal, setConfirmModal, setUiDisplay }) => {
	return (
		<Modal isOpen={confirmModal?.show} backdrop="static" zIndex="2000">
			<ModalHeader>{confirmModal?.title}</ModalHeader>
			<ModalBody>{confirmModal?.content}</ModalBody>
			<ModalFooter>
				<Button
					color="secondary"
					onClick={() => {
						setConfirmModal((o) => ({ ...o, result: false, show: false }))
						if (typeof confirmModal?.fctCancel === "function") confirmModal.fctCancel()
					}}>
					{confirmModal?.btnCancel}
				</Button>
				<Button
					color="primary"
					onClick={() => {
						setConfirmModal((o) => ({ ...o, result: true, show: false }))
						if (typeof setUiDisplay === "function") setUiDisplay((old) => ({ ...old, agendaModal: false }))
						if (typeof confirmModal?.fctConfirm === "function") confirmModal.fctConfirm()
					}}>
					{confirmModal?.btnConfirm}
				</Button>
			</ModalFooter>
		</Modal>
	)
}

export default ScheduleStartModal
