/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui"
import { Popover } from "antd"
import DOMPurify from "dompurify"
import { UserOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

const TableCell = ({
	currentlyEditedAppointment,
	appointmentFromScheduleSelector,
	children,
	style,
	props,
	schedulesTypes,
}) => {
	const [appointmentStyle, setAppointmentStyle] = useState({})
	useEffect(() => {
		const tmp = {}

		tmp.backgroundColor =
			schedulesTypes[props.data.status || appointmentFromScheduleSelector?.status]?.color || "#000000"
		tmp.border = "1px solid white"

		if (
			currentlyEditedAppointment?.id === props?.data?.id ||
			appointmentFromScheduleSelector?.id === props?.data?.id ||
			props?.data?.id === "NEW_APPOINTMENT"
		) {
			tmp.border = "2px solid red"
			if (!appointmentFromScheduleSelector) tmp.backgroundColor = "#226644"
		}

		if (props?.data?.state === "DONE" || (props?.data?.state == null && !props?.data?.note)) {
			tmp.backgroundImage =
				"linear-gradient(140deg, #00000033 25%, #00000011 25%, #00000011 50%, #00000033 50%, #00000033 75%, #00000011 75%, #00000011 100%)"
			tmp.backgroundSize = "62.23px 52.22px"
		}

		if (props?.data?.state === "ARRIVAL") {
			tmp.border = "2px solid green"
		}

		if (props.data.type === "FERIE") {
			tmp.backgroundColor = "blue"
			tmp.backgroundImage = "none"
		}
		setAppointmentStyle(tmp)
	}, [])
	const content = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.data?.preSchedulesNote) }} />
	return (
		<div
			className={
				["MISSING", "CANCELLED"].includes(props?.data?.state) ? "missed-appointment " + props?.data?.state : ""
			}>
			<Popover
				content={content}
				title="Note de pré rendez-vous"
				overlayClassName={props.data.type === "FERIE" || !props.data?.preSchedulesNote ? "d-none" : ""}
				trigger="hover">
				<Appointments.Appointment
					{...props}
					style={{
						...style,
						...appointmentStyle,
						borderRadius: "5px",
					}}>
					<div className={props.data.state === "ARRIVAL" ? "appointment-arrival" : ""} />

					{children}
				</Appointments.Appointment>
			</Popover>
			{props.data?.patient?.id && (
				<Link to={"/fiche-patient/" + props.data?.patient?.id} target={"child"}>
					<UserOutlined
						style={{ color: "white", right: "2px", top: "6px", fontSize: "14px" }}
						className="cursor-pointer position-absolute"
					/>
				</Link>
			)}
		</div>
	)
}

export default TableCell
