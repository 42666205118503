/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment"
import "moment/locale/fr"
import dayjs from "dayjs"
import "dayjs/locale/fr"
import localizedFormat from "dayjs/plugin/localizedFormat"
import isBetween from "dayjs/plugin/isBetween"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isToday from "dayjs/plugin/isToday"
import customParseFormat from "dayjs/plugin/customParseFormat"
import weekOfYear from "dayjs/plugin/weekOfYear"
import utc from "dayjs/plugin/utc"
import duration from "dayjs/plugin/duration"

import { lazy, Suspense, useContext, useEffect, useState } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AuthContext from "./contexts/AuthContext"
import PrivateRouter from "./router/PrivateRouter"
import Api from "./services/API"
import * as AuthApi from "./services/AuthApi"
import { setCurrentAttendance } from "./services/functions"
import { SOCKET_REALM, SOCKET_RNS_REALM } from "./config"
import _ from "lodash"
import localStorage from "./services/localStorage"
import hiboutik from "./services/API_Hiboutik"
import hiboutikUpdate from "./services/hiboutikUpdate"
import hiboutikRework from "./services/API_Hiboutik_Rework"
import { ConfirmModalContainer } from "./components/effects/ConfirmModalFunction"
import { socketDispatcherOn, socketDispatcherOff } from "./services/RealtimeSystem/Dispatcher"
import useNotifierSystem from "./services/RealtimeSystem/Hook"
import { etatJuxtaLink } from "pages/FSV/functionsFSV"
import { ConfigProvider, Select } from "antd"
import antdFR from "antd/lib/locale/fr_FR"
import { handleNotifier } from "services/RealtimeSystem/Notifier"
import useHasServices from "components/Hooks/useHasServices"
import { ServiceRef } from "components/hoc/withRequiredServices"
import applyAntdTheme, { setExtraPurpose } from "hooks/specific/applyAntdTheme"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
// import useLocalNotificationChecker from "components/structure/NotificationCenter/Init"

const RemoteUI = lazy(() => import("./pages/salle-attente/Remote"))
const SignDocumentRemotePage = lazy(() =>
	import("./pages/fiche-patient/Documents/SignDocument").then((module) => ({
		default: module.SignDocumentRemotePage,
	}))
)
const ChoixMotDePasseOublie = lazy(() => import("./pages/ChoixMotDePasseOublie"))
const InscriptionUser = lazy(() => import("./pages/inscription/0-PublicUser"))
const Login = lazy(() => import("./pages/Login"))
const MotDePasseOublie = lazy(() => import("./pages/MotDePasseOublie"))
const accountCreation = lazy(() => import("./pages/account-creation"))
//const TeleconsultationPatient = lazy(() => import("./pages/teleconsultation/Patient"))

// Config dayjs
dayjs.locale("fr")
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isToday)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(weekOfYear)
dayjs.extend(duration)

moment.locale("fr")

function CurrentLaboratorySelectModal({ open }) {
	const { laboratories, setLaboratory } = useContext(AuthContext)

	const handleLaboratorySelect = (id) => {
		if (id == null) return

		const laboratory = laboratories.find((l) => l.id === id)
		setLaboratory(laboratory)
	}

	return (
		<Modal isOpen={open} centered>
			<ModalHeader>Veuillez sélectionner votre laboratoire</ModalHeader>
			<ModalBody>
				<div className="form-group">
					<label htmlFor="current-laboratory-select">Mon laboratoire actuel</label>
					<Select
						id="current-laboratory-select"
						className="form-control w-100 removeantd-class antd-add-padding"
						onChange={handleLaboratorySelect}
						options={laboratories.map((l) => ({ label: l.label, value: l.id }))}
					/>
				</div>
			</ModalBody>
		</Modal>
	)
}

export const defaultAntdThemeVars = {
	primaryColor: "#18bc9c",
	infoColor: "#00b8d9",
	successColor: "#18bc9c",
	warningColor: "#ffab00;",
	errorColor: "#ff5630",
}

export const defaultPurposeThemeVars = {
	primaryColor: "#18bc9c",
	primaryHalfTrans: "#18bc9c80",
	primaryGradient: "#18abbc",
	secondaryColor: "#00b8d9",
	primaryDark: "#128f76",
	primaryLight: "#24e3be",
	secondaryDark: "#008da6",
	secondaryLight: "#0ddaff",
	loadingLogo: 'url("/static/img/brand/logo-chapeau-audiowizard-couleur.png")',
	loadingLogoAnimated: 'url("/static/img/brand/animated-logo.gif")',
}

const stepTitles = [
	"Récupération des informations du compte",
	"",
	"Récupération des laboratoires et plages de présences",
	"Mise à jour du catalogue produit",
	"Initialisation de la connexion en temps réel",
	"",
]

const App = ({ socket, socket_rns }) => {
	const ctx = useContext(AuthContext)
	const [loading, setLoading] = useState(true)
	const [loadingSteps, setLoadingSteps] = useState(0) // Final loading step is 5
	const serviceStatus = useHasServices(ServiceRef.MarqueBlanche)

	const stepTitle = stepTitles[loadingSteps]

	const initHiboutik = async (user) => {
		try {
			const token = user.company?.hiboutikAccount?.token
			const subDomain = user.company?.hiboutikAccount?.subDomain

			window.localStorage.setItem("aGlib3V0b2tlbg", token)
			window.localStorage.setItem("aGlib3V0aWtfYWNjb3VudG5hbWU", subDomain)

			if (user?.company?.tokenPdf) window.localStorage.setItem("dG9rZW5QZGY=", user.company.tokenPdf)

			if (!token || !subDomain) {
				ctx.setUiDisplay((old) => ({ ...old, topBarHiboutikTokenAlert: true }))
			}

			hiboutik.init(subDomain, token, user?.company?.tokenPdf)
			hiboutikRework.init(subDomain, token)
		} catch (e) {
			console.error("initHiboutik", e)
		}
	}

	const contextSavePersistance = async (context, exclusion) => {
		try {
			const tmp = {}

			for (const [key, val] of Object.entries(context)) {
				if (!key.includes("set") && typeof val !== "function" && !exclusion.includes(key)) {
					tmp[key] = _.clone(val)
				}
			}
			for (const [key, val] of Object.entries(tmp)) {
				if (val?.documents) delete tmp[key].documents
				if (val?.todos) delete tmp[key].todos
				if (val?.orls) delete tmp[key].orls
				if (val?.doctors) delete tmp[key].doctors
				if (val && Array.isArray(val) && !val.length) delete tmp[key]
				if (val && typeof val === "object" && !Array.isArray(val) && !Object.keys(val).length) delete tmp[key]
				if (val == null) delete tmp[key]
			}

			window.sessionStorage.setItem("APPCTX", JSON.stringify(tmp))
		} catch (e) {
			console.error("contextSavePersistance", e)
		}
	}

	const contextLoadPersistance = async (context, userData, exclusion) => {
		try {
			let fromLocal = window.sessionStorage.getItem("APPCTX")
			fromLocal = JSON.parse(fromLocal)
			if (fromLocal?.user?.id !== userData.id) {
				window.sessionStorage.removeItem("APPCTX")
				return
			}
			for (const [key, value] of Object.entries(fromLocal)) {
				if (!exclusion.includes(key)) {
					for (const ctkey of Object.keys(context)) {
						if (`set${key}`.toLocaleLowerCase() === ctkey.toLocaleLowerCase()) {
							context[ctkey](value)
							break
						}
					}
				}
			}
		} catch (e) {
			console.error("contextLoadPersistance", e)
		}
	}

	const handleLaboratoryAttendences = async (userData) => {
		if (!("laboratories" in userData) || document.location.href.includes("/inscription/")) return
		try {
			const attendances = await Api.findAll("LABORATORY_ATTENDANCES_API", `?user=${userData.id}`)
			ctx.setLaboratoryAttendances(attendances)
			ctx.setLaboratory({})
			return attendances
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		if (!serviceStatus || ctx.user.company?.whiteLabel == null) {
			return
		}

		if (!ctx.user.company?.whiteLabel?.isActive) {
			ConfigProvider.config({ theme: defaultAntdThemeVars })
			setExtraPurpose(defaultPurposeThemeVars)

			return
		}

		applyAntdTheme(
			{
				primaryColor: ctx.user.company?.whiteLabel?.primaryColor,
				secondaryColor: ctx.user.company?.whiteLabel?.secondaryColor,
			},
			ctx.user.company?.whiteLabel?.logo
		)
	}, [serviceStatus, ctx.user?.company?.whiteLabel])

	useNotifierSystem(handleNotifier, ["schedule"])

	useEffect(() => {
		socketDispatcherOff(socket_rns)
		AuthApi.initAuth(ctx, setLoading, setLoadingSteps)
	}, [])

	const loadUserdata = async () => {
		try {
			try {
				socket.off("audio")
				socket_rns.off("wizard")
			} catch (_void) {}
			const userData = await AuthApi.fetchUser()
			ctx.setUiDisplay((old) => ({ ...old, onboarding: false }))
			if (!userData["@id"]) throw Error()
			if (!userData.gdprAgreement) {
				ctx.setUiDisplay((old) => ({ ...old, onboarding: true }))

				if (!document.location.pathname.startsWith("/inscription"))
					window.location = "/inscription/user/coordonnees"
			}
			if (!userData?.personalization?.length) {
				try {
					await Api.create("PERSONALIZATIONS_API", {
						user: userData["@id"],
						settings: [],
						templates: [],
					})
				} catch (err) {}
			}
			await initHiboutik(userData)
			await contextLoadPersistance(ctx, userData, [])
			ctx.setUser(userData)

			ctx.setServices(await Api.findAll("AUDIO_WIZARD_SERVICES_API"))

			const subscriptions = await Api.findAll("AUDIO_WIZARD_SUBSCRIPTIONS_API")
			ctx.setSubscriptions(subscriptions)
			setLoadingSteps(2)

			const prescribers = await Api.findAll("PRESCRIBERS_API", "?pagination=false&order[id]=DESC")
			ctx.setOrls(prescribers.filter((p) => p.category === "ORL"))
			ctx.setDoctors(prescribers.filter((p) => p.category === "DOCTOR"))

			const templates = await Api.findAll("TEMPLATE")
			ctx.setTemplates(templates)
			setLoadingSteps(3)

			let isError
			try {
				isError = await hiboutikUpdate.update(userData)
				if (!isError) {
					hiboutikUpdate.updateWebhook("", "SUCCESS", 0)
				}
			} catch (err) {
				hiboutikUpdate.updateWebhook(err, "FAILURE: algo", 1)
			} finally {
				// L'utilisateur n'a pas de company lors de l'inscription
				if (userData.company != null && isError !== 2) {
					hiboutikUpdate.setShouldUpdate(userData, false)
				}
			}
			setLoadingSteps(4)

			const attendances = await handleLaboratoryAttendences(userData)
			const laboratoriesList = await Api.findAll("LABORATORIES_API", `?user=${userData.id}&pagination=false`)
			ctx.setLaboratories(laboratoriesList)
			// attendances est undefined si on est en inscription, on fait ce check pour ne avoir un erreur sur le .filter et rester connecté
			if (attendances != null) {
				setCurrentAttendance(
					attendances,
					ctx.laboratory,
					ctx.setLaboratory,
					laboratoriesList,
					!ctx.user.gdprAgreement
				)
			}
			// userData.company est undefined pendant l'inscription
			if (userData.company?.["@id"] != null) {
				const userInsurancesList = await Api.findAll(
					"USER_INSURANCES_API",
					`?companies=${userData.company["@id"]}`
				)
				ctx.setUserInsurances(userInsurancesList)
			}

			// if (typeof window?.Sentry?.setTag === "function") {
			// 	window.Sentry.setTag("Company", `${userData?.company?.id} ${userData?.company?.label}`)
			// 	window.Sentry.setTag("Client", `${userData?.id} ${userData?.firstName} ${userData?.lastName}`)
			// }

			setLoadingSteps(5)

			ctx.setIsAuthenticated(true)
			socketDispatcherOn(socket_rns)
			try {
				socket_rns.emit("keepalive", {
					realm: SOCKET_RNS_REALM,
					user_id: userData.id,
					company_id: userData.company.id,
				})
				setInterval(() => {
					socket_rns.emit("keepalive", {
						realm: SOCKET_RNS_REALM,
						user_id: userData.id,
						company_id: userData.company.id,
					})
					console.debug("[SOCKET_RNS] Sending keepalive")
				}, 60000)

				if (socket) {
					socket.emit("wizard", {
						realm: SOCKET_REALM,
						user: userData.id,
						lastName: userData.lastName,
						firstName: userData.firstName,
						buildDate: window?.BUILD_DATE,
					})
					socket.on("audio", () => {
						socket.emit("wizard", {
							realm: SOCKET_REALM,
							user: userData.id,
							lastName: userData.lastName,
							firstName: userData.firstName,
							buildDate: window?.BUILD_DATE,
						})
					})
				}

				if (process.env.REACT_APP_NO_JUXTA !== "true") {
					etatJuxtaLink(subscriptions)
				}
			} catch (e) {
				console.error(e)
			}
		} catch (e) {
			console.error(e)
			setLoadingSteps(5)
			setLoading(false)
			ctx.setIsAuthenticated(false)
		}
	}

	useEffect(() => {
		if (ctx.isAuthenticated && !loading && loadingSteps === 5) {
			setLoadingSteps(0)
			setLoading(true)
			loadUserdata()
		}
	}, [ctx.isAuthenticated])

	// useLocalNotificationChecker()

	useEffect(() => {
		if (!loading && ctx.isAuthenticated && loadingSteps === 5)
			contextSavePersistance(ctx, [
				"isAuthenticated",
				"uiDisplay",
				"laboratory",
				"laboratoryAttendances",
				"laboratories",
			])
	}, [ctx])

	// Persist current laboratory to localStorage
	useEffect(() => {
		const iri = ctx.laboratory?.["@id"]
		if (iri == null) return

		window.localStorage.setItem("currentLaboratoryIri", iri)
	}, [ctx.laboratory])

	useEffect(() => {
		if (loadingSteps === 1) loadUserdata()
		if (loadingSteps === 5) setTimeout(() => setLoading(false), 400)
	}, [loadingSteps])

	useEffect(() => {
		if (document.location.pathname.includes("/consentement-rgpd")) {
			localStorage.update("appcache", {
				waitingRoomData: null,
				firstVisit: true,
			})
		}
	}, [document.location.pathname])

	return (
		<ConfigProvider locale={antdFR}>
			{loading && (
				<>
					<div className="container h-100 d-flex justify-content-center">
						<div className="remoteUI-Loading loading-app-general" style={{ filter: "blur(.5px)" }}>
							<div className="app-loading-bar">
								<div style={{ width: (loadingSteps * 100) / 5 + "%" }} />
							</div>
						</div>
						<h6 style={{ position: "absolute", top: "60%", color: "#8492a6" }}>{stepTitle}</h6>
					</div>
				</>
			)}
			{!loading && ctx.isAuthenticated && <PrivateRouter />}
			{!loading && !ctx.isAuthenticated && (
				<BrowserRouter>
					<Suspense fallback={<>...</>}>
						<Switch>
							<Route path="/inscription" component={InscriptionUser} />
							<Route path="/se-connecter" component={Login} />
							<Route path="/mot-de-passe-oublie" component={MotDePasseOublie} />
							<Route path="/choix-mot-de-passe-oublie/:token" component={ChoixMotDePasseOublie} />
							<Route path="/account-creation/:token" component={accountCreation} />
							<Route path="/salle-attente/invitation-:id" component={RemoteUI} />
							<Route path="/remote/salle-attente/:id" component={RemoteUI} />
							<Route path="/documents/signer/remote/:token" component={SignDocumentRemotePage} />
							<Route path="/" component={Login} />
						</Switch>
					</Suspense>
				</BrowserRouter>
			)}
			<ToastContainer theme="light" position={toast.POSITION.TOP_CENTER} style={{ top: "42px" }} />
			<ConfirmModalContainer />
			<CurrentLaboratorySelectModal
				// Open current laboratory select modal if there's no current laboratory
				open={
					ctx.isAuthenticated &&
					loadingSteps === 5 &&
					ctx.user?.gdprAgreement && // Don't open while in sign up form
					ctx.laboratory?.["@id"] == null && // Check on @id because laboratory could be empty object ({})
					ctx.laboratories.length > 0
				}
			/>
		</ConfigProvider>
	)
}

export default App
