import { User } from "@audiowizard/common"
import { notification } from "antd"

export const handleNotifier = (type: string, res: { [key: string]: any }, currentUser: User): void => {
	if (+res?.data?.user === currentUser?.id || res?.data?.user === currentUser?.["@id"]) {
		let message, description

		switch (res.method) {
			case "post":
				message = "Rendez-vous"
				description = (
					<>
						Un nouveau rendez-vous pour{" "}
						<strong>{`${res?.data?.patient?.firstName || ""} ${
							res?.data?.patient?.lastName || ""
						}`}</strong>{" "}
						vous à été assigné !
					</>
				)

				break
			case "put":
				message = "Rendez-vous"
				switch (res?.data.state) {
					case "ARRIVAL":
						description = (
							<>
								Votre rendez-vous pour&nbsp;
								<strong>{`${res?.data?.patient?.firstName || ""} ${
									res?.data?.patient?.lastName || ""
								}`}</strong>
								&nbsp;est arrivé !
							</>
						)

						break
					default:
						break
				}
				break
			default:
				break
		}
		if (message && description)
			notification.info({
				message,
				top: 70,
				description,
			})
	}
}
