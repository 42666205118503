import { useContext, useMemo } from "react"
import AuthContext from "../../contexts/AuthContext"
import { useHistory } from "react-router-dom"
import { haveRole } from "../../services/functions"
import { toast } from "react-toastify"

/**
 * @enum {ServiceRef}
 */
export const ServiceRef = {
	Basic: "basic",
	Teletransmission: "teletransmission",
	Signature: "signature",
	MarqueBlanche: "marque-blanche",
}
// Service refs in freeAcess can be accessed without a subscription
export const freeAccess = [ServiceRef.Teletransmission, ServiceRef.Signature, ServiceRef.MarqueBlanche]
/**
 * Bloque un composant page si l'utilisateur n'a pas le service requis.
 *
 * @example export default withRequiredServices(Page)(ServiceRef.Basic) // Demande le service basic
 * @example export default withRequiredServices(Page)(ServiceRef.Basic, ServiceRef.Teletransmission) // Demande le service Basic et Teletransmission
 * @example export default withRequiredServices(Page)([ServiceRef.Basic, ServiceRef.Teletransmission]) // Array possible
 */
const withRequiredServices =
	(Component) =>
	(...servicesRefRequired) =>
	(props) => {
		const history = useHistory()
		const { user, services: userServices } = useContext(AuthContext)

		const userServicesByRef = useMemo(
			() =>
				userServices.reduce((obj, s) => {
					obj[s.ref] = s
					return obj
				}, {}),
			[userServices]
		)

		// Les services qui sont demandés mais que l'utilisateur n'a pas d'actif
		const missingServices = useMemo(() => {
			const missingServices = []

			for (const sRef of servicesRefRequired) {
				const service = userServicesByRef[sRef]
				if (service && !service.isActive && !freeAccess.includes(sRef)) {
					missingServices.push(service)
				}
			}

			return missingServices
		}, [userServicesByRef])

		const hasAllRequiredServices = missingServices.length === 0

		if (hasAllRequiredServices) return <Component {...props} />

		const formatter = new Intl.ListFormat("fr", { style: "long", type: "conjunction" })
		toast.error(
			`Il vous manque le${missingServices.length > 1 ? "s" : ""} service${
				missingServices.length > 1 ? "s" : ""
			} ${formatter.format(missingServices.map((s) => s.name))} pour pouvoir accéder à cette fonctionalité.`
		)

		history.push(haveRole(user, "ROLE_MANAGER") ? "/mon-compte/mon-abonnement" : "/")

		return ""
	}

export default withRequiredServices
