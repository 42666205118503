import dayjs from "dayjs"
import { Calendar } from "antd"
import moment from "moment"

const ViewSettings = ({ currentDate, setCurrentDate }) => {
	return (
		<>
			{" "}
			<div className="row">
				<div className="btn-group w-100" role="group">
					<button
						type="button"
						className={
							"btn btn-block " +
							(dayjs(currentDate).format("YYYYMMDD") === dayjs().format("YYYYMMDD")
								? "btn-primary"
								: "btn-outline-primary")
						}
						onClick={() => {
							setCurrentDate(new Date())
						}}>
						Cette semaine
					</button>
					<button
						type="button"
						style={{ width: "54px" }}
						className="btn btn-block btn-outline-primary"
						onClick={() => {
							const date = dayjs(currentDate)
							setCurrentDate(date.week(date.week() - 1).toDate())
						}}>
						<i className="fad fa-arrow-left" />
					</button>
					<button
						type="button"
						style={{ width: "54px" }}
						className="btn btn-block btn-outline-primary"
						onClick={() => {
							const date = dayjs(currentDate)
							setCurrentDate(date.week(date.week() + 1).toDate())
						}}>
						<i className="fad fa-arrow-right" />
					</button>
				</div>
			</div>
			<div className="calendar-picker">
				<Calendar
					fullscreen={false}
					value={moment(currentDate)}
					onChange={(e) => {
						setCurrentDate(e.toDate())
					}}
				/>
			</div>
		</>
	)
}

export default ViewSettings
