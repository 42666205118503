import * as React from "react"

type SpinnerAWProps = React.PropsWithChildren<{
	text?: string
}>

export default function SpinnerAW({ text = "", children }: SpinnerAWProps): JSX.Element {
	return (
		<div className="overlay-loading-aw">
			<div className="overlay-loading-logo" />
			<div className="overlay-loading-text">{children ?? text}</div>
		</div>
	)
}
