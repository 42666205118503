import { NavLink } from "react-router-dom"

const DropdownCompta = () => {
	return (
		<>
			<ul className="navbar-nav ml-lg-auto">
				<li className="nav-item dropdown dropdown-animate">
					<a
						className="nav-link pr-lg-0"
						href="#!"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false">
						<div className="d-none d-lg-block">
							<i style={{ fontSize: "2rem" }} className="text-white fad fa-calculator" />

							{/* <div className="media media-pill d-flex flex-column align-items-center justify-content-center">
								<div>Comptabilité</div>
							</div> */}
						</div>
					</a>
					<div
						className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow"
						aria-labelledby="navbar-primary_dropdown_1">
						<NavLink to="/mon-compte/export-comptable" className="dropdown-item">
							<i className="fad fa-file-export" />
							<span>Exports comptable</span>
						</NavLink>
						<NavLink to="/teletransmission/suivi-des-suivi" className="dropdown-item">
							{/* <i className="fad fa-file-export"></i> */}
							<i className="fad fa-exchange-alt" />
							<span>Télétransmissions de contrôle</span>
						</NavLink>
						<NavLink to="/teletransmission/suivi" className="dropdown-item">
							{/* <i className="fad fa-file-export"></i> */}
							<i className="fad fa-exchange-alt" />
							<span>Suivi des télétransmissions</span>
						</NavLink>

						<h6 className="dropdown-subtitle text-primary ">Paiements</h6>
						<div className="dropdown-subel mb-4">
							<NavLink to="/mon-compte/liste-paiements/recu" className="dropdown-item">
								<i className="fad fa-check" />
								<span>Reçu</span>
							</NavLink>
							<NavLink to="/mon-compte/liste-paiements/attente" className="dropdown-item">
								<i className="fad fa-hourglass-start" />
								<span>En attente</span>
							</NavLink>

							<NavLink to="/mon-compte/gestion-cheques" className="dropdown-item">
								<i className="fad fa-minus" />
								<span>Dépot de chèques</span>
							</NavLink>
							<NavLink to="/mon-compte/gestion-carte-bancaire" className="dropdown-item">
								<i className="fad fa-minus" />
								<span>Dépot de carte bancaire</span>
							</NavLink>
						</div>
						{/*
						<h6 className="dropdown-subtitle text-primary ">Chèques et CB</h6>
						<div className="dropdown-subel mb-4">
							<NavLink to="/mon-compte/caisse/depot" className="dropdown-item">
								<i className="fad fa-check" />
								<span>Vérification</span>
							</NavLink>
							<NavLink to="/mon-compte/caisse/depot" className="dropdown-item">
								<i className="fad fa-minus" />
								<span>Dépot de chèques</span>
							</NavLink>
						</div> */}

						<h6 className="dropdown-subtitle text-primary">Caisse (espèce)</h6>
						<div className="dropdown-subel">
							<NavLink to="/mon-compte/caisse/validation" className="dropdown-item">
								<i className="fad fa-check" />
								<span>Validation</span>
							</NavLink>
							<NavLink to="/mon-compte/caisse/depot" className="dropdown-item">
								<i className="fad fa-minus" />
								<span>Dépot</span>
							</NavLink>
							<NavLink to="/mon-compte/caisse/reassort" className="dropdown-item">
								<i className="fad fa-plus" />
								<span>Réassort</span>
							</NavLink>
							<NavLink to="/mon-compte/caisse/historique" className="dropdown-item">
								<i className="fad fa-history" />
								<span>Historique</span>
							</NavLink>
						</div>
					</div>
				</li>
			</ul>
		</>
	)
}

export default DropdownCompta
