import { useState } from "react"
import AuthContext from "./contexts/AuthContext"
import App from "./app"

const Context = ({ socket, socket_rns }) => {
	const [isAuthenticated, setIsAuthenticated] = useState()
	const [user, setUser] = useState({})
	const [services, setServices] = useState([])
	const [subscriptions, setSubscriptions] = useState([])
	const [laboratory, setLaboratory] = useState({})
	const [laboratories, setLaboratories] = useState([])
	const [laboratoryAttendances, setLaboratoryAttendances] = useState([])
	const [loading, setLoading] = useState(true)
	const [productRanges, setProductRanges] = useState([])
	const [products, setProducts] = useState([])
	const [patients, setPatients] = useState([])
	const [orls, setOrls] = useState([])
	const [doctors, setDoctors] = useState([])
	const [patientGenes, setPatientGenes] = useState([])
	const [patientOrigins, setPatientOrigins] = useState([])
	const [patientAcouphenes, setPatientAcouphenes] = useState([])
	const [patientAcoupheneDroite, setPatientAcoupheneDroite] = useState({})
	const [patientAcoupheneGauche, setPatientAcoupheneGauche] = useState({})
	const [patientAcoupheneBilateral, setPatientAcoupheneBilateral] = useState({})
	const [patientMedicals, setPatientMedicals] = useState([])
	const [patientMedical, setPatientMedical] = useState({})
	const [patientLookingFors, setPatientLookingFors] = useState([])
	const [patientEquipment, setPatientEquipment] = useState({})
	const [patientEquipments, setPatientEquipments] = useState([])
	const [patientSocialSecurity, setPatientSocialSecurity] = useState([])
	const [patientInsurances, setPatientInsurances] = useState([])
	const [eventProsthetic, setEventProsthetic] = useState({})
	const [eventProsthetics, setEventProsthetics] = useState([])
	const [eventExplanations, setEventExplanations] = useState([])
	// eslint-disable-next-line no-unused-vars
	const [eventOtoscopy, setEventOtoscopy] = useState({})
	const [eventOtoscopies, setEventOtoscopies] = useState([])
	const [eventWorkshops, setEventWorkshops] = useState({
		DROITE: { afterCleaning: ["RAS"] },
		GAUCHE: { afterCleaning: ["RAS"] },
	})
	const [patient, setPatient] = useState({})
	const [advice, setAdvice] = useState(null)
	const [schedule, setSchedule] = useState(null)
	const [patientOrigin, setPatientOrigin] = useState([])
	const [isBlockingContext, setIsBlockingContext] = useState(false)
	const [editing, setEditing] = useState(false)
	const [equipmentEditing, setEquipmentEditing] = useState(false)
	const [selectedEar, setSelectedEar] = useState("GAUCHE")
	const [reminder, setReminder] = useState({})
	const [notesGeneratedText, setNotesGeneratedText] = useState("")
	const [notesVisiteText, setNotesVisiteText] = useState("")
	const [patientUI, setPatientUI] = useState(window.localStorage.getItem("patientUI") === "true" ? true : false)
	const [mutuelle, setMutuelle] = useState({})
	const [mutuelles, setMutuelles] = useState([])
	const [situationMaxStep, setSituationMaxStep] = useState(0)
	const [situationStep, setSituationStep] = useState(0)
	const [lastLookingForStep, setLastLookingForStep] = useState(0)
	const [newQuote, setNewQuote] = useState({
		label: "",
		filename: "",
		url: "",
		bobine: true,
		class: 2,
		costLeftClass1: 1100,
		costRightClass1: 1100,
		accessories: [],
	})
	const [selectedDocument, setSelectedDocument] = useState(null)
	const [creatingInvoice, setCreatingInvoice] = useState(false)
	const [prevPatientEquipmentStatus, setPrevPatientEquipmentStatus] = useState(null)
	const [editingContact, setEditingContact] = useState(false)
	const [prevStep, setPrevStep] = useState(null)
	const [lastCart, setLastCart] = useState([])
	const [newProduct, setNewProduct] = useState(null)
	const [newProducts, setNewProducts] = useState({ earphone: {}, mouthpiece: {} })
	const [redirectToFeuilleSoin, setRedirectToFeuilleSoin] = useState(false)
	const [subcontractors, setSubcontractors] = useState()
	const [afterSales, setAfterSales] = useState([])
	const [currentSav, setCurrentSav] = useState({})
	const [isPatientEquipment, setIsPatientEquipment] = useState()
	const [currentEquipment, setCurrentEquipment] = useState(null)
	const [uiDisplay, setUiDisplay] = useState({})
	const [notesEvent, setNotesEvent] = useState({ genes: [] })
	const [notesOtoscopies, setNotesOtoscopies] = useState([])
	const [cashValidation, setCashValidation] = useState(null)
	const [caByCategories, setCaByCategories] = useState(null)
	const [dailyCash, setDailyCash] = useState(null)
	const [caByPayementType, setCaByPayementType] = useState(null)
	const [salesJournal, setSalesJournal] = useState(null)
	const [residualAmount, setResidualAmount] = useState(null)
	const [patientReferer, setPatientReferer] = useState(null)
	const [userInsurances, setUserInsurances] = useState([])
	const [templates, setTemplates] = useState([])

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				setIsAuthenticated,
				user,
				setUser,
				caByCategories,
				setCaByCategories,
				dailyCash,
				setDailyCash,
				caByPayementType,
				setCaByPayementType,
				salesJournal,
				setSalesJournal,
				residualAmount,
				setResidualAmount,
				services,
				setServices,
				subscriptions,
				setSubscriptions,
				laboratories,
				setLaboratories,
				laboratory,
				setLaboratory,
				loading,
				setLoading,
				laboratoryAttendances,
				setLaboratoryAttendances,
				productRanges,
				setProductRanges,
				products,
				setProducts,
				patients,
				setPatients,
				patient,
				setPatient,
				orls,
				setOrls,
				doctors,
				setDoctors,
				advice,
				setAdvice,
				patientReferer,
				setPatientReferer,
				patientGenes,
				setPatientGenes,
				patientMedicals,
				setPatientMedical,
				patientAcouphenes,
				setPatientAcouphenes,
				patientAcoupheneDroite,
				setPatientAcoupheneDroite,
				patientAcoupheneGauche,
				setPatientAcoupheneGauche,
				patientAcoupheneBilateral,
				setPatientAcoupheneBilateral,
				patientMedical,
				setPatientMedicals,
				patientSocialSecurity,
				setPatientSocialSecurity,
				patientInsurances,
				setPatientInsurances,
				patientOrigins,
				setPatientOrigins,
				patientLookingFors,
				setPatientLookingFors,
				schedule,
				setSchedule,
				eventWorkshops,
				setEventWorkshops,
				eventOtoscopies,
				setEventOtoscopies,
				eventProsthetic,
				setEventProsthetic,
				eventProsthetics,
				setEventProsthetics,
				patientEquipment,
				setPatientEquipment,
				patientEquipments,
				setPatientEquipments,
				patientOrigin,
				setPatientOrigin,
				isBlockingContext,
				setIsBlockingContext,
				reminder,
				setReminder,
				editing,
				setEditing,
				equipmentEditing,
				setEquipmentEditing,
				notesEvent,
				setNotesEvent,
				notesOtoscopies,
				setNotesOtoscopies,
				notesGeneratedText,
				setNotesGeneratedText,
				notesVisiteText,
				setNotesVisiteText,
				patientUI,
				setPatientUI,
				selectedEar,
				setSelectedEar,
				eventExplanations,
				setEventExplanations,
				mutuelle,
				setMutuelle,
				mutuelles,
				setMutuelles,
				situationMaxStep,
				setSituationMaxStep,
				lastLookingForStep,
				setLastLookingForStep,
				situationStep,
				setSituationStep,
				newQuote,
				setNewQuote,
				selectedDocument,
				setSelectedDocument,
				creatingInvoice,
				setCreatingInvoice,
				prevPatientEquipmentStatus,
				setPrevPatientEquipmentStatus,
				editingContact,
				setEditingContact,
				prevStep,
				setPrevStep,
				lastCart,
				setLastCart,
				newProduct,
				setNewProduct,
				eventOtoscopy,
				setEventOtoscopy,
				redirectToFeuilleSoin,
				setRedirectToFeuilleSoin,
				afterSales,
				setAfterSales,
				currentSav,
				setCurrentSav,
				isPatientEquipment,
				setIsPatientEquipment,
				subcontractors,
				setSubcontractors,
				currentEquipment,
				setCurrentEquipment,
				uiDisplay,
				setUiDisplay,
				newProducts,
				setNewProducts,
				cashValidation,
				setCashValidation,
				userInsurances,
				setUserInsurances,
				templates,
				setTemplates,
			}}>
			<App socket={socket} socket_rns={socket_rns} />
		</AuthContext.Provider>
	)
}

export default Context
