import ReactDOMServer from "react-dom/server"
import { LocaleNamespace } from "config/intl/helpers"
import dayjs from "dayjs"
import DOMPurify from "dompurify"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ReturnFormTemplate = (store: Record<string, any>, t: any): string => {
	return ReactDOMServer.renderToStaticMarkup(
		<>
			<style
				dangerouslySetInnerHTML={{
					__html: `
            @media print {
                .no-print { display: none !important; }
            }
            html {
                -webkit-print-color-adjust: exact;
              }
			table, th, td {
				padding: 5px 10px;
				border: 1px solid #afaeae;
				border-collapse: collapse;
				font-size: 0.9em;
			}
            `,
				}}
			/>
			{/* Header */}
			<>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div>
						{store?.context?.user?.company?.logo && (
							<p style={{ textAlign: "left" }}>
								<img
									src={store?.context?.user?.company?.logo}
									alt={t("billing.__editor.context-menu.Company logo on the sales invoice")}
									width="150"
									style={{ objectFit: "contain", height: "auto" }}
								/>
							</p>
						)}
					</div>
					<div style={{ textAlign: "right" }}>
						<h3>
							<strong>{t(`${LocaleNamespace.Common}:documentTitle.return_form_summary`)}</strong>
						</h3>
						<div>
							<strong>{t(`${LocaleNamespace.Common}:commonWords.date`)} :</strong>{" "}
							{store?.data?.createdAt && dayjs(store?.data?.createdAt).format("DD/MM/YYYY, HH:mm")}
						</div>
					</div>
				</div>
				<br />
			</>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<ul style={{ listStyle: "none", padding: "unset", margin: "0" }}>
					{store?.data.laboratory?.label && (
						<li>
							<strong> #(data.laboratory.label)</strong>
						</li>
					)}
					{store?.data.laboratory?.adress && <li>#(data.laboratory.adress)</li>}
					{store?.data.laboratory?.city && <li>#(data.laboratory.cpo) #(data.laboratory.city)</li>}
					{store?.data.laboratory?.email && (
						<li>
							<strong>Email :</strong> #(data.laboratory.email)
						</li>
					)}
					{store?.data.laboratory?.phone && (
						<li>
							<strong>Tél. :</strong> #(data.laboratory.phone)
						</li>
					)}
				</ul>
				<ul style={{ listStyle: "none", padding: "unset", textAlign: "right", margin: "0" }}>
					{store?.data?.supplier?.supplier_name && (
						<li>
							<strong>{store.data.supplier.supplier_name}</strong>
						</li>
					)}
					{store?.data?.supplier?.supplier_address && <li>{store.data.supplier.supplier_address}</li>}
					{store?.data?.supplier?.supplier_contact && <li>{store?.data?.supplier?.supplier_contact}</li>}
					{store?.data?.supplier?.supplier_email && <li>{store?.data?.supplier?.supplier_email}</li>}
					{store?.data?.supplier?.supplier_url && <li>{store?.data?.supplier?.supplier_url}</li>}
				</ul>
			</div>

			<br />

			<table contentEditable={false} className="reporting-template__variable" style={{ width: "100%" }}>
				<thead>
					<tr style={{ backgroundColor: "#ececec" }}>
						{[
							`${t(`${LocaleNamespace.Common}:commonWords.product`)}`,
							`${t(`${LocaleNamespace.Common}:commonWords.sizeName`)}`,
							`${t(`${LocaleNamespace.Common}:commonWords.serialNumber`)}`,
							"État",
						].map((title) => (
							<th key={title}>{title}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{store?.data?.logisticProducts?.map((item: any, key: number) => {
						return (
							<tr key={key}>
								<td>{item?.model}</td>
								<td>{item?.sizeName ?? "Pas de déclinaison"}</td>
								<td>{item?.productSerialNumber}</td>
								<td>
									<div>{item?.state}</div>
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			<br />

			<h5>Note :</h5>
			<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(store.data.notes) }} />
		</>
	)
}
