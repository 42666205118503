import API from "../../services/API"
import { formatDateForDB } from "../../services/functions"
import Todos from "../../services/Todos"
import dayjs from "dayjs"

function devientAppareille(currentSchedule, newSchedule) {
	const comingFrom = ["ESSAI", "RELANCE"]

	return newSchedule === "APPAREILLE" && comingFrom.includes(currentSchedule)
}

export async function createTodo(ctx, newSchedule) {
	const currentScheduleStatus = ctx?.schedule?.status || null
	const todo = new Todos([], () => void 0)
	const laboratoryForTodo = !ctx.patient.createdAt
		? ctx.patient.laboratory
			? ctx.patient.laboratory["@id"]
			: ctx.laboratory["@id"]
		: ctx.laboratory["@id"]

	if (laboratoryForTodo) todo.addFromSchedule(newSchedule, laboratoryForTodo)

	const todoData = {}

	if (currentScheduleStatus) {
		if (devientAppareille(currentScheduleStatus, newSchedule.status)) {
			todoData.date = dayjs(new Date()).add(7, "d")
			todoData.label = "Compte rendu à envoyer à l'ORL"
			if (ctx?.patient.mainOrl && ctx?.patient?.orl?.firstName && ctx?.patient?.orl?.lastName) {
				todoData.label = todoData.label.concat(
					` ${ctx.patient.mainOrl.firstName} ${ctx.patient.mainOrl.lastName}`
				)
			}
		} else if (currentScheduleStatus === "APPAREILLE") {
			todoData.label = "Faire le compte rendu du contrôle"
		} else if (currentScheduleStatus === "ADAPTATION") {
			todoData.label = "Vérifier la commande des appareils"
			todoData.date = dayjs(newSchedule.dateOf).subtract(4, "days").format("YYYY-MM-DD HH:mm:ss")
		}
	}

	if (todoData && todoData.label) {
		const lab = ctx.patient.laboratory ? ctx.patient.laboratory["@id"] : ctx.laboratory["@id"]
		Todos.add(todoData.label, lab, ctx.patient["@id"], "RAPPEL", todoData.date ?? formatDateForDB())
	}
}

export async function updateTodo(ctx) {
	let tmpTodoList = await Todos.fetch()
	const todo = new Todos(tmpTodoList, () => void 0)

	//const laboratoryForTodo = !ctx.patient.createdAt ? (ctx.patient.laboratory ? ctx.//patient.laboratory["@id"] : ctx.laboratory["@id"]) : ctx.laboratory["@id"]

	todo.updateFromSchedule(ctx.schedule, ctx.patient, tmpTodoList)

	if (ctx.schedule.status === "RELANCE") {
		const todos = tmpTodoList.filter((t) => {
			return t.status === "TODO" && t.type === "RELANCE" && t.patient.id === ctx.patient.id
		})
		const todoId = todos.length ? todos[0].id : null
		if (todoId) {
			await API.update("TODOS_API", todoId, { status: "DONE" })
		}
	}
}
