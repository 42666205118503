import API from "./API"
import moment from "moment"

export default class Todos {
	_get = null
	_set = null

	constructor(getContext, setContext) {
		this._get = getContext || null
		this._set = setContext || null
	}

	_updateContextFromSchedule(id) {
		if (typeof this._get !== "object" || typeof this._set !== "function") return

		let updatedContext = [...this._get]
		updatedContext[id].status = "DONE"
		this._set(updatedContext)
	}

	_addContextFromSchedule(data, patient) {
		if (typeof this._get !== "object" || typeof this._set !== "function" || !patient || !data) return
		const newCtx = { ...data }
		newCtx.patient = {
			id: parseInt(patient.match(/(\d+)/)[0]),
			"@id": patient,
		}
		this._set([...this._get, newCtx])
	}

	getTypeFromStatus(status) {
		let type = null
		let label = null
		switch (status) {
			case "PREMIERE_VISITE":
				type = "RENDEZ-VOUS PREMIERE VISITE"
				label = "Rendez-vous de première visite"
				break
			case "VISITE":
				type = "RENDEZ-VOUS VISITE"
				label = "Rendez-vous de première visite"
				break
			case "SUITE_VISITE":
				type = "RENDEZ-VOUS SUITE VISITE"
				label = "Rendez-vous de suite visite"
				break
			case "ADAPTATION":
				type = "RENDEZ-VOUS DE ADAPTATION"
				label = "Rendez-vous de livraison"
				break
			case "ESSAI":
				type = "RENDEZ-VOUS D'ESSAI"
				label = "Rendez-vous d'essai"
				break
			case "APPAREILLE":
				type = "RENDEZ-VOUS DE CONTRÔLE"
				label = "Rendez-vous de contrôle"
				break
			case "RELANCE":
				type = "RELANCE"
				label = "Une relance doit être éffectuée"
				break
			case "RETOUR_RELANCE":
				type = "RETOUR SUITE A UNE RELANCE"
				label = "Comment s'est passée la relance ?"
				break
			case "NONE":
				type = "CREATION DE COMPTE"
				label = "Création de compte à faire"
				break
			default:
				type = status
				label = status
				break
		}
		return { type, label }
	}

	async updateFromSchedule(schedule, patient, todoList) {
		try {
			const { type } = this.getTypeFromStatus(schedule.status)
			if (!schedule || !todoList || !patient || !todoList.length) return
			const filtered = todoList.filter(
				(v) =>
					v.type === type &&
					v.realisationDate.substr(0, 10) === schedule.dateOf.substr(0, 10) &&
					v.status === "TODO" &&
					v.patient["@id"] === patient["@id"]
			)[0]
			const indexOf = todoList.indexOf(filtered)
			if (todoList[indexOf] && todoList[indexOf].id) {
				this._updateContextFromSchedule(indexOf)
				await API.update("TODOS_API", todoList[indexOf].id, { status: "DONE" })
			}
		} catch (error) {
			console.error("Todos.updateFromSchedule : ", error)
		}
	}

	async addFromSchedule(schedule, laboratories, customLabel = null) {
		try {
			const { type, label } = this.getTypeFromStatus(schedule.status)
			if (type !== "RELANCE" && type !== "RETOUR_RELANCE") {
				return
			}
			const data = {
				label: customLabel || label,
				target: "ALL",
				laboratories: laboratories instanceof Array ? laboratories : [laboratories], // laboratories peut être passé comme un seul laboratoire. Il faut l'englober d'un array pour l'api.
				patient: schedule.patient,
				realisationDate: schedule.dateOf,
				type: type,
				status: "TODO",
			}
			const response = await API.create("TODOS_API", data)
			this._addContextFromSchedule(response.data, schedule.patient)
		} catch (error) {
			console.error("Todos.addTodoFromSchedule : ", error)
		}
	}

	static async add(label, laboratories, patient, type, realisationDate) {
		try {
			const data = {
				label: label,
				status: "TODO",
				target: "ALL",
				laboratories: laboratories instanceof Array ? laboratories : [laboratories], // laboratories peut être passé comme un seul laboratoire. Il faut l'englober d'un array pour l'api.
				patient: patient,
				type: type,
				realisationDate: realisationDate,
			}
			await API.create("TODOS_API", data)
		} catch (error) {
			console.error("Todos.add : ", error)
		}
	}

	static search(todos, where) {
		const result = todos.filter((v) => {
			for (let [key, value] of Object.entries(where)) {
				if (v[key] !== value) return false
			}
			return true
		})[0]
		const indexOf = todos.indexOf(result)
		return { index: indexOf, todo: result }
	}

	static updateState(todos, setTodos, where, values) {
		let data = null

		if (typeof values === "object") data = values
		else data = { status: values }

		const updatedState = [...todos]
		const result = Todos.search(todos, where)

		if (values == null) updatedState.splice(result.index, 1)
		else updatedState[result.index] = { ...updatedState[result.index], ...data }

		setTodos(updatedState)
	}

	static async update(id, param) {
		try {
			let data = null
			if (typeof param === "object") data = { status: param.status, label: param.label }
			else data = { status: param }
			return await API.update("TODOS_API", id, data)
		} catch (error) {
			console.error("Todos.update() ", error)
		}
	}

	static async fetch() {
		try {
			const date = moment().add(8, "days").format("YYYY-MM-DD")
			const params = `?realisationDate[before]=${date}`
			return await API.findAll("TODOS_API", params)
		} catch (error) {
			console.error("Todos.fetch() : ", error)
			return []
		}
	}
}
