import { HiboutikProductEntity, Logistic, LogisticProduct } from "@audiowizard/common"
import { Table } from "antd"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { useContext, useMemo, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { Link } from "react-router-dom"
import uuid from "react-uuid"
import API from "services/API"
import { getIdFromIri } from "services/functions"

type Filters = {
	status: "RETURNED" | "CREDIT_REQUESTED" | "CREDIT_RECEIVED"
	serialNumber?: string
}

const CreditRequested = (): JSX.Element => {
	const { laboratory } = useContext(AuthContext)
	const queryClient = useQueryClient()

	const [filters, setFilters] = useState<Filters>({
		status: "CREDIT_REQUESTED",
	})

	const { data: productCatalog, isLoading: catalogLoading } = useQuery(
		"PRODUCT_CATALOG",
		async (): Promise<any[]> => {
			return await API.findAll<HiboutikProductEntity[]>(
				"PRODUCTS_API",
				"?pagination=false&warehouseId=" + laboratory.warehouseIdHiboutik
			)
		}
	)

	const { data: creditRequested, isLoading: logisticLoading } = useQuery(
		["LOGISTIC_PRODUCTS_API_RETURNED", filters.status, filters.serialNumber],
		async (): Promise<any[]> => {
			let query = ""

			if (filters.status === "RETURNED") query += "?isReturned=1&isCreditRequested=0"
			else if (filters.status === "CREDIT_REQUESTED") query += "?isCreditRequested=1&isCreditReceived=0"
			else if (filters.status === "CREDIT_RECEIVED") query += "?isCreditReceived=1"

			if (filters.serialNumber) query += `&productSerialNumber=${filters.serialNumber}`

			return (await API.findAll<LogisticProduct[]>("LOGISTIC_PRODUCTS_API", query)).map((p: any) => ({
				...p,
				key: uuid(),
			}))
		},
		{
			cacheTime: 0,
		}
	)
	const isLoading = useMemo(() => catalogLoading || logisticLoading, [catalogLoading, logisticLoading])

	const tableData = useMemo(() => {
		return creditRequested?.map((product) => {
			const details: Record<string, any> =
				productCatalog?.find((catalog: any) => +catalog.id === +product.productId) ?? {}

			const sizeName = details?.sizeDetails?.find((size: any) => +size.sizeId === +product?.productSizeId)
			return { ...details, ...sizeName, ...product }
		})
	}, [productCatalog, creditRequested])

	const handleChange = async (
		event: React.ChangeEvent<HTMLSelectElement>,
		logisticProduct: LogisticProduct
	): Promise<void> => {
		const value = event.target.value
		const iri = logisticProduct["@id"]

		try {
			switch (value) {
				case "NO_MORE_CREDIT_REQUESTED":
					await API.updateWithIri(iri, { isCreditRequested: false })
					queryClient.invalidateQueries("LOGISTIC_PRODUCTS_API_RETURNED")
					break
				case "CREDIT_RECEIVED":
					await API.updateWithIri(iri, { isCreditReceived: true })
					queryClient.invalidateQueries("LOGISTIC_PRODUCTS_API_RETURNED")
					break
				case "NO_CREDIT_RECEIVED":
					await API.updateWithIri(iri, { isCreditReceived: false })
					queryClient.invalidateQueries("LOGISTIC_PRODUCTS_API_RETURNED")
					break
				case "ASK_FOR_CREDIT":
					await API.updateWithIri(iri, { isCreditRequested: true })
					queryClient.invalidateQueries("LOGISTIC_PRODUCTS_API_RETURNED")
					break

				case "NONE":
				default:
					break
			}
		} catch (error) {
			console.error(error)
		}
	}

	const dateColumn = {
		RETURNED: {
			title: "Date de renvoi",
			key: "returnDate",
		},
		CREDIT_REQUESTED: {
			title: "Date de demande d'avoir",
			key: "creditRequestedDate",
		},
		CREDIT_RECEIVED: {
			title: "Date de réception avoir",
			key: "creditReceivedDate",
		},
	}

	const actionLabel: { [key: string]: string } = {
		NONE: "Ne rien faire",
		CREDIT_RECEIVED: "Avoir reçu",
		NO_MORE_CREDIT_REQUESTED: "Retirer la demande d'avoir",
		NO_CREDIT_RECEIVED: "Avoir non reçu",
		ASK_FOR_CREDIT: "Demander un avoir",
	}

	const actionsPerFilter = {
		RETURNED: ["ASK_FOR_CREDIT"],
		CREDIT_REQUESTED: ["CREDIT_RECEIVED", "NO_MORE_CREDIT_REQUESTED"],
		CREDIT_RECEIVED: ["NO_CREDIT_RECEIVED"],
	}

	const columns = [
		{
			title: "Bon de livraison",
			dataIndex: "logistic",
			render: (logistic: Logistic) => (
				<Link
					to={"/bon-de-livraison/" + getIdFromIri(logistic?.["@id"])}
					style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
					{
						//@ts-ignore
						logistic?.label
					}
				</Link>
			),
		},
		{
			title: "Bon de retour",
			dataIndex: "productReturnForm",
			//TODO: A TYPER
			render: (productReturnForm: any) => {
				if (productReturnForm) {
					return (
						<Link
							//@ts-ignore
							to={"/mon-stock/retour/" + getIdFromIri(productReturnForm?.["@id"])}
							style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
							{
								//@ts-ignore
								productReturnForm?.label
							}
						</Link>
					)
				} else {
					return "Aucun"
				}
			},
		},
		{
			title: "Modèle",
			dataIndex: "model",
			render: (model: string, logisticProduct: LogisticProduct) => (
				<>
					<div className="font-weight-bold">{model}</div>
					<div style={{ opacity: "0.75" }}>
						{
							//@ts-ignore
							logisticProduct.sizeName ?? "Pas de déclinaison"
						}
					</div>
				</>
			),
		},
		{
			title: "Numéro de série",
			dataIndex: "productSerialNumber",
		},
		{
			title: dateColumn[filters.status].title,
			dataIndex: dateColumn[filters.status].key,
			render: (date: string) => {
				return date ? dayjs(date).format("DD/MM/YYYY") : null
			},
		},
		{
			title: "Action",
			render: (_void: void, logisticProduct: LogisticProduct) => {
				return (
					<select
						className="form-control form-control-sm"
						onChange={(event) => handleChange(event, logisticProduct)}>
						<option value="NONE">Ne rien faire</option>
						{actionsPerFilter[filters.status].map((value, key) => (
							<option value={value} key={key}>
								{actionLabel[value]}
							</option>
						))}
					</select>
				)
			},
		},
	]

	return (
		<>
			<div className="cardtabs-subtitle">Filtres</div>

			<div className="row">
				<div className="col mb-2">
					<label htmlFor="sn-filter">Numéro de série</label>
					<input
						type="text"
						className="form-control"
						placeholder="324B21"
						id="sn-filter"
						onChange={(evt) => {
							const value = evt.target.value
							setFilters((old) => ({ ...old, serialNumber: value }))
						}}
					/>
				</div>
				<div className="col">
					<label htmlFor="satatus-filter">Statut</label>
					<select
						id="status-filter"
						className="form-control"
						onChange={(evt) => {
							const value = evt.target.value
							setFilters((old) => ({ ...old, status: value as any }))
						}}>
						<option value="CREDIT_REQUESTED">En attente d'avoir</option>
						<option value="CREDIT_RECEIVED">Avoir reçu</option>
						<option value="RETURNED">Renvoyés</option>
					</select>
				</div>
			</div>
			<></>
			<div className="cardtabs-subtitle">Produits</div>
			<Table dataSource={tableData ?? []} columns={columns} loading={isLoading} />
		</>
	)
}

export default CreditRequested
