export function buildContextStore(
	store: Record<string, any>,
	t: unknown,
	data: Record<string, any>
): Record<string, any> | null {
	if (!store || !t || !data) return null

	const { user, patient, company, laboratory } = store

	return {
		context: { user, patient, company, laboratory },
		data,
	}
}
