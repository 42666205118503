import { lazy, Suspense, useContext } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import LeftBar from "../components/structure/LeftBar"
import TopBar from "../components/structure/TopBar"
import RecherchePatient from "../components/utils/RecherchePatient"
import AuthContext from "../contexts/AuthContext"
import LectureCVModal from "../pages/FSV/LectureCVModal"
import AgendaModal from "../pages/Schedules/Agenda/ModalAgendaContainer"
import SchedulingModal from "../pages/Schedules/SchedulingModal/Modal.Scheduling"
import SearchDocumentation from "components/utils/SearchDocumentation"
import PrivateRoute from "./PrivateRoute"
import StockRouter from "pages/Stock-Management/Router"
import InvoiceBuilder from "../tmp/rebuild-invoices/InvoiceBuilder"
import { confirmWithModal } from "components/effects/ConfirmModalFunction"
import { haveRole } from "services/functions"
//const TeleconsultationUser = lazy(() => import("../pages/teleconsultation/User"))
const TeletransmissionSuivi = lazy(() => import("../pages/FSV/SuiviTeletransmission"))

const Settings = lazy(() => import("../pages/Settings/ScheduleTypes"))
const HomePage = lazy(() => import("../pages/accueil/HomePage"))
const Courriers = lazy(() => import("../pages/courriers/Index"))
const PremiereVisite = lazy(() => import("../pages/etapes/0-premiere-visite/Index"))
const Visite = lazy(() => import("../pages/etapes/5-visite/Index"))
const SuiteVisite = lazy(() => import("../pages/etapes/0-suite-visite/Index"))
const Adaptation = lazy(() => import("../pages/etapes/1-adaptation/Index"))
const Essais = lazy(() => import("../pages/etapes/2-essais/Index"))
const Appareille = lazy(() => import("../pages/etapes/3-appareille/Index"))
const Relance = lazy(() => import("../pages/etapes/4-relance/Index"))
const FichePatient = lazy(() => import("../pages/fiche-patient/0-FichePatient"))
const Inscription = lazy(() => import("../pages/inscription/Index"))
const Utilisateur = lazy(() => import("../pages/mon-compte/Index"))
const RendezVous = lazy(() => import("../pages/rendez-vous/Index"))
const SalleAttenteUI = lazy(() => import("../pages/salle-attente/Index"))
const Statistiques = lazy(() => import("../pages/statistiques/Index"))
const MonEquipe = lazy(() => import("../pages/mon-compte/mon-equipe/Index"))
const MesInvitations = lazy(() => import("../pages/mon-compte/mes-invitations/index"))
const ActionsFSV = lazy(() => import("../pages/FSV/ActionsFSV.jsx"))
const SuccessFSV = lazy(() => import("../pages/FSV/Success.jsx"))
const FeuillePret = lazy(() => import("../pages/feuille-pret/FeuillePret"))
const FeuilleSoin = lazy(() => import("../pages/feuille-soin/FeuilleSoin"))
const Vente = lazy(() => import("../pages/vente/Vente"))
const BillingView = lazy(() => import("features/sales/views/billing/BillingView"))

const Agenda = lazy(() => import("../pages/Schedules/Agenda/AgendaContainer"))
const Agenda2 = lazy(() => import("../pages/mon-compte/Agenda/MobileView"))
const RendezVousNote = lazy(() => import("../pages/rendez-vous/Notes"))

const ListeBL = lazy(() => import("../pages/bl/ListeBL"))
const FactureFournisseur = lazy(() => import("../pages/bl/FactureFournisseur"))
const ListeFactures = lazy(() => import("../pages/bl/ListeFactures"))
const ValeurMarchandeStock = lazy(() => import("../pages/bl/ValeurMarchandeStock"))
const Rgpd = lazy(() => import("../pages/etapes/0-premiere-visite/0-wizard-premiere-visite/0-0-ConsentemenRgpd"))
const MesPatients = lazy(() => import("../pages/MesPatients"))
const MesRendezvous = lazy(() => import("../pages/MesRendezvous"))
const MesMutuelles = lazy(() => import("../pages/mon-compte/MesMutuelles"))
const NouveauSAV = lazy(() => import("../pages/sav/NouveauSAV"))
const ListeSAV = lazy(() => import("../pages/sav/ListeSAV"))
const ListePersonnalisations = lazy(() => import("../pages/mon-compte/mes-personnalisations/ListePersonnalisations"))
const TemplateForm = lazy(() => import("../pages/templates/TemplateForm/TemplateForm"))
const Mandate = lazy(() => import("../pages/Mandate"))

const Stats = lazy(() => import("../pages/Stats/Index"))

const FAQ = lazy(() => import("../pages/faq/index"))

const NouveauBL = lazy(() => import("../pages/bl/NouveauBL"))
const NouveauBLAncienSysteme = lazy(() => import("../pages/bl/NouveauBL.OLD"))
const HistoriqueEquipements = lazy(() => import("../pages/Gestion-Stock/HistoriqueEquipements"))
const HistoriqueRendezvous = lazy(() => import("../pages/fiche-patient/HistoriqueRendezVous"))

////////////// CAISSE ET COMPTABILITÉ
const CaisseDepot = lazy(() => import("../pages/mon-compte/ma-caisse/Depot"))
const CaisseReassort = lazy(() => import("../pages/mon-compte/ma-caisse/ReassortCaisse"))
const CaisseValidation = lazy(() => import("../pages/mon-compte/ma-caisse/EspValidationCaisse"))
const CaisseHistorique = lazy(() => import("../pages/mon-compte/ma-caisse/EspHistoriqueCaisse"))
const ListePaiementsRecu = lazy(() => import("../pages/mon-compte/ma-caisse/PaiementRecu"))
const ListePaiementsTeletransmissionSuiviRecus = lazy(() => import("../pages/FSV/PaiementTeletransSuiviRecu"))
const ExportComptable = lazy(() => import("../pages/mon-compte/comptabilite/ExportComptable"))
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConfigurationExportComptable = lazy(() => import("../pages/mon-compte/comptabilite/ConfigurationExportComptable"))
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PersonnalisationExportComptable = lazy(() =>
	import("../pages/mon-compte/comptabilite/PersonnalisationExportComptable")
)
const GestionChequeCB = lazy(() => import("../pages/mon-compte/ma-caisse/GestionChequesCB"))
// const GestionFactures = lazy(() => import("../pages/mon-compte/comptabilite/GestionFactures"))
//////////////

const ListeDocument = lazy(() => import("../pages/fiche-patient/Documents/Liste"))
const RestoreMissingDocuments = lazy(() => import("../pages/fiche-patient/Documents/RestoreMissingDocuments"))
const SignDocumentPage = lazy(() =>
	import("../pages/fiche-patient/Documents/SignDocument").then((module) => ({ default: module.SignDocumentPage }))
)

////////////// GESTION STOCK
const Inventaire = lazy(() => import("../pages/Inventaire/Index.jsx"))
const FicheProduit = lazy(() => import("../pages/Gestion-Stock/FicheProduit"))
const TransfertNouveau = lazy(() => import("../pages/StockTransfert/Nouveau"))
const TransfertListe = lazy(() => import("../pages/StockTransfert/Liste"))
const TransfertDetails = lazy(() => import("../pages/StockTransfert/Details"))

const Documentation = lazy(() => import("../pages/Documentation"))

const PrivateRouter = () => {
	const { user, patientUI, uiDisplay, setUiDisplay } = useContext(AuthContext)
	const patientUILayout = "w-100 patientLayout"
	const regularUILayout = "col-lg-10 col-xl-11"

	// eslint-disable-next-line
	//const hasBasicService = useMemo(() => services?.find((s) => s.isMain)?.isActive, [services])

	// Cache entièrement la leftbar et la topbar via le CSS (.fullscreen-agenda-hideleftbar)
	const shouldHideLeftBarAndTopBar =
		window.location.pathname.includes("success-fsv") || window.location.pathname.includes("/agenda")

	// Cache entièrement la leftbar via le JSX
	const shouldHideLeftBar = shouldHideLeftBarAndTopBar || patientUI || uiDisplay.onboarding

	return (
		//getUserConfirmation permet de demander confirmation de l'utilisateur s'il quitte une page où le composant Prompt est appellé
		<BrowserRouter
			getUserConfirmation={async (message, callback) => {
				const response = await confirmWithModal({
					title: "⚠️ Attention vous risquez de perdre vos données !",
					text: message,
				})
				callback(response)
			}}>
			{uiDisplay?.agendaModal && (
				<AgendaModal
					setShow={() => setUiDisplay((old) => ({ ...old, agendaModal: false }))}
					fromAgenda={true}
					fromNewSchedule={false}
					onlyAvailable={false}
				/>
			)}
			{uiDisplay?.lectureCVModal && (
				<LectureCVModal
					isOpen={uiDisplay?.lectureCVModal}
					mode={uiDisplay?.mode}
					onClose={() => setUiDisplay({ ...uiDisplay, lectureCVModal: false })}
				/>
			)}
			{uiDisplay?.scheduleModal && (
				<SchedulingModal
					fromNewSchedule={false}
					setShowModal={(status) => {
						setUiDisplay((old) => ({ ...old, scheduleModal: status }))
					}}
				/>
			)}
			<div className={shouldHideLeftBarAndTopBar ? "fullscreen-agenda-hideleftbar" : ""}>
				<TopBar />
				<div className={"row mx-0 "} style={{ height: "calc(100vh - 72px)" }}>
					{/* LEFTBAR CONTAINER */}
					{!shouldHideLeftBar && (
						<div
							className="col-md-2 col-lg-2 bg-secondary d-sm-block d-lg-block locked"
							id="leftBar-fullScreen">
							<LeftBar />
						</div>
					)}
					<div
						id="main-body"
						className={patientUI ? patientUILayout : `col-xs-12 pt-px-2 pb-px-2 ${regularUILayout}`}>
						<Suspense fallback={<div className="bg-secondary w-100 h-100" />}>
							{/* Force la page Mandate si l'inscription est terminé, si la période d'essaie du service basic est fini et qu'il n'y a pas de mandat gocardless actif */}
							{
								/*user.gdprAgreement && !user.company?.audioWizardContract?.hasMandate && !hasBasicService

						TODO: Remettre la condtion une fois que les utilisateurs existants ont été migré
						*/ false ? (
									<Mandate />
								) : (
									<Switch>
										<PrivateRoute
											path="/teletransmission/suivi"
											component={TeletransmissionSuivi}
										/>

										<PrivateRoute
											path="/mon-compte/mes-personnalisations"
											component={ListePersonnalisations}
										/>
										<PrivateRoute path="/settings" component={Settings} />
										<PrivateRoute
											path="/mon-compte/edition/gabarits/templates/:id"
											component={TemplateForm}
										/>
										<PrivateRoute path="/mon-compte/edition/gabarits" component={TemplateForm} />
										<PrivateRoute path="/mon-compte/mon-equipe" component={MonEquipe} />
										<PrivateRoute path="/mon-compte/mes-invitations" component={MesInvitations} />
										<PrivateRoute path="/mon-compte/mes-mutuelles" component={MesMutuelles} />

										{/*<PrivateRoute path="/mon-compte/consultation/:user" component={TeleconsultationUser} />*/}

										{/* CAISSE ET COMPTABILITE *************/}
										<PrivateRoute
											path="/mon-compte/caisse/validation"
											component={CaisseValidation}
										/>
										<PrivateRoute path="/mon-compte/caisse/depot" component={CaisseDepot} />
										<PrivateRoute path="/mon-compte/caisse/reassort" component={CaisseReassort} />
										<PrivateRoute
											path="/mon-compte/caisse/historique"
											component={CaisseHistorique}
										/>
										<PrivateRoute path="/mon-compte/gestion-cheques" component={GestionChequeCB} />
										<PrivateRoute
											path="/mon-compte/gestion-carte-bancaire"
											component={GestionChequeCB}
										/>
										<PrivateRoute
											path="/mon-compte/liste-paiements/:filter"
											component={ListePaiementsRecu}
										/>
										<PrivateRoute
											path="/mon-compte/liste-paiements"
											component={ListePaiementsRecu}
										/>
										<PrivateRoute
											path="/teletransmission/suivi-des-suivi"
											component={ListePaiementsTeletransmissionSuiviRecus}
										/>
										<PrivateRoute
											path="/mon-compte/export-comptable/parametres/:personalizationId"
											component={ConfigurationExportComptable}
										/>
										<PrivateRoute
											path="/mon-compte/export-comptable/personnalisation"
											component={PersonnalisationExportComptable}
										/>
										<PrivateRoute path="/mon-compte/export-comptable" component={ExportComptable} />
										{/**************************************/}

										<PrivateRoute path="/actions-fsv" component={ActionsFSV} />
										<PrivateRoute path="/success-fsv" component={SuccessFSV} />
										<PrivateRoute path="/inscription/" component={Inscription} />

										<PrivateRoute path="/rendez-vous/notes" component={RendezVousNote} />
										<PrivateRoute path="/mon-compte/:id" component={Utilisateur} />
										<PrivateRoute path="/devis/:id" component={PremiereVisite} />
										<PrivateRoute path="/premiere-visite/:id" component={PremiereVisite} />
										<PrivateRoute path="/visite/:id" component={Visite} />
										<PrivateRoute path="/suite-visite/:id" component={SuiteVisite} />
										<PrivateRoute
											path="/salle-attente/:id"
											component={SalleAttenteUI}
											allowPatientUI
										/>
										<PrivateRoute path="/adaptation/:id" component={Adaptation} />
										<PrivateRoute path="/essais/:id" component={Essais} />
										<PrivateRoute path="/appareille/:id" component={Appareille} />
										<PrivateRoute path="/relance/:id" component={Relance} />
										<PrivateRoute path="/agenda/month/:year/:month" component={Agenda2} />
										<PrivateRoute path="/agenda/month" component={Agenda2} />
										<PrivateRoute path="/agenda" component={Agenda} />

										<PrivateRoute path="/documents/my">
											<ListeDocument documentListType="USER" />
										</PrivateRoute>
										<PrivateRoute path="/documents/patient/:id">
											<ListeDocument documentListType="PATIENT" />
										</PrivateRoute>
										<PrivateRoute path="/documents/restore">
											<RestoreMissingDocuments />
										</PrivateRoute>
										<PrivateRoute path="/documents/signer/:id" component={SignDocumentPage} />

										<PrivateRoute
											path="/fiche-patient/:id/historique-rendez-vous"
											component={HistoriqueRendezvous}
										/>
										<PrivateRoute path="/fiche-patient/:id" component={FichePatient} />

										<PrivateRoute path="/facture-suivi/:id" component={BillingView} />
										<PrivateRoute path="/facture-client/:id" component={BillingView} />
										<PrivateRoute path="/facture-dynamic/:id" component={BillingView} />

										<PrivateRoute path="/valeur-marchande-stock" component={ValeurMarchandeStock} />

										{/* GESTION DU STOCK */}
										<PrivateRoute
											path="/stock/fiche-produit/:product_id"
											component={FicheProduit}
										/>
										<PrivateRoute
											path="/historique-equipement/:patientEquipmentId?"
											component={HistoriqueEquipements}
										/>
										<PrivateRoute path="/nouveau-bon-de-livraison" component={NouveauBL} />
										<PrivateRoute path="/bon-de-livraison/:id" component={NouveauBL} />
										<PrivateRoute
											path="/bon-de-livraison-ancien/:numero"
											component={NouveauBLAncienSysteme}
										/>
										<PrivateRoute path="/transfert-stock/nouveau" component={TransfertNouveau} />
										<PrivateRoute path="/transfert-stock/:id" component={TransfertDetails} />
										<PrivateRoute path="/transfert-stock" component={TransfertListe} />

										<PrivateRoute
											path="/nouvelle-facture-fournisseur"
											component={FactureFournisseur}
										/>
										<PrivateRoute
											path="/facture-fournisseur/:numero"
											component={FactureFournisseur}
										/>
										<PrivateRoute path="/factures-fournisseur" component={ListeFactures} />
										<PrivateRoute path="/bon-de-livraison" component={ListeBL} />
										<PrivateRoute
											path="/courriers/:id/templates/:templateId"
											component={Courriers}
										/>
										<PrivateRoute path="/courriers/:id" component={Courriers} />
										<PrivateRoute path="/evenements/:id/:step?" component={RendezVous} />
										<PrivateRoute path="/statistiques" component={Statistiques} />
										<PrivateRoute path="/feuille-pret" component={FeuillePret} />
										<PrivateRoute path="/feuille-soin" component={FeuilleSoin} />
										<PrivateRoute path="/vente/:id" component={Vente} />
										<PrivateRoute path="/vente" component={Vente} />
										<PrivateRoute path="/mes-donnees-rgpd" component={Rgpd} />
										<PrivateRoute path="/mes-patients/:status" component={MesPatients} />
										<PrivateRoute path="/mes-patients/" component={MesPatients} />
										<PrivateRoute path="/mes-rendezvous" component={MesRendezvous} />
										<PrivateRoute path="/sav/:numero" component={NouveauSAV} />
										<PrivateRoute path="/sav" component={ListeSAV} />
										<PrivateRoute path="/nouveau-sav" component={NouveauSAV} />
										{!haveRole(user, "ROLE_ASSISTANT") && (
											<PrivateRoute path="/stats" component={Stats} />
										)}
										<PrivateRoute path="/faq" component={FAQ} />

										<PrivateRoute path="/inventaire" component={Inventaire} />
										<PrivateRoute path="/rebuild/invoices" component={InvoiceBuilder} />
										<PrivateRoute path="/documentation/:id" component={Documentation} />

										<Route path="/mon-stock/">
											<StockRouter />
										</Route>

										<PrivateRoute path="/" component={HomePage} />
									</Switch>
								)
							}
						</Suspense>
					</div>
				</div>
			</div>
			<RecherchePatient />
			<SearchDocumentation />
		</BrowserRouter>
	)
}

export default PrivateRouter
