import React, { useState } from "react"
import cx from "classnames"
import { toast } from "react-toastify"
import { Button, Spin } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import exportInvoices from "./Script"
import { useGenerateDocument } from "./useGenerateDocument"
import { isNil, some } from "lodash"

type InvoiceBuilderProps = {
	className?: string
}

export default function InvoiceBuilder({ className }: InvoiceBuilderProps): JSX.Element {
	const [loading, setLoading] = useState(false)
	const [ratio, setRatio] = useState({ nominator: null, denominator: null })
	const generateInvoice = useGenerateDocument()
	const classes: string = cx(
		"invoice-builder",
		"d-flex flex-column justify-content-center align-items-center h-100",
		className
	)

	async function handleClick(detailed?: boolean): Promise<void> {
		setLoading(true)
		try {
			await exportInvoices(
				undefined,
				"TODAY",
				null,
				[
					"Janvier",
					"Février",
					"Mars",
					"Avril",
					"Mai",
					"Juin",
					"Juillet",
					"Août",
					"Septembre",
					"Octobre",
					"Novembre",
					"Décembre",
				],
				generateInvoice,
				setRatio,
				detailed
			)
		} catch (e) {
			toast.error("Une erreur est survenue lors de l'export des factures")
			console.error(e)
		}
		setLoading(false)
	}

	return (
		<div className={classes}>
			<Button
				className="mb-5"
				disabled={loading}
				type="primary"
				icon={<DownloadOutlined />}
				onClick={() => handleClick(true)}>
				Télécharger toutes les factures avec le détail des paiements
			</Button>
			<Button
				className="mb-5"
				disabled={loading}
				type="primary"
				icon={<DownloadOutlined />}
				onClick={() => handleClick()}>
				Télécharger toutes les factures sans le détail des paiements
			</Button>
			{loading && (
				<div className="d-flex flex-column justify-content-center align-items-center">
					<Spin className="mb-3" />
					<p>
						Génération en cours{" "}
						{!some(ratio, isNil) && (
							<>
								({ratio?.nominator}/{ratio?.denominator})
							</>
						)}{" "}
						...
					</p>
				</div>
			)}
		</div>
	)
}
