import { AfterSale, HiboutikProductEntity, PatientEquipment } from "@audiowizard/common"
import useCustomTitle from "components/Hooks/useTitle"
import { useContext, useEffect, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import useEffectAsync from "../../../components/Hooks/useEffectAsync"
import AuthContext from "../../../contexts/AuthContext"
import API from "../../../services/API"
import API_Hiboutik from "../../../services/API_Hiboutik"
import { extractAvailableSerialnumber } from "../StockUtils"
import { parseData } from "./ExportData"
import Filters from "./Filters"
import * as Types from "./Types"

const defaultDatabaseData: Types.DatabaseData = {
	products: [],
	availableSerialNumber: [],
	sizes: [],
	brands: [],
	suppliers: [],
	categories: [],
	equipments: [],
	afterSale: [],
	loaded: false,
}

const defaultFilterValues: Types.Filters = {
	supplierId: null,
	brandId: null,
	productId: null,
	categoryId: null,
	warehouseId: 1,
	depot: "ALL",
	equipment: "ONLY_STOCK",
	notDisplayedColumn: [],
}

const Exports = (): JSX.Element => {
	useCustomTitle("Stock | Export")

	const { user, laboratory } = useContext(AuthContext)

	const [filters, setFilters] = useState<Types.Filters>({
		...defaultFilterValues,
		warehouseId: laboratory?.warehouseIdHiboutik ?? 1,
	})

	const [exportData, setExportData] = useState<any[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const csvLinkRef = useRef()
	const databaseData = useRef<Types.DatabaseData>(defaultDatabaseData)

	const getDataFromDatabase = async (): Promise<void> => {
		if (databaseData.current.loaded) return
		setLoading(true)

		const sizeDetails = (await API_Hiboutik.getAllSizes())?.data
		const brands = await API_Hiboutik.getBrands()
		const suppliers = await API_Hiboutik.getSuppliers()
		const categories = await API_Hiboutik.getAllCategories()

		const stock = await API.findAll<HiboutikProductEntity[]>(
			"STOCK_AVAILABLE_API",
			`?warehouseId=${filters?.warehouseId}&all_products=true`
		)

		const availableSerialNumbers = extractAvailableSerialnumber(stock)

		const afterSale = await API.findAll<AfterSale[]>("SAV_API", "?pagination=false")
		const patientEquipment = await API.findAll<PatientEquipment[]>(
			"PATIENT_EQUIPMENTS_API",
			`?warehouseIdHiboutik=${filters?.warehouseId}&status[]=VENDU`
		)

		databaseData.current = {
			products: stock,
			availableSerialNumber: availableSerialNumbers,
			sizes: sizeDetails,
			afterSale: afterSale,
			equipments: patientEquipment,
			loaded: true,
			brands,
			suppliers,
			categories,
		}
		setLoading(false)
	}

	useEffectAsync(async () => {
		await getDataFromDatabase()
		setExportData(parseData(databaseData, filters))
	}, [])

	const generateHeader = (): any[] => {
		const headers = [
			{
				label: "Fournisseur",
				key: "productSupplier",
			},
			{
				label: "Marque",
				key: "productBrand",
			},
			{
				label: "Catégorie",
				key: "productCategory",
			},
			{
				label: "Model",
				key: "productModel",
			},
			{
				label: "Déclinaison",
				key: "sizeName",
			},
			{
				label: "Quantité",
				key: "quantity",
			},
			{
				label: "Numéro de série",
				key: "serialNumber",
			},
			{
				label: "Statut équipement",
				key: "equipmentStatus",
			},
			{
				label: "Patient",
				key: "patient",
			},
			{
				label: "Date de mise en essai",
				key: "tryoutDate",
			},
			{
				label: "Date de vente",
				key: "soldDate",
			},
			{
				label: "Statut produit",
				key: "productStatus",
			},
			{
				label: "Date de réception",
				key: "receivedDate",
			},
			{
				label: "Date de fin de dépôt",
				key: "depotEndDate",
			},
			{
				label: "Crédit reçu",
				key: "creditReceivedDate",
			},
			{
				label: "Date de renvoi",
				key: "returnedDate",
			},
		]

		return headers.filter(
			(header) =>
				!filters.notDisplayedColumn.includes(header.key) && exportData.some((data) => data[header.key] != null)
		)
	}

	useEffectAsync(async () => {
		try {
			setLoading(true)
			const stock = await API.findAll<HiboutikProductEntity[]>(
				"STOCK_AVAILABLE_API",
				`?warehouseId=${filters?.warehouseId}`
			)

			const availableSerialNumbers = extractAvailableSerialnumber(stock)

			databaseData.current = {
				...databaseData.current,
				products: stock,
				availableSerialNumber: availableSerialNumbers,
			}
			setExportData(parseData(databaseData, filters))
			setLoading(false)
		} catch (error) {
			databaseData.current = {
				...databaseData.current,
				products: [],
				availableSerialNumber: [],
			}
			console.error(error)
		}
	}, [filters.warehouseId])

	useEffect(() => {
		setExportData(parseData(databaseData, filters))
	}, [filters])

	return (
		<>
			<div className="cardtabs-subtitle">Filtres</div>
			<Filters
				loading={loading}
				setFilters={setFilters}
				filters={filters}
				user={user}
				laboratory={laboratory}
				databaseData={databaseData}
			/>
			<div className="cardtabs-subtitle">Télécharger</div>
			<button
				disabled={loading}
				type={"button"}
				className={"btn btn-block btn-primary"}
				onClick={() => {
					//@ts-ignore
					csvLinkRef?.current?.link?.click()
				}}>
				Télécharger en format CSV
			</button>
			<div className="cardtabs-subtitle">Aperçu</div>
			<div className={"bordered-preview"}>
				<table className="table table-striped table-hover table-bordered">
					<thead>
						<tr style={{ backgroundColor: "#999", color: "#fff", fontWeight: "bold" }}>
							{generateHeader().map((col, key) => (
								<td key={key}>{col.label}</td>
							))}
						</tr>
					</thead>
					<tbody>
						{exportData.map((product, key) => (
							<tr key={key}>
								{generateHeader().map((col, key) => (
									<td key={key} style={{ minWidth: "140px" }}>
										{product[col.key]}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<strong>{generateHeader().length}</strong> colonne(s), <strong>{exportData.length}</strong> ligne(s) <br />
			<CSVLink
				// @ts-ignore
				ref={csvLinkRef}
				data={exportData}
				headers={generateHeader()}
				separator={";"}
				filename={"Export_Stock"}
				className="hidden"
				target="_blank"
			/>
		</>
	)
}

export default Exports
