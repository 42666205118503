/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteTwoTone, DownloadOutlined, PlusCircleOutlined, StarFilled, StarTwoTone } from "@ant-design/icons"
import { HiboutikProductEntity, ProductReturnForm } from "@audiowizard/common"
import { notification, Table, Tabs } from "antd"
import axios from "axios"
import LoadingModal from "components/effects/ModalLoading"
import useEffectAsync from "components/Hooks/useEffectAsync"
import useCustomTitle from "components/Hooks/useTitle"
import { API_URL } from "config"
import AuthContext from "contexts/AuthContext"
import dayjs from "dayjs"
import { useContext, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import uuid from "react-uuid"
import API from "services/API"
import "../style.scss"
import { Filters } from "./Filters"
import { generateReturnFormPdf } from "./GenerateDocument"
import CreditRequested from "./ReturnedProduct"
import { useGenerateDocument } from "./Template/GenerateDocument"

const ReturnFormList = (): JSX.Element => {
	useCustomTitle("Stock | Liste des retours")

	const generateDocument = useGenerateDocument()

	const history = useHistory()
	const { laboratory } = useContext(AuthContext)

	const filterTimeout = useRef<NodeJS.Timeout | null>(null)

	const [returnForm, setReturnForm] = useState<any[]>([])
	const [needRefresh, setNeedRefresh] = useState<boolean>(true)
	const [totalItems, setTotalItens] = useState<number>(0)

	const [generatingPdf, setGeneratingPdf] = useState<boolean>(false)

	const [loading, setLoading] = useState<Record<string, boolean>>({
		stats: true,
		table: true,
	})

	const [filters, setFilters] = useState<Record<string, any>>({
		"order[createdAt]": "DESC",
		"status[null]": "true",
		"logisticProducts.logistic.stockIdHiboutik": laboratory.warehouseIdHiboutik,
	})

	const { data: productCatalog } = useQuery(
		"PRODUCT_CATALOG",
		async () => {
			return (await axios.get(API_URL + "/products?pagination=false"))?.data?.[
				"hydra:member"
			] as HiboutikProductEntity[]
		},
		{ cacheTime: 240 }
	)

	const downloadPdf = async (row: ProductReturnForm): Promise<void> => {
		try {
			const returnForm = await API.get<ProductReturnForm>(row["@id"])

			if (returnForm?.url) {
				window.open(returnForm?.url, "_blank")
				return
			}

			setGeneratingPdf(true)
			await generateReturnFormPdf(generateDocument, +row.id!, returnForm, productCatalog)
			setGeneratingPdf(false)
		} catch (error) {
			console.error(error)
		}
	}

	const columns = [
		{
			title: "Date de création",
			dataIndex: "createdAt",
			width: "230px",
			render: (d: string) => {
				return dayjs(d).format("DD/MM/YYYY HH:ss")
			},
		},
		{
			title: "Label",
			dataIndex: "label",
			render: (label: string, row: ProductReturnForm) => {
				let labelStr = label ?? "Retour sans nom"
				let color = "text-black"
				if (!row.productCount) {
					color = "text-warning"
					labelStr += " (aucun produit dans ce retour)"
				}
				return <span className={color}>{labelStr}</span>
			},
		},
		{
			title: "Nb produits",
			width: "120px",
			dataIndex: "productCount",
			align: "center",
			render: (count: number) => <span className="">{count}</span>,
		},
		{
			title: "Action",
			width: "120px",
			render: (_: any, row: ProductReturnForm) => {
				const isFavorite = row.status === "FAVORITE"
				if (!row.productCount) {
					return (
						<>
							<ReactTooltip type="error" effect="float" />
							<div
								title="Supprimer le retour"
								data-tip="Supprimer le retour"
								className="no-row-click cursor-pointer"
								onClick={async () => {
									try {
										setLoading((old) => ({ ...old, table: true }))
										await API.delete(row["@id"])
										setNeedRefresh(true)
									} catch (error) {
										console.error(error)
									}
								}}>
								<DeleteTwoTone className="text-lg" twoToneColor="#ff3333" />
							</div>
						</>
					)
				}
				return (
					<div className="row">
						<ReactTooltip type="warning" effect="float" />
						<div
							title={isFavorite ? "Retirer le status prioritaire" : "Mettre en priorité"}
							data-tip={isFavorite ? "Retirer le status prioritaire" : "Mettre en priorité"}
							data-type={isFavorite ? "warning" : "success"}
							className="no-row-click cursor-pointer col"
							onClick={async () => {
								try {
									setLoading((old) => ({ ...old, table: true }))
									await API.update("PRODUCT_RETURN_FORM_API", row.id!, {
										status: isFavorite ? null : "FAVORITE",
									})
									setNeedRefresh(true)
								} catch (error) {
									console.error(error)
								}
							}}>
							{isFavorite ? (
								<StarFilled className="text-lg text-warning" />
							) : (
								<StarTwoTone className="text-lg" twoToneColor="#55dd99" />
							)}
						</div>
						<div
							onClick={() => downloadPdf(row)}
							title="Télécharger le bon de retour"
							className="no-row-click cursor-pointer col"
							data-type="success"
							data-tip="Télécharger le bon de retour">
							<DownloadOutlined className="text-lg text-primary" />
						</div>
					</div>
				)
			},
			align: "center",
		},
	]

	useEffectAsync(async () => {
		try {
			if (!needRefresh) return
			setLoading((old) => ({ ...old, table: true }))

			const filtersQuery = Object.entries(filters)
				.map((v) => v.join("="))
				.join("&")

			const result = await API.findAll<ProductReturnForm[]>("PRODUCT_RETURN_FORM_API", "?&" + filtersQuery, true)
			const resultFavorite = await API.findAll<ProductReturnForm[]>("PRODUCT_RETURN_FORM_API", "?status=FAVORITE")

			setReturnForm(
				[...(resultFavorite ?? []), ...(result["hydra:member"] ?? [])].map((e: any) => ({ ...e, key: uuid() }))
			)
			setTotalItens(result["hydra:totalItems"] ?? 0)
		} catch (error) {
			console.error(error)
			notification.warn({ message: "Impossible de récupérer la liste des retours", top: 70 })
		} finally {
			setLoading((old) => ({ ...old, table: false }))
			setNeedRefresh(false)
		}
	}, [needRefresh])

	useEffect(() => {
		clearTimeout(filterTimeout.current as NodeJS.Timeout)
		filterTimeout.current = setTimeout(() => {
			setNeedRefresh(true)
		}, 250)
	}, [filters])

	// @ts-ignore
	return (
		<>
			<Tabs defaultActiveKey="1" size="large">
				<Tabs.TabPane tab="Liste des bons de retours" key="1">
					<div className="cardtabs-subtitle">Filtres</div>
					<Filters setFilters={setFilters} filters={filters} />
					<div className="cardtabs-subtitle">Liste des retours</div>
					<Link to="/mon-stock/nouveau-retour">
						<button type="button" className="btn btn-primary d-flex align-items-center">
							<PlusCircleOutlined className="mr-1" /> Nouveau retour
						</button>
					</Link>

					<Table
						onRow={(record) => {
							return {
								onClick: (evt) => {
									//@ts-ignore
									if (!evt.target?.classList?.contains("ant-table-cell")) return
									history.push("/mon-stock/retour/" + record.id)
								},
							}
						}}
						loading={loading.table}
						className="mt-2"
						dataSource={returnForm}
						//@ts-ignore
						columns={columns}
						rowClassName={(e) => {
							if (e.status === "FAVORITE") return "font-weight-bold bg-secondary"
							return ""
						}}
						pagination={{
							position: ["bottomLeft"],
							current: filters.page ?? 1,
							total: totalItems,
							showSizeChanger: true,
							// pageSize: 1,
							onChange: (page, perPage) => {
								setFilters((old) => ({ ...old, page, perPage }))
								setNeedRefresh(true)
							},
						}}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Produits renvoyés" key="2">
					<CreditRequested />
				</Tabs.TabPane>
			</Tabs>

			<LoadingModal isOpen={generatingPdf} message="Chargement du document..." />
		</>
	)
}

export default ReturnFormList
