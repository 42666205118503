import { AxiosResponse } from "axios"
import { API_URL } from "../../config"

interface Rules {
	name: string
	post?: string | RegExp
	put?: string | RegExp
	delete?: string | RegExp
}

const RULES: Rules[] = [
	{
		name: "schedule",
		post: `${API_URL}/schedules/?`,
		put: `${API_URL}/schedules/(\\d+)`,
		delete: `${API_URL}/schedules/(\\d+)`,
	},
]

const checkRule = (method: string, url: string): Rules | null => {
	for (const rule of RULES) {
		try {
			const regex = new RegExp((rule as any)?.[method])
			if (regex.test(url)) {
				return rule
			}
		} catch (error) {
			console.error(error)
		}
	}
	return null
}

const handleId = (slugid: string | { id: number }): number => {
	if (typeof slugid === "string" && /\/(.*)\/\d+/.test(slugid)) {
		const splitted = slugid.split("/")
		return +splitted?.[splitted.length - 1]
	}

	if (typeof slugid === "object") return slugid.id

	throw new Error("Wrong slugid, IRI, or ID can be used")
}

const handleSchedule = (method: string, url: string, socket: any, data: any): void => {
	try {
		let id = data?.id

		if (method === "delete") {
			const regmatch: RegExpMatchArray | null = url.match(/.*\/(\d+)/)
			if (!regmatch?.[1]) return
			id = regmatch[1]
		}

		socket.emit("schedule", {
			method,
			data: {
				id: id,
				user: handleId(data.user) || null,
				state: data.state || null,
				status: data.status || null,
				patient: {
					id: data?.patient?.id,
					firstName: data?.patient?.firstName,
					lastName: data?.patient?.lastName,
				},
				laboratory: handleId(data.laboratory) || null,
			},
		})
	} catch (error) {
		console.error(error)
		throw error
	}
}

const dispatcher = {
	schedule: handleSchedule,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const interceptResponse = async (response: AxiosResponse, socket: any): Promise<any> => {
	try {
		const request = response.config
		if (request.method === "get") return response
		const rule = checkRule(request.method!, request.url!)
		if (!rule) return response
		;(dispatcher as any)?.[rule.name]?.(request.method, request.url, socket, response.data)
	} catch (error) {
		console.error(error)
	}
	return response
}

const RealtimeNotificationSystem = {
	interceptResponse,
}

export default RealtimeNotificationSystem
