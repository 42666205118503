import { UserOutlined } from "@ant-design/icons"
import { Patient } from "@audiowizard/common"
import dayjs from "dayjs"
import { Link } from "react-router-dom"

export const PatientResults = ({ patient }: { patient: Patient }): JSX.Element => {
	return (
		<Link to={"/fiche-patient/" + patient.id} className="item">
			<div className="d-flex align-items-start">
				<UserOutlined className="ml-3 mr-1 mt-1" />
				<div>
					<span className="font-weight-bold">{patient?.lastName}</span> {patient?.firstName}
					{patient?.birthDate && <span>, {dayjs().diff(patient.birthDate, "year")} ans</span>}
				</div>
			</div>
		</Link>
	)
}
