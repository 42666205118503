export enum EquipmentStatus {
	Attente = "ATTENTE",
	Vendu = "VENDU",
	SAV = "SAV",
	Essai = "ESSAI",
	EnDepot = "EN_DEPOT",
	Exterieur = "EXTERIEUR",
	Renouvellement = "RENOUVELLEMENT",
	RenouvellementVendu = "[RENOUVELLEMENT] VENDU",
}

export enum EquipmentClass {
	Unknown = 0,
	FirstClass = 1,
	SecondClass = 2,
}

export enum EquipmentCategory {
	Auditif = "AUDITIF",
}
