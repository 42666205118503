import calculateTotalAmounts from "./amountCalculator"

import { buildCompanyLogo, buildFooter } from "utils/templates/Common.helper"
import { Laboratory, Patient, User } from "@audiowizard/common"
import { UseTranslationOptions } from "react-i18next"

export function buildContextStore(
	user: User,
	laboratory: Laboratory,
	patient: Patient,
	store: { [key: string]: any } | undefined,
	t: ((path: string, options?: UseTranslationOptions, prePath?: string) => string) | undefined,
	data: { [key: string]: any } | undefined
): any {
	if (!store || !t || !data) return null

	const { company } = store

	return {
		context: { user, patient, company, laboratory },
		invoice: {
			data,
			calculatedData: calculateTotalAmounts(data),
			companyLogo: buildCompanyLogo(store, t),
			footer: buildFooter(store, t),
		},
	}
}
