const HIB = {
	PRODUCTS: /(.*)\/store\/products\/search\/?(.*)/,
	PRODUCT: /(.*)\/store\/product\/(\d+)/,
	PRODUCTS_TAGS: /(.*)\/store\/tags\/products/,
	SIZES: /(.*)\/store\/sizes\/\d+/,
	SIZES_TYPES: /(.*)\/store\/size_types/,
	SIZES_PRETTY: /(.*)\/store\/sizes_pretty/,
	CATEGORIES: /(.*)\/store\/categories/,
	BRANDS: /(.*)\/store\/brands/,
	SUPPLIERS: /(.*)\/store\/suppliers/,
	WAREHOUSES: /(.*)\/store\/warehouses/,
}

export { HIB }
