import ReactDOMServer from "react-dom/server"
import { LocaleNamespace } from "config/intl/helpers"
import { variabilize } from "components/containers/ContextEditor/ContextEditor.helpers"
import { UseTranslationOptions } from "react-i18next"

/**
 * Return footer
 * @param store {object}
 * @param t {object}
 * @returns string
 */
export function buildFooter(
	store: { [key: string]: any },
	t: (path: string, options?: UseTranslationOptions, prePath?: string) => string
): string | void {
	if (!store.user.company) return

	const content = ReactDOMServer.renderToStaticMarkup(
		<span style={{ fontSize: "0.8em" }}>
			{store.user.company.label} - SIRET: #(user.company.siret | empty "N\A") - SIREN: #(user.company.siren |
			empty "N\A") - TVA: #(user.company.tva | empty "N\A") - RCS: #(user.company.rcs | empty "N\A") - Capital
			social: #(user.company.capitalStock | empty "N\A")
		</span>
	)

	return variabilize(content, store, t)
}

/**
 * Return laboratory header
 * @param store {object}
 * @param t {object}
 * @returns string
 */
export function buildLaboratoryHeader(
	store: { [key: string]: any },
	t: (path: string, options?: UseTranslationOptions, prePath?: string) => string
): string | void {
	if (!store.laboratory) return

	const content = ReactDOMServer.renderToStaticMarkup(
		<ul style={{ listStyle: "none", padding: "unset" }}>
			<li style={{ marginBottom: 5 }}>
				<strong>#(laboratory.label | empty "N\A")</strong>
			</li>
			<li style={{ marginBottom: 5 }}>#(laboratory.adress | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(laboratory.city | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(laboratory.cpo | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(laboratory.email | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(laboratory.phone | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(laboratory.finess | empty "N\A")</li>
		</ul>
	)

	return variabilize(content, store, t)
}

/**
 * Return patient header
 * @param store {object}
 * @param t {object}
 * @returns string
 */
export function buildPatientHeader(
	store: { [key: string]: any },
	t: (path: string, options?: UseTranslationOptions, prePath?: string) => string
): string | void {
	if (!store.patient) return

	const content = ReactDOMServer.renderToStaticMarkup(
		<ul style={{ listStyle: "none", padding: "unset" }}>
			<li style={{ marginBottom: 5 }}>#(patient.firstName | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(patient.lastName | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(patient.email | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(patient.adress | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(patient.cpo | empty "N\A")</li>
			<li style={{ marginBottom: 5 }}>#(patient.city | empty "N\A")</li>
			<li>
				<strong>N° Sécurité sociale :</strong>#(patient.secu | empty "N\A")
			</li>
		</ul>
	)

	return variabilize(content, store, t)
}

/**
 * Return break page
 * @returns string
 */
export function buildBreakPage(): string | undefined {
	return ReactDOMServer.renderToStaticMarkup(
		<div className="mce-pagebreak" style={{ breakBefore: "page" }}>
			<p className="no-print" style={{ textAlign: "center" }}>
				-------------------------------- SAUT DE PAGE --------------------------------
			</p>
		</div>
	)
}

/**
 * Return company logo
 * @param store {object}
 * @param t {object}
 * @returns string
 */
export function buildCompanyLogo(
	store: { [key: string]: any },
	t: (path: string, options?: UseTranslationOptions, prePath?: string) => string
): string | void {
	if (!store?.user?.company?.logo) return
	return ReactDOMServer.renderToStaticMarkup(
		<img
			src={store.user.company.logo}
			alt={t(
				`${LocaleNamespace.Context}:__etapes.__printQuote.__notes.__editor.context-menu.Company logo on the sales invoice`
			)}
			data-company-logo={true}
			className={"reporting-template__variable"}
			width="150"
			style={{ objectFit: "contain", height: "auto" }}
		/>
	)
}
