import { CSSProperties, forwardRef } from "react"
import * as React from "react"
import PhoneInput from "react-phone-number-input/max"
import fr from "react-phone-number-input/locale/fr.json"
import "react-phone-number-input/style.css"
import { Override } from "@audiowizard/common"

type InputComponentProps = {
	inputId: string
	name: string
	required: boolean
	inputClassName?: string
	inputStyle?: CSSProperties
}
const InputComponent = forwardRef<HTMLInputElement, InputComponentProps>(function InputComponent(
	{ inputId, name, required, inputClassName, inputStyle, ...props }: InputComponentProps,
	ref
) {
	return (
		<input
			{...props}
			ref={ref}
			className={inputClassName}
			style={inputStyle}
			id={inputId}
			name={name}
			required={required}
		/>
	)
})

type CustomPhoneInputProps = Override<
	React.ComponentPropsWithoutRef<typeof PhoneInput>,
	{
		id?: string
		name: string
		label?: string
		placeholder?: string
		error?: string
		required?: boolean
		className?: string
		style?: CSSProperties
		inputClassName?: string
		inputStyle?: CSSProperties
		international?: boolean
	}
>

function CustomPhoneInput({
	id,
	name,
	label,
	placeholder,
	value,
	onChange,
	error,
	required = false,
	className = "",
	style,
	inputClassName = "form-control",
	inputStyle,
	international = false,
	...props
}: CustomPhoneInputProps): JSX.Element {
	const inputId = id ?? name

	return (
		<div className={"form-group custom-phone-input " + className} style={style}>
			<label htmlFor={inputId}>{label || placeholder}</label>
			<PhoneInput
				limitMaxLength
				defaultCountry="FR"
				labels={fr}
				international={international}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				{...props}
				inputComponent={InputComponent as any} // as any pour éviter des erreurs au niveau des props
				numberInputProps={{ inputId, required, inputClassName, inputStyle }}
				name={name} // name est passé à InputComponent par les props de react-phone-number-input
			/>
			{error && <p className="invalid-feedback d-block">{error}</p>}
		</div>
	)
}

export default CustomPhoneInput
