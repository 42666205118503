import { AxiosPromise } from "axios"
import { URLSearchParamsInitializer } from "constants/types/url"
import { BackendClient } from "services/clients/axios/backendClient"

/**
 * Fetch a collection of documents
 * @param {URLSearchParamsInitializer} searchParams - URL query filter
 */
export function fetchDocuments(searchParams: URLSearchParamsInitializer): AxiosPromise {
	const url = searchParams ? `documents/?${new URLSearchParams(searchParams).toString()}` : "documents"
	return BackendClient.get(url).then((res) => res?.data?.["hydra:member"])
}
