/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import { Fragment, useState, useEffect, useRef } from "react"
import _ from "lodash"
import axios from "axios"
import { StringUpperCFirst } from "../../services/functions"
import API from "../../services/API"
import dayjs from "dayjs"
import API_Hiboutik from "../../services/API_Hiboutik"
import { useHistory } from "react-router-dom"

const placeholder = {
	PATIENT: "Rechercher par nom, téléphone, email, adresse...",
	PRODUCT: "Rechercher par nom de produit...",
}

const OmniSearch = ({ id, onClick, arrowNav }) => {
	const [searchQuery, setSearchQuery] = useState("")
	const [omnisearchVisible, setOmnisearchVisible] = useState(false)
	const [resultList, setResultList] = useState([])
	const [currentSelection, setCurrentSelection] = useState(arrowNav ? 0 : -1)
	const [, setLoading] = useState(true)
	const cancelTokenSourceRef = useRef(null) // Pour annuler les requêtes
	const [recentsPatients, setRecentsPatients] = useState([])

	const [searchBy, setSearchBy] = useState("PATIENT")

	const timeoutSearchProduct = useRef(null) // Pour debounce searchProduct

	const history = useHistory()

	useEffect(() => {
		setSearchQuery("")
		setResultList([])
	}, [searchBy])

	useEffect(() => {
		// Accès au DOM direct entre composants, beurk
		const patientSearchContainer = document.querySelector("#patientSearch")

		const observer = new MutationObserver((mutationList) => {
			const mutation = mutationList.find((f) => f.type === "attributes" && f.attributeName === "class")
			setOmnisearchVisible(mutation?.target?.classList.contains("show"))
		})
		observer.observe(patientSearchContainer, {
			childList: false,
			attributes: true,
			subtree: true,
		})

		return () => {
			observer.disconnect()
		}
	}, [])

	useEffect(() => {
		if (!omnisearchVisible) return
		setSearchBy("PATIENT")
		setSearchQuery("")
		setCurrentSelection(arrowNav ? 0 : -1)
		;(async function () {
			setLoading(true)
			try {
				let recents = await API.findAll("PATIENTS_API", "?order[id]=desc")
				let patientIds = window.localStorage.getItem("visitedPatients")

				if (patientIds) {
					patientIds = JSON.parse(patientIds)
					recents = _.sortBy(recents, (patient) => {
						const index = patientIds.indexOf(patient.id)
						return index === -1 ? recents.length + 1 : index
					})
				}

				setRecentsPatients(recents)
				setResultList(recents)
			} catch (e) {
				console.error(e)
			} finally {
				setLoading(false)
			}
		})()
	}, [omnisearchVisible, arrowNav])

	const updateSelection = (newSelection) => {
		setCurrentSelection(_.clamp(newSelection, 0, resultList.length - 1)) // Garde la sélection dans la liste
	}

	const handleKeys = (e) => {
		switch (e.key) {
			case "ArrowDown":
				updateSelection(currentSelection + 1)
				break
			case "ArrowUp":
				updateSelection(currentSelection - 1)
				break
			case "Enter":
				if (searchBy === "PATIENT") {
					onClick({ type: "PATIENT", ...resultList[currentSelection] })
				} else {
					history.push("/stock/fiche-produit/" + resultList[currentSelection].product_id)
				}
				updateSelection(0)
				break
			case "Escape":
				document.getElementById("omnisearch-close").click()
				setCurrentSelection(arrowNav ? 0 : -1)
				break
			default:
				break
		}
	}

	const searchProduct = (value) => {
		setSearchQuery(value)
		if (value.length < 3) {
			return
		}
		clearTimeout(timeoutSearchProduct.current)
		timeoutSearchProduct.current = setTimeout(async () => {
			try {
				setLoading(true)
				let result = await API_Hiboutik.searchProductByName(value)
				setResultList(result)
			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false)
			}
		}, 400)
	}

	const searchPatient = async (value) => {
		setSearchQuery(value)
		if (!value.length) {
			setResultList(recentsPatients)
			return
		}
		try {
			// Annule la requête précédente pour éviter des race conditions
			cancelTokenSourceRef.current?.cancel()
			cancelTokenSourceRef.current = axios.CancelToken.source()

			const searchParams = new URLSearchParams()
			for (const word of value.split(/\s+/)) {
				searchParams.append("omnisearch[]", word)
			}

			const patients = await API.findAll("PATIENTS_API", `?${searchParams}`, false, {
				cancelToken: cancelTokenSourceRef.current.token,
			})
			setResultList(patients)
		} catch (e) {
			setResultList(recentsPatients)
			console.error(e)
		} finally {
			setLoading(false)
		}
	}

	const resultDisplay = (data) => {
		switch (searchBy) {
			case "PATIENT":
				return (
					<button
						type="button"
						className="btn btn-link p-0"
						data-action="omnisearch-close"
						data-target={"#" + id}
						onClick={() => onClick({ type: "PATIENT", ...data })}>
						{data.lastName} {StringUpperCFirst(data.firstName)}{" "}
						{data.birthDate ? `(${dayjs().diff(data.birthDate, "year")} ans)` : ""}
					</button>
				)
			case "PRODUCT":
				return (
					<button
						type="button"
						className="btn btn-link p-0"
						data-action="omnisearch-close"
						data-target={"#" + id}
						onClick={() => history.push("/stock/fiche-produit/" + data.product_id)}>
						{data.product_model}
					</button>
				)
			default:
				return <></>
		}
	}

	return (
		<div id={id} className="omnisearch" onKeyDown={arrowNav ? handleKeys : undefined}>
			<div className="container">
				<form className="omnisearch-form">
					<div className="form-group">
						<div className="input-group input-group-merge">
							<div className="input-group-prepend mr-2">
								<select
									className="form-control"
									value={searchBy}
									onChange={(e) => setSearchBy(e.target.value)}>
									<option value="PATIENT">Patient</option>
									<option value="PRODUCT">Produit</option>
								</select>
							</div>

							<input
								value={searchQuery}
								id="omnisearch-input"
								onChange={(event) => {
									const value = event.target.value
									switch (searchBy) {
										case "PATIENT":
											searchPatient(value)
											break
										case "PRODUCT":
											searchProduct(value)
											break
										default:
											searchPatient(value)
											break
									}
								}}
								type="text"
								name="firstName"
								className="form-control"
								placeholder={placeholder?.[searchBy] || "Rechercher..."}
								autoComplete={"off"}
							/>
							<div className="input-group-append">
								<span className="input-group-text">
									<button
										id="omnisearch-close"
										className="btn btn-sm"
										data-action="omnisearch-close"
										data-target={"#" + id}>
										<i className="fad fa-search fa-2x" />
									</button>
								</span>
							</div>
						</div>
					</div>
				</form>
				<div className="omnisearch-suggestions">
					<div className="row">
						<div className="col-sm-12">
							<ul className="list-unstyled mb-0" style={{ minHeight: "384px" }}>
								{resultList &&
									resultList.map((p, key) => (
										<Fragment key={key}>
											{key <= 10 && (
												<li className={key === currentSelection ? "selected" : ""}>
													{resultDisplay(p)}
												</li>
											)}
										</Fragment>
									))}
								{resultList.length === 0 && <>Aucun résultat, essayez une nouvelle recherche !</>}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

OmniSearch.propTypes = {
	id: PropTypes.string.isRequired,
	placeholderFirstName: PropTypes.string,
	placeholderLastName: PropTypes.string,
	handleClickElement: PropTypes.func,
	titleTextSearch: PropTypes.string.isRequired,
	setElement: PropTypes.object,
	arrowNav: PropTypes.bool,
}

export default OmniSearch
