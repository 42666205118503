/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo } from "react"
import { WeekView } from "@devexpress/dx-react-scheduler-material-ui"
import dayjs from "dayjs"

const TableCell = ({
	currentlyEditedAppointment,
	appointmentFromScheduleSelector,
	items,
	setItems,
	setCurrentlyEditedAppointment,
	children,
	props,
	schedulesTypes,
	currentAttendences,
	bankHolliday,
}) => {
	const [dynclass, setDynclass] = useState("")
	const [tooltip, setTooltip] = useState("")

	useMemo(() => {
		const startDate = dayjs(props.startDate)
		const bankDay = bankHolliday[startDate.format("YYYY-MM-DD")]
		const dayName = startDate.format("dddd")
		const startTime = +dayjs(props.startDate).format("HHmm") // +dayjs().format("HHmm") is done to discard date part and only compare hours and minutes
		const endTime = +dayjs(props.endDate).format("HHmm")

		if (bankDay) {
			setDynclass((old) => `${old} bank-day`)
			setTooltip(`Jour férié : ${bankDay}`)
		}
		const laboratoryId = props?.groupingInfo?.find((f) => f?.fieldName === "laboratoryId")?.id || null
		const userId = props?.groupingInfo?.find((f) => f?.fieldName === "userId")?.id || null

		if (
			!currentAttendences.filter(
				(f) =>
					f.day === dayName.toUpperCase() &&
					startTime >= +dayjs(f.timeStart, "HH:mm").format("HHmm") &&
					endTime <= +dayjs(f.timeEnd, "HH:mm").format("HHmm") &&
					(laboratoryId ? +f.laboratory.id === +laboratoryId : 1) &&
					(userId ? +f.user.id === +userId : 1)
			).length
		) {
			setDynclass((old) => `${old} notworking-cell`)
			setTooltip("Pas de jour de présence dans ce laboratoire")
		}
	}, [])

	const getClassName = () => {
		const current = dayjs()
		const cell = dayjs(props.startDate)
		let classes = ""
		if (current.format("DDHH") === cell.format("DDHH")) classes += "SCROLL-HERE"
		if (current.hour() === cell.hour()) classes += " current-hour-cell"
		return classes
	}

	return (
		<WeekView.TimeTableCell
			{...props}
			className={getClassName()}
			onClick={() => {
				const laboratoryId = props?.groupingInfo?.find((f) => f?.fieldName === "laboratoryId")?.id || null
				const userId = props?.groupingInfo?.find((f) => f?.fieldName === "userId")?.id || null

				if (!currentlyEditedAppointment?.id && !appointmentFromScheduleSelector?.id) {
					const newAppointment = items.findIndex((e) => e.id === "NEW_APPOINTMENT")

					const time_range = schedulesTypes?.[appointmentFromScheduleSelector?.status]?.time || 30
					const new_end_date = dayjs(props.startDate).add(time_range, "minutes").toDate()

					if (newAppointment === -1) {
						setItems((old) => [
							...old,
							{
								id: "NEW_APPOINTMENT",
								type: "SELECTION",
								title: "Nouveau rendez-vous",
								startDate: props.startDate,
								endDate: new_end_date,
								laboratoryId: [laboratoryId],
								userId: [userId],
							},
						])
					} else {
						const tmp = [...items]
						tmp[newAppointment].startDate = props.startDate
						tmp[newAppointment].endDate = new_end_date
						tmp[newAppointment].laboratoryId = [laboratoryId]
						tmp[newAppointment].userId = [userId]
						setItems(tmp)
					}
				} else {
					const scheduleId = currentlyEditedAppointment?.id || appointmentFromScheduleSelector?.id
					const appointment = items.findIndex((e) => e.id === scheduleId)
					if (appointment !== -1) {
						try {
							const tmp = [...items]
							const start =
								currentlyEditedAppointment?.startDate || appointmentFromScheduleSelector?.startDate
							const end = currentlyEditedAppointment?.endDate || appointmentFromScheduleSelector?.endDate
							const diff = dayjs(end).diff(dayjs(start), "minute")
							const newStart = props.startDate
							const newEnd = dayjs(props.startDate).add(diff, "minute").toDate()

							tmp[appointment].startDate = newStart
							tmp[appointment].endDate = newEnd
							setCurrentlyEditedAppointment((old) => ({
								...old,
								startDate: newStart,
								endDate: newEnd,
								noSaveOrigin: true,
							}))
							setItems(tmp)
						} catch (e) {
							console.error(e)
						}
					}
				}
			}}>
			<span
				data-toggle="tooltip"
				data-placement="bottom"
				title={tooltip}
				className={`tablecell-agenda ${dynclass}`}>
				{children}
			</span>
		</WeekView.TimeTableCell>
	)
}

export default TableCell
