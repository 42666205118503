/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { Select } from "antd"
import API from "../../services/API"

const { Option } = Select

function AsyncLaboratorySelect({
	setData,
	data,
	className = "",
	size = "medium",
	placeholder = "Sélectionner un ou des laboratoires",
	mode = "multiple",
	disabled = false,
	onClear = null,
	allowClear = true,
	warehouseOnly = false,
}) {
	const [dataList, setDataList] = useState([])
	const [list, setList] = useState([])
	const [loading, setLoading] = useState(false)

	const populateOptions = (data) => {
		let options = []
		for (const el of data) {
			let label = el.label

			options.push({
				detail: el,
				value: +el.id,
				label,
			})
		}
		setDataList(options)
	}

	async function handleSearch(p) {
		try {
			setLoading(true)
			let results = await API.findAll("LABORATORIES_API", p ? `?label=${p}` : "")
			if (warehouseOnly) {
				results = results.filter((f) => f?.warehouseIdHiboutik)
			}
			populateOptions(results)
			setList(results)
			setLoading(false)
		} catch (e) {
			console.error("=> Error : ", e)
		}
	}

	useEffect(() => {
		handleSearch("")
	}, [])

	return (
		<>
			<Select
				mode={mode}
				allowClear={allowClear}
				filterOption={false}
				loading={loading}
				disabled={disabled}
				className={className}
				notFoundContent={"Aucun laboratoire"}
				placeholder={placeholder}
				size={size}
				maxTagCount={2}
				value={(function () {
					if (mode === "multiple") return [...(data || [])].map((v) => +v.id)
					else return data
				})()}
				onClear={onClear}
				onSelect={(e) => {
					if (mode === "multiple") {
						const tmp = [...(data || [])]
						const l = list.find((f) => +f.id === +e)
						if (l) tmp.push({ ...l })
						setData(tmp)
					} else {
						setData(e, dataList.find((f) => +f.value === +e)?.detail ?? {})
					}
				}}
				onDeselect={(e) => {
					if (mode === "multiple") {
						let tmp = [...data]
						const id = tmp.findIndex((f) => +f.id === +e)
						if (id !== -1) tmp.splice(id, 1)
						setData(tmp)
					} else {
						setData(e, dataList.find((f) => +f.value === +e)?.detail ?? {})
					}
				}}
				onChange={(e) => {
					const details = dataList.find((f) => +f.value === +e)

					if (typeof setData === "function" && mode !== "multiple") setData(e, details?.detail)
				}}>
				{dataList.map((d) => (
					<Option value={d.value} key={d.value}>
						{d.label}
					</Option>
				))}
			</Select>
		</>
	)
}

export default AsyncLaboratorySelect
