export const itemsAdressePatient = [
	{ id: 0, name: "adress", placeholder: "Adresse" },
	{ id: 1, name: "cpo", placeholder: "Code Postal" },
	{ id: 2, name: "city", placeholder: "Ville" },
]

export const styleAdresseInput = {
	display: "block",
	height: "50px",
	width: "100%",
	padding: "0.75rem 1.25rem",
	fontSize: "16px",
	fontWeight: "400",
	lineHeight: "1.5",
	color: "#8492a6",
	backgroundColor: "#fff",
	backgroundClip: "padding-box",
	border: "1px solid #e0e6ed",
	borderRadius: "0.25rem",
	boxShadow: "inset 0 1px 1px rgba(31, 45, 61, 0.075)",
	transition: "all 0.2s ease",
	marginRight: "10px",
}
