import { Laboratory, User } from "@audiowizard/common"
import { Dispatch, MutableRefObject, SetStateAction } from "react"
import * as Types from "./Types"

type SelectFilterByDataProps = {
	defaultOption: string
	filterKey: string
	databaseKey: string
	keyId: string
	keyLabel: string
}

type DisplayedColumnChoiceProps = {
	text: string
	columnKey: string | string[]
}

type FiltersProps = {
	loading: boolean
	user: User
	laboratory: Laboratory
	filters: Types.Filters
	setFilters: Dispatch<SetStateAction<Types.Filters>>
	databaseData: MutableRefObject<Types.DatabaseData>
}

const Filters = ({ loading, user, laboratory, filters, setFilters, databaseData }: FiltersProps): JSX.Element => {
	const switchColumnDisplay = (hide: boolean, columnKey: string | string[]): void => {
		let currentDisplayed = [...filters.notDisplayedColumn]

		const hideOrShow = (key: string): void => {
			const isHidden = currentDisplayed.includes(key)
			if (hide && !isHidden) {
				currentDisplayed.push(key)
			} else if (!hide && isHidden) {
				currentDisplayed = currentDisplayed.filter((e) => e !== key)
			}
		}

		if (Array.isArray(columnKey)) {
			for (const key of columnKey) {
				hideOrShow(key.trim())
			}
		} else {
			hideOrShow(columnKey.trim())
		}
		setFilters((old) => ({ ...old, notDisplayedColumn: currentDisplayed }))
	}

	const DisplayedColumnChoice = ({ text, columnKey }: DisplayedColumnChoiceProps): JSX.Element => {
		return (
			<select
				disabled={loading}
				className={"form-control form-control-sm mt-2"}
				value={(() => {
					if (Array.isArray(columnKey)) {
						return columnKey.some((key) => filters.notDisplayedColumn.includes(key)) ? 0 : 1
					} else {
						return filters.notDisplayedColumn.includes(columnKey) ? 0 : 1
					}
				})()}
				onChange={(e) => {
					switchColumnDisplay(+e.target.value === 0, columnKey)
				}}>
				<option value={1}>Afficher {text}</option>
				<option value={0}>Ne pas afficher {text}</option>
			</select>
		)
	}

	const SelectFilterByData = ({
		defaultOption,
		filterKey,
		databaseKey,
		keyId,
		keyLabel,
	}: SelectFilterByDataProps): JSX.Element => {
		const sortData = (a: any, b: any): boolean => {
			return (a?.[keyLabel] ?? "").localeCompare(b?.[keyLabel] ?? "")
		}
		return (
			<select
				disabled={loading}
				onChange={(e) => {
					const value = e.target.value
					if (+value === 0) {
						setFilters((old) => ({ ...old, [filterKey]: null }))
					} else {
						setFilters((old) => ({ ...old, [filterKey]: +value }))
					}
				}}
				value={
					//@ts-ignore
					filters?.[filterKey] ?? 0
				}
				className={"form-control form-control-sm mt-2"}>
				<option value={0}>{defaultOption}</option>
				{
					//@ts-ignore
					databaseData.current?.[databaseKey]?.sort(sortData).map((element, key) => (
						<option value={element?.[keyId]} key={key}>
							{element?.[keyLabel]}
						</option>
					))
				}
			</select>
		)
	}

	return (
		<div className="filter-container">
			<div className="row ">
				<div className="col">
					<div>Affichage des appareils en dépôts en stock</div>
					<select
						disabled={loading}
						className={"form-control form-control-sm mt-2"}
						value={filters.depot}
						onChange={(e) => {
							const value = e.target.value
							if (value === "NONE") {
								switchColumnDisplay(true, ["depotStartDate", "creditReceivedDate", "returnedDate"])
							} else {
								switchColumnDisplay(false, ["depotStartDate", "creditReceivedDate", "returnedDate"])
							}
							setFilters((old) => ({ ...old, depot: value }))
						}}>
						<option value={"ALL"}>Afficher les appareils en dépôt et ferme</option>
						<option value={"ONLY_DEPOSIT"}>Seulement les appareils en dépôt</option>
						<option value={"ONLY_MISSED"}>Seulement les dépôts dépassé</option>
						<option value={"NONE"}>Ne pas afficher les appareils en dépôt</option>
					</select>

					<div className={"mt-2"}>Afficher les patients appareillés</div>
					<select
						disabled={loading}
						className={"form-control form-control-sm mt-2"}
						value={filters.equipment}
						onChange={(e) => {
							const value = e.target.value
							setFilters((old) => ({ ...old, equipment: value }))
						}}>
						<option value={"ALL"}>Tout afficher</option>
						<option value={"ONLY_STOCK"}>Uniquement sur les produits en stock</option>
						<option value={"NONE"}>Non</option>
					</select>
					<DisplayedColumnChoice text={"les dates d'appareillage"} columnKey={["tryoutDate", "soldDate"]} />
				</div>
				<div className="col">
					<DisplayedColumnChoice text={"la marque"} columnKey={"productBrand"} />
					<DisplayedColumnChoice text={"le fournisseur"} columnKey={"productSupplier"} />
					<DisplayedColumnChoice text={"la catégorie"} columnKey={"productCategory"} />
					<DisplayedColumnChoice text={"la déclinaison"} columnKey={"sizeName"} />
				</div>
				<div className="col">
					<SelectFilterByData
						filterKey={"brandId"}
						defaultOption={"Filtrer par marque... (tout voir)"}
						databaseKey={"brands"}
						keyId={"brand_id"}
						keyLabel={"brand_name"}
					/>
					<SelectFilterByData
						filterKey={"supplierId"}
						defaultOption={"Filtrer par fournisseur... (tout voir)"}
						databaseKey={"suppliers"}
						keyId={"supplier_id"}
						keyLabel={"supplier_name"}
					/>
					<SelectFilterByData
						filterKey={"categoryId"}
						defaultOption={"Filtrer par catégorie... (tout voir)"}
						databaseKey={"categories"}
						keyId={"category_id"}
						keyLabel={"category_name"}
					/>
					<select
						disabled={loading}
						value={laboratory.warehouseIdHiboutik ?? 1}
						className={"form-control form-control-sm mt-2"}
						onChange={(e) => {
							const warehouseId = +e.target.value
							setFilters((old) => ({ ...old, warehouseId: warehouseId }))
						}}>
						{user.laboratories
							?.filter((l) => l.warehouseIdHiboutik)
							.map((lab, key) => (
								<option value={lab.warehouseIdHiboutik} key={key}>
									{lab.label}
								</option>
							))}
					</select>
				</div>
			</div>
		</div>
	)
}

export default Filters
