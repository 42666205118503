import { useTranslation as useI18nTranslation, UseTranslationOptions } from "react-i18next"
import { LocaleNamespace } from "config/intl/helpers"

export function useTranslation(
	ns: LocaleNamespace | (LocaleNamespace | undefined)[],
	prePath?: string,
	options?: UseTranslationOptions
): (path: string, options?: UseTranslationOptions, prePath?: string | undefined) => string {
	// @ts-ignore
	const { t } = useI18nTranslation(ns, options)
	const initialPrePath = prePath

	// TODO: externalizes if needed
	function translation(path: string, options?: UseTranslationOptions, prePath?: string | undefined): string {
		const _pre = prePath || initialPrePath ? `${prePath || initialPrePath}.` : ""

		// Check if has full path with another namespace (controlled path)
		if (/[a-z_-]*:/.test(path)) return t(path, options)

		return t(`${_pre}${path}`, options)
	}

	return translation
}
