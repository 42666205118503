import AuthContext from "contexts/AuthContext"
import { Dispatch, SetStateAction, useContext } from "react"
import { FiltersType } from "./InventoryList"

type FiltersProps = {
	setFilters: Dispatch<SetStateAction<FiltersType>>
	filters: FiltersType
}

const Filters = ({ setFilters, filters }: FiltersProps): JSX.Element => {
	const { user } = useContext(AuthContext)

	return (
		<>
			<div className="cardtabs-subtitle">Filtres</div>
			<div className="row">
				<div className="col">
					<label>Par laboratoire</label>
					<select
						onChange={(evt) => {
							const value = evt.target.value
							setFilters((old) => ({
								...old,
								laboratory: user.laboratories?.find((l) => l["@id"] === value),
							}))
						}}
						value={filters?.laboratory?.["@id"]}
						className="form-control ">
						<option value="null">Tout voir</option>
						{(user.laboratories ?? [])
							.filter((f) => f.warehouseIdHiboutik)
							.map((v, k) => (
								<option value={v["@id"]} key={k}>
									{v.label}
								</option>
							))}
					</select>
				</div>
				<div className="col">
					<label>Par état</label>
					<select
						onChange={(evt) => {
							const value = evt.target.value
							let isValidated: boolean

							if (value === "true") isValidated = true
							if (value === "false") isValidated = false

							setFilters((old) => ({
								...old,
								isValidated,
							}))
						}}
						value={filters?.laboratory?.["@id"]}
						className="form-control ">
						<option value="null">Tout voir</option>
						<option value="true">Validé</option>
						<option value="false">En attente</option>
					</select>
				</div>
			</div>
		</>
	)
}

export default Filters
