/* eslint-disable @typescript-eslint/ban-types */
import { HiboutikProductEntity, Laboratory } from "@audiowizard/common"
import { Table } from "antd"
import { ColumnDataType } from "components/BootstrapTable"
import useEffectAsync from "components/Hooks/useEffectAsync"
import dayjs from "dayjs"
import _, { cloneDeep } from "lodash"
import { useEffect, useState } from "react"
import uuid from "react-uuid"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import API from "services/API"
import { partialSearch } from "services/functions"
import { getAvailableBrands, getAvailableCategories, getAvailableSuppliers } from "../Shared/Utils"
import Filters from "./Filters"

export type ModalProps = {
	visible: boolean
	setVisible: Function
	setSelectedProducts: Function
	defaultSupplier?: number
	laboratory: Laboratory
}

const formatWithAllSerialNumbers = (data: HiboutikProductEntity[]): any[] => {
	const tmp: any[] = []
	const productsClone = cloneDeep(data)

	for (const product of productsClone) {
		if (!product.stockManagement || !product?.stockAvailable?.length) continue
		for (const available of product.stockAvailable) {
			delete product.stockAvailable
			if (!available.logisticProduct || available.logisticProduct?.productReturnForm) continue
			tmp.push({ ...product, ...available, key: uuid() })
		}
	}
	return tmp
}

const ModalSearchProducts = ({
	visible,
	setVisible,
	setSelectedProducts,
	defaultSupplier,
	laboratory,
}: ModalProps): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true)
	const [data, setData] = useState<any[]>([])
	const [displayedData, setDisplayedData] = useState<any[]>([])

	// temporary
	const [selectedRows, setSelectedRows] = useState<any[]>([])

	const [filters, setFilters] = useState<Record<string, any>>({
		warehouseId: laboratory.warehouseIdHiboutik,
		supplierId: defaultSupplier,
	})

	useEffect(() => {
		setFilters((old) => ({ ...old, supplierId: defaultSupplier }))
	}, [defaultSupplier])

	useEffectAsync(async () => {
		if (!visible) return
		try {
			setLoading(true)
			const result = await API.findAll<HiboutikProductEntity[]>(
				"STOCK_AVAILABLE_API",
				`?warehouseId=${filters?.warehouseId}`
			)

			setData(formatWithAllSerialNumbers(result))

			getAvailableCategories(result)
			getAvailableSuppliers(result)
			getAvailableBrands(result)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}, [visible, filters.warehouseId])

	useEffect(() => {
		let filteredData = data.filter(
			(product) =>
				(filters?.brandId ? +product.brandId === +filters?.brandId : 1) &&
				(filters?.categoryId ? +product.categoryId === +filters?.categoryId : 1) &&
				(filters?.supplierId ? +product.supplierId === +filters?.supplierId : 1) &&
				!product?.logisticProduct?.productReturnForm
		)

		if (filters.state === "DEPOT" || filters.state === "FERME") {
			filteredData = filteredData.filter((product) =>
				filters.state === "FERME" ? !product.logisticProduct.isDepot : product.logisticProduct.isDepot
			)
		}

		if (filters.state === "DEPOT_DEPASSE") {
			filteredData = filteredData.filter(
				(product) =>
					product.logisticProduct.isDepot &&
					product.logisticProduct.depotDateEnd &&
					dayjs(product.logisticProduct.depotDateEnd)?.isBefore(dayjs())
			)
		}

		if (filters.serialNumber) {
			filteredData = filteredData.filter((product) => partialSearch(product.serialNumber, filters.serialNumber))
		}

		if (filters.logisticFormLabel) {
			filteredData = filteredData.filter(
				(product) =>
					product.logisticProduct &&
					partialSearch(product.logisticProduct?.logistic?.label, filters.logisticFormLabel)
			)
		}

		if (filters.depositDate) {
			filteredData = filteredData.filter(
				(product) => product.depotDateEnd && dayjs(product.depotDateEnd).isBefore(dayjs(filters.depositDate))
			)
		}

		filteredData = filteredData.filter((product) => !product?.patientEquipment)

		setDisplayedData(filteredData)
	}, [data, filters])

	const isAlreadySelected = (row: any): boolean => {
		return selectedRows.find((f) => _.isEqual(f, row))
	}

	const columns: ColumnDataType[] = [
		{
			title: "Fournisseur",
			dataIndex: "supplierName",
			render: (supplierName: string) =>
				supplierName ?? <span style={{ opacity: "0.75" }}>Pas de fournisseur</span>,
		},
		{
			title: "Catégorie",
			dataIndex: "categoryName",
		},
		{
			title: "Nom",
			dataIndex: "model",
			render: (model: string, product: Record<string, any>) => (
				<>
					<div className="font-weight-bold">{model}</div>
					<div style={{ opacity: "0.75" }}>{product.sizeName ?? "Pas de déclinaison"}</div>
				</>
			),
		},

		{
			title: "Numéro de série / quantité",
			dataIndex: "serialNumber",
		},
		{
			title: "Status",
			dataIndex: "logisticProduct",
			render: (lp) => {
				const status = []
				if (lp.depotState === "EN_DEPOT" || lp.isDepot) status.push("En dépôt")
				else status.push("En ferme")
				if (dayjs(lp?.depotDateEnd).isBefore(dayjs())) {
					status.push("dépôt dépassé")
				}
				return status.join(", ")
			},
		},
		{
			title: "Date de fin de dépôt",
			dataIndex: "logisticProduct",
			render: (lp) => {
				if (lp?.depotDateEnd) {
					return dayjs(lp?.depotDateEnd).format("DD/MM/YYYY")
				}
				return null
			},
		},
		{
			title: "Action",
			dataIndex: "",
			render: (_data, row) => (
				<button
					type="button"
					className={
						"btn btn-sm text-lg btn-block p-0 pl-1 pr-1 " +
						(isAlreadySelected(row) ? "btn-primary" : "btn-outline-primary")
					}
					onClick={() => {
						const id = selectedRows.findIndex((f) => _.isEqual(f, row))
						if (id !== -1) {
							setSelectedRows((old) => {
								old.splice(id, 1)
								return [...old]
							})
						} else {
							setSelectedRows((old) => [...old, row])
						}
					}}>
					{isAlreadySelected(row) ? "-" : "+"}
				</button>
			),
		},
	]

	const getString = (() => {
		if (selectedRows.length === 0) {
			return ""
		} else if (selectedRows.length === 1) {
			return "un produit"
		} else {
			return `${selectedRows.length} produits`
		}
	})()

	const ValidateButton = (): void => {
		setSelectedProducts(selectedRows)
		setVisible(false)
	}
	const CancelButton = (): void => {
		setSelectedRows([])
		setVisible(false)
	}

	return (
		<Modal isOpen={visible} size="xl">
			<ModalHeader>Rechercher un ou des produit(s)</ModalHeader>
			<ModalBody>
				<div className="cardtabs-subtitle">Filtres</div>
				<Filters data={data} filters={filters} setFilters={setFilters} />
				<div className="cardtabs-subtitle">Liste des produits</div>
				<Table
					dataSource={displayedData}
					columns={columns as any[]}
					loading={loading}
					pagination={{ pageSize: 5 }}
				/>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={CancelButton}>
					Annuler
				</Button>
				<Button
					color="primary"
					style={{ minWidth: "200px" }}
					onClick={ValidateButton}
					disabled={selectedRows.length === 0}>
					Ajouter {getString}{" "}
				</Button>
			</ModalFooter>
		</Modal>
	)
}

export default ModalSearchProducts
