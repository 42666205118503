import dayjs from "dayjs"
import { Dispatch, MutableRefObject, SetStateAction } from "react"

export const exportToCsv = (
	products: any[],
	setCsvConfig: Dispatch<SetStateAction<Record<string, any>>>,
	csvLinkRef: MutableRefObject<any>
): void => {
	let headers: any[] = []

	headers = [
		{
			label: "Produit",
			key: "model",
		},
		{
			label: "Déclinaison",
			key: "sizeName",
		},
		{
			label: "Patient",
			key: "patientInfos",
		},
		{
			label: "Numéro de série",
			key: "productSerialNumber",
		},
		{
			label: "Quantité",
			key: "newQuantity",
		},
		{
			label: "État",
			key: "state",
		},
	]

	setCsvConfig({
		headers,
		data: products
			.filter((product) => !product?.ghostProduct)
			.map((p) => {
				let state = p.state
				let patientInfos = ""

				if (state === "DIFFERENCE") {
					state = "Différence"
				} else if (state === "NEW") {
					state = "Nouveau produit"
				} else if (state === "NONE") {
					state = "Non précisé"
				}

				if (p.patientEquipment?.length === 1) {
					patientInfos = `En ${p.patientEquipment[0].status} ${p.patientEquipment[0].patient?.lastName} ${p.patientEquipment[0].patient?.firstName}`
				} else if (p.patientEquipment?.length > 1) {
					patientInfos = "Sur plusieurs patients"
				}

				return { ...p, state, patientInfos }
			}),
		filename: "Export_Inventaire_" + dayjs().format("DDMMYYYY_HHmm") + ".csv",
	})

	setTimeout(() => {
		// @ts-ignore
		csvLinkRef.current?.link.click()
	}, 200)
}
